import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { get, isEmpty, set } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import qs from 'qs'

// atoms
import ReactQueryParams from 'react-query-params'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import DefaultModal from '../../components/Modals/DefaultModal'
import ModalSendItemDuplicate from '../../components/Modals/ModalSendItemDuplicate'
import { CHANNEL_TO_CHANGE_SELECTOR } from '../../components/BusinessChannelDropdownMenu'

// utils
import { getReq, postReq } from '../../utils/request'
import { getAccessToken } from '../../utils/auth'
import config from '../../utils/config'
import { setRouteParams, UPOMIENKY_ZOZNAM } from '../../utils/routes'
import { NOTIFICATION_TYPES, UKONY, FILTER_SELECTORS } from '../../utils/enums'
import { history } from '../../utils/history'
import Permissions, { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// actions
import ObchodniPartneriActions from '../../actions/ObchodniPartneri'
import * as TrackingActions from '../../actions/TrackingActions'
import * as HistoriaUkonovActions from '../../actions/UkonyActions'

class UpomienkaDetailPage extends ReactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		tracking: PropTypes.shape(),
		trackingActions: PropTypes.shape(),
		ciselniky: PropTypes.shape(),
		channelToSwitch: PropTypes.shape(),
		forbiddenModal: PropTypes.bool,
		computedMatch: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		} else {
			backUrlLink = setRouteParams(UPOMIENKY_ZOZNAM, get(props, 'interakcia.data.opCislo'))
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			loading: false,
			document: null,
			documentHidden: false,
			upomienka: null,
			redirect: false,
			backUrlLink
		}
	}

	loadDocument = async () => {
		try {
			const { interakcia, computedMatch } = this.props
			const upomienka = await getReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/upomienky/${computedMatch.params.id}`)

			this.setState({
				upomienka: get(upomienka, 'response.obsah')
			})
			if (get(upomienka, 'response.obsah.dokumentId')) {
				const dokumentId = get(upomienka, 'response.obsah.dokumentId')
				const accessToken = getAccessToken()
				this.setState({
					loading: false,
					document: `${config.baseUrl}/api/v0/dokumenty/${dokumentId}?accessToken=${accessToken}`
				})
			} else {
				this.setState({
					loading: false,
					documentFail: true
				})
			}
		} catch (e) {
			this.setState({
				loading: false,
				documentFail: true
			})
		}
	}

	componentDidMount = () => {
		this.setState({
			loading: true
		})

		this.loadDocument()
	}

	confirmModal = () => {
		const { interakcia } = this.props
		const { success, redirect } = this.state

		if (success) {
			this.setState(
				{
					result: null,
					success: false
				},
				() => {
					if (redirect) {
						history.push(setRouteParams(UPOMIENKY_ZOZNAM, get(interakcia, 'data.opCislo')))
					} else {
						this.setState({
							documentHidden: false
						})
					}
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	onSubmitUkonUC05b = async (data) => {
		const { interakcia, t, auth, tracking, trackingActions, historiaUkonovActions, selectedFiltersUkony } = this.props
		const { upomienka } = this.state
		try {
			let notifikacie = {}
			if (get(data, 'address')) {
				set(notifikacie, 'adresyPosta', [get(data, 'address')])
			}

			if (get(data, 'email')) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.EMAIL,
						hodnota: get(data, 'email')
					}
				])
			}

			if (get(data, 'typ') === NOTIFICATION_TYPES.PRINTER) {
				set(notifikacie, 'adresyUri', [
					{
						typ: NOTIFICATION_TYPES.PRINTER,
						poradie: 0
					}
				])
			}

			notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
			const body = {
				ukon: {
					interakciaId: interakcia.data.id,
					riesitel: auth.user.id,
					kanal: auth.businessChannel.actual,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					poznamka: get(data, 'poznamka.value'),
					zacatyOd: dayjs(tracking.startTime).toISOString(),
					vstup: {
						id: get(data, 'ukonVstup.value.value'),
						nazov: get(data, 'ukonVstup.value.label')
					}
				},
				ukonData: {
					opCislo: get(interakcia, 'data.opCislo'),
					upomienkaId: get(upomienka, 'id')
				}
			}
			const res = await postReq(`/api/v0/obchodni-partneri/${interakcia.data.opCislo}/ukon-duplikat-upomienky/vytvorit`, null, body)
			const ukonId = get(res, 'response.obsah.ukon.id')
			const spustitBody = {
				...body.ukon,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				id: ukonId,
				notifikacie
			}
			await postReq(`/api/v0/ukony/${ukonId}/spustit`, null, spustitBody)
			// clear tracking timer
			trackingActions.clearTracking()
			// refetch new data for historia ukonov
			historiaUkonovActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

			this.setState({
				result: t('containers:UpomienkaDetailPage.Duplikát upomienky bol úspešne odoslaný'),
				success: true,
				showModalUkonUC05b: false,
				documentHidden: true
			})
		} catch (e) {
			this.setState({
				result: t('containers:UpomienkaDetailPage.Počas odosielania úkonu nastala chyba'),
				success: false,
				documentHidden: true
			})
		}
	}

	render = () => {
		const { channelToSwitch, forbiddenModal, t } = this.props
		const { result, success, documentFail, document, documentHidden, showModalUkonUC05b, upomienka, backUrlLink, loading } = this.state

		const style = {
			display: 'none',
			zIndex: 101,
			position: 'relative'
		}

		if (!loading && !result) {
			style.display = 'block'
		}

		if (channelToSwitch || forbiddenModal || showModalUkonUC05b) {
			style.visibility = 'hidden'
		}

		let modal
		if (showModalUkonUC05b) {
			modal = (
				<ModalSendItemDuplicate
					zuCislo={get(upomienka, 'zmluvnyUcetCislo')}
					ukonTyp={UKONY.DUPLIKAT_UPOMIENKY}
					dokumentId={get(upomienka, 'dokumentId')}
					dokumentName={`${t('translation:Upomienky.upomienka')}.pdf`}
					notificationTypes={[NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.PRINTER]}
					modalTitle={t('containers:UpomienkaDetailPage.Zaslanie duplikátu upomienky')}
					onCloseButton={() => this.setState({ showModalUkonUC05b: false })}
					onSubmit={this.onSubmitUkonUC05b}
					selectedUpomienka={upomienka}
				/>
			)
		}

		if (result) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('containers:Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		return (
			<>
				{modal}
				<div className='content-header clearfix' style={{ zIndex: 0 }}>
					<Link to={backUrlLink} className='button pull-left' data-type='back-button' data-color='blue'>
						{t('containers:Späť')}
					</Link>
					<div className='header-title pull-left'>{t('containers:UpomienkaDetailPage.Náhľad upomienky')}</div>

					<div className='pull-right'>
						<Permissions
							allowed={[PERMISSIONS.UKON_DUPLIKAT_UPOMIENKY]}
							render={(hasPerm, actions) => {
								return (
									<Tooltip
										html={t('containers:UpomienkaDetailPage.Zaslanie duplikátu upomienky')}
										position='bottom'
										trigger='mouseenter'
										theme='light'
									>
										<a
											className='button send-button'
											data-type='icon-button'
											data-icon='send'
											onClick={(e) => {
												if (!hasPerm) {
													e.preventDefault()
													actions.openForbiddenModal()
												} else {
													this.setState({ showModalUkonUC05b: true })
												}
											}}
										/>
									</Tooltip>
								)
							}}
						/>
					</div>
				</div>

				<div className='content-wrapper embed-wrapper'>
					{!documentFail && !result && !channelToSwitch && !forbiddenModal && <ElementLoading />}
					{documentFail && !forbiddenModal && (
						<ElementFailure text={t('containers:UpomienkaDetailPage.Nepodarilo sa načítať dokument pre upomienku')} />
					)}
					{/* hide embed durring modal is showing because of IE issue */}
					{document && !documentHidden && <embed src={document} type='application/pdf' width='100%' height='100%' style={style} />}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky,
	tracking: state.tracking,
	auth: state.auth,
	channelToSwitch: state.data[CHANNEL_TO_CHANGE_SELECTOR],
	forbiddenModal: state.data.forbiddenModal,
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	obchodniPartneriActions: bindActionCreators(ObchodniPartneriActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_UPOMIENKY])
)(UpomienkaDetailPage)
