import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ReactTimeout from 'react-timeout'
import { isArray, map, get } from 'lodash'

import getColorClassByEnum from '../utils/getColorClassByEnum'

class ToastMessage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			shown: false,
			timer: null
		}
	}

	toggleShown = (timer) => {
		this.setState({
			shown: true,
			timer
		})
	}

	toggleHide = () => {
		this.setState({
			shown: false,
			timer: null
		})
	}

	onClickHandler = () => {
		const { clearTimeout, statusPop } = this.props
		const { timer } = this.state

		// turn off timer
		if (timer) {
			clearTimeout(timer)
			this.setState({
				timer: null
			})
		}

		statusPop(1)
	}

	toggle = () => {
		const { statusPop } = this.props

		this.setState({ timer: null }, () => {
			statusPop(1)
		})
	}

	checkStatuses = () => {
		const { statuses, setTimeout } = this.props
		const { timer, shown } = this.state

		// if exists any statuses and timer is not turn on show toast message
		if (get(statuses, 'length') > 0 && !timer) {
			// set 10 sec timer for cancel current toast message
			const timer = setTimeout(this.toggle, 10 * 1000)
			this.toggleShown(timer)
		}
		// if status does not exist anymore and toast is shown hide itnow
		if (get(statuses, 'length') === 0 && shown) {
			this.toggleHide()
		}
	}

	componentDidMount() {
		this.checkStatuses()
	}

	componentWillUnmount() {
		this.toggleHide()
	}

	componentDidUpdate() {
		this.checkStatuses()
	}

	render() {
		const { statuses } = this.props

		if (get(statuses, 'length') < 1) {
			return null
		}
		const { typ, popis } = statuses[0]
		const backgroundColor = getColorClassByEnum(typ)

		let msgText = popis
		if (isArray(popis)) {
			msgText = map(popis, (text, index) => (
				<div key={index} style={{ textAlign: 'center' }}>
					{text}
				</div>
			))
		}

		return (
			<div className={cx('toast-message', { shown: this.state.shown })} data-color={backgroundColor}>
				<div className='label circle large' data-color={backgroundColor}>
					<span>{`${get(statuses, 'length')}`}</span>
				</div>
				<div className='message-text' style={{ whiteSpace: 'pre-line' }}>
					{msgText}
				</div>
				<div>
					<div className='button' data-type='outline' data-color={backgroundColor} onClick={this.onClickHandler}>
						Zatvoriť
					</div>
				</div>
			</div>
		)
	}
}

ToastMessage.propTypes = {
	statuses: PropTypes.array,
	statusPop: PropTypes.func.isRequired,
	setTimeout: PropTypes.func.isRequired,
	clearTimeout: PropTypes.func.isRequired
}

export default ReactTimeout(ToastMessage)
