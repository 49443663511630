import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { get, isEmpty, map, size, forEach, concat, upperFirst } from 'lodash'
import { withTranslation } from 'react-i18next'
import { Collapse } from 'react-collapse'

// utils
import { Tooltip } from 'react-tippy'
import { formatAddressCityFirst, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'
import { KOMODITA_TYP, PONUKA_MODE, ZMLUVA_TYP_SHORTCUT, ZMLUVNY_VZTAH_STAV, ZMLUVNY_VZTAH_TYP } from '../../utils/enums'

// actions
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'
import * as ZmluvneVztahyConfigActions from '../../actions/ZmluvneVztahyConfigActions'

// components
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import ElementEmptyContent from '../ElementEmptyContent'
import ZmluvnyVztahListItem from './ZmluvnyVztahListItem'
import ZmluvnyUcetListItem from './ZmluvnyUcetListItem'
import PonukaDropdownMenu from './PonukaDropdownMenu'

class MiestoSpotrebyWrapper extends React.Component {
	static propTypes = {
		miestoSpotreby: PropTypes.shape(),
		msCislo: PropTypes.string.isRequired,
		searchIdentifikator: PropTypes.string,
		searchCisloZmluvy: PropTypes.string,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		t: PropTypes.func.isRequired,
		zmluvneVztahyActions: PropTypes.shape(),
		zmluvneVztahy: PropTypes.shape({
			data: PropTypes.array.isRequired,
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		odberneMiesta: PropTypes.shape(),
		zmluvneUcty: PropTypes.shape(),
		miestaSpotrebySize: PropTypes.number,
		zmluvneVztahyConfigActions: PropTypes.shape(),
		zmluvneVztahyConfig: PropTypes.shape(),
		neregulovanaKomoditnaZmluva: PropTypes.bool
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	fetchData = () => {
		const { msCislo, zmluvneVztahyActions, neregulovanaKomoditnaZmluva } = this.props

		zmluvneVztahyActions.loadAllZmluvneVztahy(msCislo, { neregulovanaKomoditnaZmluva: neregulovanaKomoditnaZmluva ?? undefined })
	}

	onOpenMiestoSpotrebyClick = () => {
		const { msCislo, zmluvneVztahyConfigActions, zmluvneVztahyConfig } = this.props

		if (!get(zmluvneVztahyConfig, 'isOpenMiestoSpotreby')) {
			this.fetchData()
		}

		zmluvneVztahyConfigActions.updateMiestaSpotrebyConfig(msCislo, !get(zmluvneVztahyConfig, 'isOpenMiestoSpotreby'))
	}

	onOpenNeaktivneZmluvneUctyClick = () => {
		const { msCislo, zmluvneVztahyConfigActions, zmluvneVztahyConfig } = this.props

		zmluvneVztahyConfigActions.updateNeaktivneZmluvneUctyConfig(msCislo, !get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneUcty'))
	}

	onOpenNeaktivneZmluvyClick = () => {
		const { msCislo, zmluvneVztahyConfigActions, zmluvneVztahyConfig } = this.props

		zmluvneVztahyConfigActions.updateNeaktivneZmluvneVztahyConfig(msCislo, !get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneVztahy'))
	}

	formatZmluvaTypShortcut = (zmluva) => {
		if ((zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.VYROBA_KOMODITA) return ZMLUVA_TYP_SHORTCUT.VYR
		if ((zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.PREDAJ) return ZMLUVA_TYP_SHORTCUT.NCB
		if (
			zmluva?.zmluvnyVztahTyp?.id === ZMLUVNY_VZTAH_TYP.SLUZBA_KOMODITA ||
			zmluva?.typ?.id === ZMLUVNY_VZTAH_TYP.SLUZBA_KOMODITA ||
			zmluva?.typ?.id === ZMLUVNY_VZTAH_TYP.SLUZBA_NCB
		)
			return ZMLUVA_TYP_SHORTCUT.VAS
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.ELEKTRINA && (zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.DODATOK)
			return ZMLUVA_TYP_SHORTCUT.EE_DOD
		if (
			(zmluva?.typ?.id === ZMLUVNY_VZTAH_TYP.KOMODITA && zmluva?.komoditaTyp?.id === KOMODITA_TYP.ELEKTRINA) ||
			zmluva?.komoditaTyp?.id === KOMODITA_TYP.ELEKTRINA
		)
			return ZMLUVA_TYP_SHORTCUT.EE
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.PLYN && (zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.DODATOK)
			return ZMLUVA_TYP_SHORTCUT.GAS_DOD
		if ((zmluva?.typ?.id === ZMLUVNY_VZTAH_TYP.KOMODITA && zmluva?.komoditaTyp?.id === KOMODITA_TYP.PLYN) || zmluva?.komoditaTyp?.id === KOMODITA_TYP.PLYN)
			return ZMLUVA_TYP_SHORTCUT.GAS
		return ''
	}

	formatZmluvaTypTooltip = (zmluva, active) => {
		const { t } = this.props
		if ((zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.VYROBA_KOMODITA) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.VYR.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.VYR.INACTIVE`)
		}
		if ((zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.PREDAJ) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.NCB.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.NCB.INACTIVE`)
		}
		if ((zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.SLUZBA_KOMODITA) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.VAS.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.VAS.INACTIVE`)
		}
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.ELEKTRINA && (zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.DODATOK) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.EEDOD.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.EEDOD.INACTIVE`)
		}
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.ELEKTRINA) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.EE.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.EE.INACTIVE`)
		}
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.PLYN && (zmluva?.zmluvnyVztahTyp?.id || zmluva?.typ?.id) === ZMLUVNY_VZTAH_TYP.DODATOK) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.GASDOD.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.GASDOD.INACTIVE`)
		}
		if (zmluva?.komoditaTyp?.id === KOMODITA_TYP.PLYN) {
			return active ? t(`translation:ZmluvneUcty.zmluvyPrehlad.GAS.ACTIVE`) : t(`translation:ZmluvneUcty.zmluvyPrehlad.GAS.INACTIVE`)
		}

		return ''
	}

	formatZmluvneVztahyCollection = (zmluvneVztahy, nested = false) => {
		const { searchIdentifikator, searchCisloZmluvy, odberneMiesta, t } = this.props

		let zmluvneVztahyItems = []

		if (nested) {
			zmluvneVztahyItems.push(<div className='hr' />)
		}

		forEach(zmluvneVztahy, (zmluvnyVztah, index) => {
			const omCislo = get(zmluvnyVztah, 'odberneMiestoCislo')

			const odberneMiesto = omCislo ? get(odberneMiesta, omCislo, null) : null

			const zmluvnyVztahItem = (
				<>
					{index !== 0 && <div className='hr' />}
					<ZmluvnyVztahListItem
						key={`zmluvny-vztah-${get(zmluvnyVztah, 'cislo')}`}
						searchIdentifikator={searchIdentifikator}
						searchCisloZmluvy={searchCisloZmluvy}
						zmluvnyVztah={zmluvnyVztah}
						odberneMiesto={odberneMiesto}
						nested={nested}
						t={t}
					/>
				</>
			)

			zmluvneVztahyItems.push(zmluvnyVztahItem)

			const nestedZmluvneVztahy = get(zmluvnyVztah, 'zmluvneVztahy', [])
			if (!isEmpty(nestedZmluvneVztahy)) {
				const nestedZmluvneVztahyItems = this.formatZmluvneVztahyCollection(nestedZmluvneVztahy, true)
				zmluvneVztahyItems = concat(zmluvneVztahyItems, nestedZmluvneVztahyItems)
			}
		})

		return zmluvneVztahyItems
	}

	commonContentContainer = (content) => {
		const { miestoSpotreby, zmluvneVztahyConfig, zmluvneVztahy, t } = this.props

		const adresaDodatok = miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)
		let adresa = formatAddressCityFirst(get(miestoSpotreby, 'adresa', null))
		if (get(miestoSpotreby, 'adresa.budova')) {
			adresa += ` - ${upperFirst(get(miestoSpotreby, 'adresa.budova.nazov') || get(miestoSpotreby, 'adresa.budova.id'))}`
		}
		if (adresaDodatok) {
			adresa += ` (${adresaDodatok})`
		}

		return (
			<div className='box collapsible miesto-spotreby'>
				<div className='box-header clearfix' style={{ fontWeight: 'normal', padding: '0' }}>
					<table className='content-table' style={{ padding: '0' }}>
						<tbody>
							<tr>
								<td style={{ padding: '15px' }} onClick={this.onOpenMiestoSpotrebyClick}>
									{miestoSpotreby ? (
										<strong>
											{get(miestoSpotreby, 'cislo')} - {adresa}
										</strong>
									) : (
										<i>{t('translation:ZmluvneUcty.Neviazané na MS')}</i>
									)}
								</td>
								<td onClick={this.onOpenMiestoSpotrebyClick}>
									<div className={'d-flex justify-content-end'} style={{ gap: '8px' }}>
										{miestoSpotreby
											? map(get(miestoSpotreby, 'zmluvyPrehlad'), (zmluva) => {
													return (
														<Tooltip
															html={this.formatZmluvaTypTooltip(zmluva, zmluva.aktivnaZmluva)}
															position='left'
															trigger='mouseenter'
															theme='light'
														>
															<span className={'label'} data-type={zmluva.aktivnaZmluva ? 'active' : 'outline'}>
																{this.formatZmluvaTypShortcut(zmluva)}
															</span>
														</Tooltip>
													)
												})
											: map(get(zmluvneVztahy.data, 'all'), (vztah) => {
													let isActive = [ZMLUVNY_VZTAH_STAV.NOVY, ZMLUVNY_VZTAH_STAV.PLATNY, ZMLUVNY_VZTAH_STAV.UCINNY].some(
														(stav) => stav === vztah.stav.id
													)
													return (
														<Tooltip
															html={this.formatZmluvaTypTooltip(vztah, isActive)}
															position='left'
															trigger='mouseenter'
															theme='light'
														>
															<span className={'label'} data-type={isActive ? 'active' : 'outline'}>
																{this.formatZmluvaTypShortcut(vztah)}
															</span>
														</Tooltip>
													)
												})}
									</div>
								</td>
								{miestoSpotreby && (
									<Permissions
										allowed={[PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA]}
										render={(hasPerm) => {
											if (hasPerm) {
												return (
													<td style={{ width: '60px', padding: '15px 0 15px 15px' }}>
														<PonukaDropdownMenu miestoSpotreby={miestoSpotreby} ponukaMode={PONUKA_MODE.MIESTO_SPOTREBY} />
													</td>
												)
											}
											return null
										}}
									/>
								)}
								<td style={{ width: '55px', padding: '15px' }} onClick={this.onOpenMiestoSpotrebyClick}>
									<div
										className={cx('button', 'noselect', 'arrow', { opened: get(zmluvneVztahyConfig, 'isOpenMiestoSpotreby') })}
										data-type='icon-button'
										data-icon='arrow'
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className={'box-content'} style={{ height: 'auto', overflow: 'inherit' }}>
					<Collapse isOpened={get(zmluvneVztahyConfig, 'isOpenMiestoSpotreby')}>{content}</Collapse>
				</div>
			</div>
		)
	}

	render() {
		const { zmluvneVztahy, zmluvneUcty, t, zmluvneVztahyConfig } = this.props

		const isLoading = get(zmluvneVztahy, 'isLoading') || false
		const isFailure = get(zmluvneVztahy, 'isFailure') || false

		if (isLoading) {
			return this.commonContentContainer(
				<div style={{ height: '200px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať zmluvné vzťahy')} />)
		}

		if (isEmpty(get(zmluvneVztahy, 'data.all'))) {
			return this.commonContentContainer(
				<div style={{ height: '300px' }}>
					<ElementEmptyContent text={t('translation:ZmluvneUcty.Miesto spotreby nemá žiadne zmluvné vzťahy')} />
				</div>
			)
		}

		const aktivneZmluvneUcty = get(zmluvneVztahy, 'data.aktivne', [])
		const aktivneZmluvneUctyCollection = map(aktivneZmluvneUcty, (zmluvneVztahyByZU, index) => {
			const aktivneZmluvneVztahyByZU = get(zmluvneVztahyByZU, 'aktivne', [])
			const neaktivneZmluvneVztahyByZU = get(zmluvneVztahyByZU, 'neaktivne', [])

			const aktivneZmluvneVztahyCollection = this.formatZmluvneVztahyCollection(aktivneZmluvneVztahyByZU)
			const neaktivneZmluvneVztahyCollection = this.formatZmluvneVztahyCollection(neaktivneZmluvneVztahyByZU)

			const zmluvnyUcetCislo = get(zmluvneVztahyByZU, 'cislo', null)
			const zmluvnyUcet = get(zmluvneUcty, zmluvnyUcetCislo, null)

			return (
				<>
					<ZmluvnyUcetListItem key={`zmluvny-ucet-${zmluvnyUcetCislo}`} zmluvnyUcet={zmluvnyUcet} />
					{aktivneZmluvneVztahyCollection}
					{!isEmpty(neaktivneZmluvneVztahyCollection) && (
						<div key={`neaktivny-zmluvny-vztah-${index}`} className='collapsible zmluvne-vztahy' style={{ marginTop: '20px' }}>
							<div
								className={cx('collapsible-header', 'clearfix', { opened: get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneVztahy') })}
								onClick={this.onOpenNeaktivneZmluvyClick}
							>
								<span className='pull-left'>
									<i>
										{t('translation:ZmluvneUcty.Neaktívne zmluvy')} {`(${size(neaktivneZmluvneVztahyCollection)})`}
									</i>
								</span>
								<div className='pull-right'>
									<div
										className={cx('button', 'pull-right', 'noselect', 'arrow', {
											opened: get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneVztahy')
										})}
										data-type='icon-button'
										data-icon='arrow'
									/>
								</div>
							</div>
							<div className={cx('collapsible-content', { opened: get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneVztahy') })}>
								{neaktivneZmluvneVztahyCollection}
							</div>
						</div>
					)}
				</>
			)
		})

		const neaktivneZmluvneUcty = get(zmluvneVztahy, 'data.neaktivne', [])
		const neaktivneZmluvneUctyCollection = map(neaktivneZmluvneUcty, (zmluvneVztahyByZU) => {
			const neaktivneZmluvneVztahyByZU = get(zmluvneVztahyByZU, 'neaktivne', [])

			const zmluvneVztahyCollection = this.formatZmluvneVztahyCollection(neaktivneZmluvneVztahyByZU)

			const zmluvnyUcetCislo = get(zmluvneVztahyByZU, 'cislo', null)
			const zmluvnyUcet = get(zmluvneUcty, zmluvnyUcetCislo, null)

			return (
				<>
					<ZmluvnyUcetListItem key={`zmluvny-ucet-${zmluvnyUcetCislo}`} zmluvnyUcet={zmluvnyUcet} />
					{zmluvneVztahyCollection}
				</>
			)
		})

		const collection = (
			<>
				{aktivneZmluvneUctyCollection}
				{!isEmpty(neaktivneZmluvneUctyCollection) && (
					<div className='collapsible zmluvne-ucty'>
						<div
							className='collapsible-header clearfix'
							onClick={this.onOpenNeaktivneZmluvneUctyClick}
							style={{ marginTop: isEmpty(aktivneZmluvneUctyCollection) ? '20px' : '0px' }}
						>
							<span className='pull-left'>
								<i>
									{t('translation:ZmluvneUcty.Neaktívne zmluvné účty')} {`(${size(neaktivneZmluvneUctyCollection)})`}
								</i>
							</span>
							<div className='pull-right'>
								<div
									className={cx('button', 'pull-right', 'noselect', 'arrow', {
										opened: get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneUcty')
									})}
									data-type='icon-button'
									data-icon='arrow'
								/>
							</div>
						</div>
						<div className={cx('collapsible-content', { opened: get(zmluvneVztahyConfig, 'isOpenNeaktivneZmluvneUcty') })}>
							{neaktivneZmluvneUctyCollection}
						</div>
					</div>
				)}
			</>
		)

		return this.commonContentContainer(collection)
	}
}

const mapStateToProps = (state, ownProps) => {
	const msCislo = get(ownProps, 'miestoSpotreby.cislo') || '0'
	const zmluvneVztahy = get(state, `zmluvneVztahy.miestaSpotreby[${msCislo}]`)
	const zmluvneVztahyConfig = get(state, `zmluvneVztahyConfig.miestaSpotreby.${msCislo}`) || {}
	return {
		interakcia: get(state, 'interakcie.detail.data'),
		zmluvneVztahy,
		msCislo,
		zmluvneVztahyConfig
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch),
	zmluvneVztahyConfigActions: bindActionCreators(ZmluvneVztahyConfigActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(MiestoSpotrebyWrapper)
