import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link } from 'react-router-dom'
import { isEmpty, get, map, find, isEqual, values, pick, keys, indexOf } from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import IBAN from 'iban'
import reactQueryParams from 'react-query-params'
import dayjs from 'dayjs'

// utils
import { formatAddress } from '../../utils/address'
import { formatDegrees } from '../../utils/degrees'
import Permissions, { withPermissions, PERMISSIONS, getRequiredPermissionsForUkonWrite } from '../../utils/permissionsHoc'
import { setRouteParams, OP_UPRAVIT, BANKOVE_UCTY_ZOZNAM, GENERIC_UKON } from '../../utils/routes'
import { OP_IDENTIFIKATOR_TYP, OBCHODNY_PARTNER_TYP, FILTER_SELECTORS, KOMODITA_TYP } from '../../utils/enums'
import extractProperties from '../../utils/extractProperties'
import { formatDate } from '../../utils/date'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as PonukaActions from '../../actions/PonukaActions'

// atoms
import DataTable from '../../atoms/DataTable'
import Select from '../../atoms/BasicSelect'
import Switch from '../../atoms/Switch'

const tabs = {
	zakladneInfo: 'zakladne-info',
	bankoveSpojenia: 'bankove-spojenia',
	regulacie: 'regulacie',
	marketingoveAtributy: 'marketingove-atributy'
}

const queryParams = {
	marketingSort: 'marketingSort',
	marketingDolezite: 'marketingDolezite',
	regulaciaStav: 'regulaciaStav',
	regulaciaTyp: 'regulaciaTyp'
}

const initFilters = {
	[queryParams.marketingSort]: null,
	[queryParams.marketingDolezite]: true,
	[queryParams.regulaciaStav]: 'aktivne',
	[queryParams.regulaciaTyp]: null
}

const REGULACIA_STAV = {
	GREEN: 'green',
	NONE: null
}

class ObchodnyPartnerPage extends reactQueryParams {
	static propTypes = {
		obchodnyPartnerDetail: PropTypes.shape({
			data: PropTypes.shape({
				meno: PropTypes.string,
				priezvisko: PropTypes.string,
				cislo: PropTypes.string.isRequired,
				typ: PropTypes.string,
				druh: PropTypes.shape({
					id: PropTypes.number.isRequired,
					nazov: PropTypes.string.isRequired,
					popis: PropTypes.string
				}),
				jazyk: PropTypes.shape({
					id: PropTypes.string.isRequired,
					nazov: PropTypes.string.isRequired
				}),
				oslovenie: PropTypes.shape({
					id: PropTypes.number.isRequired,
					nazov: PropTypes.string.isRequired
				}),
				tituly: PropTypes.array
			}).isRequired,
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		bankoveUcty: PropTypes.shape(),
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.any.isRequired,
				opCislo: PropTypes.string.isRequired
			})
		}).isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		filtersActions: PropTypes.shape().isRequired,
		t: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape(),
		selectedFilters: PropTypes.shape(),
		marketingoveAtributy: PropTypes.shape(),
		auth: PropTypes.shape({
			user: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
				displayName: PropTypes.string,
				email: PropTypes.string.isRequired
			})
		}).isRequired
	}

	defaultQueryParams = {
		[queryParams.marketingSort]: null,
		[queryParams.marketingDolezite]: false,
		[queryParams.regulaciaStav]: null,
		[queryParams.regulaciaTyp]: null
	}

	constructor() {
		super()
		this.state = {
			ponuka: []
		}
	}

	async componentDidMount() {
		const { obchodnyPartnerActions, selectedFilters, filtersActions, auth, ponukaActions, interakcia } = this.props

		// react-router doesn't scroll to anchor on page load
		const locationHash = window.location.hash
		if (locationHash) {
			setTimeout(() => {
				const targetElement = document.querySelector(locationHash)
				targetElement?.scrollIntoView({ behavior: 'smooth', block: 'start' })
			}, 250)
		}

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...selectedFilters,
			...filteredParams
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_DETAIL_PAGE, filters)

		obchodnyPartnerActions.loadBankoveUcty(1)
		obchodnyPartnerActions.loadMarketingoveAtributy(1, filters)
		obchodnyPartnerActions.loadRegulacie(1, filters)

		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		if (get(interakcia, 'data.opCislo')) {
			const ponuka = await ponukaActions.loadPonukaObchodnyPartner({
				kanalId: activeBusinessChannel,
				kontextId: 'obchodnyPartner'
			})

			this.setState({
				ponuka
			})
		}
	}

	componentDidUpdate() {
		const { selectedFilters } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))
		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	renderPonukaButtons = (ponuka) => {
		const { t, interakcia } = this.props
		return ponuka?.map((moznost) => {
			return (
				<Permissions
					allowed={getRequiredPermissionsForUkonWrite(moznost?.ukon)}
					render={(hasPerm, actions) => {
						let title = moznost?.nazov
						let tootltipText = title
						if (!hasPerm) {
							tootltipText = `${title} - ${t('containers:Na vykonanie akcie nemáte potrebné oprávnenia')}`
						}
						return (
							<Tooltip html={<span>{tootltipText}</span>} position='bottom' trigger='mouseenter' theme='light'>
								<Link
									to={{
										pathname: setRouteParams(GENERIC_UKON, get(interakcia, 'data.opCislo')),
										backUrlLink: `${window.location.pathname}${window.location.search}`,
										ponuka: moznost
									}}
									className={cx('button', { disabled: !hasPerm })}
									style={{ marginRight: '15px' }}
									data-color='blue'
									type='button'
									onClick={(e) => {
										if (!hasPerm) {
											e.preventDefault()
											actions.openForbiddenModal()
										}
									}}
								>
									{title}
								</Link>
							</Tooltip>
						)
					}}
				/>
			)
		})
	}

	sortingColumn = (stringFormat) => {
		const { obchodnyPartnerActions, filtersActions, selectedFilters } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[queryParams.marketingSort]: stringFormat || initFilters.marketingSort
		}
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_DETAIL_PAGE, newFilter)

		obchodnyPartnerActions.loadMarketingoveAtributy(1, newFilter)

		return newFilter[queryParams.marketingSort]
	}

	changeSelectFilter = (selectedValue, fieldName) => {
		const newFilterValue = get(selectedValue, 'value')
		this.changeFilter(newFilterValue, fieldName)
	}

	changeFilter = (newFilterValue, fieldName) => {
		const { filtersActions, selectedFilters, obchodnyPartnerActions } = this.props

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: newFilterValue ?? null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		switch (fieldName) {
			case queryParams.marketingDolezite:
				obchodnyPartnerActions.loadMarketingoveAtributy(1, newFilter)
				break
			case queryParams.regulaciaStav:
			case queryParams.regulaciaTyp:
				obchodnyPartnerActions.loadRegulacie(1, newFilter)
				break
			default:
				break
		}

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.OP_DETAIL_PAGE, newFilter)
	}

	loadMoreBankoveSpojenia = () => {
		const { bankoveUcty, obchodnyPartnerActions } = this.props
		const nextPage = bankoveUcty.page + 1
		obchodnyPartnerActions.loadBankoveUcty(nextPage)
	}

	loadMoreMarketingoveAtributy = () => {
		const { marketingoveAtributy, obchodnyPartnerActions, selectedFilters } = this.props
		const nextPage = marketingoveAtributy.page + 1
		obchodnyPartnerActions.loadMarketingoveAtributy(nextPage, selectedFilters)
	}

	loadMoreRegulacie = () => {
		const { regulacie, obchodnyPartnerActions, selectedFilters } = this.props
		const nextPage = regulacie.page + 1
		obchodnyPartnerActions.loadRegulacie(nextPage, selectedFilters)
	}

	render() {
		const { interakcia, t, bankoveUcty, obchodnyPartnerDetail, ciselniky, selectedFilters, marketingoveAtributy, regulacie } = this.props
		const { data, isLoading, isFailure } = obchodnyPartnerDetail
		const { data: marketingData, isLoading: isMarketingLoading, hasNextPage: marketingHasNextPage } = marketingoveAtributy
		const { data: regulacieData, isLoading: isRegulacieLoading, hasNextPage: regulacieHasNextPage } = regulacie
		const { ponuka } = this.state

		let bankoveUctyContent
		const loadMoreBtnText = get(bankoveUcty, 'isLoading')
			? t('containers:ObchodnyPartnerPage.Načítavam')
			: t('containers:ObchodnyPartnerPage.Načítať ďalšie')
		const bankoveUctyList = map(get(bankoveUcty, 'data'), (bankovyUcet) => {
			// NOTE: CP-878 1) Do zoznamu (aj detailu) Bankových spojení pridať stĺpec / atribút "Zdroj dát" s hodnotami SAP vs. Flores (pri tom druhom bude treba fixne naviazať text na inú hodnotu = 3 namiesto 4).
			let zdrojDat
			if (get(bankovyUcet, 'zdrojDat.id') == 3) {
				const zdrojDatCiselnik4 = find(get(ciselniky, 'zdrojDat'), { id: 4 })
				zdrojDat = get(zdrojDatCiselnik4, 'nazov') || '-'
			} else {
				zdrojDat = get(bankovyUcet, 'zdrojDat.nazov') || '-'
			}
			return (
				<tr key={bankovyUcet.id} className={cx({ active: true })}>
					<td>{get(bankovyUcet, 'banka.nazov') || '-'}</td>
					<td>{IBAN.printFormat(get(bankovyUcet, 'IBAN') || '')}</td>
					<td>{zdrojDat}</td>
				</tr>
			)
		})
		if (isEmpty(bankoveUctyList)) {
			bankoveUctyContent = (
				<div className='col-12'>
					<table className='content-table padded' cellSpacing='0'>
						<tbody>
							<tr>
								<td>{t('containers:ObchodnyPartnerPage.Pre obchodného partnera neevidujeme žiadne bankové spojenia')}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		} else {
			bankoveUctyContent = (
				<div className='row'>
					<div className='col-9'>
						<table className='content-table padded' cellSpacing='0'>
							<thead>
								<tr>
									<th>{t('containers:ObchodnyPartnerPage.Banka')}</th>
									<th>{t('containers:ObchodnyPartnerPage.IBAN')}</th>
									<th>{t('containers:ObchodnyPartnerPage.Zdroj dát')}</th>
								</tr>
							</thead>
							<tbody>{bankoveUctyList}</tbody>
						</table>
					</div>
					<div
						className='col-3'
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'flex-end'
						}}
					>
						<Link
							to={setRouteParams(BANKOVE_UCTY_ZOZNAM, get(interakcia, 'data.opCislo'))}
							className='button'
							data-type='outline'
							data-color='blue'
							style={{ width: '230px', textAlign: 'center' }}
						>
							{t('containers:ObchodnyPartnerPage.Detail bankových spojení')}
						</Link>
					</div>
					{!get(bankoveUcty, 'isLastPage') && (
						<div style={{ margin: 'auto', paddingTop: '25px' }}>
							<a
								className='button'
								data-type='outline'
								data-color='blue'
								style={{ width: '230px', textAlign: 'center' }}
								onClick={this.loadMoreBankoveSpojenia}
							>
								{loadMoreBtnText}
							</a>
						</div>
					)}
				</div>
			)
		}

		if (isLoading) {
			return this.loadingContentElement()
		}

		if (isFailure || isEmpty(data)) {
			return this.failureContentElement()
		}

		// addresa marketing check if exist or if it should inherit from another addresses
		let adresaMarketing = '-'
		let adresaMarketingInherit = ''
		if (get(data, 'adresaMarketing')) {
			adresaMarketing = formatAddress(data.adresaMarketing)
		} else if (get(data, 'adresaKorespondencna')) {
			// inherit from adresa korespondenca
			adresaMarketingInherit = t('containers:ObchodnyPartnerPage.Adresa korešpondenčná')
			adresaMarketing = formatAddress(data.adresaKorespondencna)
		} else if (get(data, 'adresaZakaznika')) {
			// inherit from adresa zakaznika
			adresaMarketingInherit = t('containers:ObchodnyPartnerPage.Adresa trvalého pobytu')
			adresaMarketing = formatAddress(data.adresaZakaznika)
		}

		// adresa reklamacie check if exist or if it should inherit from another addresses
		let adresaReklamacie = '-'
		let adresaReklamacieInherit = ''
		if (get(data, 'adresaReklamacie')) {
			adresaReklamacie = formatAddress(data.adresaReklamacie)
		} else if (get(data, 'adresaKorespondencna')) {
			// inherit from adresa korespondenca
			adresaReklamacieInherit = t('containers:ObchodnyPartnerPage.Adresa korešpondenčná')
			adresaReklamacie = formatAddress(data.adresaKorespondencna)
		} else if (get(data, 'adresaZakaznika')) {
			// inherit from adresa zakaznika
			adresaReklamacieInherit = t('containers:ObchodnyPartnerPage.Adresa trvalého pobytu')
			adresaReklamacie = formatAddress(data.adresaZakaznika)
		}

		const cislaOpDistribucnehoSystemu = get(data, 'identifikatory', []).filter(
			(identifikator) => get(identifikator, 'opIdentifikatorTyp.id') === OP_IDENTIFIKATOR_TYP.CISLO_OP_Z_DISTRIBUCNEHO_SYSTEMU
		)

		const cislaObchodnehoRegistra = get(data, 'identifikatory', []).filter(
			(identifikator) => get(identifikator, 'opIdentifikatorTyp.id') === OP_IDENTIFIKATOR_TYP.CISLO_Z_OBCHODNEHO_REGISTRA
		)

		const cislaZivnostenskehoRegistra = get(data, 'identifikatory', []).filter(
			(identifikator) => get(identifikator, 'opIdentifikatorTyp.id') === OP_IDENTIFIKATOR_TYP.CISLO_ZO_ZIVNOSTENSKEHO_REGISTRA
		)

		const stavFilterOptions = [
			{
				value: 'aktivne',
				label: t('containers:ObchodnyPartnerPage.Len aktívne regulácie')
			},
			{
				value: 'regulacie',
				label: t('containers:ObchodnyPartnerPage.Len regulácie')
			},
			{
				value: 'ziadosti',
				label: t('containers:ObchodnyPartnerPage.Len žiadosti')
			},
			{
				value: null,
				label: t('containers:ObchodnyPartnerPage.Všetky')
			}
		]

		const regulaciaTypOptions = [
			{
				value: null,
				label: t('containers:ObchodnyPartnerPage.Všetky')
			},
			...get(ciselniky, 'opRegulaciaTyp', []).map((item) => {
				return { value: item.id, label: item.nazov }
			})
		]

		return this.commonContentContainer(
			<>
				<div className='content-header tab-navigation'>
					<a className='tab-navigation-link' href={`#${tabs.zakladneInfo}`}>
						{t('containers:ObchodnyPartnerPage.Základné info a adresy')}
					</a>
					<a className='tab-navigation-link' href={`#${tabs.bankoveSpojenia}`}>
						{t('containers:ObchodnyPartnerPage.Bankové spojenia')}
					</a>
					<a className='tab-navigation-link' href={`#${tabs.regulacie}`}>
						{t('containers:ObchodnyPartnerPage.Regulácie')}
					</a>
					<a className='tab-navigation-link' href={`#${tabs.marketingoveAtributy}`}>
						{t('containers:ObchodnyPartnerPage.Marketingové atribúty')}
					</a>
				</div>
				<div className='content-wrapper'>
					<div className='box' id={tabs.zakladneInfo}>
						<div className='box-content'>
							<div className='row'>
								<div className='col-6'>
									<div className='inner-box'>
										<table className='op-detail-table'>
											<tbody>
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Typ')}</td>
													<td>
														<strong>{get(data, 'typ', '-')}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Druh')}</td>
													<td>
														<strong>{get(data, 'druh.nazov', '-')}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Jazyk')}</td>
													<td>
														<strong>{get(data, 'jazyk.nazov', '-')}</strong>
													</td>
												</tr>
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Oslovenie')}</td>
													<td>
														<strong>{get(data, 'oslovenie.nazov', '-')}</strong>
													</td>
												</tr>
												{get(data, 'typ') === OBCHODNY_PARTNER_TYP.MOO && (
													<tr>
														<td>{t('containers:ObchodnyPartnerPage.Titul pred menom')}</td>
														<td>
															<strong>{formatDegrees(data.tituly, true) || '-'}</strong>
														</td>
													</tr>
												)}
												{get(data, 'typ') === OBCHODNY_PARTNER_TYP.MOO && (
													<tr>
														<td>{t('containers:ObchodnyPartnerPage.Titul za menom')}</td>
														<td>
															<strong>{formatDegrees(data.tituly) || '-'}</strong>
														</td>
													</tr>
												)}
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Číslo OP DIS')}</td>
													<td className='identifikatory-list'>
														{cislaOpDistribucnehoSystemu.map((identifikator) => {
															return (
																<div key={get(identifikator, 'hodnotaa')}>
																	<strong>
																		{`${get(identifikator, 'hodnota', '')} (${get(identifikator, 'firma', '')})`}
																	</strong>
																</div>
															)
														})}
													</td>
												</tr>
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.Kód ESU 95')}</td>
													<td>
														<strong>{get(data, 'kodESU.nazov', '-')}</strong>
													</td>
												</tr>
												{get(data, 'typ') === OBCHODNY_PARTNER_TYP.MOP && (
													<tr>
														<td>{t('containers:ObchodnyPartnerPage.Právna forma OP')}</td>
														<td>
															<strong>{get(data, 'pravnaForma.nazov', '-')}</strong>
														</td>
													</tr>
												)}
												{get(data, 'typ') === OBCHODNY_PARTNER_TYP.MOP && (
													<tr>
														<td>{t('containers:ObchodnyPartnerPage.Číslo z obchodného registra')}</td>
														<td>
															{cislaObchodnehoRegistra.length > 0
																? cislaObchodnehoRegistra.map((identifikator) => {
																		return (
																			<div key={get(identifikator, 'hodnota')}>
																				<strong>{get(identifikator, 'hodnota', '-')}</strong>
																			</div>
																		)
																	})
																: '-'}
														</td>
													</tr>
												)}
												{get(data, 'typ') === OBCHODNY_PARTNER_TYP.MOP && (
													<tr>
														<td>{t('containers:ObchodnyPartnerPage.Číslo zo živnostenského registra')}</td>
														<td>
															{cislaZivnostenskehoRegistra.length > 0
																? cislaZivnostenskehoRegistra.map((identifikator) => {
																		return (
																			<div key={get(identifikator, 'hodnota')}>
																				<strong>{get(identifikator, 'hodnota', '-')}</strong>
																			</div>
																		)
																	})
																: '-'}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
								<div className='col-6'>
									<table className='content-table' cellSpacing='0'>
										<thead>
											<tr>
												<th colSpan='2'>{t('containers:ObchodnyPartnerPage.Marketing')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td colSpan='1'>{t('containers:ObchodnyPartnerPage.Adresa pre marketingové materiály')}</td>
												<td colSpan='2'>
													<strong style={{ position: 'relative', paddingRight: '20px' }}>
														{adresaMarketing}
														{adresaMarketingInherit && (
															<Tooltip
																html={
																	<>
																		<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
																		<br />
																		<span>
																			<strong>{adresaMarketingInherit}</strong>
																		</span>
																	</>
																}
																position='bottom'
																trigger='mouseenter'
																theme='light'
															>
																<div className='info-icon' />
															</Tooltip>
														)}
													</strong>
												</td>
											</tr>
										</tbody>
										<thead>
											<tr>
												<th colSpan='2'>{t('containers:ObchodnyPartnerPage.Doplnkové adresy')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td colSpan='1'>{t('containers:ObchodnyPartnerPage.Reklamácie')}</td>
												<td colSpan='2'>
													<strong style={{ position: 'relative', paddingRight: '20px' }}>
														{adresaReklamacie}
														{adresaReklamacieInherit && (
															<Tooltip
																html={
																	<>
																		<span>{t('components:UserSubheader.Odvodená adresa z')}</span>
																		<br />
																		<span>
																			<strong>{adresaReklamacieInherit}</strong>
																		</span>
																	</>
																}
																position='bottom'
																trigger='mouseenter'
																theme='light'
															>
																<div className='info-icon' />
															</Tooltip>
														)}
													</strong>
												</td>
											</tr>
											{data.onlineUcet && (
												<tr>
													<td>{t('containers:ObchodnyPartnerPage.E-mail zákazníckej zóny')}</td>
													<td>
														<strong>{get(data, 'onlineUcet', '-')}</strong>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
									{!ponuka?.isLoading && this.renderPonukaButtons(ponuka)}
									<Permissions
										allowed={[PERMISSIONS.UKON_UDAJE_OP]}
										render={(hasPerm, actions) => {
											return (
												<Link
													to={setRouteParams(OP_UPRAVIT, get(interakcia, 'data.opCislo'))}
													className={cx('button', 'pull-right', { disabled: !hasPerm })}
													data-color='blue'
													onClick={(e) => {
														if (!hasPerm) {
															e.preventDefault()
															actions.openForbiddenModal()
														}
													}}
												>
													{t('containers:ObchodnyPartnerPage.Upraviť údaje OP')}
												</Link>
											)
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='box' id={tabs.bankoveSpojenia}>
						<div className='box-header'>{t('containers:ObchodnyPartnerPage.Bankové spojenia aktívne')}</div>
						<div className='box-content'>{bankoveUctyContent}</div>
					</div>
					<div className='box'>
						<div className='box-header'>{t('containers:ObchodnyPartnerPage.Nekomoditná fakturácia')}</div>
						<div className='box-content'>
							<div className='col-12'>
								<table className='content-table padded' cellSpacing='0'>
									<tbody>
										<tr>
											<td>{t('containers:ObchodnyPartnerPage.Preferovaný deň splatnosti faktúr')}</td>
											<td>
												<b>{get(data, 'denSplatnostiPreferovany', '-')}</b>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className='box' id={tabs.regulacie}>
						<div className='box-header'>{t('containers:ObchodnyPartnerPage.Regulácie')}</div>
						<div className='box-content'>
							<div className='row' style={{ marginBottom: '20px' }}>
								<div className='col-3'>
									<Select
										value={find(stavFilterOptions, (option) => option.value === selectedFilters[queryParams.regulaciaStav])}
										label={t('containers:ObchodnyPartnerPage.Stav')}
										classNamePrefix='react-select'
										options={stavFilterOptions}
										onChange={(stav) => this.changeSelectFilter(stav, queryParams.regulaciaStav)}
										showInlineLabel
									/>
								</div>
								<div className='col-4'>
									<Select
										value={find(regulaciaTypOptions, (option) => option.value === selectedFilters[queryParams.regulaciaTyp])}
										label={t('containers:ObchodnyPartnerPage.Druh regulácie')}
										classNamePrefix='react-select'
										options={regulaciaTypOptions}
										onChange={(typ) => this.changeSelectFilter(typ, queryParams.regulaciaTyp)}
										showInlineLabel
									/>
								</div>
							</div>
							{regulacieData?.length > 0 ? (
								<DataTable
									columns={[
										{
											title: '',
											style: { width: '30px' }
										},
										{
											title: t('containers:ObchodnyPartnerPage.Typ'),
											style: { width: '150px' }
										},
										{
											title: t('containers:ObchodnyPartnerPage.EIC/POD'),
											style: { width: '250px' }
										},
										{
											title: t('containers:ObchodnyPartnerPage.Druh regulácie')
										},
										{
											title: t('containers:ObchodnyPartnerPage.Platnosť od - do'),
											style: { width: '200px' }
										},
										{
											title: t('containers:ObchodnyPartnerPage.Stav žiadosti'),
											style: { width: '120px' }
										},
										{
											title: '',
											style: { width: '30px' }
										}
									]}
								>
									{regulacieData.map((regulacia) => {
										const isZiadost = get(regulacia, 'ziadost', false)
										const komoditaTypId = get(regulacia, 'komoditaTyp.id')

										let stavIndicator = REGULACIA_STAV.NONE
										if (isZiadost === false && dayjs().isSameOrBefore(dayjs(get(regulacia, 'platnostDo', null)), 'day')) {
											stavIndicator = REGULACIA_STAV.GREEN
										}

										let typ = '-'
										if (isZiadost) {
											if (komoditaTypId === KOMODITA_TYP.ELEKTRINA) {
												typ = t('containers:ObchodnyPartnerPage.Žiadosť EE')
											} else if (komoditaTypId === KOMODITA_TYP.PLYN) {
												typ = t('containers:ObchodnyPartnerPage.Žiadosť GAS')
											}
										} else if (komoditaTypId === KOMODITA_TYP.ELEKTRINA) {
											typ = t('containers:ObchodnyPartnerPage.Regulácia EE')
										} else if (komoditaTypId === KOMODITA_TYP.PLYN) {
											typ = t('containers:ObchodnyPartnerPage.Regulácia GAS')
										}

										let eicPod = '-'
										if (isZiadost) {
											if (get(regulacia, 'omIdentifikator')) {
												eicPod = get(regulacia, 'omIdentifikator')
											} else {
												eicPod = t('containers:ObchodnyPartnerPage.Všetky OM')
											}
										}

										const stavZiadosti = isZiadost ? get(regulacia, 'regulaciaStav.nazov', '-') : '-'

										return (
											<tr>
												<td>{stavIndicator && <div className='label circle' data-color={stavIndicator} />}</td>
												<td>{typ}</td>
												<td>{eicPod}</td>
												<td>{get(regulacia, 'regulaciaTyp.nazov', '-')}</td>
												<td>{`${formatDate(get(regulacia, 'platnostOd'), '-', 'DD.MM.YYYY')} - ${formatDate(
													get(regulacia, 'platnostDo'),
													'-',
													'DD.MM.YYYY'
												)}`}</td>
												<td>{stavZiadosti}</td>
												<td>
													<Tooltip
														html={
															<div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', gap: '8px' }}>
																<div>
																	<strong>{t('containers:ObchodnyPartnerPage.Vytvoril')}:</strong>{' '}
																	{get(regulacia, 'vytvoreniePouzivatel', '-')}
																</div>
																<div>
																	<strong>{t('containers:ObchodnyPartnerPage.Vytvorené')}:</strong>{' '}
																	{formatDate(get(regulacia, 'vytvorenieDatumCas'), '-', 'DD.MM.YYYY')}
																</div>
																<div>
																	<strong>{t('containers:ObchodnyPartnerPage.Zmenil')}:</strong>{' '}
																	{get(regulacia, 'poslednaZmenaPouzivatel', '-')}
																</div>
																<div>
																	<strong>{t('containers:ObchodnyPartnerPage.Zmenené')}:</strong>{' '}
																	{formatDate(get(regulacia, 'poslednaZmenaDatumCas'), '-', 'DD.MM.YYYY')}
																</div>
															</div>
														}
														position='bottom-start'
														trigger='mouseenter'
														theme='light'
													>
														<div className='info-icon' />
													</Tooltip>
												</td>
											</tr>
										)
									})}
									{!isRegulacieLoading && regulacieHasNextPage && (
										<button className='button' data-color='blue' data-type='link' onClick={this.loadMoreRegulacie}>
											{t('containers:ObchodnyPartnerPage.Zobraziť ďalšie')}
										</button>
									)}
								</DataTable>
							) : (
								<div style={{ height: '220px', position: 'relative' }}>
									<ElementEmptyContent text={t('containers:ObchodnyPartnerPage.Žiadne záznamy Skontrolujte aktívne filtre')} />
								</div>
							)}
						</div>
					</div>
					<div className='box' id={tabs.marketingoveAtributy}>
						<div className='box-header'>{t('containers:ObchodnyPartnerPage.Marketingové atribúty')}</div>
						<div className='box-content'>
							<div className='inline-filter' style={{ marginBottom: '20px' }}>
								<strong>{t('containers:ObchodnyPartnerPage.Zobraziť len dôležité')}</strong>
								<Switch
									isChecked={selectedFilters[queryParams.marketingDolezite]}
									onChange={(isCheckedNewValue) => this.changeFilter(isCheckedNewValue, queryParams.marketingDolezite)}
								/>
							</div>
							{marketingData?.length > 0 ? (
								<DataTable
									columns={[
										{
											title: t('containers:ObchodnyPartnerPage.Skupina atribútov'),
											name: 'skupinaNazov',
											sortable: true
										},
										{
											title: t('containers:ObchodnyPartnerPage.Atribút'),
											name: 'nazov',
											sortable: true
										},
										{
											title: t('containers:ObchodnyPartnerPage.Hodnota'),
											name: 'hodnota',
											sortable: false
										}
									]}
									triedenie={selectedFilters[queryParams.marketingSort]}
									sortingColumn={this.sortingColumn}
								>
									{marketingData.map((marketingovyAtribut) => {
										return (
											<tr>
												<td>{get(marketingovyAtribut, 'typ.skupinaNazov', '-')}</td>
												<td>{get(marketingovyAtribut, 'typ.nazov', '-')}</td>
												<td>{get(marketingovyAtribut, 'hodnotaPopis', '-')}</td>
											</tr>
										)
									})}
									{!isMarketingLoading && marketingHasNextPage && (
										<button className='button' data-color='blue' data-type='link' onClick={this.loadMoreMarketingoveAtributy}>
											{t('containers:ObchodnyPartnerPage.Zobraziť ďalšie')}
										</button>
									)}
								</DataTable>
							) : (
								<div style={{ height: '220px', position: 'relative' }}>
									<ElementEmptyContent text={t('containers:ObchodnyPartnerPage.Žiadne záznamy Skontrolujte aktívne filtre')} />
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		)
	}

	commonContentContainer = (content) => {
		return content
	}

	loadingContentElement = () => {
		return this.commonContentContainer(<ElementLoading />)
	}

	failureContentElement = () => {
		const { t } = this.props
		return this.commonContentContainer(<ElementFailure text={t('containers:ObchodnyPartnerPage.Nepodarilo sa načítať detail obchodného partnera')} />)
	}
}

const mapStateToProps = (state) => ({
	obchodnyPartnerDetail: state.obchodnyPartner.detail,
	bankoveUcty: state.obchodnyPartner.bankoveUcty,
	marketingoveAtributy: state.obchodnyPartner.marketingoveAtributy,
	regulacie: state.obchodnyPartner.regulacie,
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.OP_DETAIL_PAGE, {}),
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky.data
})

const mapDispatchToProps = (dispatch) => ({
	action: bindActionCreators({}, dispatch),
	ponukaActions: bindActionCreators(PonukaActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(ObchodnyPartnerPage)
