import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { get, head } from 'lodash'
import { Tooltip } from 'react-tippy'

// actions
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { formatOp } from '../../utils/obchodniPartneri'
import { formatAddress } from '../../utils/address'
import { setRouteParams, PREHLAD } from '../../utils/routes'
import {
	getZmluvnyVztahStateDateLabel,
	getZmluvnyVztahStateDate,
	getZmenaZmluvnyVztahStateDateLabel,
	getZmenaZmluvnyVztahStateDate,
	getZmluvnyVztahStateColor,
	getZmluvnyVztahStateText
} from '../../utils/zmluvneVztahy'
import { formatDate } from '../../utils/date'
import { ZMLUVNY_VZTAH_TECHNICKY_STAV } from '../../utils/enums'
import AlertIcon from '../../resources/img/icons/alert.svg'

class ZmluvnyVztahDetail extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		komoditaTyp: PropTypes.string.isRequired,
		omCislo: PropTypes.string.isRequired,
		zmluvnyVztah: PropTypes.shape().isRequired,
		zmluvneVztahyActions: PropTypes.shape().isRequired,
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { komoditaTyp, omCislo, zmluvneVztahyActions } = this.props

		zmluvneVztahyActions.loadZmluvnyVztahMimoOP(true, {
			komoditaTyp,
			omCislo
		})
	}

	componentWillUnmount() {
		const { zmluvneVztahyActions } = this.props

		zmluvneVztahyActions.clearZmluvnyVztah()
	}

	render() {
		const { modalTitle, onCloseButton, zmluvnyVztah, t } = this.props

		const stateDateLabel = getZmluvnyVztahStateDateLabel(get(zmluvnyVztah, 'data'), t)
		const stateDate = getZmluvnyVztahStateDate(get(zmluvnyVztah, 'data'), t)
		const zmenaStateDateLabel = getZmenaZmluvnyVztahStateDateLabel(get(zmluvnyVztah, 'data'), t)
		const zmenaStateDate = getZmenaZmluvnyVztahStateDate(get(zmluvnyVztah, 'data'), t)

		let content
		if (get(zmluvnyVztah, 'isLoading')) {
			content = <ElementLoading />
		} else if (get(zmluvnyVztah, 'isFailure')) {
			content = <ElementFailure text={t('translation:Common.Nepodarilo sa načítať zmluvné vzťahy')} />
		} else {
			content = (
				<>
					<div className='inner-box'>
						<table>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:Common.Číslo OP')}</strong>
									</td>
									<td>
										<Link to={setRouteParams(PREHLAD, get(zmluvnyVztah, 'data.op.cislo'))} target='_blank' rel='noopener noreferrer'>
											{get(zmluvnyVztah, 'data.op.cislo') || '-'}
										</Link>
									</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Common.Meno a priezvisko')}</strong>
									</td>
									<td>{formatOp(get(zmluvnyVztah, 'data.op')) || '-'}</td>
								</tr>
								<tr>
									<td>
										<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
									</td>
									<td>{formatAddress(get(zmluvnyVztah, 'data.op.adresaZakaznika')) || '-'}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<table>
						<tbody>
							<tr>
								<td style={{ paddingLeft: '15px' }}>
									<strong>{t('translation:ZmluvneUcty.Číslo zmluvy')}</strong>
								</td>
								<td>{get(zmluvnyVztah, 'data.cisloISU') || get(zmluvnyVztah, 'data.cislo') || '-'}</td>
							</tr>
							{get(zmluvnyVztah, 'data.viazanostDo') && (
								<tr>
									<td style={{ paddingLeft: '15px' }}>
										<strong>{t('translation:ZmluvneUcty.Dátum viazanosti')}</strong>
									</td>
									<td>{formatDate(get(zmluvnyVztah, 'data.viazanostDo'), '-', 'DD.MM.YYYY')}</td>
								</tr>
							)}
							<tr>
								<td style={{ paddingLeft: '15px' }}>
									<strong>{stateDateLabel}</strong>
								</td>
								<td>
									{stateDate}
									<Tooltip html={getZmluvnyVztahStateText(get(zmluvnyVztah, 'data'), t)} position='right' trigger='mouseenter' theme='light'>
										<span
											className='label circle'
											data-color={getZmluvnyVztahStateColor(get(zmluvnyVztah, 'data'))}
											style={{
												lineHeight: '21px',
												marginBottom: '2px',
												marginLeft: '5px',
												backgroundImage:
													get(head(get(get(zmluvnyVztah, 'data'), 'zmeny', [])), 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.CHYBNE &&
													`url(${AlertIcon})`
											}}
										/>
									</Tooltip>
								</td>
							</tr>
							{zmenaStateDateLabel && zmenaStateDate && (
								<tr>
									<td style={{ paddingLeft: '15px' }}>
										<strong>{zmenaStateDateLabel}</strong>
									</td>
									<td>{zmenaStateDate}</td>
								</tr>
							)}
						</tbody>
					</table>
				</>
			)
		}

		return (
			<Modal shown size='l' handleOutsideClick={onCloseButton}>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' alt={t('translation:Common.Zavrieť')} />
						</div>
					</div>
				)}
				<div className='modal-content'>{content}</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	zmluvnyVztah: get(state, 'zmluvneVztahy.detail')
})

const mapDispatchToProps = (dispatch) => ({
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZmluvnyVztahDetail)
