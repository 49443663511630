import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, map, groupBy, head, isEmpty, startsWith } from 'lodash'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { UnmountClosed } from 'react-collapse'
import { animateScroll as scroll } from 'react-scroll'
import qs from 'qs'
import reactQueryParams from 'react-query-params'
import cx from 'classnames'

// utils
import {
	formatIdentifikatorTyp,
	formatOdberneMiestoObsluha,
	formatOdberneMiestoObsluhaColor,
	formatOdberneMiestoObsluhaTooltip,
	getZmluvnyVztahStateDateLabel,
	getZmluvnyVztahStateDate,
	getZmluvnyVztahStateColor,
	getZmluvnyVztahStateText,
	isZmluvnyVztahAktivny
} from '../../utils/zmluvneVztahy'
import { PONUKA_MODE, ZMLUVNY_VZTAH_TECHNICKY_STAV, ZMLUVNY_VZTAH_TYP } from '../../utils/enums'
import { formatDate } from '../../utils/date'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

// components
import ZmluvnyVztahDetailItem from './ZmluvnyVztahDetailItem'
import PonukaDropdownMenu from './PonukaDropdownMenu'

import AlertIcon from '../../resources/img/icons/alert.svg'

class ZmluvnyVztahListItem extends reactQueryParams {
	static propTypes = {
		searchIdentifikator: PropTypes.string,
		searchCisloZmluvy: PropTypes.string,
		zmluvnyVztah: PropTypes.shape().isRequired,
		odberneMiesto: PropTypes.shape(),
		nested: PropTypes.bool,
		t: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired
	}

	defaultQueryParams = {
		itemId: ''
	}

	_mounted = false

	state = {
		visibleDetail: false
	}

	constructor(props) {
		super(props)

		const { itemId } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		this.state = {
			visibleDetail: false,
			openItemId: itemId
		}
	}

	componentDidMount = () => {
		this._mounted = true
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	openItemDetail = () => {
		const { openItemId } = this.state
		const { zmluvnyVztah } = this.props

		// CP - 1810 fix
		setTimeout(() => {
			if (this._mounted && openItemId) {
				if (get(zmluvnyVztah, 'cisloISU') == openItemId || get(zmluvnyVztah, 'cislo') == openItemId) {
					this.toggleVisibleDetail()
					// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
					this.setQueryParams({
						itemId: ''
					})
					this.setState({
						openItemId: null
					})
				}
			}
		}, 100)
	}

	toggleVisibleDetail = () => {
		const { zmluvnyVztah } = this.props
		if (this._mounted) {
			if (!this.state.visibleDetail) {
				const elmntID = `zmluvny-vztah-${get(zmluvnyVztah, 'cislo')}`
				const elmnt = document.getElementById(elmntID)
				if (elmnt) {
					let parent = elmnt
					let offset = 0
					do {
						offset += get(parent, 'offsetTop', 0)
						parent = get(parent, 'offsetParent')
					} while (!isEmpty(parent) && get(parent, 'id') != 'miesta-spotreby-scroll-container')
					scroll.scrollTo(offset, {
						duration: 600,
						delay: 100,
						smooth: true,
						containerId: 'miesta-spotreby-scroll-container'
					})
				}
			}
			this.setState({
				visibleDetail: !this.state.visibleDetail
			})
		}
	}

	checkIfIsHighlighted = (identifikator, cisloZmluvy, zmluvnyVztahCislo) => {
		const { searchIdentifikator, searchCisloZmluvy } = this.props

		if (searchIdentifikator && searchCisloZmluvy) {
			return identifikator === searchIdentifikator && cisloZmluvy === searchCisloZmluvy
		}

		if (identifikator === searchIdentifikator || cisloZmluvy === searchCisloZmluvy) return true

		return searchCisloZmluvy === zmluvnyVztahCislo
	}

	render = () => {
		const {
			zmluvnyVztah,
			odberneMiesto,
			nested,
			/* interakcia, */
			t
		} = this.props

		const { visibleDetail } = this.state

		/* const backUrl = btoa(`${window.location.pathname}${window.location.search}`) */

		const productsGroupedByRodina = groupBy(get(zmluvnyVztah, 'produkty', []), 'rodina.kod')
		const productsGroupedByRodinaKeys = Object.keys(productsGroupedByRodina)

		const products = map(productsGroupedByRodinaKeys, (rodina) => {
			const names = map(productsGroupedByRodina[rodina], (product, index) => {
				return (
					<React.Fragment key={`produkt-nazov-${index}`}>
						<span>{get(product, 'nazov') || get(product, 'kod') || '-'}</span>
						{get(productsGroupedByRodina[rodina], 'length') > index + 1 && <br />}
					</React.Fragment>
				)
			})
			return (
				<tr key={`produkt-rodina-${rodina}`} style={{ verticalAlign: 'top' }}>
					<td data-text-size='medium'>
						<strong>{get(head(productsGroupedByRodina[rodina]), 'rodina.nazov', '-')}</strong>
					</td>
					<td data-text-size='medium'>
						<strong>{names}</strong>
					</td>
				</tr>
			)
		})

		const stateDateLabel = getZmluvnyVztahStateDateLabel(zmluvnyVztah, t)
		const stateDate = getZmluvnyVztahStateDate(zmluvnyVztah, t)

		let identifikator = get(odberneMiesto, 'identifikator')
		const cisloZmluvy = get(zmluvnyVztah, 'cisloISU') || get(zmluvnyVztah, 'cislo')
		const zmluvnyVztahCislo = get(zmluvnyVztah, 'cislo')

		if (!identifikator && get(zmluvnyVztah, 'odberneMiestoCislo') && !startsWith(get(zmluvnyVztah, 'odberneMiestoCislo'), '4')) {
			identifikator = get(zmluvnyVztah, 'odberneMiestoCislo')
		}

		this.openItemDetail()

		const isHighlighted = this.checkIfIsHighlighted(identifikator, cisloZmluvy, zmluvnyVztahCislo)

		return (
			<>
				<div
					id={`zmluvny-vztah-${get(zmluvnyVztah, 'cislo')}`}
					name={`zmluvny-vztah-${get(zmluvnyVztah, 'cislo')}`}
					className={cx('row', 'zmluvny-vztah', { highlighted: isHighlighted })}
				>
					<div className={cx('col-6', { highlighted: isHighlighted })}>
						<table className='content-table' style={{ paddingLeft: nested ? '30px' : undefined }}>
							<tbody>
								{products}
								{get(zmluvnyVztah, 'viazanostDo') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Dátum viazanosti')}</td>
										<td>
											<strong>{formatDate(get(zmluvnyVztah, 'viazanostDo'), '-', 'DD.MM.YYYY')}</strong>
										</td>
									</tr>
								)}
								<tr>
									<td>{stateDateLabel}</td>
									<td>
										<strong>{stateDate}</strong>
										<Tooltip html={getZmluvnyVztahStateText(zmluvnyVztah, t)} position='right' trigger='mouseenter' theme='light'>
											<span
												className='label circle'
												data-color={getZmluvnyVztahStateColor(zmluvnyVztah)}
												style={{
													lineHeight: '21px',
													marginBottom: '2px',
													marginLeft: '5px',
													backgroundImage:
														get(head(get(zmluvnyVztah, 'zmeny', [])), 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.CHYBNE &&
														`url(${AlertIcon})`
												}}
											/>
										</Tooltip>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className={cx('col-6', { highlighted: isHighlighted })}>
						<table className='content-table'>
							<tbody>
								<tr>
									<td>{t('translation:ZmluvneUcty.Číslo zmluvy')}</td>
									<td>
										<strong>{cisloZmluvy || '-'}</strong>
									</td>
									<td style={{ textAlign: 'right', verticalAlign: 'top' }}>
										<Tooltip html={t('translation:ZmluvneUcty.Detail zmluvy')} position='bottom' trigger='mouseenter' theme='light'>
											{visibleDetail ? (
												<button
													onClick={this.toggleVisibleDetail}
													className='button bleached'
													data-type='icon-button'
													data-color='blue'
													data-icon='view'
												/>
											) : (
												<button onClick={this.toggleVisibleDetail} className='button' data-type='icon-button' data-icon='view' />
											)}
										</Tooltip>
										{isZmluvnyVztahAktivny(zmluvnyVztah) && get(zmluvnyVztah, 'typ.id') == ZMLUVNY_VZTAH_TYP.KOMODITA && (
											<Permissions
												allowed={[PERMISSIONS.UKON_PREPIS_IDEALNY, PERMISSIONS.UKON_ZMENA_PRODUKTU, PERMISSIONS.UKON_UKONCENIE_ZMLUVY]}
												render={(hasPerm) => {
													if (hasPerm) {
														return <PonukaDropdownMenu zmluvnyVztah={zmluvnyVztah} ponukaMode={PONUKA_MODE.ZMLUVNY_VZTAH} />
													}
													return null
												}}
											/>
										)}
									</td>
								</tr>
								{get(zmluvnyVztah, 'typ.id') === ZMLUVNY_VZTAH_TYP.KOMODITA && (
									<tr>
										<td style={{ paddingRight: '0px' }}>
											<div className='pull-left'>{formatIdentifikatorTyp(zmluvnyVztah, t)}</div>
											{get(odberneMiesto, 'obsluha') && (
												<Tooltip
													html={formatOdberneMiestoObsluhaTooltip(odberneMiesto, t)}
													position='bottom'
													trigger='mouseenter'
													theme='light'
													disabled={false}
												>
													<span className='label pull-right' data-color={formatOdberneMiestoObsluhaColor(odberneMiesto, t)}>
														{formatOdberneMiestoObsluha(odberneMiesto, t)}
													</span>
												</Tooltip>
											)}
										</td>
										<td colSpan='2'>
											<strong>{identifikator}</strong>
										</td>
									</tr>
								)}
								{get(zmluvnyVztah, 'typ.id') === ZMLUVNY_VZTAH_TYP.VYROBA_KOMODITA && (
									<tr>
										<td style={{ paddingRight: '0px' }}>
											<div className='pull-left'>{formatIdentifikatorTyp(zmluvnyVztah, t)}</div>
										</td>
										<td colSpan='2'>
											<strong>{identifikator}</strong>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
				<UnmountClosed isOpened={visibleDetail}>
					<ZmluvnyVztahDetailItem zvCislo={get(zmluvnyVztah, 'cislo')} omCislo={get(odberneMiesto, 'cislo')} nested={nested} t={t} />
				</UnmountClosed>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data')
})

export default compose(withTranslation('components'), connect(mapStateToProps))(ZmluvnyVztahListItem)
