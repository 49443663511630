import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { get, head, upperFirst } from 'lodash'
import { NumericFormat } from 'react-number-format'
import * as IBAN from 'iban'

// actions
import * as PlatobnePrikazyActions from '../../actions/ObchodniPartneri/PlatobnePrikazyActions'

// components
import Modal from './Modal'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

// utils
import { formatDate } from '../../utils/date'
import { formatAddress } from '../../utils/address'
import { POHLADAVKA_TYP } from '../../utils/enums'

class PlatobnyPrikazDetail extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		platobnePrikazy: PropTypes.shape().isRequired,
		platobnePrikazyActions: PropTypes.shape().isRequired,
		pohladavkaId: PropTypes.string.isRequired,
		onCloseButton: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { pohladavkaId, platobnePrikazyActions } = this.props

		platobnePrikazyActions.loadPlatobnePrikazy(pohladavkaId)
	}

	render() {
		const { modalTitle, onCloseButton, platobnePrikazy, t } = this.props

		let content
		if (get(platobnePrikazy, 'isLoading')) {
			content = <ElementLoading />
		} else if (get(platobnePrikazy, 'isFailure')) {
			content = <ElementFailure text={t('translation:PlatobnePrikazy.Nepodarilo sa načítať detail platobného príkazu')} />
		} else {
			const platobnyPrikaz = head(get(platobnePrikazy, 'data', []))
			content = (
				<>
					<table>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:PlatobnePrikazy.Číslo platobného príkazu')}</strong>
								</td>
								<td>{get(platobnyPrikaz, 'id') || '-'}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:PlatobnePrikazy.Zadaný od')}</strong>
								</td>
								<td>{formatDate(get(platobnyPrikaz, 'zadanyOd'), '-', 'DD.MM.YYYY')}</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:PlatobnePrikazy.Stav')}</strong>
								</td>
								<td>
									<strong>{upperFirst(get(platobnyPrikaz, 'stav.nazov')) || '-'}</strong>
								</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:PlatobnePrikazy.Spôsob platby')}</strong>
								</td>
								<td>
									{get(platobnyPrikaz, 'doslePlatbySposob') && t('translation:PlatobnePrikazy.Prijatá platba')}
									{get(platobnyPrikaz, 'odoslanePlatbySposob') && t('translation:PlatobnePrikazy.Odoslaná platba')}
								</td>
							</tr>
							<tr>
								<td>
									<strong>{t('translation:PlatobnePrikazy.Typ platby')}</strong>
								</td>
								<td>
									{upperFirst(get(platobnyPrikaz, 'doslePlatbySposob.nazov')) ||
										upperFirst(get(platobnyPrikaz, 'odoslanePlatbySposob.nazov')) ||
										'-'}
								</td>
							</tr>
						</tbody>
					</table>
					{get(platobnyPrikaz, 'platba') && (
						<>
							<h4 style={{ fontSize: '18px' }}>{t('translation:PlatobnePrikazy.Platba')}</h4>
							<div className='inner-box'>
								<table>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Platby.Variabilný symbol')}</strong>
											</td>
											<td>{get(platobnyPrikaz, 'platba.variabilnySymbol') || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Dátum platby')}</strong>
											</td>
											<td>{formatDate(get(platobnyPrikaz, 'platba.platbaDatum'), '-', 'DD.MM.YYYY')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Suma')}</strong>
											</td>
											<td>
												<NumericFormat
													thousandSeparator={' '}
													decimalSeparator={','}
													decimalScale={2}
													fixedDecimalScale={true}
													displayType='text'
													suffix=' €'
													value={get(platobnyPrikaz, 'platba.suma')}
												/>
											</td>
										</tr>
										{get(platobnyPrikaz, 'platba.sumaAnulovana') != null && (
											<tr>
												<td>
													<strong>{t('translation:Platby.Suma anulovaná')}</strong>
												</td>
												<td>
													<NumericFormat
														thousandSeparator={' '}
														decimalSeparator={','}
														decimalScale={2}
														fixedDecimalScale={true}
														displayType='text'
														suffix=' €'
														value={get(platobnyPrikaz, 'platba.sumaAnulovana')}
													/>
												</td>
											</tr>
										)}
										<tr>
											<td>
												<strong>{t('translation:Platby.Zdroj dát')}</strong>
											</td>
											<td>{upperFirst(get(platobnyPrikaz, 'platba.zdrojDat.nazov')) || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Typ platby')}</strong>
											</td>
											<td>{upperFirst(get(platobnyPrikaz, 'platba.typ.nazov')) || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Typ platby (detail)')}</strong>
											</td>
											<td>{upperFirst(get(platobnyPrikaz, 'platba.typDetail.nazov')) || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.IBAN')}</strong>
											</td>
											<td>{IBAN.printFormat(get(platobnyPrikaz, 'platba.iban') || '') || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Text platby')}</strong>
											</td>
											<td>{get(platobnyPrikaz, 'platba.platbaText') || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>
													{get(platobnyPrikaz, 'platba.typ.id') == POHLADAVKA_TYP.PRIJATA_PLATBA.value
														? t('translation:Platby.Meno platiteľa')
														: t('translation:Platby.Meno príjemcu')}
												</strong>
											</td>
											<td>{get(platobnyPrikaz, 'platba.meno') || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Poštová adresa')}</strong>
											</td>
											<td>{formatAddress(get(platobnyPrikaz, 'platba.adresaPostova')) || '-'}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Platby.Číslo SEPA mandátu')}</strong>
											</td>
											<td>{get(platobnyPrikaz, 'platba.sepaMandatCislo') || '-'}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</>
					)}
				</>
			)
		}

		return (
			<Modal shown size='m'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' alt={t('translation:Common.Zavrieť')} />
						</div>
					</div>
				)}
				<div className='modal-content'>{content}</div>
			</Modal>
		)
	}
}
const mapStateToProps = (state) => ({
	platobnePrikazy: get(state, 'platobnePrikazy.detail')
})

const mapDispatchToProps = (dispatch) => ({
	platobnePrikazyActions: bindActionCreators(PlatobnePrikazyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(PlatobnyPrikazDetail)
