import { get } from 'lodash'
import React from 'react'
import { WARN, INFO } from './enums'

/**
 * Function returns formated form value acording to original value
 * @param {String} formValue - changed form value
 * @param {String} originalValue - original form value
 * @param valueForLabel
 * @returns {String | Element} - formated form value
 */
export function formatFormValue(formValue, originalValue, valueForLabel) {
	if (formValue != originalValue) {
		if (originalValue && !formValue && !valueForLabel) {
			return <strong>vynulované</strong>
		}
		return <strong>{valueForLabel || formValue}</strong>
	}
	return valueForLabel || formValue
}

export const createFormInitValues = (initValues) => {
	return {
		...initValues,
		INIT_TIMESTAMP: new Date() // this init timestamp is for dispaching redux-form/UPDATE_SYNC_ERRORS actions everty time after init form values
	}
}

export const isValidacneKriteriumError = (kriterium) => {
	const statusTyp = get(kriterium, 'statusTyp')
	return statusTyp !== WARN && statusTyp !== INFO
}

export const isValidacneKriteriumWarning = (kriterium) => {
	const statusTyp = get(kriterium, 'statusTyp')
	return statusTyp === WARN
}
