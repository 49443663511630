// noinspection JSUnusedAssignment

import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, some, find, isEmpty, filter, without, size, sumBy, uniqBy } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import reactQueryParams from 'react-query-params'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'
import dayjs from 'dayjs'

// components
import ElementLoading from '../../ElementLoading'
import ElementEmptyContent from '../../ElementEmptyContent'
import FormInfo from '../../FormInfo'
import PreplatkySearchResultRow from '../../TableRows/PreplatkySearchResultRow'

import validate from './validateForm'

// atoms
import CheckBoxField from '../../../atoms/CheckboxField'

// utils
import { FORMS, PREPLATOK_TYP, POHLADAVKA_ZDROJ_DAT } from '../../../utils/enums'
import { isValidacneKriteriumError } from '../../../utils/form'

class VrateniePreplatkovSearch extends reactQueryParams {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape(),
		koKriteriaHandler: PropTypes.func.isRequired,
		filters: PropTypes.shape()
	}

	_mounted = false

	componentDidMount = () => {
		this._mounted = true

		const { filters, pohladavkyActions } = this.props

		pohladavkyActions.loadAllPreplatky(filters)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	selectAll = () => {
		const { change, formValues, preplatky } = this.props

		const eligiblePreplatky = filter(get(preplatky, 'data', []), (pohladavka) => {
			return (
				(isEmpty(get(pohladavka, 'odkladSplatnostiDo', [])) ||
					!some(get(pohladavka, 'odkladSplatnostiDo', []), (splatnost) => dayjs(splatnost) >= dayjs().startOf('day'))) &&
				!get(pohladavka, 'platobnyPrikaz') &&
				get(pohladavka, 'typ.id') != PREPLATOK_TYP.PREPLATOK_Z_FAKTURACIE.value &&
				get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU &&
				(!get(pohladavka, 'splatnostDatum') || dayjs(get(pohladavka, 'splatnostDatum')) < dayjs().startOf('day'))
			)
		})

		change('preplatky', size(get(formValues, 'preplatky', [])) < size(eligiblePreplatky) ? eligiblePreplatky : [])
	}

	formatSearchResult = () => {
		const { preplatky, formValues, change, t } = this.props

		if (get(preplatky, 'isLoading')) {
			return (
				<div className='cornered-loading' style={{ minHeight: '118px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(preplatky, 'isFailure')) {
			return (
				<div className='alert' data-color='red'>
					{t('translation:Preplatky.Pri vyhľadávaní preplatkov nastala chyba')}
				</div>
			)
		}

		if (isEmpty(get(preplatky, 'data', []))) {
			return (
				<div className='box-content' style={{ minHeight: '318px' }}>
					<ElementEmptyContent text={t('translation:Preplatky.Zadanému filtru nekorešponduje žiadny preplatok')} style={{ borderRadius: '5px' }} />
				</div>
			)
		}

		return (
			<table className='search-results-table no-pointer' cellSpacing='0'>
				<thead>
					<tr>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Číslo faktúry')}</th>
						<th style={{ whiteSpace: 'nowrap', width: '15%' }}>{t('translation:Preplatky.filter.Typ')}</th>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Číslo dokladu')}</th>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Dátum dokladu')}</th>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Splatnosť')}</th>
						<th style={{ textAlign: 'right', width: '10%' }}>{t('translation:Preplatky.filter.Suma')}</th>
						<th style={{ textAlign: 'right', width: '10%' }}>{t('translation:Preplatky.filter.Otvorená suma')}</th>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Odklad splatnosti')}</th>
						<th style={{ width: '10%' }}>{t('translation:Preplatky.filter.Platobný príkaz')}</th>
						<th className='checkbox-column' />
					</tr>
				</thead>
				<tbody data-empty={t('translation:Common.Nenašli sa žiadne výsledky')}>
					{map(get(preplatky, 'data', []), (pohladavka, index) => {
						let disabledCheckbox
						let disabledCheckboxText
						if (some(get(pohladavka, 'odkladSplatnostiDo', []), (splatnost) => dayjs(splatnost) >= dayjs().startOf('day'))) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:Preplatky.Preplatok s odkladom splatnosti nie je možné zvoliť')
						} else if (get(pohladavka, 'platobnyPrikaz')) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:Preplatky.Preplatok s platobným príkazom nie je možné zvoliť')
						} else if (get(pohladavka, 'typ.id') == PREPLATOK_TYP.PREPLATOK_Z_FAKTURACIE.value) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:Preplatky.Preplatok z fakturácie nie je možné zvoliť')
						} else if (get(pohladavka, 'zdrojDat.id') != POHLADAVKA_ZDROJ_DAT.SAP_ISU) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:Preplatky.Vrátenie je možné len pre preplatky na komoditných ZÚ')
						} else if (get(pohladavka, 'splatnostDatum') && dayjs(get(pohladavka, 'splatnostDatum')) >= dayjs().startOf('day')) {
							disabledCheckbox = true
							disabledCheckboxText = t('translation:Preplatky.Vrátenie je možné len pre preplatky po splatnosti')
						}
						return (
							<PreplatkySearchResultRow
								checkRows
								t={t}
								key={`faktura_${index}`}
								pohladavka={pohladavka}
								preplatky={get(formValues, 'preplatky', [])}
								disabledCheckbox={disabledCheckbox}
								disabledCheckboxText={disabledCheckboxText}
								onClickCheckbox={async () => {
									const selectedPohladavka = find(
										get(formValues, 'preplatky', []),
										(selectedPohladavka) =>
											get(pohladavka, 'cislo') == get(selectedPohladavka, 'cislo') &&
											get(pohladavka, 'typ.id') == get(selectedPohladavka, 'typ.id')
									)
									let newPreplatky = []
									if (selectedPohladavka) {
										if (!get(selectedPohladavka, 'variabilnySymbol')) {
											newPreplatky = without(get(formValues, 'preplatky', []), selectedPohladavka)
										} else {
											newPreplatky = filter(get(formValues, 'preplatky', []), (item) => {
												return (
													!get(item, 'variabilnySymbol') ||
													get(item, 'variabilnySymbol') != get(selectedPohladavka, 'variabilnySymbol')
												)
											})
										}
									} else if (!get(pohladavka, 'variabilnySymbol')) {
										newPreplatky = [...get(formValues, 'preplatky', []), pohladavka]
									} else {
										newPreplatky = uniqBy(
											[
												...get(formValues, 'preplatky', []),
												...filter(get(preplatky, 'data', []), (item) => {
													return get(item, 'variabilnySymbol') == get(pohladavka, 'variabilnySymbol')
												})
											],
											(pohladavka) => {
												return `${get(pohladavka, 'id')}_${get(pohladavka, 'typ.id')}`
											}
										)
									}
									await change('preplatky', newPreplatky)
								}}
							/>
						)
					})}
				</tbody>
			</table>
		)
	}

	render = () => {
		const {
			filters,
			formTitle,
			handleSubmit,
			invalid,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			onBackClick,
			onCancelClick,
			formValues,
			preplatky,
			pohladavkyZostatok,
			t
		} = this.props

		const eligiblePreplatky = filter(get(preplatky, 'data', []), (pohladavka) => {
			return (
				(isEmpty(get(pohladavka, 'odkladSplatnostiDo', [])) ||
					!some(get(pohladavka, 'odkladSplatnostiDo', []), (splatnost) => dayjs(splatnost) >= dayjs().startOf('day'))) &&
				!get(pohladavka, 'platobnyPrikaz') &&
				get(pohladavka, 'typ.id') != PREPLATOK_TYP.PREPLATOK_Z_FAKTURACIE.value &&
				get(pohladavka, 'zdrojDat.id') == POHLADAVKA_ZDROJ_DAT.SAP_ISU &&
				(!get(pohladavka, 'splatnostDatum') || dayjs(get(pohladavka, 'splatnostDatum')) < dayjs().startOf('day'))
			)
		})

		// result from procesnyKonfigurator if form is valid
		const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium && isEmpty(invalidKoKriteria)
		const disableSubmit = !isValid || invalid || isEmpty(get(formValues, 'preplatky', []))

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (isEmpty(get(formValues, 'preplatky', []))) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					html={<span>{t('translation:Preplatky.validate.Vyberte riadky preplatkov na vrátenie')}</span>}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else if (!isEmpty(invalidKoKriteria)) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					html={map(invalidKoKriteria, (koKriterium, index) => (
						<div key={`koKriterium-${index}`}>
							{koKriterium.nazov}: {koKriterium.popis}
						</div>
					))}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					{wrappedSubmitBtn}
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-header'>
					<div className='row'>
						<div className='col-2 header-info'>
							<span>{t('translation:Common.Zmluvný účet')}</span>
							<br />
							<strong>{get(filters, 'zmluvnyUcetCislo')}</strong>
						</div>
						<div className='col-2 header-info'>
							{get(filters, 'variabilnySymbol') && (
								<>
									<span>{t('translation:Common.Číslo faktúry')}</span>
									<br />
									<strong>{get(filters, 'variabilnySymbol')}</strong>
								</>
							)}
						</div>
						<div className='col-2' />
						<div className='col-2' style={{ padding: '10px 20px 20px 20px' }}>
							<span>{t('translation:Preplatky.Preplatky')}</span>
							<br />
							<strong>
								<NumericFormat
									thousandSeparator={' '}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									displayType='text'
									suffix=' €'
									value={get(pohladavkyZostatok, 'data.sumaPreplatky')}
								/>
							</strong>
						</div>
						<div className='col-2' style={{ padding: '12px 20px 12px 20px' }}>
							<span>{t('translation:Preplatky.Preplatky na vrátenie')}</span>
							<br />
							<strong>
								<NumericFormat
									thousandSeparator={' '}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									displayType='text'
									suffix=' €'
									value={sumBy(get(formValues, 'preplatky', []), 'sumaOtvorena')}
								/>
							</strong>
						</div>
						<div className='col-2' style={{ padding: '12px 20px 12px 20px' }}>
							<div className='pull-right' style={{ fontSize: '12px' }}>
								<CheckBoxField
									label={t('translation:Common.Označiť všetky')}
									input={{
										value: size(get(formValues, 'preplatky', [])) == size(eligiblePreplatky) && size(get(formValues, 'preplatky', [])) > 0,
										onChange: this.selectAll,
										label: ''
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo koKriteria={koKriteria} />
						{this.formatSearchResult()}
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.VRATENIE_PREPLATKOV_VYTVORENIE_VYBER_POHLADAVOK,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(VrateniePreplatkovSearch)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.VRATENIE_PREPLATKOV_VYTVORENIE_VYBER_POHLADAVOK)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
