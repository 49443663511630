import { get, filter, orderBy } from 'lodash'

import { OBCHODNY_PARTNER_LOAD_DONE, OBCHODNY_PARTNER_LOAD_START, OBCHODNY_PARTNER_LOAD_FAIL } from '../../types/obchodnyPartner'
import { getReq } from '../../utils/request'

export function partnerLoadStart() {
	return {
		type: OBCHODNY_PARTNER_LOAD_START
	}
}

export function partnerLoadDone(partner) {
	return {
		type: OBCHODNY_PARTNER_LOAD_DONE,
		payload: {
			partner
		}
	}
}

export function partnerLoadFail() {
	return {
		type: OBCHODNY_PARTNER_LOAD_FAIL
	}
}

export const loadObchodnyPartnerDetail = (opCislo) => {
	return async (dispatch) => {
		dispatch(partnerLoadStart())
		try {
			const { response } = await getReq(`/api/v2/op/${opCislo}`)

			const obchodnyPartner = get(response, 'content')

			const titulyPredMenom = orderBy(
				filter(get(obchodnyPartner, 'tituly'), (op) => get(op, 'hodnota.pred_menom')),
				['poradie'],
				['asc']
			)
				.map((op) => get(op, 'hodnota.nazov'))
				.join(' ')
			const titulyZaMenom = orderBy(
				filter(get(obchodnyPartner, 'tituly'), (op) => !get(op, 'hodnota.pred_menom')),
				['poradie'],
				['asc']
			)
				.map((op) => get(op, 'hodnota.nazov'))
				.join(' ')

			dispatch(
				partnerLoadDone({
					...obchodnyPartner,
					titulyPredMenom,
					titulyZaMenom
				})
			)
		} catch (e) {
			dispatch(partnerLoadFail())
			throw new Error('Load ObchodnyPartnerDetail failed', { cause: e })
		}
	}
}
