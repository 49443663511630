import { uniqBy, get } from 'lodash'
import {
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE
} from '../../types/obchodnyPartner'
import { getReq } from '../../utils/request'
import { minutesBetween } from '../../utils/date'

export function odberneMiestaAllLoadStart() {
	return {
		type: OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_START
	}
}

export function odberneMiestaAllLoadDone() {
	return {
		type: OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_DONE
	}
}

export function odberneMiestaAllLoadFail() {
	return {
		type: OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_FAIL
	}
}

export function odberneMiestaAllLoadPartDone(odberneMiesta) {
	return {
		type: OBCHODNY_PARTNER_ODBERNE_MIESTA_ALL_LOAD_PART_DONE,
		payload: {
			odberneMiesta
		}
	}
}

/**
 *  Load all odberne miesta by recursive funcion
 * @param cisloOP
 * @param dispatch
 * @param pageSize
 * @param getStore
 * @return {Promise<void>}
 */
const loadOdberneMiestaByParts = async (cisloOP, dispatch, pageSize, getStore) => {
	const loadPage = async (page) => {
		const query = {
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const res = await getReq(`/api/v0/obchodni-partneri/${cisloOP}/odberne-miesta`, query)
			const { strankovanie } = res.response
			if (!strankovanie) {
				return
			}

			const odberneMiesta = res.response.obsah
			const store = getStore()
			const { allData } = store.obchodnyPartner.odberneMiesta
			const data = {
				odberneMiesta: uniqBy([...get(allData, 'odberneMiesta', []), ...odberneMiesta.odberneMiesta], 'cislo'),
				miestaSpotreby: uniqBy([...get(allData, 'miestaSpotreby', []), ...odberneMiesta.miestaSpotreby], 'cislo'),
				zmluvneUcty: uniqBy([...get(allData, 'zmluvneUcty', []), ...odberneMiesta.zmluvneUcty], 'cislo'),
				zmluvneVztahy: uniqBy([...get(allData, 'zmluvneVztahy', []), ...odberneMiesta.zmluvneVztahy], 'cislo')
			}
			dispatch(odberneMiestaAllLoadPartDone(data))

			const { zaznamov, velkostStranky } = res.response.strankovanie
			if (velkostStranky * page < zaznamov) {
				const nextPage = page + 1
				await loadPage(nextPage)
			} else {
				dispatch(odberneMiestaAllLoadDone())
			}
		} catch (err) {
			dispatch(odberneMiestaAllLoadFail())
		}
	}
	await loadPage(1)
}

export const loadAllOdberneMiesta = (cacheTime = 20) => {
	return (dispatch, getStore) => {
		const store = getStore()

		if (store.obchodnyPartner.odberneMiesta.allDataPrepared && minutesBetween(store.obchodnyPartner.odberneMiesta.lastLoad, new Date()) < cacheTime) {
			return
		}
		const { opCislo } = store.interakcie.detail.data
		dispatch(odberneMiestaAllLoadStart())
		loadOdberneMiestaByParts(opCislo, dispatch, 100, getStore)
	}
}
