import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { concat, get, isEmpty, join, map, sortBy } from 'lodash'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { UnmountClosed } from 'react-collapse'
import cx from 'classnames'

// actions
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'
import * as OdberneMiestaActions from '../../actions/OdberneMiestaActions'
import * as HistoriaUkonovActions from '../../actions/UkonyActions'

// components
import ZmluvnyVztahDetailDocuments from './ZmluvnyVztahDetailDocuments'
import ElementLoading from '../ElementLoading'
import ElementFailure from '../ElementFailure'
import Odpocet from './Odpocet'
import UkonList from '../UkonList'

// utils
import { formatDate } from '../../utils/date'
import { formatOp } from '../../utils/obchodniPartneri'
import { getZmenaZmluvnyVztahStateDateLabel, getZmenaZmluvnyVztahStateDate } from '../../utils/zmluvneVztahy'
import {
	FILTER_SELECTORS,
	KOMODITA_TYP,
	UKON_LIST_ROW_TYPE,
	ZMLUVNY_VZTAH_TYP,
	ODPOJENIE_STATUS,
	ZMLUVNY_VZTAH_STAV,
	OBCHODNY_PARTNER_TYP
} from '../../utils/enums'

class ZmluvnyVztahDetailItem extends React.Component {
	static propTypes = {
		zvCislo: PropTypes.string.isRequired,
		omCislo: PropTypes.string,
		nested: PropTypes.bool,
		t: PropTypes.func.isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		zmluvnyVztah: PropTypes.shape().isRequired,
		zmluvneVztahyActions: PropTypes.shape().isRequired,
		odberneMiestaActions: PropTypes.shape().isRequired
	}

	_mounted = false

	state = {
		utilityFakty: [],
		visibleDocuments: false,
		visibleMOPAttributesModal: false,
		// CP-3162 - Odberne miesta pridavame do statu, pretože ich nevieme oddeliť v redux store a spôsobilo to, že sa prepisovali
		odberneMiesto: {
			data: null,
			isLoading: true,
			isFailure: false
		},
		isExtraColumnVisible: false
	}

	async componentDidMount() {
		this._mounted = true

		const { zvCislo, omCislo, zmluvneVztahyActions, odberneMiestaActions, obchodnyPartner } = this.props

		const isMop = get(obchodnyPartner, 'typ') === OBCHODNY_PARTNER_TYP.MOP

		const zmluvnyVztahResponse = await zmluvneVztahyActions.loadZmluvnyVztah(zvCislo, true, true, { ...(isMop && { faktyPrioritaMin: 2 }) })

		let odberneMiestoResponse
		if (omCislo) {
			let queryDate
			if (get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.STORNOVANY) {
				queryDate = formatDate(get(zmluvnyVztahResponse, 'stornovanyOd'), '-', 'YYYY-MM-DD')
			}

			if (get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.UKONCENY) {
				queryDate = formatDate(get(zmluvnyVztahResponse, 'ukoncenyOd'), '-', 'YYYY-MM-DD')
			}

			odberneMiestoResponse = await odberneMiestaActions.loadOdberneMiesto(omCislo, false, {
				...(isMop && { faktyPrioritaMin: 2 }),
				...(isMop && get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.UCINNY
					? { platnostOd: formatDate(new Date(), '-', 'YYYY-MM-DD') }
					: { platnostOd: queryDate }),
				platnostDo: queryDate
			})
		}

		const mergedFakty = concat(get(odberneMiestoResponse, 'fakty', []), get(zmluvnyVztahResponse, 'fakty', []))
		const sortedFakty = sortBy(mergedFakty, (fakt) => {
			return get(fakt, 'nazov.nazov', get(fakt, 'nazov', ''))
		})

		this.setState({
			utilityFakty: sortedFakty || [],
			odberneMiesto: {
				data: odberneMiestoResponse || null,
				isLoading: false,
				isFailure: false
			}
		})
	}

	componentWillUnmount() {
		this._mounted = false

		const { zvCislo, omCislo, zmluvneVztahyActions, odberneMiestaActions } = this.props

		zmluvneVztahyActions.clearZmluvnyVztah(zvCislo)
		if (omCislo) {
			odberneMiestaActions.clearOdberneMiesto(omCislo)
		}
		zmluvneVztahyActions.clearDocuments(zvCislo)
	}

	toggleVisibleDocuments = () => {
		if (this._mounted) {
			this.setState({
				visibleDocuments: !this.state.visibleDocuments
			})
		}
	}

	handleModalVisibility = (state) => {
		this.setState({
			visibleMOPAttributesModal: state
		})
	}

	handleExtraColumnVisibility = (state) => {
		this.setState({
			isExtraColumnVisible: state
		})
	}

	handleShowAllAttributes = async () => {
		const { zvCislo, omCislo, zmluvneVztahyActions, odberneMiestaActions } = this.props

		const zmluvnyVztahResponse = await zmluvneVztahyActions.loadZmluvnyVztahDetail(zvCislo, true, true, { faktyPrioritaMin: 1 })
		if (omCislo) {
			let queryDate
			if (get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.STORNOVANY) {
				queryDate = formatDate(get(zmluvnyVztahResponse, 'stornovanyOd'), '-', 'YYYY-MM-DD')
			}

			if (get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.UKONCENY) {
				queryDate = formatDate(get(zmluvnyVztahResponse, 'ukoncenyOd'), '-', 'YYYY-MM-DD')
			}

			await odberneMiestaActions.loadOdberneMiestoDetail(omCislo, false, {
				faktyPrioritaMin: 1,
				...(get(zmluvnyVztahResponse, 'stav.id') === ZMLUVNY_VZTAH_STAV.UCINNY
					? { platnostOd: formatDate(new Date(), '-', 'YYYY-MM-DD') }
					: { platnostOd: queryDate }),
				platnostDo: queryDate
			})
		}

		this.handleModalVisibility(true)
	}

	render() {
		const { zvCislo, zmluvnyVztah, nested, interakcia, t, obchodnyPartner, odberneMiestoDetailFakty, zmluvnyVztahDetailFakty } = this.props
		const { odberneMiesto, visibleMOPAttributesModal, utilityFakty, isExtraColumnVisible } = this.state

		const { visibleDocuments } = this.state

		if (!this._mounted || get(zmluvnyVztah, 'isLoading') || get(odberneMiesto, 'isLoading')) {
			return (
				<div style={{ position: 'relative', minHeight: '250px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (get(zmluvnyVztah, 'isFailure') || isEmpty(get(zmluvnyVztah, 'data'))) {
			return (
				<div style={{ position: 'relative', minHeight: '250px' }}>
					<ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať zmluvný vzťah')} />
				</div>
			)
		}
		if (get(odberneMiesto, 'isFailure')) {
			return (
				<div style={{ position: 'relative', minHeight: '250px' }}>
					<ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať odberné miesto')} />
				</div>
			)
		}

		const zmenaStateDateLabel = getZmenaZmluvnyVztahStateDateLabel(get(zmluvnyVztah, 'data'), t)
		const zmenaStateDate = getZmenaZmluvnyVztahStateDate(get(zmluvnyVztah, 'data'), t)
		const spotrebaSkutocna = get(odberneMiesto, 'data.spotrebaSkutocna', [])
		const spotrebaPredpokladana = get(zmluvnyVztah, 'data.spotrebaPredpokladana')
		let spotrebaLabel
		if (!isEmpty(spotrebaSkutocna)) {
			spotrebaLabel = t('translation:ZmluvneUcty.Spotreba - posledná vyúčtovaná')
		} else if (spotrebaPredpokladana) {
			spotrebaLabel = t('translation:ZmluvneUcty.Spotreba - predpokladaná')
		}

		const ukony = get(zmluvnyVztah, 'data.ukony', [])

		const isMop = get(obchodnyPartner, 'typ') === OBCHODNY_PARTNER_TYP.MOP

		let ucelPouzitiaPlynu
		if (get(zmluvnyVztah, 'data.komoditaTyp.id') == KOMODITA_TYP.PLYN) {
			const temp = []
			if (get(zmluvnyVztah, 'data.ucelPouzitiaGas.varenie')) {
				temp.push(t('translation:Common.Varenie'))
			}
			if (get(zmluvnyVztah, 'data.ucelPouzitiaGas.vykurovanie')) {
				temp.push(t('translation:Common.Vykurovanie'))
			}
			if (get(zmluvnyVztah, 'data.ucelPouzitiaGas.tuv')) {
				temp.push(t('translation:Common.TÚV'))
			}
			ucelPouzitiaPlynu = join(temp, ', ') || null
		}

		const mergedFakty = concat(get(odberneMiestoDetailFakty, 'data.fakty', []), get(zmluvnyVztahDetailFakty, 'data.fakty', []))

		const sortedFakty = sortBy(mergedFakty, (fakt) => {
			return get(fakt, 'nazov.nazov', get(fakt, 'nazov', ''))
		})
		return (
			<div className='zmluvny-vztah-detail'>
				<div className='col-6'>
					<table className='content-table' style={{ paddingLeft: nested ? '30px' : undefined }}>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Common.Všeobecné')}</strong>
								</td>
							</tr>
							{get(zmluvnyVztah, 'data.cisloISU') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Číslo zmluvy CRM')}</td>
									<td>
										<strong>{get(zmluvnyVztah, 'data.cislo', '-')}</strong>
									</td>
								</tr>
							)}
							{get(odberneMiesto, 'data.cislo') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Číslo odberného miesta')}</td>
									<td>
										<strong>{get(odberneMiesto, 'data.cislo', '-')}</strong>
									</td>
								</tr>
							)}
							{get(odberneMiesto, 'data.odpojenieStatus.nazov') && get(zmluvnyVztah, 'data.typ.id') !== ZMLUVNY_VZTAH_TYP.VYROBA_KOMODITA && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Stav odpojenia')}</td>
									<td>
										<span
											className='label circle'
											data-color={get(odberneMiesto, 'data.odpojenieStatus.id') == ODPOJENIE_STATUS.NIEJE_ODPOJENE ? 'green' : 'red'}
											style={{ lineHeight: '21px', marginBottom: '2px', marginRight: '5px' }}
										/>
										<strong>{get(odberneMiesto, 'data.odpojenieStatus.nazov', '-')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.okamzitaDodavkaSuhlas') != undefined && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Súhlas s okamžitou dodávkou')}</td>
									<td>
										<strong>
											{get(zmluvnyVztah, 'data.okamzitaDodavkaSuhlas') ? t('translation:Common.Áno') : t('translation:Common.Nie')}
										</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.kampan') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Kampaň')}</td>
									<td>
										<strong>{get(zmluvnyVztah, 'data.kampan')}</strong>
									</td>
								</tr>
							)}
							<tr>
								<td style={{ paddingTop: '20px' }}>
									<strong>{t('translation:Common.Dátumy / Termíny')}</strong>
								</td>
							</tr>
							{get(zmluvnyVztah, 'data.vytvorenyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Zmluva vytvorená od')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.vytvorenyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.stornovanyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Zmluva stornovaná od')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.stornovanyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.platnyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Zmluva platná od')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.platnyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.ucinnyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Zmluva účinná od')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.ucinnyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.ukoncenyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Zmluva ukončená')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.ukoncenyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.tlacOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Dátum tlače')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.tlacOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.prijatyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Prijatie podpísanej zmluvy')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.prijatyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{get(zmluvnyVztah, 'data.ziadanyOd') && (
								<tr>
									<td>{t('translation:ZmluvneUcty.Dátum prijatia žiadosti')}</td>
									<td>
										<strong>{formatDate(get(zmluvnyVztah, 'data.ziadanyOd'), '-', 'DD.MM.YYYY')}</strong>
									</td>
								</tr>
							)}
							{zmenaStateDateLabel && zmenaStateDate && (
								<tr>
									<td>{zmenaStateDateLabel}</td>
									<td>
										<strong>{zmenaStateDate}</strong>
									</td>
								</tr>
							)}
							{(!isEmpty(get(zmluvnyVztah, 'data.predajca')) ||
								!isEmpty(get(zmluvnyVztah, 'data.riesitel')) ||
								!isEmpty(get(zmluvnyVztah, 'data.nadriadeny'))) && (
								<>
									<tr>
										<td style={{ paddingTop: '20px' }}>
											<strong>{t('translation:Common.Pracovníci')}</strong>
										</td>
									</tr>
									{!isEmpty(get(zmluvnyVztah, 'data.predajca')) && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Predajca')}</td>
											<td>
												<strong>{formatOp(get(zmluvnyVztah, 'data.predajca'))}</strong>
											</td>
										</tr>
									)}
									{!isEmpty(get(zmluvnyVztah, 'data.riesitel')) && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Riešiteľ')}</td>
											<td>
												<strong>{formatOp(get(zmluvnyVztah, 'data.riesitel'))}</strong>
											</td>
										</tr>
									)}
									{!isEmpty(get(zmluvnyVztah, 'data.nadriadeny')) && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Nadriadený')}</td>
											<td>
												<strong>{formatOp(get(zmluvnyVztah, 'data.nadriadeny'))}</strong>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</table>
				</div>
				{get(zmluvnyVztah, 'data.typ.id') == ZMLUVNY_VZTAH_TYP.KOMODITA && (
					<div className='col-6'>
						<table className='content-table'>
							{isMop ? (
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Konfigurácia utilít')}</strong>
										</td>
										<td>
											<div
												className={cx('button subtle no-padding no-margin pull-right', {
													loading: get(zmluvnyVztahDetailFakty, 'isLoading') || get(odberneMiestoDetailFakty, 'isLoading')
												})}
												data-color={'blue'}
												data-type={'link'}
												onClick={() => this.handleShowAllAttributes()}
											>
												{t('translation:ZmluvneUcty.Zobraziť všetky')}
											</div>
										</td>
									</tr>
									{map(utilityFakty, (fakt, index) => (
										<tr key={`fakt-${index}`}>
											<td>{get(fakt, 'nazov.nazov') ?? get(fakt, 'nazov') ?? '-'}</td>
											<td>
												<strong>
													{get(fakt, 'hodnotaPopis') ??
														get(fakt, 'hodnota.nazov') ??
														get(fakt, 'hodnota.id') ??
														get(fakt, 'hodnota') ??
														'-'}
												</strong>
											</td>
										</tr>
									))}
								</tbody>
							) : (
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Konfigurácia utilít')}</strong>
										</td>
									</tr>
									{get(zmluvnyVztah, 'data.procesTyp.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Typ procesu')}</td>
											<td>
												<strong>{get(zmluvnyVztah, 'data.procesTyp.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.distribucnaTarifa.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Distribučná tarifa')}</td>
											<td>
												<strong>{get(zmluvnyVztah, 'data.distribucnaTarifa.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.komoditaTyp.id') == KOMODITA_TYP.PLYN && !isEmpty(spotrebaSkutocna) && spotrebaPredpokladana && (
										<tr>
											<td style={{ verticalAlign: 'top' }}>{t('translation:Common.Distribučná kapacita')}</td>
											<td style={{ fontWeight: 'bold' }}>
												<Odpocet
													withoutLabel={true}
													withoutPeriodLabel={true}
													periodLabelAfterValue={true}
													spotrebaPredpokladana={spotrebaPredpokladana}
													t={t}
												/>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.komoditaTyp.id') == KOMODITA_TYP.PLYN &&
										get(zmluvnyVztah, 'data.spotrebaPredpokladanaM3') >= 0 && (
											<tr>
												<td>{t('translation:Common.Distribučná kapacita (m3)')}</td>
												<td>
													<strong>{get(zmluvnyVztah, 'data.spotrebaPredpokladanaM3')} m3</strong>
												</td>
											</tr>
										)}
									{get(zmluvnyVztah, 'data.vyrocieDK') && (
										<tr>
											<td>{t('translation:Common.Výročie distribučnej kapacity')}</td>
											<td>
												<strong>{formatDate(get(zmluvnyVztah, 'data.vyrocieDK'), null, 'DD.MM.YYYY')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.komoditaTyp.id') == KOMODITA_TYP.PLYN && ucelPouzitiaPlynu && (
										<tr>
											<td>{t('translation:Common.Použitie plynu')}</td>
											<td>
												<strong>{ucelPouzitiaPlynu}</strong>
											</td>
										</tr>
									)}
									{get(odberneMiesto, 'data.hlavnyIsticHodnota') > 0 && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Hodnota hlavného ističa')}</td>
											<td>
												<strong>{get(odberneMiesto, 'data.hlavnyIsticHodnota')} A</strong>
											</td>
										</tr>
									)}
									{get(odberneMiesto, 'data.fazyPocet.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Počet fáz')}</td>
											<td>
												<strong>{get(odberneMiesto, 'data.fazyPocet.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(odberneMiesto, 'data.hdoKod') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.HDO kód')}</td>
											<td>
												<strong>{get(odberneMiesto, 'data.hdoKod', '-')}</strong>
											</td>
										</tr>
									)}
									{get(odberneMiesto, 'data.typMerania.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Typ merania')}</td>
											<td>
												<strong>{get(odberneMiesto, 'data.typMerania.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.tarifikacia.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Tarifikácia')}</td>
											<td>
												<strong>{get(zmluvnyVztah, 'data.tarifikacia.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.smart') != undefined && (
										<tr>
											<td>{t('translation:ZmluvneUcty.SMART meradlo')}</td>
											<td>
												<strong>{get(zmluvnyVztah, 'data.smart') ? t('translation:Common.Áno') : t('translation:Common.Nie')}</strong>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.zuctovaniePeriodicita.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Periodicita zúčtovania')}</td>
											<td>
												<strong>{get(zmluvnyVztah, 'data.zuctovaniePeriodicita.nazov', '-')}</strong>
											</td>
										</tr>
									)}
									{get(odberneMiesto, 'data.fakturacnaOblast.nazov.nazov') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Fakturačná oblasť')}</td>
											<td>
												<Tooltip
													title={`${t('translation:Common.Kód')} = ${get(odberneMiesto, 'data.fakturacnaOblast.nazov.id', '-')}`}
													position='top-start'
													trigger='mouseenter'
													theme='light'
												>
													<strong>{get(odberneMiesto, 'data.fakturacnaOblast.nazov.nazov', '-')}</strong>
												</Tooltip>
											</td>
										</tr>
									)}
									{(!isEmpty(spotrebaSkutocna) || spotrebaPredpokladana) && (
										<tr>
											<td>{spotrebaLabel}</td>
											<td style={{ fontWeight: 'bold' }}>
												<Odpocet
													withoutLabel={true}
													withoutPeriodLabel={true}
													periodLabelAfterValue={true}
													spotrebaSkutocna={spotrebaSkutocna}
													spotrebaPredpokladana={spotrebaPredpokladana}
													t={t}
												/>
											</td>
										</tr>
									)}
									{get(zmluvnyVztah, 'data.subjektZuctovania.id') && (
										<tr>
											<td>{t('translation:ZmluvneUcty.Subjekt zúčtovania')}</td>
											<td>
												<Tooltip
													html={get(zmluvnyVztah, 'data.subjektZuctovania.nazov', '-')}
													position='bottom'
													trigger='mouseenter'
													theme='light'
												>
													<strong>{get(zmluvnyVztah, 'data.subjektZuctovania.id', '-')}</strong>
												</Tooltip>
											</td>
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>
				)}
				{get(zmluvnyVztah, 'data.typ.id') == ZMLUVNY_VZTAH_TYP.VYROBA_KOMODITA && (
					<div className='col-6'>
						<table className='content-table'>
							<tbody>
								<tr>
									<td>
										<strong>{t('translation:Common.Konfigurácia utilít')}</strong>
									</td>
								</tr>

								{get(zmluvnyVztah, 'data.procesTyp.nazov') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Typ procesu')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.procesTyp.nazov', '-')}</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.omDruh.nazov') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Druh odovzdávajúceho miesta')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.omDruh.nazov', '-')}</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.vypovednaLehota') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.vypovednaLehota')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.vypovednaLehota', '-')}</strong>
										</td>
									</tr>
								)}

								{get(odberneMiesto, 'data.fazyPocet.nazov') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Počet fáz')}</td>
										<td>
											<strong>{get(odberneMiesto, 'data.fazyPocet.nazov', '-')}</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.zuctovaniePeriodicita.nazov') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Periodicita zúčtovania')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.zuctovaniePeriodicita.nazov', '-')}</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.vykonInstalovany') !== undefined && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Celkový inštalovaný výkon')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.vykonInstalovany', '-')} kW</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.vyrobaPredpokladana') !== undefined && (
									<tr>
										<td>{t('translation:ZmluvneUcty.vyrobaPredpokladana')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.vyrobaPredpokladana', '-')} kWh</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.subjektZuctovania.id') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.Subjekt zúčtovania')}</td>
										<td>
											<Tooltip
												html={get(zmluvnyVztah, 'data.subjektZuctovania.nazov', '-')}
												position='bottom'
												trigger='mouseenter'
												theme='light'
											>
												<strong>{get(zmluvnyVztah, 'data.subjektZuctovania.id', '-')}</strong>
											</Tooltip>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.drivePercent') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.drivePercent')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.drivePercent', '-')} %</strong>
										</td>
									</tr>
								)}

								{get(zmluvnyVztah, 'data.driveRFID') && (
									<tr>
										<td>{t('translation:ZmluvneUcty.driveRFID')}</td>
										<td>
											<strong>{get(zmluvnyVztah, 'data.driveRFID', '-')}</strong>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				)}
				<div className='col-6'>
					<table className='content-table' style={{ marginTop: '20px' }}>
						<tbody>
							<tr style={{ cursor: 'pointer' }} onClick={this.toggleVisibleDocuments}>
								<td>
									<div className='collapsible-subheader clearfix'>
										<div className='pull-left' style={{ paddingRight: '10px' }}>
											<strong>{t('translation:Common.Dokumenty')}</strong>
										</div>
										<div
											className={cx('button', 'pull-left', 'noselect', 'arrow', { opened: visibleDocuments })}
											data-type='icon-button'
											data-icon='arrow'
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td style={{ position: 'relative' }}>
									<UnmountClosed isOpened={visibleDocuments}>
										<ZmluvnyVztahDetailDocuments zvCislo={zvCislo} t={t} />
									</UnmountClosed>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				{!isEmpty(ukony) && (
					<div className='col-12'>
						<table className='content-table' style={{ marginTop: '20px' }}>
							<tbody>
								<tr>
									<td colSpan={2}>
										<strong>{t('translation:Common.Úkony')}</strong>
									</td>
								</tr>
								<UkonList
									uniqueKeyPrefix={`zmluvny-vztah-${zvCislo}-ukon`}
									ukony={ukony}
									interakcia={interakcia}
									zvCislo={get(zmluvnyVztah, 'data.cisloISU') || get(zmluvnyVztah, 'data.cislo')}
									rowType={UKON_LIST_ROW_TYPE.ONE_CELL}
								/>
							</tbody>
						</table>
					</div>
				)}
				{visibleMOPAttributesModal && (
					<div className={cx('fakty-modal', { 'fakty-modal-extra-width': isExtraColumnVisible })}>
						<div className='header'>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<h4 style={{ margin: 0 }}>{t('translation:Common.Konfigurácia utilít')}</h4>
								<div
									className={'button subtle no-padding pull-right'}
									data-color={'blue'}
									data-type={'link'}
									style={{ margin: '0 0 0 10px' }}
									onClick={() => this.handleExtraColumnVisibility(!isExtraColumnVisible)}
								>
									{isExtraColumnVisible ? t('translation:ZmluvneUcty.Skryť kódy') : t('translation:ZmluvneUcty.Zobraziť kódy')}
								</div>
							</div>
							<div
								className={'button subtle no-padding no-margin pull-right'}
								data-color={'blue'}
								data-type={'link'}
								onClick={() => this.handleModalVisibility(false)}
							>
								{t('translation:ZmluvneUcty.Zavrieť')}
							</div>
						</div>
						<div className='content'>
							<table className='content-table'>
								{odberneMiesto && (
									<tbody>
										{map(sortedFakty, (fakt, index) => (
											<tr key={`fakt-${index}`}>
												<td>{get(fakt, 'nazov.nazov') ?? get(fakt, 'nazov') ?? '-'}</td>
												{isExtraColumnVisible && (
													<td style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>{get(fakt, 'kod') ?? get(fakt, 'nazov.id')}</td>
												)}
												<td style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
													{get(fakt, 'hodnotaPopis') ??
														get(fakt, 'hodnota.nazov') ??
														get(fakt, 'hodnota.id') ??
														get(fakt, 'hodnota') ??
														'-'}
												</td>
											</tr>
										))}
									</tbody>
								)}
							</table>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const zvCislo = get(ownProps, 'zvCislo')
	return {
		interakcia: get(state, 'interakcie.detail.data'),
		zmluvnyVztah: get(state, `zmluvneVztahy.details[${zvCislo}]`),
		obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
		zmluvnyVztahDetailFakty: get(state, `zmluvneVztahy.detailFakty`),
		odberneMiestoDetailFakty: get(state, 'odberneMiesta.detailFakty'),
		selectedFiltersSidebarHistory: get(state, `selectedFilters[${FILTER_SELECTORS.SIDEBAR_HISTORY}]`, {})
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch),
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	historiaUkonovActions: bindActionCreators(HistoriaUkonovActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(ZmluvnyVztahDetailItem)
