import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import { get, toUpper, find, every, some, isEmpty, includes, map, uniqBy, reduce } from 'lodash'
import { withTranslation } from 'react-i18next'

// components
import ImportPodpisanychDokumentovModal from '../Modals/ImportPodpisanychDokumentovModal'
import VyberZaslaniaDokumentu from '../Modals/VyberZasielaniaDokumentu'
import ElementLoading from '../ElementLoading'
import CommonContainer from './CommonContainer'

// actions
import * as ZmluvneUctyActions from '../../actions/ZmluvneUctyActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'

// utils
import { openDataUriWindow } from '../../utils/files'
import { formatAddress } from '../../utils/address'
import { SCENARE, NOTIFICATION_TYPES, PODPIS_MIESTO } from '../../utils/enums'
import { checkPermissions } from '../../utils/permissionsHoc'

class PodpisovanieDokumentov extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		podpisovanieDokumentovAction: PropTypes.shape({
			signFile: PropTypes.func.isRequired,
			clearSignFile: PropTypes.func.isRequired,
			setNotification: PropTypes.func.isRequired,
			clearNotification: PropTypes.func.isRequired,
			importSignedFile: PropTypes.func.isRequired
		}).isRequired,
		notification: PropTypes.shape(),
		additionalNotification: PropTypes.shape(),
		templatePdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		signedPdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		scenarioOption: PropTypes.shape(),
		schvalovacieKriteria: PropTypes.array,
		t: PropTypes.func.isRequired,
		title: PropTypes.string,
		zmluvneUcty: PropTypes.shape(),
		zmluvneUctyActions: PropTypes.shape(),
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		suhlasVerzia: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		this.state = {
			showImportSignedPdfModal: false,
			showNotificationModal: false,
			showAdditionalNotificationModal: false,
			showChangeDocumentButton: false,
			mozneScenare: null,
			readyToUploadSignedPdf: false
		}
	}

	getPdfForSigning = () => {
		const { auth, ukonNovy, scenarioOption, podpisovanieDokumentovAction, interakcia, suhlasVerzia } = this.props

		if (get(scenarioOption, 'documentRequired', true) === false) return

		const allWithoutSignature = every(get(scenarioOption, 'scenarios', []), {
			allowedDocumentWithoutSignature: true
		})

		const someWithoutSignature = some(get(scenarioOption, 'scenarios', []), {
			allowedDocumentWithoutSignature: true
		})

		if (someWithoutSignature && !allWithoutSignature) {
			// eslint-disable-next-line no-console
			console.log('WARNING: allowedDocumentWithoutSignature should be set for all scenarios')
		}

		if (get(scenarioOption, 'generateDocumentFromCes', false)) {
			// Nacitam template dokumentu na podpis z CES API
			podpisovanieDokumentovAction.loadPdfForSigningFromCes(interakcia?.opCislo, suhlasVerzia?.id, PODPIS_MIESTO)
		} else {
			// Nacitam template dokumentu na podpis
			podpisovanieDokumentovAction.loadPdfForSigning(get(ukonNovy, 'id'), get(auth, 'businessChannel.actual.id'), allWithoutSignature)
		}
	}

	componentDidMount() {
		const { zmluvneUcty, zmluvneUctyActions } = this.props

		if (isEmpty(get(zmluvneUcty, 'data', [])) && !get(zmluvneUcty, 'isLoading')) {
			zmluvneUctyActions.loadAllZmluvneUcty()
		}

		this.getPdfForSigning()
	}

	componentWillUnmount() {
		const { podpisovanieDokumentovAction } = this.props

		// Vymazem podpisany subor z reduxu
		podpisovanieDokumentovAction.clearSignFile()
		podpisovanieDokumentovAction.resetTemplatePdf()

		// Vymazem notifikaciu
		podpisovanieDokumentovAction.clearNotification()
	}

	handleImportSignedDocuments = ({ name, uploadedDocumentAsBase64, type }) => {
		const { podpisovanieDokumentovAction } = this.props

		// set uploaded file to redux store
		podpisovanieDokumentovAction.importSignedFile(name, uploadedDocumentAsBase64, type)

		// close modal
		this.setState({
			showImportSignedPdfModal: false,
			showChangeDocumentButton: true
		})
	}

	onSubmitNotificationModal = (notification) => {
		const { scenarioOption, schvalovacieKriteria, podpisovanieDokumentovAction } = this.props

		podpisovanieDokumentovAction.setNotification(notification)

		this.setState({
			showNotificationModal: false
		})

		if (
			get(scenarioOption, 'additionalNotification.required') == true &&
			includes(get(scenarioOption, 'additionalNotification.rules.types', []), get(notification, 'typ')) &&
			!some(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok'))
		) {
			podpisovanieDokumentovAction.setAdditionalNotification({
				typ: get(scenarioOption, 'additionalNotification.defaultType')
			})
		}
	}

	onSubmitAdditionalNotificationModal = (notification) => {
		const { podpisovanieDokumentovAction } = this.props

		podpisovanieDokumentovAction.setAdditionalNotification(notification)

		this.setState({
			showAdditionalNotificationModal: false
		})
	}

	render() {
		const {
			title,
			auth,
			templatePdf,
			podpisovanieDokumentovAction,
			signedPdf,
			notification,
			additionalNotification,
			scenarioOption,
			zmluvneUcty,
			schvalovacieKriteria,
			t
		} = this.props

		const { showImportSignedPdfModal, showNotificationModal, showAdditionalNotificationModal, readyToUploadSignedPdf, showChangeDocumentButton } =
			this.state

		const mappedEFakturaEmaily = map(get(zmluvneUcty, 'data', []), (zmluvnyUcet) => {
			return get(zmluvnyUcet, 'eFakturaEmaily', [])
		})
		const reducedEFakturaEmaily = reduce(
			mappedEFakturaEmaily,
			(allEmails, otherEmails) => {
				return allEmails.concat(otherEmails)
			},
			[]
		)
		const eFakturaEmaily = uniqBy(reducedEFakturaEmaily, 'email')

		// if any scenar does not exist for current kanal and ukon return null
		if (!scenarioOption) {
			return null
		}

		const { scenarios } = scenarioOption

		let podpisatNaMiesteButton = null
		let vytlacitANahratButton = null
		let ibaNahratButton = null
		let changeDocumentButton = null
		let modal = null
		let notificationBtn = null
		let additionalNotificationBtn = null

		if (get(signedPdf, 'isLoading')) {
			return (
				<CommonContainer>
					<tr>
						<td>
							<div style={{ background: 'white', padding: '20px', borderRadius: '4px', border: '2px solid #E70000' }}>
								<strong>{t('components:PodpisovanieDokumentov.Prebieha podpisovanie dokumentu')}</strong>
								<br />
								<small>{t('components:PodpisovanieDokumentov.Prosím podpíšte dokument na externom zariadení')}</small>
								<button
									type='button'
									className='button small pull-right'
									// data-type="outline"
									data-color='red'
									style={{ marginLeft: '10px', height: '32px' }}
									onClick={() => podpisovanieDokumentovAction.cancelSigningPDF()}
								>
									{t('components:PodpisovanieDokumentov.Zrušiť podpisovanie')}
								</button>
							</div>
						</td>
					</tr>
				</CommonContainer>
			)
		}

		if (get(templatePdf, 'isLoading')) {
			return (
				<CommonContainer>
					<tr>
						<td colSpan='2'>
							<ElementLoading />
						</td>
					</tr>
				</CommonContainer>
			)
		}

		//  Ak nacitanie suboru na podpis zlyhalo
		if (get(templatePdf, 'isFailure')) {
			return (
				<CommonContainer>
					<tr>
						<td>{t('components:PodpisovanieDokumentov.Nepodarilo sa načítať dokument')}</td>
						<td style={{ textAlign: 'right' }}>
							<button
								type='button'
								className='button small'
								data-type='outline'
								data-color='blue'
								style={{ marginLeft: '10px', height: '32px' }}
								onClick={this.getPdfForSigning}
							>
								{t('components:PodpisovanieDokumentov.Znovu načítať dokument')}
							</button>
						</td>
					</tr>
				</CommonContainer>
			)
		}

		let documentUrl = '#'
		let fileName = ''
		if (get(signedPdf, 'data')) {
			documentUrl = `data:${get(signedPdf, 'data.type')};base64,${get(signedPdf, 'data.dataAsBase64')}`
			fileName = get(signedPdf, 'data.name')
		} else if (get(templatePdf, 'data')) {
			documentUrl = `data:application/pdf;base64,${get(templatePdf, 'data.dataAsBase64')}`
			fileName = get(templatePdf, 'data.name')
		}

		// Ak neexistuje podpisany subor zobraz subor na podpis alebo nahratie podpisaneho suboru - podla zvoleneho scenaru
		if (!get(signedPdf, 'data') && isEmpty(get(scenarioOption, 'onlyShowDocuments', []))) {
			// Scenar VYTLACIT_A_NAHRAT
			const scenarVytlacitNahrat = find(scenarios, {
				typ: SCENARE.VYTLACIT_A_NAHRAT
			})

			let scenarVytlacitNahratHasPermission = true
			if (!isEmpty(get(scenarVytlacitNahrat, 'allowedRoles', []))) {
				scenarVytlacitNahratHasPermission = checkPermissions(get(auth, 'user.roles', []), get(scenarVytlacitNahrat, 'allowedRoles', []))
			}

			if (scenarVytlacitNahrat && scenarVytlacitNahratHasPermission) {
				vytlacitANahratButton = (
					<button
						type='button'
						className='button pull-right'
						data-type='icon-button'
						data-icon='print'
						style={{ marginLeft: '10px' }}
						onClick={() => {
							openDataUriWindow(documentUrl, fileName)
							this.setState({
								readyToUploadSignedPdf: true
							})
						}}
					/>
				)
				if (readyToUploadSignedPdf) {
					vytlacitANahratButton = (
						<button
							type='button'
							className='button small pull-right'
							data-type='outline'
							data-color='blue'
							style={{ marginLeft: '10px', height: '32px' }}
							onClick={() =>
								this.setState({
									showImportSignedPdfModal: true
								})
							}
						>
							{t('components:PodpisovanieDokumentov.Nahrať podpísané dokumenty')}
						</button>
					)
				}
			}

			// Scenar PODPISANIE_NA_MIESTE
			const scenarPodpisatNaMieste = find(scenarios, {
				typ: SCENARE.PODPISANIE_NA_MIESTE
			})

			let scenarPodpisatNaMiesteHasPermission = true
			if (!isEmpty(get(scenarPodpisatNaMieste, 'allowedRoles', []))) {
				scenarPodpisatNaMiesteHasPermission = checkPermissions(get(auth, 'user.roles', []), get(scenarPodpisatNaMieste, 'allowedRoles', []))
			}

			if (scenarPodpisatNaMieste && scenarPodpisatNaMiesteHasPermission && !readyToUploadSignedPdf) {
				podpisatNaMiesteButton = (
					<button
						type='button'
						className='button small pull-right'
						data-color='blue'
						style={{ marginLeft: '10px', height: '32px' }}
						onClick={() =>
							podpisovanieDokumentovAction.signFile({ name: get(templatePdf, 'data.name'), data: get(templatePdf, 'data.dataAsBase64') })
						}
					>
						{t('components:PodpisovanieDokumentov.Podpísať')}
					</button>
				)
			}

			// Scenar NAHRAT
			const scenarIbaNahrat = find(scenarios, {
				typ: SCENARE.NAHRAT
			})

			let scenarIbaNahratHasPermission = true
			if (!isEmpty(get(scenarIbaNahrat, 'allowedRoles', []))) {
				scenarIbaNahratHasPermission = checkPermissions(get(auth, 'user.roles', []), get(scenarIbaNahrat, 'allowedRoles', []))
			}

			if (scenarIbaNahrat && scenarIbaNahratHasPermission && !readyToUploadSignedPdf) {
				ibaNahratButton = (
					<button
						type='button'
						className='button small pull-right'
						data-type='outline'
						data-color='blue'
						style={{ marginLeft: '10px', height: '32px' }}
						onClick={() =>
							this.setState({
								showImportSignedPdfModal: true
							})
						}
					>
						{t('components:PodpisovanieDokumentov.Nahrať podpísanú žiadosť')}
					</button>
				)
			}
		} else if (!get(notification, 'typ') && get(scenarioOption, 'notificationRequired', true)) {
			// Ak existuje podpisany subor a notifikacia nieje zvolena, zobraz tlacidlo na nastavenie notifikacie
			notificationBtn = (
				<button
					type='button'
					className='button small pull-right'
					data-type='outline'
					data-color='blue'
					style={{ marginLeft: '10px', height: '32px' }}
					onClick={() =>
						this.setState({
							showNotificationModal: true
						})
					}
				>
					{t('components:PodpisovanieDokumentov.Nastaviť notifikáciu')}
				</button>
			)
		}

		// Ak notifikacia neexistuje
		if (!get(notification, 'typ')) {
			const scenarOdoslatBezPodpisu = find(scenarios, {
				typ: SCENARE.ODOSLAT_BEZ_PODPISU
			})
			const scenarOdoslatNaPodpis = find(scenarios, {
				typ: SCENARE.ODOSLAT_NA_PODPIS
			})

			let hasPermission = true

			if (!isEmpty(get(scenarOdoslatBezPodpisu, 'allowedRoles', [])) || !isEmpty(get(scenarOdoslatNaPodpis, 'allowedRoles', []))) {
				// merge allowed roles from both
				const mergedRoles = [...get(scenarOdoslatBezPodpisu, 'allowedRoles', []), ...get(scenarOdoslatNaPodpis, 'allowedRoles', [])]
				hasPermission = checkPermissions(get(auth, 'user.roles', []), mergedRoles)
			}

			if (hasPermission && (scenarOdoslatBezPodpisu || scenarOdoslatNaPodpis)) {
				notificationBtn = (
					<button
						type='button'
						className='button small'
						data-type='outline'
						data-color='blue'
						style={{ marginLeft: '10px', height: '32px' }}
						onClick={() =>
							this.setState({
								showNotificationModal: true
							})
						}
					>
						{t('components:PodpisovanieDokumentov.Nastaviť notifikáciu')}
					</button>
				)
			}
		} else if (
			!get(additionalNotification, 'typ') &&
			get(scenarioOption, 'additionalNotification.required') == true &&
			includes(get(scenarioOption, 'additionalNotification.rules.types', []), get(notification, 'typ')) &&
			some(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok'))
		) {
			additionalNotificationBtn = (
				<button
					type='button'
					className='button small'
					data-type='outline'
					data-color='blue'
					style={{ marginLeft: '10px', marginTop: '10px', height: '32px' }}
					onClick={() =>
						this.setState({
							showAdditionalNotificationModal: true
						})
					}
				>
					{t('components:PodpisovanieDokumentov.Nastaviť doplňujúcu notifikáciu')}
				</button>
			)
		}

		if (showChangeDocumentButton) {
			changeDocumentButton = (
				<button
					type='button'
					className='button small'
					data-type='outline'
					data-color='blue'
					style={{ marginLeft: '10px', height: '32px' }}
					onClick={() =>
						this.setState({
							showImportSignedPdfModal: true
						})
					}
				>
					{t('components:PodpisovanieDokumentov.Zmeniť dokument')}
				</button>
			)
		}

		if (showImportSignedPdfModal) {
			modal = (
				<ImportPodpisanychDokumentovModal
					modalTitle={t('components:PodpisovanieDokumentov.Vloženie podpísaného dokumentu')}
					onCloseButton={() =>
						this.setState({
							showImportSignedPdfModal: false
						})
					}
					onSubmit={this.handleImportSignedDocuments}
				/>
			)
		}

		let selectedNotification = null
		if (get(notification, 'typ')) {
			const notificationText = toUpper(
				`${t('components:PodpisovanieDokumentov.Zvolený typ notifikácie')} ${this.formatNotificationType(get(notification, 'typ'))}`
			)
			const email = get(notification, 'email')
			const address = formatAddress(get(notification, 'address'))
			selectedNotification = (
				<div>
					<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
						{notificationText}
					</span>
					<br />
					{(email || address) && (
						<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
							{email}
							{address}
						</span>
					)}
				</div>
			)
		}

		let selectedAdditionalNotification = null
		if (get(additionalNotification, 'typ')) {
			const notificationText = toUpper(
				`${t('components:PodpisovanieDokumentov.Zvolený typ doplňujúcej notifikácie')} ${this.formatNotificationType(
					get(additionalNotification, 'typ')
				)}`
			)
			const email = get(additionalNotification, 'email')
			const address = formatAddress(get(additionalNotification, 'address'))
			selectedAdditionalNotification = (
				<div>
					<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
						{notificationText}
					</span>
					<br />
					{(email || address) && (
						<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
							{email}
							{address}
						</span>
					)}
				</div>
			)
		}

		if (showNotificationModal) {
			modal = (
				<VyberZaslaniaDokumentu
					defaultNotificationType={scenarioOption.defaultNotificationType}
					notificationTypes={scenarioOption.notificationTypes}
					modalTitle={t('components:PodpisovanieDokumentov.Zvoľte typ notifikácie')}
					onCloseButton={() => this.setState({ showNotificationModal: false })}
					onSubmit={this.onSubmitNotificationModal}
					documentUrl={documentUrl}
					fileName={fileName}
					eFakturaEmaily={eFakturaEmaily}
				/>
			)
		}
		if (showAdditionalNotificationModal) {
			modal = (
				<VyberZaslaniaDokumentu
					defaultNotificationType={scenarioOption.defaultNotificationType}
					notificationTypes={get(scenarioOption, 'additionalNotification.types', [])}
					modalTitle={t('components:PodpisovanieDokumentov.Zvoľte typ doplňujúcej notifikácie')}
					onCloseButton={() => this.setState({ showAdditionalNotificationModal: false })}
					onSubmit={this.onSubmitAdditionalNotificationModal}
					documentUrl={documentUrl}
					fileName={fileName}
					eFakturaEmaily={eFakturaEmaily}
				/>
			)
		}

		let content = null
		if (get(signedPdf, 'data') || get(templatePdf, 'data') || !get(scenarioOption, 'documentRequired', true)) {
			const templatePdfLabel =
				get(templatePdf, 'data.dataAsBase64') &&
				(find(scenarios, { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) || find(scenarios, { typ: SCENARE.ODOSLAT_NA_PODPIS }))
			content = (
				<tr>
					<td>
						{fileName && (
							<a
								className='file'
								onClick={(e) => {
									e.preventDefault()
									openDataUriWindow(documentUrl, fileName)
								}}
								data-type='general'
								style={{ cursor: 'pointer' }}
							>
								{fileName}
							</a>
						)}
						{get(signedPdf, 'data.dataAsBase64') && (
							<span className='label' style={{ marginLeft: '15px' }} data-color='green'>
								{t('components:PodpisovanieDokumentov.Výstupný dokument podpísaný')}
							</span>
						)}
						{templatePdfLabel && get(scenarioOption, 'documentRequired', true) && (
							<span className='label' style={{ marginLeft: '15px' }} data-color='green'>
								{t('components:PodpisovanieDokumentov.Bude odoslané po dokončení úkonu')}
							</span>
						)}
						{/* NOTE: CP-2223 */}
						{map(get(scenarioOption, 'onlyShowDocuments', []), (document) => (
							<a
								className='file'
								onClick={() => openDataUriWindow(get(document, 'id'), get(document, 'nazov') || get(document, 'name'))}
								data-type='general'
								style={{ cursor: 'pointer', marginRight: '15px' }}
							>
								{get(document, 'nazov') || get(document, 'name')}
							</a>
						))}
					</td>
					<td style={{ textAlign: 'right' }}>
						{changeDocumentButton}
						{vytlacitANahratButton}
						{podpisatNaMiesteButton}
						{ibaNahratButton}
						<div className='pull-right'>
							{notificationBtn}
							{selectedNotification}
							{selectedAdditionalNotification}
							{additionalNotificationBtn}
						</div>
					</td>
				</tr>
			)
		}
		return <CommonContainer title={title} content={content} modal={modal} />
	}

	formatNotificationType = (type) => {
		switch (type) {
			case NOTIFICATION_TYPES.ADDRESS:
				return this.props.t('components:PodpisovanieDokumentov.TypNotifikacie.ADDRESS')
			case NOTIFICATION_TYPES.EMAIL:
				return this.props.t('components:PodpisovanieDokumentov.TypNotifikacie.EMAIL')
			case NOTIFICATION_TYPES.PRINTER:
				return this.props.t('components:PodpisovanieDokumentov.TypNotifikacie.PRINT')
			case NOTIFICATION_TYPES.VOID_NOTIFICATION:
				return this.props.t('components:PodpisovanieDokumentov.TypNotifikacie.VOID_NOTIFICATION')
			default:
				return '-'
		}
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		zmluvneUctyActions: bindActionCreators(ZmluvneUctyActions, dispatch),
		podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	interakcia: get(state, 'interakcie.detail.data'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	additionalNotification: get(state, 'podpisovanieDokumentov.additionalNotification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	zmluvneUcty: get(state, 'zmluvneUcty.collection')
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(PodpisovanieDokumentov)
