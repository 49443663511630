import { filter } from 'lodash'

/**
 * @return string
 *
 * Formating degrees array into beauty string
 * @param {Array.<{ pred_menom: string, nazov: string }>} degrees
 * @param before
 */
export const formatDegrees = (degrees, before = false) => {
	if (!Array.isArray(degrees)) {
		return ''
	}
	const temp = filter(degrees, (item) => {
		return item.hodnota && item.hodnota.pred_menom == before
	}).map((item) => {
		return item.hodnota.nazov
	})
	if (temp.length > 1) {
		return temp.join(', ')
	}
	return temp.join('')
}
