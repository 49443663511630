// noinspection JSUnusedAssignment

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import icon from '../resources/img/icons/empty.svg'

import { setRouteParams, PREHLAD, INDEX } from '../utils/routes'

function NotFoundPage(props) {
	let buttonBack = null
	if (props.interakcia && props.interakcia.data) {
		buttonBack = (
			<Link to={setRouteParams(PREHLAD, get(props.interakcia, 'data.opCislo'))} className='button' data-color='blue'>
				{' '}
				Späť na prehľad
			</Link>
		)
	} else {
		buttonBack = (
			<Link to={INDEX} className='button' data-color='blue'>
				{' '}
				Späť na vyhľadávanie
			</Link>
		)
	}

	return (
		<div className='not-found-page'>
			<h4>404 Not found</h4>

			<div className='failure-content-container lg'>
				<img src={icon} alt={'Stránka nebola nájdená'} />
				<p>Stránka nebola nájdená.</p>
			</div>

			<div className='content'>{buttonBack}</div>
		</div>
	)
}

NotFoundPage.propTypes = {
	interakcia: PropTypes.shape()
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail
})

export default connect(mapStateToProps)(NotFoundPage)
