import React from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, filter, head, map, forEach, set, isEmpty, includes } from 'lodash'
import dayjs from 'dayjs'
import { destroy, change, initialize, reset, getFormValues } from 'redux-form'

// components
import SplnomocneniaSearch from '../../components/Splnomocnenia/SplnomocneniaCreateForm/SplnomocneniaSearch/SplnomocneniaSearch'
import SplnomocneniaInfo from '../../components/Splnomocnenia/SplnomocneniaCreateForm/SplnomocneniaInfo/SplnomocneniaInfo'
import SplnomocneniaConfirm from '../../components/Splnomocnenia/SplnomocneniaCreateForm/SplnomocneniaConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { deleteReq, postReq, putReq } from '../../utils/request'
import {
	UKONY,
	UKONY_CISELNIK,
	FORMS,
	BUSINESS_CHANNELS,
	SCENARE,
	NOTIFICATION_TYPES,
	DOKUMENT_TYP_SPLNOMOCNENIA,
	OBCHODNY_PARTNER_DRUH,
	FILTER_SELECTORS,
	DOKUMENT_TYP
} from '../../utils/enums'
import { getUkonVstupIdByBiznisKanal } from '../../utils/scenar'
import { setRouteParams, VZTAHY_ZOZNAM } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { formatAddressForResponse } from '../../utils/address'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { celeMeno } from '../../utils/obchodnyPartner'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as DataActions from '../../actions/DataActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as FormAddresssesActions from '../../actions/FormAddressesActions'
import * as SearchActions from '../../actions/SearchActions'
import * as StatusActions from '../../actions/StatusActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'

// resources
import failureStateIcon from '../../resources/img/icons/warning.svg'

const scenariosZSE_CENTRUM = {}
scenariosZSE_CENTRUM[DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE] = [
	{ typ: SCENARE.PODPISANIE_NA_MIESTE },
	{ typ: SCENARE.VYTLACIT_A_NAHRAT },
	{ typ: SCENARE.NAHRAT }
]
scenariosZSE_CENTRUM[DOKUMENT_TYP_SPLNOMOCNENIA.VLOZIT_SPLNOMOCNENIE] = [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }]

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: scenariosZSE_CENTRUM[DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: false,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class SplnomocneniaCreatePage extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.any.isRequired
			}).isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		notification: PropTypes.shape(),
		signedPdf: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		t: PropTypes.func.isRequired,
		newOPFormData: PropTypes.shape(),
		splnomocnenieFormData: PropTypes.shape(),
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}).isRequired,
		trackingActions: PropTypes.shape().isRequired,
		formAddressesActions: PropTypes.shape({
			formAddressesInit: PropTypes.func.isRequired
		}).isRequired,
		dataActions: PropTypes.shape().isRequired,
		searchActions: PropTypes.shape().isRequired,
		statusActions: PropTypes.shape().isRequired,
		podpisovanieDokumentovAction: PropTypes.shape({
			resetTemplatePdf: PropTypes.func.isRequired
		}).isRequired,
		selectedFiltersUkony: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.SPLNOMOCNENIA, 'splnomocnenie')

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return scenarioOption.kanal == get(props, 'auth.businessChannel.actual.id')
		})

		this.state = {
			step: 1,
			scenarioOption: head(scenarioOption),
			isLoading: true,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			ProcesnyKonfigurator,
			context: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { dispatch, tracking, trackingActions, dataActions, formAddressesActions, auth, ciselniky, searchActions } = this.props
		const { scenarioOption } = this.state

		searchActions.searchUsersClear()

		dataActions.registerLeavePageModal()

		if (!tracking.startTime || (tracking.startTime && tracking.type !== UKONY.SPLNOMOCNENIE)) {
			trackingActions.tryToStartTracking(UKONY.SPLNOMOCNENIE)
		}

		formAddressesActions.formAddressClean()

		const initOPValues = createFormInitValues({
			/* NOTE: CP-1234 typ: null,
			druh: null, */
			mode: 'EXISTING_OP',
			selectedOP: null,
			oslovenie: null,
			titulPred1: null,
			titulPred2: null,
			titulZa: null,
			meno: null,
			priezvisko: null,
			telefonneCislo: null,
			kontaktnyEmail: null,
			narodenieDatum: null,
			adresaZakaznika: null,
			adresaKorespondencna: null
		})

		dispatch(initialize(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, initOPValues, true))
		dispatch(reset(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP))

		// trigger validation immediately after init form
		/* NOTE: CP-1234 dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'druh', null, true)) */
		dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'oslovenie', null, true))
		dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'meno', null, true))
		dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'priezvisko', null, true))
		dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'narodenieDatum', null, true))
		dispatch(change(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP, 'adresaZakaznika.id', null, true))

		let dokumentSplnomocneniaTyp
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			dokumentSplnomocneniaTyp = DOKUMENT_TYP_SPLNOMOCNENIA.VLOZIT_SPLNOMOCNENIE
			this.setState({
				scenarioOption: {
					...scenarioOption,
					documentRequired: false
				}
			})
		} else {
			dokumentSplnomocneniaTyp = DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE
		}

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'data.ukonVstup')),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenecCislo: '',
			splnomocnenaOsoba: '',
			datumPrijatiaZiadosti: new Date(),
			platnostOd: new Date(),
			platnostDo: null,
			dokumentSplnomocneniaTyp,
			splnomocnenieRozsah: '',
			dokumenty: []
		})

		dispatch(initialize(FORMS.SPLNOMOCNENIA, initValues, true))
		dispatch(reset(FORMS.SPLNOMOCNENIA))

		this.setState({
			isLoading: false
		})
	}

	componentWillUnmount() {
		const { dispatch, dataActions, trackingActions, ukonyActions, searchActions } = this.props

		dispatch(destroy(FORMS.SPLNOMOCNENIA))
		dispatch(destroy(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP))

		searchActions.searchUsersClear()
		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			auth,
			splnomocnenieFormData /* ,
			podpisovanieDokumentovAction */
		} = this.props
		const { step, scenarioOption } = this.state

		//  NOTE: In case of return from step 3 back to step 2 revert default list of available
		if (prevState.step == 3 && step == 2) {
			this.setState({ dissallowedScenarios: [] })
			/* podpisovanieDokumentovAction.resetTemplatePdf() */
		}

		if (get(splnomocnenieFormData, 'dokumentSplnomocneniaTyp') != get(prevProps, 'splnomocnenieFormData.dokumentSplnomocneniaTyp')) {
			let updatedScenarioOption = {
				...scenarioOption,
				documentRequired: get(splnomocnenieFormData, 'dokumentSplnomocneniaTyp') == DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE
			}
			// NOTE: update scenarios only for ZSEC role https://jira.zsee.sk/jira/browse/CP-2961
			if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
				updatedScenarioOption = {
					...updatedScenarioOption,
					scenarios: scenariosZSE_CENTRUM[get(splnomocnenieFormData, 'dokumentSplnomocneniaTyp')]
				}
				// NOTE: removing Printer from notificationTypes due to request https://jira.zsee.sk/jira/browse/CP-2967
				if (get(splnomocnenieFormData, 'dokumentSplnomocneniaTyp') == DOKUMENT_TYP_SPLNOMOCNENIA.VLOZIT_SPLNOMOCNENIE) {
					updatedScenarioOption = {
						...updatedScenarioOption,
						// NOTE: remove "Tlač/Manuálne" notification type
						notificationTypes: [...scenarioOption.notificationTypes.filter((notificationType) => notificationType !== NOTIFICATION_TYPES.PRINTER)]
					}
				} else if (get(splnomocnenieFormData, 'dokumentSplnomocneniaTyp') == DOKUMENT_TYP_SPLNOMOCNENIA.VZOROVE_SPLNOMOCNENIE) {
					// NOTE: filter initial values for notification types
					const initNotificationTypes = get(
						head(
							filter(scenarioOptions, (scenarioOption) => {
								return scenarioOption.kanal == get(auth, 'businessChannel.actual.id')
							})
						),
						'notificationTypes'
					)
					// NOTE: set initial values
					updatedScenarioOption = {
						...updatedScenarioOption,
						notificationTypes: initNotificationTypes
					}
				}
			}
			this.setState({
				scenarioOption: updatedScenarioOption
			})
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, ciselniky, tracking, auth, newOPFormData, addresses } = this.props

		const vstup = find(get(ciselniky, 'data.ukonVstup', []), (vstup) => {
			return vstup.id == get(values, 'ukonVstup')
		})

		let splnomocnenec

		// if is equal to -1 it is new OP
		if (get(values, 'splnomocnenecCislo') === -1) {
			const tituly = []
			forEach(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => {
				if (newOPFormData.titulPred1 && titul.id == get(newOPFormData, 'titulPred1')) {
					tituly.push({
						poradie: 1,
						hodnota: titul
					})
				}
				if (newOPFormData.titulPred2 && titul.id == get(newOPFormData, 'titulPred2')) {
					tituly.push({
						poradie: 2,
						hodnota: titul
					})
				}
				if (newOPFormData.titulZa && titul.id == get(newOPFormData, 'titulZa')) {
					tituly.push({
						poradie: 1,
						hodnota: titul
					})
				}
			})

			const druh = find(get(ciselniky, 'data.obchodnyPartnerDruh', []), (item) => {
				return item.id == OBCHODNY_PARTNER_DRUH.DOMACNOST
			})

			const oslovenie = find(get(ciselniky, 'data.obchodnyPartnerOslovenie', []), (item) => {
				return item.id == get(newOPFormData, 'oslovenie')
			})

			const adresaZakaznika = formatAddressForResponse('adresaZakaznika', newOPFormData, addresses)
			let adresaKorespondencna
			if (get(newOPFormData, 'adresaZakaznika.globalnaZmena')) {
				adresaKorespondencna = adresaZakaznika
			} else {
				adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', newOPFormData, addresses)
			}

			splnomocnenec = {
				cislo: get(values, 'splnomocnenecCislo'),
				typ: 'MOO', // NOTE: CP-1234
				druh,
				oslovenie,
				tituly,
				meno: get(newOPFormData, 'meno'),
				priezvisko: get(newOPFormData, 'priezvisko'),
				narodenieDatum: get(newOPFormData, 'narodenieDatum') ? dayjs(get(newOPFormData, 'narodenieDatum')).format('YYYY-MM-DD') : '',
				kontaktnyTelefon: get(newOPFormData, 'telefonneCislo') || '',
				kontaktnyEmail: get(newOPFormData, 'kontaktnyEmail') || '',
				adresaZakaznika,
				adresaKorespondencna
			}
		} else {
			splnomocnenec = {
				cislo: get(values, 'splnomocnenecCislo')
			}
		}

		const typ = find(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => get(ukonTyp, 'id') == UKONY_CISELNIK.SPLNOMOCNENIE)

		return {
			typ,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			vstup,
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			data: {
				splnomocnenec,
				platnostOd: get(values, 'platnostOd') ? dayjs(get(values, 'platnostOd')).format('YYYY-MM-DD') : null,
				platnostDo: get(values, 'platnostDo') ? dayjs(get(values, 'platnostDo')).format('YYYY-MM-DD') : null,
				splnomocnenieRozsah: get(values, 'splnomocnenieRozsah')
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${ukonNovy.id}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch } = this.props

		let selectedOP
		let splnomocnenaOsoba

		if (get(values, 'selectedOP')) {
			selectedOP = get(values, 'selectedOP')
			splnomocnenaOsoba = get(values, 'splnomocnenaOsoba', '')
		} else {
			selectedOP = -1 // NOTE: pre noveho OP sa nastavuje zaporna hodnota
			splnomocnenaOsoba = celeMeno(values)
		}
		dispatch(change(FORMS.SPLNOMOCNENIA, 'splnomocnenecCislo', selectedOP))
		dispatch(change(FORMS.SPLNOMOCNENIA, 'splnomocnenaOsoba', splnomocnenaOsoba))

		if (this._mounted) {
			this.setState({
				step: 2
			})
		}
	}

	stepTwoClickHandler = async (values) => {
		const { interakcia, ukonyActions, ukonNovy, tracking, dispatch } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonId
			// if ukon not exist yet create a new one else update it
			if (!ukonNovy.id) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.SPLNOMOCNENIA, 'podpisMiesto', podpisMiesto))

				ukonId = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonId)
			} else {
				ukonId = ukonNovy.id
				const updateBody = {
					...body,
					trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
					id: ukonId
				}
				await putReq(`/api/v2/ukony/${ukonId}`, null, updateBody)
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumentySplnomocnenia = map(
				filter(get(values, 'dokumentSplnomocnenia', []), (dokument) => !dokument.id),
				(dokument) => ({
					...dokument,
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					}
				})
			)
			const notUploadedDokumenty = map(
				filter(get(values, 'dokumenty', []), (dokument) => !dokument.id),
				(dokument) => ({
					...dokument,
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
			)
			const uploadedDokumentySplnomocnenia = filter(get(values, 'dokumentSplnomocnenia', []), (dokument) => dokument.id)
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (dokument) => dokument.id)

			const dokumentySplnomocnenia = map(notUploadedDokumentySplnomocnenia, (file) => this.processDokumentUpload(ukonId, file))
			const dokumentySplnomocneniaPromises = await Promise.all(dokumentySplnomocnenia)

			const dokumenty = map(notUploadedDokumenty, (file) => this.processDokumentUpload(ukonId, file))
			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.SPLNOMOCNENIA, 'dokumentSplnomocnenia', [...uploadedDokumentySplnomocnenia, ...dokumentySplnomocneniaPromises]))
			dispatch(change(FORMS.SPLNOMOCNENIA, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setLoading(false)
				this.setState({
					step: 3
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	processDokumentUpload = async (ukonId, file) => {
		const body = {
			contentType: file.type,
			nazov: file.name,
			data: file.dataAsBase64,
			typ: file.typ
		}

		const dokumentResponse = await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, body)
		return {
			type: get(dokumentResponse, 'response.contentType'),
			id: get(dokumentResponse, 'response.id'),
			dataAsBase64: get(file, 'dataAsBase64'),
			typ: get(dokumentResponse, 'response.typ'),
			name: get(dokumentResponse, 'response.nazov')
		}
	}

	stepThreeClickHandler = async (values) => {
		const { ukonNovy, tracking, notification, signedPdf, templatePdf, dataActions, t } = this.props

		this.setLoading(true)

		let notifikacie = {}
		if (get(notification, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
			set(notifikacie, 'adresyPosta', [get(notification, 'address')])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.EMAIL) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notification, 'email')
				}
			])
		} else if (get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER) {
			set(notifikacie, 'adresyUri', [
				{
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				}
			])
		}

		notifikacie = isEmpty(notifikacie) ? undefined : notifikacie
		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		const dokument = {
			contentType: get(documentForUpload, 'type'),
			typ: {
				id: DOKUMENT_TYP.VYSTUPNY
			},
			nazov: get(documentForUpload, 'name'),
			data: get(documentForUpload, 'dataAsBase64')
		}

		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonNovy.id}/prilozit-dokument`, null, dokument)
			}

			const body = this.formatDataForUkon(values)
			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
				notifikacie,
				id: ukonNovy.id
			}
			await postReq(`/api/v2/ukony/${ukonNovy.id}/spustit`, null, spustitBody)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia, ukonyActions, selectedFiltersUkony } = this.props
		const { success } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonId from storage
						ukonyActions.clearUkonId()
						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
						history.replace(setRouteParams(VZTAHY_ZOZNAM, interakcia.opCislo))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) => {
		return this.setState({
			isKoKriteriumChecked,
			koKriteria
		})
	}

	validacneKriteriaHandler = (validacneKriteria) => this.setState({ validacneKriteria })

	schvalovacieKriteriaHandler = (schvalovacieKriteria) => this.setState({ schvalovacieKriteria })

	koKriteriumContentElement = (koKriteria) => {
		const { t } = this.props
		const content = map(koKriteria, (koKriterium, index) => (
			<React.Fragment key={index}>
				<p>{koKriterium.nazov}:</p>
				<p className='message fail'>{koKriterium.popis}</p>
			</React.Fragment>
		))

		return (
			<div className='failure-content-container'>
				<img src={failureStateIcon} alt={t('translation:Common.Upozornenie!')} />
				{content}
			</div>
		)
	}

	render() {
		const { t, notification, signedPdf, interakcia, procesnyKonfigurator, auth } = this.props
		const {
			step,
			success,
			result,
			scenarioOption,
			isLoading,
			koKriteria,
			schvalovacieKriteria,
			validacneKriteria,
			context,
			dissallowedScenarios,
			ProcesnyKonfigurator
		} = this.state

		if (isLoading || get(procesnyKonfigurator, 'isLoading')) {
			return this.loadingContentElement()
		}

		if (get(procesnyKonfigurator, 'isFailure') || !get(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('containers:Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		// check if every Ko kriterium pass condition
		const invalidKoKriteria = filter(koKriteria, { vysledok: false })
		if (!isEmpty(invalidKoKriteria)) {
			return this.koKriteriumContentElement(invalidKoKriteria)
		}

		let component = null
		switch (step) {
			case 1:
				component = (
					<SplnomocneniaSearch
						{...this.props}
						formTitle={t('containers:SplnomocneniaCreatePage.Krok 1 z 3 Vytvorenie splnomocnenia')}
						onSubmit={this.stepOneClickHandler}
						onBackClick={() => {
							history.push(setRouteParams(VZTAHY_ZOZNAM, get(interakcia, 'opCislo')))
						}}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
					/>
				)
				break
			case 2:
				component = (
					<SplnomocneniaInfo
						{...this.props}
						formTitle={t('containers:SplnomocneniaCreatePage.Krok 2 z 3 Vytvorenie splnomocnenia')}
						onSubmit={this.stepTwoClickHandler}
						onDeleteFile={this.onDeleteFile}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
					/>
				)
				break
			case 3: {
				// check if scenar is defined
				const customizedScenarioOption = {
					...scenarioOption,
					scenarios: filter(get(scenarioOption, 'scenarios', []), (scenar) => {
						const allowedRoles = get(scenar, 'allowedRoles', [])
						return (
							!includes(dissallowedScenarios, get(scenar, 'typ')) &&
							(isEmpty(get(scenar, 'allowedRoles', [])) || checkPermissions(get(auth, 'user.roles'), allowedRoles))
						)
					})
				}

				// if notification is required and not selected yet
				const isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

				// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
				let isDisabledSignedPdf = true
				if (
					(find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
						find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
					get(signedPdf, 'data.dataAsBase64')
				) {
					isDisabledSignedPdf = false
				} else if (
					find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
					find(get(customizedScenarioOption, 'scenarios'), { typ: SCENARE.ODOSLAT_NA_PODPIS })
				) {
					isDisabledSignedPdf = false
				}

				component = (
					<SplnomocneniaConfirm
						{...this.props}
						formTitle={t('containers:SplnomocneniaCreatePage.Krok 3 z 3 Vytvorenie splnomocnenia')}
						scenarioOption={customizedScenarioOption}
						onSubmit={this.stepThreeClickHandler}
						onBackClick={() => {
							this.setState({ step: 2 })
						}}
						notification={notification}
						isDisabledNotification={isDisabledNotification}
						isDisabledSignedPdf={isDisabledSignedPdf}
						schvalovacieKriteria={schvalovacieKriteria}
						disallowScenarios={this.disallowScenarios}
					/>
				)
				break
			}
			default:
		}

		return (
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('containers:Odoslané') : t('containers:Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('containers:Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{isLoading && <ElementLoading />}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={context}
				/>
			</>
		)
	}

	loadingContentElement = () => <ElementLoading />

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})
}

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail.data,
	auth: state.auth,
	tracking: state.tracking,
	addresses: state.formAddresses.data,
	ukonNovy: state.ukony.ukonNovy,
	ciselniky: state.ciselniky,
	signedPdf: state.podpisovanieDokumentov.signedPdf,
	notification: state.podpisovanieDokumentov.notification,
	templatePdf: state.podpisovanieDokumentov.templatePdf,
	procesnyKonfigurator: state.procesnyKonfigurator.procesnyKonfigurator,
	search: state.search,
	statuses: state.statuses.statuses,
	newOPFormData: getFormValues(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP)(state),
	splnomocnenieFormData: getFormValues(FORMS.SPLNOMOCNENIA)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	searchActions: bindActionCreators(SearchActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_SPLNOMOCNENIE])
)(SplnomocneniaCreatePage)
