import { get, concat, size, keyBy } from 'lodash'
import {
	ZMLUVNE_UCTY_LOAD_START,
	ZMLUVNE_UCTY_LOAD_PART_DONE,
	ZMLUVNE_UCTY_LOAD_DONE,
	ZMLUVNE_UCTY_LOAD_FAIL,
	ZMLUVNY_UCET_LOAD_START,
	ZMLUVNY_UCET_LOAD_DONE,
	ZMLUVNY_UCET_LOAD_FAIL
} from '../types/zmluvneUctyTypes'

import { getReq } from '../utils/request'

async function loadZmluvneUctyByParts(opCislo, dispatch, pageSize = 100, queryParams = {}, getStore) {
	const loadPage = async (page) => {
		const store = getStore()
		const query = {
			...queryParams,
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const zmluvneUctyData = await getReq(`/api/v0/obchodni-partneri/${opCislo}/zmluvne-ucty`, query)

			// if is page one assing current data if page is bigger merge with pevious data
			const zmluvneUcty =
				page == 1
					? get(zmluvneUctyData, 'response.obsah', [])
					: concat(get(store, 'zmluvneUcty.collection.data', []), get(zmluvneUctyData, 'response.obsah', []))

			dispatch({
				type: ZMLUVNE_UCTY_LOAD_PART_DONE,
				payload: {
					zmluvneUcty
				}
			})

			if (size(get(zmluvneUctyData, 'response.obsah', [])) >= pageSize) {
				await loadPage(page + 1)
			} else {
				dispatch({
					type: ZMLUVNE_UCTY_LOAD_DONE,
					payload: {
						zmluvneUcty,
						byCislo: keyBy(zmluvneUcty, 'cislo')
					}
				})
				return zmluvneUcty
			}
		} catch (e) {
			dispatch({
				type: ZMLUVNE_UCTY_LOAD_FAIL
			})
		}
	}
	return await loadPage(1)
}

export const loadAllZmluvneUcty = (queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNE_UCTY_LOAD_START
		})

		return await loadZmluvneUctyByParts(get(interakcia, 'opCislo'), dispatch, 100, queryParams, getStore)
	}
}

export const loadZmluvnyUcet = (zuCislo, queryParams = {}) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = get(store, 'interakcie.detail.data')

		dispatch({
			type: ZMLUVNY_UCET_LOAD_START
		})

		const query = {
			...queryParams
		}
		try {
			const zmluvnyUcetData = await getReq(`/api/v2/op/${get(interakcia, 'opCislo')}/zu/${zuCislo}`, query)
			const zmluvnyUcet = get(zmluvnyUcetData, 'response.content', null)

			dispatch({
				type: ZMLUVNY_UCET_LOAD_DONE,
				payload: {
					zmluvnyUcet
				}
			})

			return zmluvnyUcet
		} catch (e) {
			dispatch({
				type: ZMLUVNY_UCET_LOAD_FAIL
			})
		}
	}
}
