import {
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START,
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE,
	OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL
} from '../../types/obchodnyPartner'

import { getReq } from '../../utils/request'

export function rozpisyZalohLoadStart(page) {
	return {
		type: OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_START,
		payload: { page }
	}
}

export function rozpisyZalohLoadDone(rozpisyZaloh, isLastPage) {
	return {
		type: OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_DONE,
		payload: {
			rozpisyZaloh,
			isLastPage
		}
	}
}

export function rozpisyZalohLoadFail() {
	return {
		type: OBCHODNY_PARTNER_ROZPISY_ZALOH_LOAD_FAIL
	}
}

export function loadRozpisyZaloh(page, pageSize, queryParams) {
	return async (dispatch, getStore) => {
		const store = getStore()
		const interakcia = store.interakcie.detail

		dispatch(rozpisyZalohLoadStart(page))
		try {
			const query = {
				...queryParams,
				velkostStranky: pageSize,
				stranka: page
			}

			const res = await getReq(`/api/v2/op/${interakcia.data.opCislo}/rozpisy-zaloh`, query)
			const { content, pageSize: resPageSize } = res.response
			const rozpisyZaloh = content
			const isLastPage = content.length < resPageSize

			dispatch(rozpisyZalohLoadDone(rozpisyZaloh, isLastPage))
		} catch (e) {
			dispatch(rozpisyZalohLoadFail())
		}
	}
}
