// noinspection JSUnusedAssignment

import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { get, head, isEmpty, isEqual, toUpper } from 'lodash'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { change, Field } from 'redux-form'

// components
import VyberZaslaniaDokumentu from '../../Modals/VyberZasielaniaDokumentu'

// actions
import PodpisovanieDokumentovActions from '../../../actions/PodpisovanieDokumentov'

// utils
import { FORMS as FORM, NOTIFICATION_TYPES } from '../../../utils/enums'
import { formatAddress } from '../../../utils/address'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class NotifikaciaField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		formValues: PropTypes.shape(),
		manualnaTlac: PropTypes.bool,
		povinny: PropTypes.bool,
		notifikaciaTypy: PropTypes.shape(),
		scenarioOption: PropTypes.shape(),
		editMode: PropTypes.string,
		notification: PropTypes.shape(),
		signedPdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		templatePdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		})
	}

	constructor(props) {
		super(props)

		this.state = {
			showNotificationModal: false,
			notificationTypes: [],
			adresyPosta: null,
			emails: []
		}
	}

	parseNotificationTypes = (notifikaciaTypy, manualnaTlac, bezNotifikacie, povinny) => {
		const notificationTypes = []

		if (manualnaTlac) {
			notificationTypes.push(NOTIFICATION_TYPES.PRINTER)
		}

		if (bezNotifikacie || povinny === false) {
			notificationTypes.push(NOTIFICATION_TYPES.VOID_NOTIFICATION)
		}

		const adresyUri = head(notifikaciaTypy?.filter((notifikaciaTyp) => notifikaciaTyp?.adresyUri))
		// NOTE: if the OP does not have an email, BB should return only the 'EMAIL' type, not a list of email options.
		const isEmail = get(adresyUri, 'adresyUri')?.find((adresaUri) => adresaUri?.typ === NOTIFICATION_TYPES.EMAIL)
		const adresyEmail = []
		get(adresyUri, 'adresyUri')?.forEach((email) => {
			if (email?.hodnota) {
				adresyEmail.push({
					label: email.hodnota,
					value: email.hodnota
				})
			}
		})

		if (!isEmpty(isEmail) || !isEmpty(adresyEmail)) {
			notificationTypes.push(NOTIFICATION_TYPES.EMAIL)
		}

		const adresyPosta = head(notifikaciaTypy?.filter((notifikaciaTyp) => notifikaciaTyp?.adresyPosta))

		if (!isEmpty(adresyPosta)) {
			notificationTypes.push(NOTIFICATION_TYPES.ADDRESS)
		}

		this.setState({
			...this.state,
			notificationTypes,
			adresyPosta: get(adresyPosta, 'adresyPosta'),
			emails: [...adresyEmail]
		})
	}

	componentDidMount() {
		const { notifikaciaTypy, manualnaTlac, bezNotifikacie, povinny } = this.props

		this.parseNotificationTypes(notifikaciaTypy, manualnaTlac, bezNotifikacie, povinny)
	}

	componentDidUpdate(prevProps, preState) {
		const { notifikaciaTypy, manualnaTlac, bezNotifikacie, povinny } = this.props
		const { notificationTypes } = this.state

		if (
			(!isEqual(notifikaciaTypy, get(prevProps, 'notifikaciaTypy')) ||
				!isEqual(manualnaTlac, get(prevProps, 'manualnaTlac')) ||
				!isEqual(povinny, get(prevProps, 'povinny'))) &&
			!isEqual(notificationTypes, get(preState, 'notificationTypes'))
		) {
			this.parseNotificationTypes(notifikaciaTypy, manualnaTlac, bezNotifikacie, povinny)
		}
	}

	onSubmitNotificationModal = (notification) => {
		const { dispatch, field, podpisovanieDokumentovAction } = this.props

		if (
			get(notification, 'email') ||
			get(notification, 'address') ||
			get(notification, 'typ') == NOTIFICATION_TYPES.PRINTER ||
			get(notification, 'typ') == NOTIFICATION_TYPES.VOID_NOTIFICATION
		) {
			podpisovanieDokumentovAction.setNotification(notification)

			dispatch(change(FORM.GENERIC_UKON, field, notification))
		}

		this.setState({
			showNotificationModal: false
		})
	}

	formatNotificationType = (type) => {
		const { t } = this.props
		switch (type) {
			case NOTIFICATION_TYPES.ADDRESS:
				return t('components:PodpisovanieDokumentov.TypNotifikacie.ADDRESS')
			case NOTIFICATION_TYPES.EMAIL:
				return t('components:PodpisovanieDokumentov.TypNotifikacie.EMAIL')
			case NOTIFICATION_TYPES.PRINTER:
				return t('components:PodpisovanieDokumentov.TypNotifikacie.PRINT')
			case NOTIFICATION_TYPES.VOID_NOTIFICATION:
				return t('components:PodpisovanieDokumentov.TypNotifikacie.VOID_NOTIFICATION')
			default:
				return '-'
		}
	}

	getSelectedNotificationValue = (notification) => {
		const { t } = this.props

		let selectedNotification = null
		const notificationText = toUpper(
			`${t('components:PodpisovanieDokumentov.Zvolený typ notifikácie')} ${this.formatNotificationType(get(notification, 'typ'))}`
		)
		const email = get(notification, 'email')
		const address = formatAddress(get(notification, 'address'))
		selectedNotification = (
			<div>
				<span className='label' style={{ textTransform: 'none' }} data-color='blue'>
					{notificationText}
				</span>
				{(email || address) && (
					<span className='label' style={{ marginLeft: '15px', textTransform: 'none' }} data-color='blue'>
						{email}
						{address}
					</span>
				)}
			</div>
		)
		return selectedNotification
	}

	getDefaultNotificationType = () => {
		const { emails } = this.state

		if (emails?.length > 0) {
			return NOTIFICATION_TYPES.EMAIL
		}

		return NOTIFICATION_TYPES.ADDRESS
	}

	validate = (value) => {
		const { povinny, t } = this.props
		if (povinny && isEmpty(value)) {
			let isEmpty = true
			if (get(value, 'typ') == NOTIFICATION_TYPES.ADDRESS && get(value, 'address')) {
				isEmpty = false
			} else if (get(value, 'typ') == NOTIFICATION_TYPES.EMAIL && get(value, 'email')) {
				isEmpty = false
			} else if (get(value, 'typ') == NOTIFICATION_TYPES.PRINTER) {
				isEmpty = false
			} else if (get(value, 'typ') == NOTIFICATION_TYPES.VOID_NOTIFICATION) {
				isEmpty = false
			}
			if (isEmpty) {
				return t('translation:Common.validate.Notifikácia je povinná')
			}
		}
	}

	render() {
		const { t, field, value, editMode, formValues } = this.props
		const { showNotificationModal, notificationTypes, adresyPosta, emails } = this.state

		if (editMode == EDIT_MODE.DETAIL) return null

		const signedDocuments = formValues.dokumenty ?? []
		const signedDocumentsUrls = signedDocuments.map((podpisanyDokument) => {
			return { id: podpisanyDokument?.id, fileName: get(podpisanyDokument, 'name'), documentUrl: podpisanyDokument?.url }
		})

		const defaultNotificationType = this.getDefaultNotificationType()

		let modal = null
		if (showNotificationModal) {
			modal = (
				<VyberZaslaniaDokumentu
					adresy={adresyPosta}
					emailAddresses={emails}
					defaultNotificationType={defaultNotificationType}
					notificationTypes={notificationTypes}
					modalTitle={t('components:PodpisovanieDokumentov.Zvoľte typ notifikácie')}
					onCloseButton={() => this.setState({ showNotificationModal: false })}
					onSubmit={this.onSubmitNotificationModal}
					signedDocuments={signedDocumentsUrls}
				/>
			)
		}

		const error = this.validate(value)

		return (
			<>
				<div data-name={field} className={cx('generic-field with-background inner-box', { 'danger-box': error })}>
					<strong>{t('components:Notifikácia')}</strong>
					<div className='notification-box'>
						<div>{this.getSelectedNotificationValue(value)}</div>
						<div>
							<button
								type='button'
								className='button small pull-right'
								data-type='outline'
								data-color='blue'
								style={{ marginLeft: '10px', height: '32px' }}
								onClick={() =>
									this.setState({
										showNotificationModal: true
									})
								}
							>
								{isEmpty(value)
									? t('components:PodpisovanieDokumentov.Nastaviť notifikáciu')
									: t('components:PodpisovanieDokumentov.Zmeniť notifikáciu')}
							</button>
							{/* NOTE: Do not remove due to the validation of the field in the form */}
							<Field name={field} validate={this.validate} component='input' type='hidden' />
						</div>
					</div>
					{error && <div className='text-danger'>{error}</div>}
				</div>
				{modal}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(NotifikaciaField)
