import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { capitalize, get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import { Tooltip } from 'react-tippy'
import UserDropdownMenu from '../../components/UserDropdownMenu'
import BusinessChannelDropdownMenu from '../../components/BusinessChannelDropdownMenu'
import DefaultModal from '../../components/Modals/DefaultModal'

// actions
import * as PonukaActions from '../../actions/PonukaActions'
import * as ExternalIntegrationsActions from '../../actions/ExternalIntegrationsActions'

// utils
import { getLogo } from '../../utils/environment'
import { getAccessToken } from '../../utils/auth'
import { setRouteParams, INDEX, GENERIC_UKON } from '../../utils/routes'
import { EXTERNAL_INTEGRATIONS, UKONY_CISELNIK } from '../../utils/enums'
import Permissions, { PERMISSIONS } from '../../utils/permissionsHoc'

class Header extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape({
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		auth: PropTypes.shape({
			user: PropTypes.shape({
				firstName: PropTypes.string,
				lastName: PropTypes.string,
				displayName: PropTypes.string,
				email: PropTypes.string.isRequired
			})
		}).isRequired,
		obchodnyPartner: PropTypes.shape({
			meno: PropTypes.string,
			priezvisko: PropTypes.string,
			cislo: PropTypes.string
		}).isRequired,
		settings: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		page: PropTypes.string,
		finesseSession: PropTypes.string,
		liveAgentSession: PropTypes.string,
		call250Session: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			showLiveAgentModal: false,
			showFinesseModal: false,
			showCall250Modal: false,
			ponuka: []
		}
	}

	async componentDidMount() {
		const { auth, ponukaActions, interakcia } = this.props
		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')

		if (get(interakcia, 'opCislo')) {
			const ponuka = await ponukaActions.loadPonukaObchodnyPartner({
				kanalId: activeBusinessChannel,
				kontextId: 'banner'
			})

			this.setState({
				ponuka
			})
		}
	}

	getIcon = (ukonId) => {
		switch (ukonId) {
			case UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY:
				return 'vau'
			case UKONY_CISELNIK.SYSTEMOVE_LISTY:
				return 'post'
			default:
		}
	}

	getPermissions = (ukonId) => {
		switch (ukonId) {
			case UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY:
				return [PERMISSIONS.UKON_VSEOBECNY_AKTIVNY]
			case UKONY_CISELNIK.SYSTEMOVE_LISTY:
				return [PERMISSIONS.UKON_ODPOVED_ZAKAZNIKOVI]
			default:
		}
	}

	renderPonukaButtons = (ponuka) => {
		const { t, interakcia } = this.props
		return ponuka?.map((moznost) => {
			const ukonId = moznost?.ukon?.typ?.id
			return (
				<Permissions
					allowed={this.getPermissions(ukonId)}
					render={(hasPerm, actions) => {
						let title = capitalize(moznost?.nazov)
						if (!hasPerm) {
							title = `${title} - ${t('containers:Na vykonanie akcie nemáte potrebné oprávnenia')}`
						}
						return (
							<div>
								<Tooltip html={<span>{title}</span>} position='bottom' trigger='mouseenter' theme='light'>
									<Link
										to={{
											pathname: setRouteParams(GENERIC_UKON, get(interakcia, 'opCislo')),
											ponuka: moznost
										}}
										className='button'
										data-type='icon-button'
										data-icon={this.getIcon(ukonId)}
										data-color='black'
										type='button'
										onClick={(e) => {
											if (!hasPerm) {
												e.preventDefault()
												actions.openForbiddenModal()
											}
										}}
										style={{
											width: '40px',
											height: '40px',
											marginLeft: '10px',
											borderColor: '#9B9B9B',
											borderRadius: '7px'
										}}
									/>
								</Tooltip>
							</div>
						)
					}}
				/>
			)
		})
	}

	getHladatOPUrl = () => {
		const { liveAgentSession, finesseSession, call250Session } = this.props
		let url = `${INDEX}?clear=true`

		// TODO: Ak je sucasne otvorenych viacero interakcii, tak sa ulozi iba jedna
		if (liveAgentSession) {
			url = `${url}&source=${EXTERNAL_INTEGRATIONS.LIVE_AGENT}&externalId=${liveAgentSession}`
		} else if (finesseSession) {
			url = `${url}&source=${EXTERNAL_INTEGRATIONS.FINESSE}&externalId=${finesseSession}`
		} else if (call250Session) {
			url = `${url}&source=${EXTERNAL_INTEGRATIONS.CALL250}&externalId=${call250Session}`
		}

		return url
	}

	render() {
		const { t, auth, obchodnyPartner, settings, interakcia, liveAgentSession, finesseSession, call250Session, externalIntegrationsActions } = this.props
		const { firstName, lastName, displayName, email } = auth.user
		const { ponuka } = this.state

		const kid = get(interakcia, 'riesitelIdentita.kid')
		const ts = dayjs().format('YYYYMMDDHHmmss')

		const isHomePage = window.location.pathname === INDEX
		const hladatOPUrl = this.getHladatOPUrl()

		return (
			<header className='top-header'>
				<a className='logo' href='/' target='_blank'>
					<img src={getLogo(get(settings, 'env'))} alt={t('containers:Header.ZSE, Súčasť vášho domova')} />
				</a>

				<div className='header-search'>
					<a href={hladatOPUrl} target='_blank' rel='noopener noreferrer'>
						{t('containers:Header.Hľadať OP')}
					</a>
				</div>

				<ul className='ext-integrations'>
					{liveAgentSession && (
						<li className='ext-integrations-badge liveagent-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.LiveAgent Ticket')}</div>
								<div className='ext-integrations-id'>{liveAgentSession}</div>
							</div>
							{isHomePage && <button className='ext-integrations-close' onClick={() => this.setState({ showLiveAgentModal: true })} />}
						</li>
					)}
					{finesseSession && (
						<li className='ext-integrations-badge finesse-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.ID hovoru')}</div>
								<div className='ext-integrations-id'>{finesseSession}</div>
							</div>
							{isHomePage && <button className='ext-integrations-close' onClick={() => this.setState({ showFinesseModal: true })} />}
						</li>
					)}
					{call250Session && (
						<li className='ext-integrations-badge call250-badge'>
							<div className='ext-integrations-icon' />
							<div>
								<div className='ext-integrations-title'>{t('containers:Header.ID vyvolania')}</div>
								<div className='ext-integrations-id'>{call250Session}</div>
							</div>
							{isHomePage && <button className='ext-integrations-close' onClick={() => this.setState({ showCall250Modal: true })} />}
						</li>
					)}
				</ul>

				<div className='top-header-divider' />

				{!isEmpty(obchodnyPartner) && (
					<a
						className='button'
						data-color='black'
						data-type='outline'
						href={`/api/v2/op/${obchodnyPartner.cislo}/sprava-uctov?kid=${kid}&ts=${ts}&accessToken=${getAccessToken()}`}
						target='_blank'
						rel='noopener noreferrer'
						style={{ marginRight: '10px' }}
					>
						{t('containers:Header.Správa Online účtu')}
					</a>
				)}

				{!isEmpty(obchodnyPartner) && (
					<a
						className='button'
						data-color='black'
						data-type='outline'
						href={`/api/v0/esign/zakaznici/${obchodnyPartner.cislo}?accessToken=${getAccessToken()}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						{t('containers:Header.Otvoriť OP v eSign')}
					</a>
				)}

				{!ponuka?.isLoading && this.renderPonukaButtons(ponuka)}

				<div className='header-profile'>
					<div className='profile-content'>
						<strong>{firstName && lastName ? `${firstName} ${lastName}` : displayName || email}</strong>
						<BusinessChannelDropdownMenu />
					</div>
				</div>

				<UserDropdownMenu />

				<DefaultModal
					modalTitle={t('containers:Header.LiveAgent ticket')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym LiveAgent ticketom?', {
						liveAgentSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showLiveAgentModal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearLiveAgentSession()
							this.setState({ showLiveAgentModal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showLiveAgentModal}
				/>
				<DefaultModal
					modalTitle={t('containers:Header.Hovor')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym hovorom?', {
						finesseSession
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showFinesseModal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearFinesseSession()
							this.setState({ showFinesseModal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showFinesseModal}
				/>
				<DefaultModal
					modalTitle={t('containers:Header.Vyvolanie')}
					modalContent={t('containers:Header.Chcete ukončiť prácu s aktuálnym vyvolaním?', {
						call250Session
					})}
					leftButton={{
						onClick: () => {
							this.setState({ showCall250Modal: false })
						},
						text: t('containers:Header.Nie'),
						color: 'blue'
					}}
					rightButton={{
						onClick: () => {
							externalIntegrationsActions.clearCall250Session()
							this.setState({ showCall250Modal: false })
						},
						text: t('containers:Header.Áno'),
						color: 'blue',
						outline: true
					}}
					visible={this.state.showCall250Modal}
				/>
			</header>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: state.auth,
	obchodnyPartner: state.obchodnyPartner.detail.data,
	settings: get(state, 'settings.settings'),
	liveAgentSession: get(state, 'externalIntegrations.liveAgentId'),
	finesseSession: get(state, 'externalIntegrations.finesseId'),
	call250Session: get(state, 'externalIntegrations.call250Id')
})

const mapDispatchToProps = (dispatch) => ({
	ponukaActions: bindActionCreators(PonukaActions, dispatch),
	externalIntegrationsActions: bindActionCreators(ExternalIntegrationsActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(Header)
