// noinspection JSUnusedAssignment

import React from 'react'
import { reduxForm, propTypes } from 'redux-form'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, upperFirst } from 'lodash'
import { compose } from 'redux'

import validate from './validateForm'

// utils
import { formatAddress } from '../../../utils/address'
import { FORMS } from '../../../utils/enums'
import { formatDate } from '../../../utils/date'

class ZmluvnyUcetEditConfirm extends React.Component {
	static propTypes = {
		...propTypes,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired
	}

	render() {
		const { handleSubmit, formValues, formTitle, onBackClick, onCancelClick, addresses, ciselniky, t } = this.props

		const ukonVstup = find(get(ciselniky, 'data.ukonVstup', []), (vstup) => {
			return get(vstup, 'id') == get(formValues, 'ukonVstup')
		})
		const titulPred1 = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulPred1')
		})
		const titulPred2 = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulPred2')
		})
		const titulZa = find(get(ciselniky, 'data.akademickyTitulHodnota', []), (item) => {
			return get(item, 'id') == get(formValues, 'titulZa')
		})
		const oslovenie = find(get(ciselniky, 'data.obchodnyPartnerOslovenie', []), (item) => {
			return get(item, 'id') == get(formValues, 'oslovenie')
		})

		const formAdresaZakaznika = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaZakaznika.id')
		})
		const formAdresaKorespondencna = find(addresses, (address) => {
			return get(address, 'id') == get(formValues, 'adresaKorespondencna.id')
		})

		let adresaKorespondencna = null
		let adresaKorespondencnaDoRuk = null

		if (get(formValues, 'adresaZakaznika.globalnaZmena')) {
			adresaKorespondencna = formatAddress(formAdresaZakaznika, false)
			adresaKorespondencnaDoRuk = get(formAdresaZakaznika, 'doRukMeno', '')
		} else {
			adresaKorespondencna = formatAddress(formAdresaKorespondencna, false)
			adresaKorespondencnaDoRuk = get(formAdresaKorespondencna, 'doRukMeno', '')
		}

		const submitBtn = (
			<button className='button pull-right' type='submit' data-color='blue' style={{ marginLeft: '20px' }}>
				{t('translation:Common.Dokončiť')}
			</button>
		)

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{submitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='container' style={{ marginTop: '20px', marginBottom: '20px' }}>
						<div className='box'>
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<thead>
										<tr>
											<th />
											<th>{t('translation:Common.Nové hodnoty')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Vstup do úkonu')}</strong>
											</td>
											<td>{upperFirst(get(ukonVstup, 'nazov'))}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
											</td>
											<td>{get(formValues, 'podpisMiesto')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
											</td>
											<td>{formatDate(get(formValues, 'datumPrijatiaZiadosti'), null, 'DD.MM.YYYY')}</td>
										</tr>
									</tbody>
								</table>
								<div className='inner-box'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:Common.Oslovenie')}</strong>
												</td>
												<td>{get(oslovenie, 'nazov')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Meno')}</strong>
												</td>
												<td>{get(formValues, 'meno')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Priezvisko')}</strong>
												</td>
												<td>{get(formValues, 'priezvisko')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Titul pred menom')}</strong>
												</td>
												<td>
													<div className='row'>
														<div className='col-6'>{get(titulPred1, 'nazov')}</div>
														<div className='col-6'>{get(titulPred2, 'nazov')}</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Titul za menom')}</strong>
												</td>
												<td>{get(titulZa, 'nazov')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Dátum narodenia')}</strong>
												</td>
												<td>{formatDate(get(formValues, 'narodenieDatum'), null, 'DD.MM.YYYY')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Kontaktné telefónne číslo')}</strong>
												</td>
												<td>{get(formValues, 'telefonneCislo')}</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Kontaktný email')}</strong>
												</td>
												<td>{get(formValues, 'kontaktnyEmail')}</td>
											</tr>
											<tr>
												<td>
													<div>
														<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
													</div>
													{get(formAdresaZakaznika, 'doRukMeno') && (
														<>
															<br />
															<div>
																<strong>{t('translation:Common.Do rúk')}</strong>
															</div>
														</>
													)}
													{get(formValues, 'adresaZakaznika.globalnaZmena') && (
														<>
															<br />
															<div>
																<strong>{t('translation:Common.Globálne použitie adresy')}</strong>
															</div>
														</>
													)}
												</td>
												<td>
													<div>{formatAddress(formAdresaZakaznika, false)}</div>
													{get(formAdresaZakaznika, 'doRukMeno') && (
														<>
															<br />
															<div>{get(formAdresaZakaznika, 'doRukMeno')}&nbsp;</div>
														</>
													)}
													{get(formValues, 'adresaZakaznika.globalnaZmena') && (
														<>
															<br />
															<div>{t('translation:Common.Áno')}</div>
														</>
													)}
												</td>
											</tr>
											<tr>
												<td>
													<div>
														<strong>{t('translation:Common.Korešpondenčná adresa')}</strong>
													</div>
													{adresaKorespondencnaDoRuk && (
														<>
															<br />
															<div>
																<strong>{t('translation:Common.Do rúk')}</strong>
															</div>
														</>
													)}
												</td>
												<td>
													<div>{adresaKorespondencna}</div>
													{adresaKorespondencnaDoRuk && (
														<>
															<br />
															<div>{adresaKorespondencnaDoRuk}</div>
														</>
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Poznámka')}</strong>
											</td>
											<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
												<div className='input-wrapper'>
													<textarea
														disabled='disabled'
														rows='5'
														className='form-control static'
														style={{ backgroundColor: 'white' }}
														value={get(formValues, 'poznamka', '')}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.OBCHODNY_PARTNER_ZALOZENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate
})(ZmluvnyUcetEditConfirm)

export default compose(withTranslation('components'))(form)
