import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// resources
import approvedIcon from '../../resources/img/icons/icon-approved.svg'
import warningIcon from '../../resources/img/icons/icon-warning.svg'
import deniedIcon from '../../resources/img/icons/icon-denied.svg'

// utils
import { SUHLAS_TYP_VYJADRENIA } from '../../utils/enums'

class SuhlasBadge extends React.Component {
	static propTypes = {
		typVyjadrenieSuhlas: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	// TODO: Add all cases and verify badge states
	getColorClass = (typVyjadrenia) => {
		switch (typVyjadrenia) {
			case SUHLAS_TYP_VYJADRENIA.SUHLAS:
				return 'green'
			case SUHLAS_TYP_VYJADRENIA.NESUHLAS:
			case SUHLAS_TYP_VYJADRENIA.NAMIETKA:
			case SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE:
				return 'red'
			default:
				return 'gray'
		}
	}

	// TODO: Add all cases
	getIcon = (typVyjadrenia) => {
		switch (typVyjadrenia) {
			case SUHLAS_TYP_VYJADRENIA.SUHLAS:
				return approvedIcon
			case SUHLAS_TYP_VYJADRENIA.NESUHLAS:
			case SUHLAS_TYP_VYJADRENIA.NAMIETKA:
			case SUHLAS_TYP_VYJADRENIA.NAMIETKA_NA_SCHVALENIE:
				return deniedIcon
			default:
				return warningIcon
		}
	}

	render() {
		const { t, typVyjadrenieSuhlas } = this.props

		return (
			<div className={`badge-box ${this.getColorClass(typVyjadrenieSuhlas)}`}>
				<img src={this.getIcon(typVyjadrenieSuhlas)} alt={typVyjadrenieSuhlas || t('translation:Suhlasy.Nevyžiadané')} />
				{typVyjadrenieSuhlas || t('translation:Suhlasy.Nevyžiadané')}
			</div>
		)
	}
}

export default withTranslation('components')(SuhlasBadge)
