// noinspection JSUnusedAssignment

import React from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, find, filter, map, forEach, isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { destroy, change, touch, initialize, reset, getFormValues } from 'redux-form'

// layouts
import Header from '../Layout/Header'

// components
import ObchodnyPartnerZalozenieEdit from '../../components/ObchodnyPartner/ObchodnyPartnerZalozenie/ObchodnyPartnerZalozenieEdit'
import ObchodnyPartnerZalozenieConfirm from '../../components/ObchodnyPartner/ObchodnyPartnerZalozenie/ObchodnyPartnerZalozenieConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { postReq, putReq, deleteReq } from '../../utils/request'
import { UKONY, UKONY_CISELNIK, FORMS, BUSINESS_CHANNELS, OBCHODNY_PARTNER_DRUH, OBCHODNY_PARTNER_ROLA, DOKUMENT_TYP } from '../../utils/enums'
import { getUkonVstupIdByBiznisKanal } from '../../utils/scenar'
import { setRouteParams, INDEX, PREHLAD } from '../../utils/routes'
import { history } from '../../utils/history'
import { createFormInitValues } from '../../utils/form'
import { formatAddressForResponse } from '../../utils/address'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

// actions
import * as TrackingActions from '../../actions/TrackingActions'
import * as DataActions from '../../actions/DataActions'
import * as UkonyActions from '../../actions/UkonyActions'
import * as FormAddresssesActions from '../../actions/FormAddressesActions'
import * as ProcesnyKonfiguratorActions from '../../actions/ProcesnyKonfigurator/ProcesnyKonfiguratorActions'
import * as CiselnikyActions from '../../actions/CiselnikyActions'
import InterakcieActions from '../../actions/Interakcie'

// resources
import failureStateIcon from '../../resources/img/icons/warning.svg'

class ObchodnyPartnerZalozeniePage extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		ukonNovy: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		formValues: PropTypes.shape(),
		ukonyActions: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		formAddressesActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		procesnyKonfiguratorActions: PropTypes.shape().isRequired,
		interakcieActions: PropTypes.shape().isRequired,
		ciselnikyActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'zalozenieOP')

		this.state = {
			step: 1,
			isLoading: true,
			ProcesnyKonfigurator,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			context: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	async componentDidMount() {
		this._mounted = true

		const { dispatch, tracking, trackingActions, dataActions, formAddressesActions, procesnyKonfiguratorActions, ciselnikyActions, auth, ciselniky } =
			this.props

		await ciselnikyActions.loadCiselniky()
		procesnyKonfiguratorActions.loadProcesnyKonfigurator()
		dataActions.registerLeavePageModal()

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== UKONY.ZALOZENIE_OP)) {
			trackingActions.tryToStartTracking(UKONY.ZALOZENIE_OP)
		}

		formAddressesActions.formAddressClean()

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'data.ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			oslovenie: null,
			titulPred1: null,
			titulPred2: null,
			titulZa: null,
			meno: null,
			priezvisko: null,
			telefonneCislo: null,
			kontaktnyEmail: null,
			narodenieDatum: null,
			adresaZakaznika: {
				globalnaZmena: true
			},
			adresaKorespondencna: null,
			poznamka: null
		})

		dispatch(initialize(FORMS.OBCHODNY_PARTNER_ZALOZENIE, initValues, true))
		dispatch(reset(FORMS.OBCHODNY_PARTNER_ZALOZENIE))

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'dokumenty'))
		}

		// trigger validation immediately after init form
		dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'oslovenie', null, true))
		dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'meno', null, true))
		dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'priezvisko', null, true))
		dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'narodenieDatum', null, true))
		dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'adresaZakaznika.id', null, true))

		this.setState({
			isLoading: false
		})
	}

	componentWillUnmount() {
		const { dispatch, dataActions, trackingActions, ukonyActions } = this.props

		dispatch(destroy(FORMS.OBCHODNY_PARTNER_ZALOZENIE))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonID from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	formatDataForUkon = (values) => {
		const { ciselniky, tracking, auth, formValues, addresses } = this.props

		const typ = find(get(ciselniky, 'data.ukonTyp', []), (ukonTyp) => {
			return get(ukonTyp, 'id') == UKONY_CISELNIK.ZALOZENIE_OP
		})
		const vstup = find(get(ciselniky, 'data.ukonVstup', []), (vstup) => {
			return vstup.id == get(values, 'ukonVstup')
		})

		const trvanie = dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond')
		const zacatyOd = dayjs(get(tracking, 'startTime')).toISOString()
		const ziadanyOd = dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString()

		const pozadovaneRole = find(get(ciselniky, 'data.opRolaTyp', []), (rola) => get(rola, 'id') == OBCHODNY_PARTNER_ROLA.PROSPEKT)

		const tituly = []
		forEach(get(ciselniky, 'data.akademickyTitulHodnota', []), (titul) => {
			if (get(formValues, 'titulPred1') && get(titul, 'id') == get(formValues, 'titulPred1')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
			if (get(formValues, 'titulPred2') && get(titul, 'id') == get(formValues, 'titulPred2')) {
				tituly.push({
					poradie: 2,
					hodnota: titul
				})
			}
			if (get(formValues, 'titulZa') && get(titul, 'id') == get(formValues, 'titulZa')) {
				tituly.push({
					poradie: 1,
					hodnota: titul
				})
			}
		})

		const druh = find(get(ciselniky, 'data.obchodnyPartnerDruh', []), (item) => {
			return get(item, 'id') == OBCHODNY_PARTNER_DRUH.DOMACNOST
		})
		const oslovenie = find(get(ciselniky, 'data.obchodnyPartnerOslovenie', []), (item) => {
			return get(item, 'id') == get(formValues, 'oslovenie')
		})

		const narodenieDatum = get(formValues, 'narodenieDatum') ? dayjs(get(formValues, 'narodenieDatum')).format('YYYY-MM-DD') : ''

		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', formValues, addresses)

		// NOTE: change based on CP-3206 and CP-3276 request
		let adresaKorespondencna = null
		if (get(formValues, 'adresaZakaznika.globalnaZmena')) {
			adresaKorespondencna = adresaZakaznika
		} else {
			adresaKorespondencna = formatAddressForResponse('adresaKorespondencna', formValues, addresses)
		}

		return {
			typ,
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie,
			zacatyOd,
			vstup,
			podpisMiesto: get(values, 'podpisMiesto') || '',
			poznamka: get(values, 'poznamka') || '',
			ziadanyOd,
			data: {
				pozadovaneRole: [pozadovaneRole],
				obchodnyPartner: {
					typ: 'MOO',
					druh,
					oslovenie,
					tituly,
					meno: get(formValues, 'meno'),
					priezvisko: get(formValues, 'priezvisko'),
					narodenieDatum,
					kontaktnyTelefon: get(formValues, 'telefonneCislo') || '',
					kontaktnyEmail: get(formValues, 'kontaktnyEmail') || '',
					adresaZakaznika,
					adresaKorespondencna
				}
			}
		}
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch, ukonyActions, tracking, ukonNovy } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		try {
			let ukonID
			// if ukon not exist yet create a new one else update it
			if (!get(ukonNovy, 'id')) {
				const res = await postReq(`/api/v2/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'podpisMiesto', podpisMiesto))

				ukonID = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonID)
			} else {
				ukonID = get(ukonNovy, 'id')
				await putReq(`/api/v2/ukony/${ukonID}`, null, {
					...body,
					trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
					id: ukonID
				})
			}

			// documents which are not uploaded does not have ID
			const notUploadedDocuments = filter(get(values, 'dokumenty', []), (document) => !get(document, 'id'))
			const uploadedDocuments = filter(get(values, 'dokumenty', []), (document) => get(document, 'id'))

			const documents = map(notUploadedDocuments, async (document) => {
				const documentResponse = await postReq(`/api/v0/ukony/${ukonID}/prilozit-dokument`, null, {
					contentType: get(document, 'type'),
					nazov: get(document, 'name'),
					data: get(document, 'dataAsBase64'),
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(documentResponse, 'response.contentType'),
					id: get(documentResponse, 'response.id'),
					dataAsBase64: get(document, 'dataAsBase64'),
					typ: get(documentResponse, 'response.typ'),
					name: get(documentResponse, 'response.nazov')
				}
			})

			const documentsPromises = await Promise.all(documents)

			// merge already uploaded documents and fresh uploaded documents
			dispatch(change(FORMS.OBCHODNY_PARTNER_ZALOZENIE, 'dokumenty', [...uploadedDocuments, ...documentsPromises]))

			if (this._mounted) {
				this.setState({
					step: 2,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	stepTwoClickHandler = async (values) => {
		const { ukonNovy, tracking, dataActions, t } = this.props

		this.setLoading(true)

		try {
			const body = this.formatDataForUkon(values)
			const spustitBody = {
				...body,
				trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
				id: get(ukonNovy, 'id')
			}
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				const response = await postReq(`/api/v2/ukony/${get(ukonNovy, 'id')}/spustit`, null, spustitBody)
				const opCislo = get(response, 'response.content.opCislo')
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					createOpCislo: opCislo,
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	confirmModal = () => {
		const { interakcieActions, ukonyActions } = this.props

		const { success, createOpCislo } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// clear ukonID from storage
						ukonyActions.clearUkonId()

						if (createOpCislo) {
							interakcieActions.zaciatokInterakcie(createOpCislo)
							history.replace(setRouteParams(PREHLAD, createOpCislo))
						} else {
							history.replace(setRouteParams(INDEX))
						}
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) =>
		this.setState({
			isKoKriteriumChecked,
			koKriteria
		})

	validacneKriteriaHandler = (validacneKriteria) =>
		this.setState({
			validacneKriteria
		})

	schvalovacieKriteriaHandler = (schvalovacieKriteria) =>
		this.setState({
			schvalovacieKriteria
		})

	koKriteriumContentElement = (koKriteria) => {
		const { t } = this.props
		const content = map(koKriteria, (koKriterium, index) => {
			return (
				<React.Fragment key={index}>
					<p>{get(koKriterium, 'nazov')}:</p>
					<p className='message fail'>{get(koKriterium, 'popis')}</p>
				</React.Fragment>
			)
		})

		return (
			<div className='failure-content-container'>
				<img src={failureStateIcon} alt={t('translation:Common.Upozornenie!')} />
				{content}
			</div>
		)
	}

	commonContentContainer = (content) => {
		return (
			<div style={{ backgroundColor: '#F4F6F9', height: '100%', overflow: 'auto', fontSize: '14px' }}>
				<Header />
				{content}
			</div>
		)
	}

	render() {
		const { ciselniky, procesnyKonfigurator, t } = this.props
		const { step, success, result, isLoading, koKriteria, schvalovacieKriteria, validacneKriteria, context, ProcesnyKonfigurator } = this.state

		if (isLoading || get(ciselniky, 'isLoading') || get(procesnyKonfigurator, 'isLoading')) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (get(ciselniky, 'isFailure') || get(procesnyKonfigurator, 'isFailure') || !get(procesnyKonfigurator, 'data.ukony')) {
			return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		// check if every Ko kriterium pass condition
		const invalidKoKriteria = filter(koKriteria, { vysledok: false })
		if (!isEmpty(invalidKoKriteria)) {
			const koKriteriaElement = this.koKriteriumContentElement(invalidKoKriteria)
			return this.commonContentContainer(koKriteriaElement)
		}

		let component = null
		switch (step) {
			case 1:
				component = (
					<ObchodnyPartnerZalozenieEdit
						{...this.props}
						formTitle={t('translation:ZalozenieOP.Krok 1 z 2 Založenie obchodného partnera')}
						onSubmit={this.stepOneClickHandler}
						onBackClick={() => {
							history.push(setRouteParams(INDEX))
						}}
						onCancelClick={() => {
							history.push(setRouteParams(INDEX))
						}}
						onDeleteFile={this.onDeleteFile}
						schvalovacieKriteria={schvalovacieKriteria}
						validacneKriteria={validacneKriteria}
					/>
				)
				break
			case 2: {
				component = (
					<ObchodnyPartnerZalozenieConfirm
						{...this.props}
						formTitle={t('translation:ZalozenieOP.Krok 2 z 2 Založenie obchodného partnera')}
						onSubmit={this.stepTwoClickHandler}
						onBackClick={() => {
							this.setState({ step: 1 })
						}}
						onCancelClick={() => {
							history.push(setRouteParams(INDEX))
						}}
					/>
				)
				break
			}
			default:
		}

		return this.commonContentContainer(
			<>
				{result && (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={context}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	ciselniky: get(state, 'ciselniky'),
	tracking: get(state, 'tracking'),
	addresses: get(state, 'formAddresses.data'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	formValues: getFormValues(FORMS.OBCHODNY_PARTNER_ZALOZENIE)(state)
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	procesnyKonfiguratorActions: bindActionCreators(ProcesnyKonfiguratorActions, dispatch),
	interakcieActions: bindActionCreators(InterakcieActions, dispatch),
	ciselnikyActions: bindActionCreators(CiselnikyActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_ZALOZENIE_OP])
)(ObchodnyPartnerZalozeniePage)
