// noinspection JSUnusedAssignment

import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, split, head, last } from 'lodash'
import deleteIcon from '../../resources/img/icons/button-delete.svg'
import crossIcon from '../../resources/img/icons/cross-black.svg'
import Modal from './Modal'

class ImportPodpisanychDokumentovModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			file: null
		}
	}

	handleSubmit = () => {
		const { onSubmit } = this.props
		const { file } = this.state

		const reader = new FileReader()

		reader.readAsDataURL(this.state.file)
		reader.onload = () => {
			if (!get(reader, 'result')) {
				this.setState({ file: null })
			} else {
				onSubmit({
					uploadedDocumentType: head(split(get(reader, 'result'), ',')),
					uploadedDocumentAsBase64: last(split(get(reader, 'result'), ',')),
					name: get(file, 'name'),
					type: get(file, 'type')
				})
			}
		}
		reader.onerror = function () {
			this.setState({ file: null })
		}
	}

	onChangeFile = (e) => {
		if (e.target.files && e.target.files.length) {
			const file = e.target.files[0]
			e.target.value = null
			this.setState({ file })
		}
	}

	render() {
		const { modalTitle, t } = this.props
		const { file } = this.state

		let text = null
		if (file) {
			text = (
				<div>
					<span>{file.name}</span>
					<img
						width='25'
						src={deleteIcon}
						alt={t('translation:Common.Odstrániť')}
						style={{ float: 'right', cursor: 'pointer' }}
						onClick={() => this.setState({ file: null })}
					/>
				</div>
			)
		} else {
			text = (
				<div>
					<span>{t('components:ImpdModal.Nájdite a vyberte podpísaný dokument')}</span>
				</div>
			)
		}

		return (
			<Modal shown size='s'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={() => this.props.onCloseButton()} style={{ float: 'right' }}>
							<img src={crossIcon} width='25' alt={t('translation:Common.Zavrieť')} />
						</div>
					</div>
				)}
				<div className='modal-content'>
					<div className='row'>
						<div className='col-12'>{text}</div>
					</div>
				</div>
				<div className='modal-footer clearfix'>
					<div className='row'>
						<div className='col-6'>
							<div className='upload-button-wrapper'>
								<label>
									<input
										className='upload-button-file'
										ref={(ref) => {
											this.input = ref
										}}
										onChange={this.onChangeFile}
										type='file'
									/>
									<button className='button' type='button' data-color='blue' style={{ width: '100%', marginLeft: 0 }}>
										{t('components:ImpdModal.Nájsť dokument')}
									</button>
								</label>
							</div>
						</div>
						<div className='col-6'>
							<button
								className='button'
								type='button'
								data-color='blue'
								onClick={this.handleSubmit}
								style={{ width: '100%', marginLeft: 0 }}
								disabled={!this.state.file}
							>
								{t('components:ImpdModal.Vložiť dokument')}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

ImportPodpisanychDokumentovModal.propTypes = {
	modalTitle: PropTypes.string,
	onCloseButton: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired
}

export default withTranslation('components')(ImportPodpisanychDokumentovModal)
