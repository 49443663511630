import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { initialize, reset, change, destroy, getFormValues, touch } from 'redux-form'
import { cloneDeep, find, get, set, map, filter, head, isEmpty, includes, maxBy, omit } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import reactQueryParams from 'react-query-params'

// components
import ZmluvnyUcetEdit from '../../components/ZmluvneUcty/ZmluvnyUcetEdit'
import ZmluvnyUcetEditConfirm from '../../components/ZmluvneUcty/ZmluvnyUcetEditConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { history } from '../../utils/history'
import { postReq, putReq, deleteReq } from '../../utils/request'
import { getUkonVstupIdByBiznisKanal } from '../../utils/scenar'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, checkPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import {
	UKON_STAV,
	ZMLUVNY_UCET_TYP,
	SPOSOB_PLATBY_DOSLA_PLATBA,
	SCENARE,
	NOTIFICATION_TYPES,
	UKONY,
	BUSINESS_CHANNELS,
	FORMS,
	UKONY_CISELNIK,
	FAKTURACNA_PERIODA,
	FILTER_SELECTORS,
	DOKUMENT_TYP,
	DEFINICIA_TYP,
	SUHLAS_HODNOTA,
	TYP_SUHLASU,
	BIZNIS_REFERENCIA_TYP,
	SPOSOB_PLATBY_ODOSLANA_PLATBA
} from '../../utils/enums'
import { ZMLUVNE_UCTY_ZOZNAM, UKON_DETAIL, setRouteParams } from '../../utils/routes'
import { formatAddress, formatAddressForResponse } from '../../utils/address'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as UkonyActions from '../../actions/UkonyActions'
import * as TrackingActions from '../../actions/TrackingActions'
import * as FormAddresssesActions from '../../actions/FormAddressesActions'
import * as DataActions from '../../actions/DataActions'
import * as UkonEditZuActions from '../../actions/UkonEditZuActions'
import InterakcieActions from '../../actions/Interakcie'

// recources
import failureStateIcon from '../../resources/img/icons/warning.svg'
import { areDifferentNesuhlas } from '../../utils/suhlas'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.PRINTER, NOTIFICATION_TYPES.ADDRESS]
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.ODOSLAT_BEZ_PODPISU }],
		notificationRequired: true,
		documentRequired: true,
		notificationTypes: [NOTIFICATION_TYPES.EMAIL, NOTIFICATION_TYPES.ADDRESS, NOTIFICATION_TYPES.VOID_NOTIFICATION]
	}
]

class ZmluvnyUcetEditPage extends reactQueryParams {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				cisloZU: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.any.isRequired
			}).isRequired
		}).isRequired,
		bankoveUcty: PropTypes.shape({
			allData: PropTypes.array.isRequired,
			allDataPrepared: PropTypes.bool.isRequired,
			allDataLoading: PropTypes.bool.isRequired
		}).isRequired,
		auth: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape(),
		tracking: PropTypes.shape(),
		obchodnyPartnerActions: PropTypes.shape({
			loadZmluvneUcty: PropTypes.func.isRequired,
			loadObchodnyPartnerDetail: PropTypes.func.isRequired
		}).isRequired,
		formAddressesActions: PropTypes.shape({
			formAddressesInit: PropTypes.func.isRequired
		}).isRequired,
		procesnyKonfigurator: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired,
			data: PropTypes.shape()
		}).isRequired,
		obchodnyPartner: PropTypes.shape.isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		ukonyActions: PropTypes.shape({
			clearUkonId: PropTypes.func.isRequired
		}),
		context: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		notification: PropTypes.shape(),
		signedPdf: PropTypes.shape(),
		templatePdf: PropTypes.shape(),
		dataActions: PropTypes.shape(),
		editZmluvnyUcet: PropTypes.shape(),
		ukonEditZuActions: PropTypes.shape(),
		interakcieActions: PropTypes.shape().isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const ProcesnyKonfigurator = initProcesnyKonfigurator(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'nastavenieZU')

		// filter possible scenars for signing pdf files
		const scenarioOption = filter(scenarioOptions, (scenarioOption) => {
			return get(scenarioOption, 'kanal') === get(props, 'auth.businessChannel.actual.id')
		})

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			step: 1,
			scenarioOption: head(scenarioOption),
			originalValues: {},
			originalValuesConfirm: {},
			addresses: [],
			isLoading: true,
			ProcesnyKonfigurator,
			koKriteria: [],
			isKoKriteriumChecked: false,
			validacneKriteria: [],
			schvalovacieKriteria: [],
			dissallowedScenarios: [],
			backUrlLink
		}
	}

	parseNesuhlasy = (nesuhlasy) => {
		let paymentOriginalData = {}
		let paymentInitData = {}
		nesuhlasy?.definicie?.forEach((definicia) => {
			if (!definicia?.aktualnyNesuhlas?.nesuhlasDovod?.id) {
				return
			}
			if (definicia.id === DEFINICIA_TYP.E_FAKTURA) {
				const suhlasEfaktura = {
					hodnota: SUHLAS_HODNOTA.NAMIETKA,
					nesuhlasDovod: { ...definicia?.aktualnyNesuhlas?.nesuhlasDovod },
					nesuhlasPoznamka: definicia?.nesuhlasPoznamka,
					nesuhlasDovodIny: definicia?.nesuhlasPoznamka,
					platnostOd: definicia?.aktualnyNesuhlas?.platnostOd
				}
				paymentOriginalData = {
					...paymentOriginalData,
					suhlasEfaktura
				}
				if (definicia?.aktualnyNesuhlas?.isValid) {
					paymentInitData = {
						...paymentInitData,
						suhlasEfaktura
					}
				}
			} else if (definicia.id === DEFINICIA_TYP.E_PLATBA) {
				const suhlasEplatba = {
					hodnota: SUHLAS_HODNOTA.NAMIETKA,
					nesuhlasDovod: { ...definicia?.aktualnyNesuhlas?.nesuhlasDovod },
					nesuhlasPoznamka: definicia?.nesuhlasPoznamka,
					nesuhlasDovodIny: definicia?.nesuhlasPoznamka,
					platnostOd: definicia?.aktualnyNesuhlas?.platnostOd
				}
				paymentOriginalData = {
					...paymentOriginalData,
					suhlasEplatba
				}
				if (definicia?.aktualnyNesuhlas?.isValid) {
					paymentInitData = {
						...paymentInitData,
						suhlasEplatba
					}
				}
			} else if (definicia.id === DEFINICIA_TYP.E_PREPLATKY) {
				const suhlasEpreplatky = {
					hodnota: SUHLAS_HODNOTA.NAMIETKA,
					nesuhlasDovod: { ...definicia?.aktualnyNesuhlas?.nesuhlasDovod },
					nesuhlasPoznamka: definicia?.nesuhlasPoznamka,
					nesuhlasDovodIny: definicia?.nesuhlasPoznamka,
					platnostOd: definicia?.aktualnyNesuhlas?.platnostOd
				}
				paymentOriginalData = {
					...paymentOriginalData,
					suhlasEpreplatky
				}
				if (definicia?.aktualnyNesuhlas?.isValid) {
					paymentInitData = {
						...paymentInitData,
						suhlasEpreplatky
					}
				}
			}
		})
		return { paymentOriginalData, paymentInitData }
	}

	componentDidMount = async () => {
		this._mounted = true

		const {
			dispatch,
			auth,
			tracking,
			ciselniky,
			trackingActions,
			dataActions,
			obchodnyPartnerActions,
			formAddressesActions,
			computedMatch,
			obchodnyPartner,
			interakcia,
			ukonEditZuActions
		} = this.props

		dataActions.registerLeavePageModal()

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== UKONY.NASTAVENIE_ZU)) {
			trackingActions.tryToStartTracking(UKONY.NASTAVENIE_ZU)
		}

		formAddressesActions.formAddressClean()

		const { cisloZU } = computedMatch.params

		const ukonData = await ukonEditZuActions.loadDataForUkonEditZu(get(interakcia, 'opCislo'), cisloZU)
		let initData = head(get(ukonData, 'data.zmluvneUcty', []))
		let originalData = head(get(ukonData, 'dataPred.zmluvneUcty', []))

		obchodnyPartnerActions.loadAllBankoveUcty(0)

		const addresses = [...map(get(obchodnyPartner, 'adresy', []), (address) => cloneDeep(address))]

		formAddressesActions.formAddressesInit(addresses)

		// NOTE: adding new feature based on https://jira.zsee.sk/jira/browse/CP-2990
		const nesuhlasForZu = await ukonEditZuActions.loadNesuhlasForZu(get(interakcia, 'opCislo'), cisloZU)

		const { paymentInitData, paymentOriginalData } = this.parseNesuhlasy(nesuhlasForZu.response)

		// NOTE: removing suhlasy from init data based on CP-3144 task
		initData = { ...omit(initData, ['suhlasEfaktura', 'suhlasEplatba', 'suhlasEpreplatky']) }

		initData = {
			...initData,
			...paymentInitData
		}

		originalData = {
			...originalData,
			...paymentOriginalData
		}

		const { adresaDoslePlatby, adresaDoslePlatbySectionInherit, adresaDoslePlatbySection } = this.inheritAddress('adresaDoslePlatby', initData)

		const { adresaOdoslanePlatby, adresaOdoslanePlatbySectionInherit, adresaOdoslanePlatbySection } = this.inheritAddress('adresaOdoslanePlatby', initData)

		const { adresaFakturacie, adresaFakturacieSectionInherit, adresaFakturacieSection } = this.inheritAddress('adresaFakturacie', initData)

		const { adresaUpominacia, adresaUpominaciaSectionInherit, adresaUpominaciaSection } = this.inheritAddress('adresaUpominacia', initData)

		const adresaDoslePlatbyConfirm = this.inheritAddress('adresaDoslePlatby', initData, false)
		const adresaOdoslanePlatbyConfirm = this.inheritAddress('adresaOdoslanePlatby', initData, false)
		const adresaFakturacieConfirm = this.inheritAddress('adresaFakturacie', initData, false)
		const adresaUpominaciaConfirm = this.inheritAddress('adresaUpominacia', initData, false)

		let showEditAdresaOdoslanePlatby = false

		const odoslanePlatbySposob = get(initData, 'odoslanePlatbySposob')

		if (
			odoslanePlatbySposob?.id === SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA &&
			adresaOdoslanePlatby?.id &&
			adresaOdoslanePlatby?.id !== adresaFakturacie?.id
		) {
			showEditAdresaOdoslanePlatby = true
		}

		const initValues = createFormInitValues({
			ukonVstup: getUkonVstupIdByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			datumPrijatiaZiadosti: new Date(),
			podpisMiesto: get(auth, 'user.podpisMiesto', ''),
			splnomocnenec: null,
			eFakturaEmaily: get(initData, 'eFakturaEmaily', []),
			doslePlatbySposob: get(initData, 'doslePlatbySposob'),
			odoslanePlatbySposob,
			adresaDoslePlatby: adresaDoslePlatby || null,
			adresaOdoslanePlatby: adresaOdoslanePlatby || null,
			adresaFakturacie: adresaFakturacie || null,
			adresaUpominacia: adresaUpominacia || null,
			doslePlatbyBankoveSpojenie: get(initData, 'doslePlatbyBankoveSpojenie'),
			odoslanePlatbyBankoveSpojenie: get(initData, 'odoslanePlatbyBankoveSpojenie'),
			neposielatPripomienkuPlatby: get(initData, 'neposielatPripomienkuPlatby'),
			suhlasEfaktura: get(initData, 'suhlasEfaktura'),
			suhlasEplatba: get(initData, 'suhlasEplatba'),
			suhlasEpreplatky: get(initData, 'suhlasEpreplatky'),
			showEditAdresaOdoslanePlatby
		})

		const originalValues = {
			...initValues,
			eFakturaEmaily: get(originalData, 'eFakturaEmaily', []),
			doslePlatbySposob: get(originalData, 'doslePlatbySposob', null),
			odoslanePlatbySposob: get(originalData, 'odoslanePlatbySposob', null),
			adresaDoslePlatby,
			adresaDoslePlatbySectionInherit,
			adresaDoslePlatbySection,
			adresaOdoslanePlatby,
			adresaOdoslanePlatbySectionInherit,
			adresaOdoslanePlatbySection,
			adresaFakturacie,
			adresaFakturacieSectionInherit,
			adresaFakturacieSection,
			adresaUpominacia,
			adresaUpominaciaSectionInherit,
			adresaUpominaciaSection,
			neposielatPripomienkuPlatby: get(originalData, 'neposielatPripomienkuPlatby'),
			suhlasEfaktura: get(originalData, 'suhlasEfaktura'),
			suhlasEplatba: get(originalData, 'suhlasEplatba'),
			suhlasEpreplatky: get(originalData, 'suhlasEpreplatky'),
			doslePlatbyBankoveSpojenie: get(originalData, 'doslePlatbyBankoveSpojenie', null),
			odoslanePlatbyBankoveSpojenie: get(originalData, 'odoslanePlatbyBankoveSpojenie', null)
		}

		// NOTE: CP-1632
		const isNCB = get(originalData, 'typ.id') === ZMLUVNY_UCET_TYP.NCB
		if (isNCB) {
			const fakturacnaPeriodaDefault = find(get(ciselniky, 'fakturacnaPerioda', []), (fakturacnaPerioda) => {
				return get(fakturacnaPerioda, 'id') === FAKTURACNA_PERIODA.MESACNE
			})
			initValues.fakturacnaPerioda = get(initData, 'fakturacnaPerioda.id') || get(fakturacnaPeriodaDefault, 'id') || null
			originalValues.fakturacnaPerioda = get(originalData, 'fakturacnaPerioda.id', null)
		}
		if (initValues.doslePlatbySposob === SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ) {
			// NOTE: CP-1017 - V pripade zvoleneho inkasneho prikazu pre prijate platby sa cislo uctu IBAN pouzije automaticky aj pre odoslane platby
			initValues.odoslanePlatbyBankoveSpojenie = get(initData, 'doslePlatbyBankoveSpojenie.IBAN')
		}

		// NOTE: CP-3251, CP-3255 - pre zobrazenie adries na druhom kroku potrebujeme adresu bez "do ruk", preto sa nemozu pouzit originalValues (kde je adresa aj s hodnotou "do ruk", kvoli prvemu kroku)
		const originalValuesConfirm = {
			...originalValues,
			adresaDoslePlatby: adresaDoslePlatbyConfirm.adresaDoslePlatby,
			adresaDoslePlatbySectionInherit: adresaDoslePlatbyConfirm.adresaDoslePlatbySectionInherit,
			adresaDoslePlatbySection: adresaDoslePlatbyConfirm.adresaDoslePlatbySection,
			adresaOdoslanePlatby: adresaOdoslanePlatbyConfirm.adresaOdoslanePlatby,
			adresaOdoslanePlatbySectionInherit: adresaOdoslanePlatbyConfirm.adresaOdoslanePlatbySectionInherit,
			adresaOdoslanePlatbySection: adresaOdoslanePlatbyConfirm.adresaOdoslanePlatbySection,
			adresaFakturacie: adresaFakturacieConfirm.adresaFakturacie,
			adresaFakturacieSectionInherit: adresaFakturacieConfirm.adresaFakturacieSectionInherit,
			adresaFakturacieSection: adresaFakturacieConfirm.adresaFakturacieSection,
			adresaUpominacia: adresaUpominaciaConfirm.adresaUpominacia,
			adresaUpominaciaSectionInherit: adresaUpominaciaConfirm.adresaUpominaciaSectionInherit,
			adresaUpominaciaSection: adresaUpominaciaConfirm.adresaUpominaciaSection
		}

		dispatch(initialize(FORMS.UPRAVA_ZMLUVNEHO_UCTU, initValues, true))
		dispatch(reset(FORMS.UPRAVA_ZMLUVNEHO_UCTU))

		if (this._mounted) {
			this.setState(
				{
					originalValues,
					originalValuesConfirm,
					zmluvnyUcet: originalData
				},
				() => {
					const body = this.formatDataForUkon()
					ukonEditZuActions.loadContext({
						...body
					})
				}
			)
		}

		if (!get(initValues, 'ukonVstup')) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'ukonVstup'))
		}
		if (get(auth, 'businessChannel.actual.id') === BUSINESS_CHANNELS.BACK_OFFICE && isEmpty(get(initValues, 'dokumenty'))) {
			// trigger validation immediately after init form
			dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'dokumenty'))
		}

		this.setState({
			isLoading: false
		})
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { step } = this.state

		// In case of return from step 2 back to step 1 revert default list of available
		if (prevState.step === 2 && step === 1) {
			this.setState({ dissallowedScenarios: [] })
		}
	}

	componentWillUnmount = () => {
		const { dispatch, trackingActions, ukonyActions, dataActions } = this.props

		dispatch(destroy(FORMS.UPRAVA_ZMLUVNEHO_UCTU))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		this._mounted = false
	}

	inheritAddress = (key, zmluvnyUcet, formatWithIntoTheHands = true) => {
		const { obchodnyPartner, t } = this.props

		let adresa = null
		let adresaSectionInherit = ''
		let adresaSection = '-'
		if (get(zmluvnyUcet, key)) {
			adresa = get(zmluvnyUcet, key, null)
			adresaSection = formatAddress(get(zmluvnyUcet, key), formatWithIntoTheHands)
		} else if (get(zmluvnyUcet, 'adresaFakturacie')) {
			// inherit from adresa fakturacna
			adresa = get(zmluvnyUcet, 'adresaFakturacie', null)
			adresaSectionInherit = t('translation:ZmluvneUcty.Adresa fakturačná')
			adresaSection = formatAddress(get(zmluvnyUcet, 'adresaFakturacie'), formatWithIntoTheHands)
		} else if (get(obchodnyPartner, 'adresaKorespondencna')) {
			// inherit from adresa korespondencna
			adresa = get(obchodnyPartner, 'adresaKorespondencna', null)
			adresaSectionInherit = t('translation:ZmluvneUcty.Adresa korešpondenčná')
			adresaSection = formatAddress(get(obchodnyPartner, 'adresaKorespondencna'), formatWithIntoTheHands)
		} else if (get(obchodnyPartner, 'adresaZakaznika')) {
			// inherit from adresa zakaznika
			adresa = get(obchodnyPartner, 'adresaZakaznika', null)
			adresaSectionInherit = t('translation:Common.Adresa trvalého pobytu')
			adresaSection = formatAddress(get(obchodnyPartner, 'adresaZakaznika'), formatWithIntoTheHands)
		}

		return {
			[`${key}`]: adresa,
			[`${key}SectionInherit`]: adresaSectionInherit,
			[`${key}Section`]: adresaSection
		}
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ciselniky, addresses } = this.props
		const { zmluvnyUcet, schvalovacieKriteria } = this.state

		const adresaFakturacie = formatAddressForResponse('adresaFakturacie', values, addresses)
		const adresaOdoslanePlatby = formatAddressForResponse('adresaOdoslanePlatby', values, addresses)
		const showEditAdresaOdoslanePlatby = get(values, 'showEditAdresaOdoslanePlatby')

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: get(schvalovacieKriterium, 'nazov'),
				vysledok: get(schvalovacieKriterium, 'vysledok'),
				dovod: get(schvalovacieKriterium, 'popis')
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		const typ = find(get(ciselniky, 'ukonTyp', []), (ukonTyp) => {
			return get(ukonTyp, 'id') === UKONY_CISELNIK.NASTAVENIE_ZU
		})

		const vstup = find(get(ciselniky, 'ukonVstup', []), (vstup) => {
			return get(vstup, 'id') === get(values, 'ukonVstup')
		})

		const suhlasEfaktura = get(values, 'suhlasEfaktura')
		const suhlasEplatba = get(values, 'suhlasEplatba')
		const suhlasEpreplatky = get(values, 'suhlasEpreplatky')

		const body = {
			typ,
			vstup,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id'),
			riesitel: get(auth, 'user.id'),
			kanal: get(auth, 'businessChannel.actual'),
			trvanie: dayjs().diff(dayjs(tracking.startTime), 'millisecond'),
			zacatyOd: dayjs(tracking.startTime).toISOString(),
			podpisMiesto: get(values, 'podpisMiesto'),
			poznamka: get(values, 'poznamka'),
			ziadanyOd: dayjs(get(values, 'datumPrijatiaZiadosti')).toISOString(),
			splnomocnenec: get(values, 'splnomocnenec'),
			data: {
				schvalovaciRezim,
				zmluvneUcty: [
					{
						...zmluvnyUcet,
						adresaFakturacie,
						adresaUpominacia: adresaFakturacie,
						adresaDoslePlatby: adresaFakturacie,
						adresaOdoslanePlatby: showEditAdresaOdoslanePlatby ? adresaOdoslanePlatby : adresaFakturacie,
						adresaVseobecna: adresaFakturacie,
						adresaDane: adresaFakturacie,
						doslePlatbySposob: get(values, 'doslePlatbySposob'),
						odoslanePlatbySposob: get(values, 'odoslanePlatbySposob'),
						doslePlatbyBankoveSpojenie: get(values, 'doslePlatbyBankoveSpojenie'),
						odoslanePlatbyBankoveSpojenie: get(values, 'odoslanePlatbyBankoveSpojenie'),
						eFakturaEmaily: get(values, 'eFakturaEmaily'),
						neposielatPripomienkuPlatby: get(values, 'neposielatPripomienkuPlatby'),
						suhlasEfaktura,
						suhlasEplatba,
						suhlasEpreplatky
					}
				]
			}
		}

		// NOTE: CP-1632
		const isNCB = get(zmluvnyUcet, 'typ.id') === ZMLUVNY_UCET_TYP.NCB
		if (isNCB) {
			const fakturacnaPerioda = find(get(ciselniky, 'fakturacnaPerioda', []), (fakturacnaPerioda) => {
				return get(fakturacnaPerioda, 'id') === get(values, 'fakturacnaPerioda')
			})
			set(body, 'data.zmluvneUcty[0].fakturacnaPerioda', fakturacnaPerioda)
		}

		return body
	}

	setLoading = (value) => {
		if (this._mounted) {
			this.setState({
				isLoading: value
			})
		}
	}

	stepOneClickHandler = async (values) => {
		const { dispatch, interakcia, ukonyActions, tracking, ukonNovy, ukonEditZuActions } = this.props

		this.setLoading(true)

		const body = this.formatDataForUkon(values)

		await ukonEditZuActions.loadContext(body)

		try {
			let ukonID
			// if ukon not exist yet create a new one else update it
			if (!get(ukonNovy, 'id')) {
				const res = await postReq(`/api/v2/op/${get(interakcia, 'opCislo')}/ukony`, null, body)

				// set podpis Miesto from response CP-588
				const podpisMiesto = get(res, 'response.content.podpisMiesto')
				dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'podpisMiesto', podpisMiesto))

				ukonID = get(res, 'response.content.id')
				ukonyActions.setUkonId(ukonID)
			} else {
				ukonID = get(ukonNovy, 'id')
				await putReq(`/api/v2/ukony/${ukonID}`, null, {
					...body,
					trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
					id: ukonID
				})
			}

			// dokumenty which are not upload does not have ID
			const notUploadedDokumenty = filter(get(values, 'dokumenty', []), (document) => !get(document, 'id'))
			const uploadedDokumenty = filter(get(values, 'dokumenty', []), (document) => get(document, 'id'))

			const dokumenty = map(notUploadedDokumenty, async (document) => {
				const documentResponse = await postReq(`/api/v0/ukony/${ukonID}/prilozit-dokument`, null, {
					contentType: get(document, 'type'),
					nazov: get(document, 'name'),
					data: get(document, 'dataAsBase64'),
					typ: {
						id: DOKUMENT_TYP.VSTUPNY
					}
				})
				return {
					type: get(documentResponse, 'response.contentType'),
					id: get(documentResponse, 'response.id'),
					dataAsBase64: get(document, 'dataAsBase64'),
					typ: get(documentResponse, 'response.typ'),
					name: get(documentResponse, 'response.nazov')
				}
			})

			const dokumentyPromises = await Promise.all(dokumenty)

			// merge already uploaded dokumenty and fresh uploaded
			dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'dokumenty', [...uploadedDokumenty, ...dokumentyPromises]))

			if (this._mounted) {
				this.setState({
					step: 2,
					isLoading: false
				})
			}
		} catch (e) {
			this.setLoading(false)
			// eslint-disable-next-line
			console.log(e)
		}
	}

	prepareSuhlas = ({ suhlasData, definiciaId, opCislo, kanal, pouzivatel, pouzivatelIdentifikator, cisloZU, biznisReferenciaTyp, biznisReferencia }) => {
		return {
			// suhlasId: '',
			datumVyjadrenie: suhlasData?.platnostOd,
			definiciaId,
			kanal,
			nesuhlasDovod: suhlasData?.nesuhlasDovod,
			nesuhlasPoznamka: suhlasData?.nesuhlasDovodIny,
			opCislo,
			platnostOd: suhlasData?.platnostOd,
			pouzivatel,
			pouzivatelIdentifikator,
			typVyjadrenieSuhlas: TYP_SUHLASU.SUHLAS,
			zmluvnyUcet: cisloZU,
			biznisReferenciaTyp,
			biznisReferencia
		}
	}

	formDataForSuhlasy = ({ values, opCislo, auth, interakcia, cisloZU, ukonId }) => {
		const { originalValues } = this.state
		const suhlasy = []

		const suhlas = {
			opCislo,
			kanal: interakcia?.kanal?.id,
			pouzivatel: get(auth, 'user.displayName'),
			pouzivatelIdentifikator: get(auth, 'user.id'),
			cisloZU,
			biznisReferenciaTyp: BIZNIS_REFERENCIA_TYP.UKON_ID.id,
			biznisReferencia: ukonId
		}

		if (!isEmpty(values?.suhlasEfaktura?.nesuhlasDovod) && values.suhlasEfaktura?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			if (areDifferentNesuhlas(originalValues?.suhlasEfaktura, values.suhlasEfaktura)) {
				suhlasy.push(
					this.prepareSuhlas({
						...suhlas,
						suhlasData: values.suhlasEfaktura,
						definiciaId: DEFINICIA_TYP.E_FAKTURA
					})
				)
			}
		}
		if (!isEmpty(values?.suhlasEplatba?.nesuhlasDovod) && values.suhlasEplatba?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			if (areDifferentNesuhlas(originalValues?.suhlasEplatba, values.suhlasEplatba)) {
				suhlasy.push(
					this.prepareSuhlas({
						...suhlas,
						suhlasData: values.suhlasEplatba,
						definiciaId: DEFINICIA_TYP.E_PLATBA
					})
				)
			}
		}
		if (!isEmpty(values?.suhlasEpreplatky?.nesuhlasDovod) && values.suhlasEpreplatky?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			if (areDifferentNesuhlas(originalValues?.suhlasEpreplatky, values.suhlasEpreplatky)) {
				suhlasy.push(
					this.prepareSuhlas({
						...suhlas,
						suhlasData: values.suhlasEpreplatky,
						definiciaId: DEFINICIA_TYP.E_PREPLATKY
					})
				)
			}
		}

		return suhlasy
	}

	stepTwoClickHandler = async (values) => {
		const { auth, tracking, dataActions, ukonNovy, signedPdf, notification, templatePdf, t, interakcia, computedMatch } = this.props

		const opCislo = get(interakcia, 'opCislo')
		const { cisloZU } = computedMatch.params
		const ukonId = get(ukonNovy, 'id')

		this.setLoading(true)

		const notifikacie = {
			adresyPosta: [],
			adresyUri: []
		}
		if (get(notification, 'typ') === NOTIFICATION_TYPES.ADDRESS) {
			notifikacie.adresyPosta.push(get(notification, 'address'))
		} else if (get(notification, 'typ') === NOTIFICATION_TYPES.EMAIL) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.EMAIL,
				hodnota: get(notification, 'email')
			})
		} else if (get(notification, 'typ') === NOTIFICATION_TYPES.PRINTER) {
			notifikacie.adresyUri.push({
				typ: NOTIFICATION_TYPES.PRINTER,
				poradie: 0
			})
		}

		const documentForUpload = get(signedPdf, 'data.dataAsBase64') ? get(signedPdf, 'data') : get(templatePdf, 'data')
		try {
			if (documentForUpload) {
				await postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, {
					contentType: get(documentForUpload, 'type'),
					typ: {
						id: DOKUMENT_TYP.VYSTUPNY
					},
					nazov: get(documentForUpload, 'name'),
					data: get(documentForUpload, 'dataAsBase64')
				})
			}

			const promiseArray = []

			const body = this.formatDataForUkon(values)
			const updateUkonPromise = postReq(`/api/v2/ukony/${ukonId}/spustit`, null, {
				...body,
				trvanie: dayjs().diff(dayjs(get(tracking, 'startTime')), 'millisecond'),
				notifikacie,
				id: ukonId
			})
			promiseArray.push(updateUkonPromise)

			const suhlasy = this.formDataForSuhlasy({
				values,
				opCislo,
				auth,
				interakcia,
				cisloZU,
				ukonId
			})

			if (!isEmpty(suhlasy)) {
				const suhlasyPromise = postReq(`/api/ces/suhlasy/${opCislo}`, null, [...suhlasy])
				promiseArray.push(suhlasyPromise)
			}

			const results = await Promise.all(promiseArray)

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				const dependency = find(get(head(results), 'response.content.ukonDependencies', []), (dependency) => {
					return get(dependency, 'ukonStav.id') === UKON_STAV.ODLOZENY
				})
				let message = t('translation:Common.Úkon bol úspešne odoslaný')
				if (dependency) {
					if (get(auth, 'businessChannel.actual.id') === BUSINESS_CHANNELS.ZSE_CENTRUM) {
						message = t('translation:Common.Úkon bol úspešne odoslaný, môžete pokračovať podpisom alebo nahratím SEPA mandátu')
					} else if (get(auth, 'businessChannel.actual.id') === BUSINESS_CHANNELS.BACK_OFFICE) {
						message = t('translation:Common.Úkon bol úspešne odoslaný, môžete pokračovať nahratím alebo zaslaním SEPA mandátu')
					}
				}
				this.setState({
					result: message,
					dependency: get(dependency, 'id'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	onDeleteFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	confirmModal = () => {
		const { interakcia, selectedFiltersUkony, ukonyActions, obchodnyPartnerActions, interakcieActions } = this.props
		const { success, backUrlLink } = this.state

		if (this._mounted) {
			if (success) {
				this.setState(
					{
						success: false
					},
					() => {
						// refetch new data for OP
						obchodnyPartnerActions.loadZmluvneUcty(true)

						// clear ukonId from storage
						ukonyActions.clearUkonId()

						// refetch new data for historia ukonov
						ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)

						// refetch new data for OP - addresses can be changed
						obchodnyPartnerActions.loadObchodnyPartnerDetail(get(interakcia, 'opCislo'))

						interakcieActions.refreshAcw()

						history.replace(backUrlLink || setRouteParams(ZMLUVNE_UCTY_ZOZNAM, get(interakcia, 'opCislo')))
					}
				)
			} else {
				this.setState({
					result: null
				})
			}
		}
	}

	continueSEPASigning = () => {
		const { selectedFiltersUkony } = this.props
		const { interakcia, ukonyActions, obchodnyPartnerActions } = this.props
		const { dependency } = this.state

		this.setState(
			{
				success: false
			},
			() => {
				// refetch new data for OP
				obchodnyPartnerActions.loadZmluvneUcty(true)

				// clear ukonID from storage
				ukonyActions.clearUkonId()

				// refetch new data for historia ukonov
				ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
				history.replace(setRouteParams(UKON_DETAIL, get(interakcia, 'opCislo'), dependency))
			}
		)
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteria) =>
		this.setState({
			isKoKriteriumChecked,
			koKriteria
		})

	validacneKriteriaHandler = (validacneKriteria) =>
		this.setState({
			validacneKriteria
		})

	schvalovacieKriteriaHandler = (schvalovacieKriteria) =>
		this.setState({
			schvalovacieKriteria
		})

	koKriteriumContentElement = (koKriteria) => {
		const { t } = this.props
		const content = map(koKriteria, (koKriterium, index) => {
			return (
				<React.Fragment key={index}>
					<p>{get(koKriterium, 'nazov')}:</p>
					<p className='message fail'>{get(koKriterium, 'popis')}</p>
				</React.Fragment>
			)
		})

		return (
			<div className='failure-content-container'>
				<img src={failureStateIcon} alt={t('translation:Common.Upozornenie!')} />
				{content}
			</div>
		)
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})

	render = () => {
		const { auth, context, interakcia, computedMatch, editZmluvnyUcet, bankoveUcty, signedPdf, notification, procesnyKonfigurator, formValues, t } =
			this.props
		const {
			step,
			scenarioOption,
			originalValuesConfirm,
			originalValues,
			isLoading,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			success,
			result,
			dependency,
			dissallowedScenarios,
			backUrlLink,
			ProcesnyKonfigurator
		} = this.state
		const { cisloZU } = computedMatch.params

		if (
			!this._mounted ||
			isLoading ||
			get(editZmluvnyUcet, 'zmluvnyUcet.isLoading') ||
			get(bankoveUcty, 'allDataLoading') ||
			get(procesnyKonfigurator, 'isLoading') ||
			get(context, 'isLoading')
		) {
			return <ElementLoading />
		}

		if (get(procesnyKonfigurator, 'zmluvnyUcet.isFailure') || isEmpty(get(procesnyKonfigurator, 'data.ukony')) || get(context, 'isFailure')) {
			return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
		}

		if (get(editZmluvnyUcet, 'zmluvnyUcet.isFailure')) {
			return <ElementFailure text={t('translation:ZmluvneUcty.Nepodarilo sa načítať zmluvný účet')} />
		}

		if (isEmpty(get(editZmluvnyUcet, 'zmluvnyUcet.data'))) {
			return <ElementEmptyContent text={t('translation:ZmluvneUcty.Nepodarilo sa načítať zmluvný účet')} />
		}

		// check if every Ko kriterium pass condition, if not navigate user back to step 1
		const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		if (!isEmpty(invalidKoKriteria)) {
			return this.koKriteriumContentElement(invalidKoKriteria)
		}

		let modal = null
		if (result) {
			if (dependency && success) {
				modal = (
					<DefaultModal
						modalTitle={t('translation:Common.Odoslané')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: 'green',
							outline: 'outline'
						}}
						rightButton={{
							onClick: this.continueSEPASigning,
							text: t('translation:Common.Pokračovať'),
							color: 'green'
						}}
						visible
					/>
				)
			} else {
				modal = (
					<DefaultModal
						modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
						modalContent={result}
						leftButton={{
							onClick: this.confirmModal,
							text: t('translation:Common.Zavrieť'),
							color: success ? 'green' : 'red'
						}}
						visible
					/>
				)
			}
		}

		const zmluvnyUcet = head(get(editZmluvnyUcet, 'zmluvnyUcet.data.data.zmluvneUcty', []))
		const isNCB = get(zmluvnyUcet, 'typ.id') == ZMLUVNY_UCET_TYP.NCB

		let component = null
		if (!modal) {
			switch (step) {
				case 1:
					component = (
						<ZmluvnyUcetEdit
							{...this.props}
							cisloZU={cisloZU}
							originalValues={originalValues}
							isNCB={isNCB}
							formTitle={t('translation:ZmluvneUcty.Krok 1 z 2 Editácia údajov zmluvného účtu', { cisloZU })}
							onSubmit={this.stepOneClickHandler}
							onBackClick={() => history.push(backUrlLink || setRouteParams(ZMLUVNE_UCTY_ZOZNAM, get(interakcia, 'opCislo')))}
							onCancelClick={() => history.push(backUrlLink || setRouteParams(ZMLUVNE_UCTY_ZOZNAM, get(interakcia, 'opCislo')))}
							onDeleteFile={this.onDeleteFile}
							schvalovacieKriteria={schvalovacieKriteria}
							validacneKriteria={validacneKriteria}
						/>
					)
					break
				case 2: {
					// check if scenar is defined
					const customizedScenarioOption = {
						...scenarioOption,
						scenarios: filter(get(scenarioOption, 'scenarios', []), (scenario) => {
							const allowedRoles = get(scenario, 'allowedRoles', [])
							return (
								!includes(dissallowedScenarios, get(scenario, 'typ')) &&
								(isEmpty(get(scenario, 'allowedRoles', [])) || checkPermissions(get(auth, 'user.roles'), allowedRoles))
							)
						})
					}
					if (customizedScenarioOption.notificationTypesCallback) {
						customizedScenarioOption.notificationTypes = customizedScenarioOption.notificationTypesCallback(get(formValues, 'doslePlatbySposob'))
					}

					// if notification is required and not selected yet
					const isDisabledNotification = get(customizedScenarioOption, 'notificationRequired') && !get(notification, 'typ')

					// for scenarios VYTLACIT_A_NAHRAT and PODPISANIE_NA_MIESTE is required signedPDF
					let isDisabledSignedPdf = true
					if (
						(find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.VYTLACIT_A_NAHRAT }) ||
							find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.PODPISANIE_NA_MIESTE })) &&
						get(signedPdf, 'data.dataAsBase64')
					) {
						isDisabledSignedPdf = false
					} else if (
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_BEZ_PODPISU }) ||
						find(get(customizedScenarioOption, 'scenarios', []), { typ: SCENARE.ODOSLAT_NA_PODPIS })
					) {
						isDisabledSignedPdf = false
					}

					const isDisabledScenarNotSelected =
						get(customizedScenarioOption, 'scenarios', []).length > get(customizedScenarioOption, 'maxAllowedScenars')
					component = (
						<ZmluvnyUcetEditConfirm
							{...this.props}
							cisloZU={cisloZU}
							originalValues={originalValuesConfirm}
							isNCB={isNCB}
							scenarioOption={customizedScenarioOption}
							formTitle={t('translation:ZmluvneUcty.Krok 2 z 2 Editácia údajov zmluvného účtu', { cisloZU })}
							onSubmit={this.stepTwoClickHandler}
							onBackClick={() => {
								this.setState({ step: 1 })
							}}
							onCancelClick={() => history.push(backUrlLink || setRouteParams(ZMLUVNE_UCTY_ZOZNAM, get(interakcia, 'opCislo')))}
							disallowScenarios={this.disallowScenarios}
							formatDataForUkon={this.formatDataForUkon}
							isDisabledNotification={isDisabledNotification}
							isDisabledSignedPdf={isDisabledSignedPdf}
							isDisabledScenarNotSelected={isDisabledScenarNotSelected}
							schvalovacieKriteria={schvalovacieKriteria}
						/>
					)
					break
				}
				default:
			}
		}

		return (
			<>
				{modal}
				{component}
				<ProcesnyKonfigurator
					koKriteriaHandler={this.koKriteriaHandler}
					validacneKriteriaHandler={this.validacneKriteriaHandler}
					schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
					formatDataForUkon={this.formatDataForUkon}
					context={get(context, 'data') || {}}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	templatePdf: get(state, 'podpisovanieDokumentov.templatePdf'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	addresses: get(state, 'formAddresses.data'),
	context: get(state, 'ukonEditZU.contextZu'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	bankoveUcty: get(state, 'obchodnyPartner.bankoveUcty'),
	formValues: getFormValues(FORMS.UPRAVA_ZMLUVNEHO_UCTU)(state),
	editZmluvnyUcet: get(state, 'ukonEditZU'),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	ukonEditZuActions: bindActionCreators(UkonEditZuActions, dispatch),
	interakcieActions: bindActionCreators(InterakcieActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_UDAJE_ZU])
)(ZmluvnyUcetEditPage)
