import { getReq } from '../utils/request'
import { minutesBetween } from '../utils/date'
import {
	CISELNIKY_MIESTA_SPOTREBY_LOAD_START,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_DONE,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_FAIL,
	CISELNIKY_MIESTA_SPOTREBY_LOAD_PART_DONE,
	CISELNIKY_ZMLUVNE_UCTY_START,
	CISELNIKY_ZMLUVNE_UCTY_DONE,
	CISELNIKY_ZMLUVNE_UCTY_FAIL
} from '../types/ciselnikySelectBox'

export function miestaSpotrebyCiselnikyLoadStart() {
	return {
		type: CISELNIKY_MIESTA_SPOTREBY_LOAD_START
	}
}

export function miestaSpotrebyCiselnikyLoadDone() {
	return {
		type: CISELNIKY_MIESTA_SPOTREBY_LOAD_DONE
	}
}

export function miestaSpotrebyCiselnikyLoadFail() {
	return {
		type: CISELNIKY_MIESTA_SPOTREBY_LOAD_FAIL
	}
}

export function miestaSpotrebyCiselnikyLoadPartDone(miestaSpotreby) {
	return {
		type: CISELNIKY_MIESTA_SPOTREBY_LOAD_PART_DONE,
		payload: {
			miestaSpotreby
		}
	}
}

export const zmluvneUctyLoadStart = () => {
	return {
		type: CISELNIKY_ZMLUVNE_UCTY_START
	}
}

export const zmluvneUctyLoadDone = (zmluvneUcty) => {
	return {
		type: CISELNIKY_ZMLUVNE_UCTY_DONE,
		payload: {
			zmluvneUcty
		}
	}
}

export const zmluvneUctyLoadFail = () => {
	return {
		type: CISELNIKY_ZMLUVNE_UCTY_FAIL
	}
}

// load all odberne miesta by recursive funcion
const loadMiestaSpotrebyByParts = async (cisloOP, dispatch, pageSize, getStore) => {
	const loadPage = async (page) => {
		const query = {
			velkostStranky: pageSize,
			stranka: page
		}
		try {
			const res = await getReq(`/api/v0/obchodni-partneri/${cisloOP}/miesta-spotreby`, query)
			const { strankovanie } = res.response
			if (!strankovanie) {
				return
			}

			const store = getStore()
			const { data } = store.ciselnikySelectBox.miestaSpotreby

			const miestaSpotreby = [...data, ...res.response.obsah]

			dispatch(miestaSpotrebyCiselnikyLoadPartDone(miestaSpotreby))

			const { zaznamov, velkostStranky } = res.response.strankovanie
			if (velkostStranky * page < zaznamov) {
				const nextPage = page + 1
				await loadPage(nextPage)
			} else {
				dispatch(miestaSpotrebyCiselnikyLoadDone())
			}
		} catch (err) {
			dispatch(miestaSpotrebyCiselnikyLoadFail())
		}
	}
	await loadPage(1)
}

export const loadCiselnikMiestaSpotreby = (cacheTime = 20) => {
	return (dispatch, getStore) => {
		const store = getStore()

		if (
			store.ciselnikySelectBox.miestaSpotreby.allDataPrepared &&
			minutesBetween(store.ciselnikySelectBox.miestaSpotreby.lastLoad, new Date()) < cacheTime
		) {
			return
		}
		const { opCislo } = store.interakcie.detail.data
		dispatch(miestaSpotrebyCiselnikyLoadStart())
		loadMiestaSpotrebyByParts(opCislo, dispatch, 50, getStore)
	}
}

export const loadCiselnikZmluvneUcty = (cacheTime = 20) => {
	return async (dispatch, getStore) => {
		const store = getStore()
		if (store.ciselnikySelectBox.zmluvneUcty.data && minutesBetween(store.ciselnikySelectBox.zmluvneUcty.lastLoad, new Date()) < cacheTime) {
			return
		}

		dispatch(zmluvneUctyLoadStart())
		try {
			const { opCislo } = store.interakcie.detail.data
			const res = await getReq(`/api/v0/obchodni-partneri/${opCislo}/zmluvne-ucty/filter`, { velkostStranky: 1000 })

			dispatch(zmluvneUctyLoadDone(res.response.obsah))
		} catch (e) {
			// eslint-disable-next-line
			console.log(e)
			dispatch(zmluvneUctyLoadFail())
		}
	}
}
