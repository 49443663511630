import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues, change } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, upperFirst, isEmpty, some, filter, isEqual, sumBy } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

import validate from './validateForm'

// components
import FormInfo from '../../FormInfo'

// atoms
import { SelectField, DropZoneField, DatePickerField, TextareaField, TextInputField } from '../../../atoms'

// utils
import { FORMS, POHLADAVKA_TYP, BUSINESS_CHANNELS } from '../../../utils/enums'
import { getTypPohladavkyTooltip } from '../../../utils/pohladavky'
import { formatDate } from '../../../utils/date'
import { isValidacneKriteriumError } from '../../../utils/form'

class VrateniePreplatkovInfo extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	state = {}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		this.composeOdkladSplatnostiRange()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { procesnyKonfigurator } = this.props

		if (
			!isEqual(
				get(procesnyKonfigurator, 'data.ukony.vrateniePreplatkov.standardneAtributy'),
				get(prevProps, 'procesnyKonfigurator.data.ukony.vrateniePreplatkov.standardneAtributy')
			)
		) {
			this.composeOdkladSplatnostiRange()
		}
	}

	composeOdkladSplatnostiRange = () => {
		const { dispatch, procesnyKonfigurator, formValues } = this.props

		const odkladSplatnostiPracovneDni = +get(procesnyKonfigurator, 'data.ukony.vrateniePreplatkov.standardneAtributy.odkladSplatnostiPracDni', 0)
		const odkladSplatnosti = dayjs().startOf('day').add(odkladSplatnostiPracovneDni, 'day')

		if (!get(formValues, 'odkladSplatnosti') || !dayjs(get(formValues, 'odkladSplatnosti')).diff(odkladSplatnosti)) {
			dispatch(change(FORMS.VRATENIE_PREPLATKOV_VYTVORENIE, 'odkladSplatnosti', odkladSplatnosti.toDate()))
		}

		this.setState({
			odkladSplatnosti,
			odkladSplatnostiPracovneDni
		})
	}

	render() {
		const {
			formTitle,
			handleSubmit,
			invalid,
			auth,
			validacneKriteria,
			schvalovacieKriteria,
			onDeleteFile,
			onBackClick,
			onCancelClick,
			ciselniky,
			formValues,
			t
		} = this.props

		const { odkladSplatnosti, odkladSplatnostiPracovneDni } = this.state

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{validacneKriterium.nazov}: {validacneKriterium.popis}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
					</div>
				))
			}
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		let odkladSplatnostiTooltipComponent
		if (odkladSplatnosti) {
			odkladSplatnostiTooltipComponent = (
				<Tooltip
					html={
						<div style={{ textAlign: 'left' }}>
							<span>
								<strong>{t('translation:Preplatky.Výpočet odkladu splatnosti')}</strong>
								<br />
								<br />
								{t('translation:Preplatky.Aktuálny dátum ({currentDate}) + {odkladSplatnostiPracovneDni} PD = {odkladSplatnosti}', {
									currentDate: dayjs().format('DD.MM.YYYY'),
									odkladSplatnostiPracovneDni,
									odkladSplatnosti: dayjs(odkladSplatnosti).format('DD.MM.YYYY')
								})}
							</span>
							<br />
							<br />
							<strong>{t('translation:Preplatky.Výsledný dátum je informatívny a môže byť ešte upravený o víkendy a sviatky')}</strong>
						</div>
					}
					position='right'
					trigger='mouseenter'
					theme='light'
				>
					<div className='info-icon' />
				</Tooltip>
			)
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<FormInfo validacneKriteria={validacneKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={isEmpty(ukonVstupOptions)}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Zmluvný účet')}</strong>
											</td>
											<td>{get(formValues, 'zuCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Preplatky.Preplatky')}</strong>
											</td>
											<td style={{ padding: 0 }}>
												<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
													<thead>
														<tr>
															<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo faktúry')}</th>
															<th style={{ width: '40%' }}>{t('translation:Preplatky.filter.Typ')}</th>
															<th style={{ width: '20%' }}>{t('translation:Preplatky.filter.Číslo dokladu')}</th>
															<th style={{ whiteSpace: 'nowrap', textAlign: 'right', width: '20%' }}>
																{t('translation:Preplatky.filter.Otvorená suma')}
															</th>
														</tr>
													</thead>
													<tbody data-empty={t('translation:Preplatky.Neboli zvolené žiadne preplatky')}>
														{map(get(formValues, 'preplatky', []), (pohladavka, index) => {
															let typPohladavky
															switch (get(pohladavka, 'typ.id')) {
																case POHLADAVKA_TYP.PREPLATOK_Z_FAKTURACIE.value:
																case POHLADAVKA_TYP.AKONTO.value:
																case POHLADAVKA_TYP.ZALOHA_ZNOVUPRIPOJENIE.value:
																case POHLADAVKA_TYP.UDRZBA_UCTU.value:
																case POHLADAVKA_TYP.STANDARD_KVALITY.value:
																	typPohladavky = (
																		<Tooltip
																			html={getTypPohladavkyTooltip(pohladavka, t)}
																			position='top'
																			trigger='mouseenter'
																			theme='light'
																		>
																			{get(pohladavka, 'typ.nazov')}
																		</Tooltip>
																	)
																	break
																default:
																	typPohladavky = get(pohladavka, 'typ.nazov')
															}

															return (
																<tr key={`pohladavka_${index}`}>
																	<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'variabilnySymbol', '-')}</td>
																	<td style={{ wordBreak: 'normal' }}>{typPohladavky}</td>
																	<td style={{ whiteSpace: 'nowrap' }}>{get(pohladavka, 'cislo')}</td>
																	<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
																		<NumericFormat
																			thousandSeparator={' '}
																			decimalSeparator={','}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			displayType='text'
																			suffix=' €'
																			value={get(pohladavka, 'sumaOtvorena')}
																		/>
																	</td>
																</tr>
															)
														})}
														{!isEmpty(get(formValues, 'preplatky', [])) && (
															<tr key={`pohladavka_spolu`}>
																<td>
																	<strong>{t('translation:Common.SPOLU')}</strong>
																</td>
																<td />
																<td />
																<td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
																	<strong>
																		<NumericFormat
																			thousandSeparator={' '}
																			decimalSeparator={','}
																			decimalScale={2}
																			fixedDecimalScale={true}
																			displayType='text'
																			suffix=' €'
																			value={sumBy(get(formValues, 'preplatky', []), 'sumaOtvorena')}
																		/>
																	</strong>
																</td>
															</tr>
														)}
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Preplatky.Odklad splatnosti (informatívny)')}</strong>
												{odkladSplatnostiTooltipComponent}
											</td>
											<td>{formatDate(get(formValues, 'odkladSplatnosti'), '', 'DD.MM.YYYY')}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td>
											<Field name='poznamka' component={TextareaField} rows='3' placeholder={t('translation:Common.Zadajte poznámku')} />
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.VRATENIE_PREPLATKOV_VYTVORENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(VrateniePreplatkovInfo)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.VRATENIE_PREPLATKOV_VYTVORENIE)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
