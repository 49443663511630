import React from 'react'
import { get, indexOf, head, startsWith, upperFirst } from 'lodash'
import EIC from 'eic'

// utils
import { KOMODITA_TYP, ZMLUVNY_VZTAH_STAV, ODBERNE_MIESTO_OBSLUHA, MERANIE_TYP, FAZY_POCET, ZMLUVNY_VZTAH_TECHNICKY_STAV } from './enums'

import { formatDate } from './date'

const AKTIVNE_ZMLUVNE_VZTAHY = [
	ZMLUVNY_VZTAH_STAV.NOVY,
	ZMLUVNY_VZTAH_STAV.PLATNY,
	ZMLUVNY_VZTAH_STAV.UCINNY,
	ZMLUVNY_VZTAH_STAV.V_AKTIVACII,
	ZMLUVNY_VZTAH_STAV.V_DEAKTIVACII
]

export const formatIdentifikatorTyp = (zmluvnyVztah, t) => {
	const id = get(zmluvnyVztah, 'komoditaTyp.id')
	switch (id) {
		case KOMODITA_TYP.ELEKTRINA:
			return t('translation:ZmluvneUcty.EIC')
		case KOMODITA_TYP.PLYN:
			return t('translation:ZmluvneUcty.POD')
		default:
			return t('translation:ZmluvneUcty.EIC / POD')
	}
}

export const formatOdberneMiestoObsluha = (odberneMiesto, t) => {
	const obsluha = get(odberneMiesto, 'obsluha')
	switch (obsluha) {
		case ODBERNE_MIESTO_OBSLUHA.STANDARDNA:
			return t('translation:Common.ŠO')
		case ODBERNE_MIESTO_OBSLUHA.LIMITOVANA:
			return t('translation:Common.LO')
		default:
			return null
	}
}

export const formatOdberneMiestoObsluhaColor = (odberneMiesto) => {
	const obsluha = get(odberneMiesto, 'obsluha')
	switch (obsluha) {
		case ODBERNE_MIESTO_OBSLUHA.LIMITOVANA:
			return 'red'
		default:
			return null
	}
}

export const formatOdberneMiestoObsluhaTooltip = (odberneMiesto, t) => {
	if (get(odberneMiesto, 'obsluha') == ODBERNE_MIESTO_OBSLUHA.LIMITOVANA) {
		return (
			<>
				<b>{t('translation:Common.Limitovaná obsluha')}</b> –{' '}
				{t('translation:Common.na OP je obmedzené vykonávanie úkonov, nakoľko aspoň na 1 OM bola historicky zhoršená platobná disciplína')}
			</>
		)
	}

	return (
		<>
			<b>{t('translation:Common.Štandardná obsluha')}</b> – {t('translation:Common.na OP nie je obmedzené vykonávanie úkonov')}
		</>
	)
}

export const isZmluvnyVztahAktivny = (zmluvnyVztah) => {
	return indexOf(AKTIVNE_ZMLUVNE_VZTAHY, get(zmluvnyVztah, 'stav.id')) >= 0
}

export const getZmluvnyVztahStateDateLabel = (zmluvnyVztah, t) => {
	const id = get(zmluvnyVztah, 'stav.id')
	switch (id) {
		case ZMLUVNY_VZTAH_STAV.NOVY:
			return t('translation:ZmluvneUcty.Dátum vytvorenia')
		case ZMLUVNY_VZTAH_STAV.PLATNY:
			return t('translation:ZmluvneUcty.Dátum platnosti')
		case ZMLUVNY_VZTAH_STAV.UCINNY:
			return t('translation:ZmluvneUcty.Dátum účinnosti')
		case ZMLUVNY_VZTAH_STAV.UKONCENY:
			return t('translation:ZmluvneUcty.Dátum ukončenia')
		case ZMLUVNY_VZTAH_STAV.STORNOVANY:
			return t('translation:ZmluvneUcty.Dátum stornovania')
		default:
			return null
	}
}

export const getZmluvnyVztahStateDate = (zmluvnyVztah) => {
	const id = get(zmluvnyVztah, 'stav.id')
	switch (id) {
		case ZMLUVNY_VZTAH_STAV.NOVY:
			return formatDate(get(zmluvnyVztah, 'vytvorenyOd'), '-', 'DD.MM.YYYY')
		case ZMLUVNY_VZTAH_STAV.PLATNY:
			return formatDate(get(zmluvnyVztah, 'platnyOd'), '-', 'DD.MM.YYYY')
		case ZMLUVNY_VZTAH_STAV.UCINNY:
			return formatDate(get(zmluvnyVztah, 'ucinnyOd'), '-', 'DD.MM.YYYY')
		case ZMLUVNY_VZTAH_STAV.UKONCENY:
			return formatDate(get(zmluvnyVztah, 'ukoncenyOd'), '-', 'DD.MM.YYYY')
		case ZMLUVNY_VZTAH_STAV.STORNOVANY:
			return formatDate(get(zmluvnyVztah, 'stornovanyOd'), '-', 'DD.MM.YYYY')
		default:
			return '-'
	}
}

// NOTE: CP-1679
export const getZmenaZmluvnyVztahStateDateLabel = (zmluvnyVztah, t) => {
	const zmena = head(get(zmluvnyVztah, 'zmeny', []))
	if (get(zmena, 'cielovyDatum')) {
		return t('translation:ZmluvneUcty.Dátum požadovanej zmeny')
	}
	if (get(zmena, 'zdrojovyDatum')) {
		return t('translation:ZmluvneUcty.Dátum vytvorenia zmeny')
	}
	return null
}

// NOTE: CP-1679
export const getZmenaZmluvnyVztahStateDate = (zmluvnyVztah) => {
	const zmena = head(get(zmluvnyVztah, 'zmeny', []))
	if (get(zmena, 'cielovyDatum')) {
		return formatDate(get(zmena, 'cielovyDatum'), '-', 'DD.MM.YYYY')
	}
	if (get(zmena, 'zdrojovyDatum')) {
		return formatDate(get(zmena, 'zdrojovyDatum'), '-', 'DD.MM.YYYY')
	}
	return null
}

export const getZmluvnyVztahStateColor = (zmluvnyVztah) => {
	// NOTE: CP-1679
	const zmena = head(get(zmluvnyVztah, 'zmeny', []))
	if (
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_AKTIVACII ||
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_DEAKTIVACII ||
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_ZMENE ||
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_ODOSLANE_ZAKAZNIKOVI ||
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_POZIADAVKA_NA_TLAC ||
		get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_V_SPRACOVANI
	) {
		return 'orange'
	}

	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.CHYBNE) {
		return 'red'
	}

	const id = get(zmluvnyVztah, 'stav.id')
	switch (id) {
		case ZMLUVNY_VZTAH_STAV.NOVY:
			return 'yellow'
		case ZMLUVNY_VZTAH_STAV.PLATNY:
			return 'blue'
		case ZMLUVNY_VZTAH_STAV.UCINNY:
			return 'green'
		case ZMLUVNY_VZTAH_STAV.UKONCENY:
			return 'gray'
		case ZMLUVNY_VZTAH_STAV.STORNOVANY:
			return 'red'
		default:
			return ''
	}
}

export const getZmluvnyVztahStateText = (zmluvnyVztah, t) => {
	let text
	const id = get(zmluvnyVztah, 'stav.id')
	switch (id) {
		case ZMLUVNY_VZTAH_STAV.NOVY: {
			text = `${t('translation:Common.Zmluvný stav')} = ${t('translation:Common.Nová')}`
			break
		}
		case ZMLUVNY_VZTAH_STAV.PLATNY: {
			text = `${t('translation:Common.Zmluvný stav')} = ${t('translation:Common.Platná')}`
			break
		}
		case ZMLUVNY_VZTAH_STAV.UCINNY: {
			text = `${t('translation:Common.Zmluvný stav')} = ${t('translation:Common.Účinná')}`
			break
		}
		case ZMLUVNY_VZTAH_STAV.UKONCENY: {
			text = `${t('translation:Common.Zmluvný stav')} = ${t('translation:Common.Ukončená')}`
			break
		}
		case ZMLUVNY_VZTAH_STAV.STORNOVANY: {
			text = `${t('translation:Common.Zmluvný stav')} = ${t('translation:Common.Stornovaná')}`
			break
		}
		default:
			text = ''
	}

	// NOTE: CP-1679
	const zmena = head(get(zmluvnyVztah, 'zmeny', []))
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_AKTIVACII) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.V aktivácii')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_DEAKTIVACII) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.V deaktivácii')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.V_ZMENE) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.V zmene')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.CHYBNE) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.V chybe')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_V_SPRACOVANI) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.Dodatok v spracovaní')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_POZIADAVKA_NA_TLAC) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.Požiadavka na tlač')}`}
			</p>
		)
	}
	if (get(zmena, 'stav') == ZMLUVNY_VZTAH_TECHNICKY_STAV.DOD_ODOSLANE_ZAKAZNIKOVI) {
		text = (
			<p style={{ textAlign: 'left' }}>
				{text} <br /> {`${t('translation:Common.Technický stav')} = ${t('translation:Common.Odoslaný zákazníkovi')}`}
			</p>
		)
	}
	return text
}

export const getUkonStateColor = (ukon) => {
	switch (get(ukon, 'ukonStav.id')) {
		case 1:
			return 'blue'
		case 2:
			return 'yellow'
		case 3:
		case 53:
			return 'orange'
		case 4:
			return 'green'
		case 5:
			return 'red'
		default:
			return 'grey'
	}
}

export const isValidIdentifikator = (value) => {
	if (!value) {
		return false
	}
	if ((startsWith(value, '24ZZS') || startsWith(value, '24ZVS') || startsWith(value, '24ZSS')) && EIC.verifyEIC(value)) {
		return true
	}
	if (startsWith(value, 'SKSPPD')) {
		return true
	}
	return false
}

export const isValidCisloZmluvy = (value) => {
	if (!value) {
		return false
	}
	if (value.length >= 9 && value.length <= 10) {
		return true
	}

	return false
}

export const formatTypMeraniaLabel = (value, t) => {
	const id = get(value, 'id')
	switch (id) {
		case MERANIE_TYP.C:
			return t('translation:Common.TypMerania.C (stavové)')
		case MERANIE_TYP.A:
			return t('translation:Common.TypMerania.A (profilové, SMART)')
		default:
			return upperFirst(get(value, 'nazov')) || '-'
	}
}

export const formatFazyPocetLabel = (value, t) => {
	const id = get(value, 'id')
	switch (id) {
		case FAZY_POCET.F3:
			return t('translation:Common.FazyPocet.3 fázy')
		case FAZY_POCET.F1:
			return t('translation:Common.FazyPocet.1 fáza')
		default:
			return upperFirst(get(value, 'nazov')) || '-'
	}
}

export const getHlavnyIsticOptions = () => {
	return [
		{
			label: '15 A',
			value: 15
		},
		{
			label: '16 A',
			value: 16
		},
		{
			label: '20 A',
			value: 20
		},
		{
			label: '25 A',
			value: 25
		},
		{
			label: '32 A',
			value: 32
		}
	]
}
