import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, some, indexOf, includes, isEmpty, find } from 'lodash'
import { getIsMop } from './obchodnyPartner'

import * as DataActions from '../actions/DataActions'

import { UKONY_CISELNIK } from './enums'

import { FORBIDDEN } from './routes'

// CP-2881
export const getMopException = (obchodnyPartner) => {
	if (get(obchodnyPartner, 'data.skupinaOpravneni')) {
		const isMOP = getIsMop(get(obchodnyPartner, 'data.skupinaOpravneni'))

		let mopException = []
		if (isMOP) {
			mopException = MOP_PERMISSIONS_BLACKLIST
		}

		return mopException
	}
	return []
}

export const checkPermissions = (roles = [], allowed = [], except = [], mopException = []) => {
	// CP-2881
	if (!isEmpty(mopException)) {
		if (
			allowed.some((allowPermission) => {
				return !mopException.includes(allowPermission)
			})
		) {
			return true
		}

		return false
	}
	if (!isEmpty(except) && some(except, (elem) => indexOf(roles, elem) > -1)) {
		return false
	}
	if (!isEmpty(allowed)) {
		return some(allowed, (elem) => indexOf(roles, elem) > -1)
	}
	return true
}

export const withPermissions =
	(allowed = [], except = []) =>
	(WrappedComponent) => {
		class WithPermissionsClass extends Component {
			static propTypes = {
				roles: PropTypes.array.isRequired
			}

			constructor(props) {
				super(props)

				this.state = {
					visible: checkPermissions(props.roles, allowed, except)
				}
			}

			componentDidUpdate() {
				const { roles } = this.props
				const { visible } = this.state

				this.checkValidPermissions(roles, visible)
			}

			checkValidPermissions = (roles, visible = false) => {
				const { obchodnyPartnerDetail } = this.props
				const mopException = getMopException(obchodnyPartnerDetail)

				let newVisibility = checkPermissions(roles, allowed, except, mopException)

				if (visible !== newVisibility) {
					this.setState({
						visible: newVisibility
					})
				}
			}

			render() {
				const { visible } = this.state

				if (visible) {
					return <WrappedComponent {...this.props} />
				}
				return <Redirect to={FORBIDDEN} />
			}
		}

		return connect((state) => ({
			roles: get(state, 'auth.user.roles')
		}))(WithPermissionsClass)
	}

export const withBusinessChannels =
	(allowed = [], except = []) =>
	(WrappedComponent) => {
		class WithBusinessChannels extends Component {
			static propTypes = {
				businessChannel: PropTypes.shape().isRequired
			}

			constructor(props) {
				super(props)

				let visible = false
				if (!isEmpty(except)) {
					visible = !includes(except, get(props, 'businessChannel.actual.id'))
				} else if (!isEmpty(allowed)) {
					visible = includes(allowed, get(props, 'businessChannel.actual.id'))
				}

				this.state = { visible }
			}

			render() {
				const { visible } = this.state

				if (visible) {
					return <WrappedComponent {...this.props} />
				}
				return <Redirect to={FORBIDDEN} />
			}
		}

		return connect((state) => ({
			businessChannel: get(state, 'auth.businessChannel')
		}))(WithBusinessChannels)
	}

export const getRequiredPermissionsForUkonWrite = (ukon) => {
	const key = find(Object.keys(UKONY_CISELNIK), (key) => UKONY_CISELNIK[key] == get(ukon, 'typ.id', ''))
	if (!key) {
		return []
	}
	return UKONY_WRITE_PERMISSIONS[key] || []
}

export const getRequiredPermissionsForUkonRead = (ukon) => {
	const key = find(Object.keys(UKONY_CISELNIK), (key) => UKONY_CISELNIK[key] == get(ukon, 'typ.id', ''))
	if (!key) {
		return []
	}
	return UKONY_VIEW_PERMISSIONS[key] || []
}

export const PERMISSIONS = {
	UKON_ZALOZENIE_OP: 'per-ukon-zalozenie-op',
	UKON_UDAJE_OP: 'per-ukon-udaje-op',
	UKON_UDAJE_ZU: 'per-ukon-udaje-zu',
	UKON_UPRAVA_MS: 'per-ukon-uprava-ms',
	UKON_DUPLIKAT_FAKTURY: 'per-ukon-duplikat-faktury',
	UKON_DUPLIKAT_UPOMIENKY: 'per-ukon-duplikat-upomienky',
	UKON_DUPLIKAT_UCTOVNEHO_DOKLADU: 'per-ukon-duplikat-uctovneho-dokladu',
	UKON_OVERENIE_PLATBY: 'per-ukon-overenie-platby',
	UKON_PREDDAVKOVE_PLATBY: 'per-ukon-preddavkove-platby',
	UKON_FORMA_FAKTURY: 'per-ukon-forma-faktury',
	UKON_SPOSOB_PLATBY: 'per-ukon-sposob-platby',
	UKON_SPLNOMOCNENIE: 'per-ukon-splnomocnenie',
	UKON_PLAN_SPLATOK: 'per-ukon-plan-splatok',
	UKON_VSEOBECNY_AKTIVNY: 'per-ukon-vseobecny-aktivny',
	UKON_ANONYM: 'per-ukon-anonym',
	UKON_VRATENIE_PREPLATKOV: 'per-ukon-vratenie-preplatkov',
	UKON_BLOKOVANIE_UPOMINANIA: 'per-ukon-blokovanie-upominania',
	UKON_ODPOVED_ZAKAZNIKOVI: 'per-ukon-odpoved-zakaznikovi',
	UKON_PREPIS_IDEALNY: 'per-ukon-prepis-idealny',
	UKON_NOVA_ZMLUVA_KOMODITA: 'per-ukon-nova-zmluva-komodita',
	UKON_ZMENA_SUHLASU: 'per-edit-suhlasy',
	VIEW_OP_ZMLUVY: 'per-view-op-zmluvy',
	VIEW_FAKTURY: 'per-view-faktury',
	VIEW_SUHLASY: 'per-view-suhlasy',
	VIEW_POHLADAVKY: 'per-view-pohladavky',
	VIEW_PREDDAVKOVE_PLATBY: 'per-view-preddavkove-platby',
	VIEW_UPOMIENKY: 'per-view-upomienky',
	VIEW_PLAN_SPLATOK: 'per-view-plan-splatok',
	VIEW_SPLNOMOCNENIE: 'per-view-splnomocnenie',
	UKON_GENEROVANIE_PIN: 'per-ukon-generovanie-pin',
	UKON_OBNOVA_MS: 'per-ukon-obnova-ms',
	UKON_NOVY_ODBER: 'per-ukon-novy-odber',
	UKON_ZMENA_PRODUKTU: 'per-ukon-zmena-produktu',
	UKON_UKONCENIE_ZMLUVY: 'per-ukon-ukoncenie-zmluvy',
	UKON_EDITACIA: 'per-ukon-editacia',
	UKON_ZMENA_UDAJOV_OP_UMRTIE: 'per-ukon-umrtie-op'
}

const MOP_PERMISSIONS_BLACKLIST = [
	PERMISSIONS.UKON_ZALOZENIE_OP,
	PERMISSIONS.UKON_UDAJE_OP,
	PERMISSIONS.UKON_UDAJE_ZU,
	PERMISSIONS.UKON_UPRAVA_MS,
	PERMISSIONS.UKON_OVERENIE_PLATBY,
	PERMISSIONS.UKON_PREDDAVKOVE_PLATBY,
	PERMISSIONS.UKON_FORMA_FAKTURY,
	PERMISSIONS.UKON_SPOSOB_PLATBY,
	PERMISSIONS.UKON_SPLNOMOCNENIE,
	PERMISSIONS.UKON_PLAN_SPLATOK,
	PERMISSIONS.UKON_VSEOBECNY_AKTIVNY,
	PERMISSIONS.UKON_ANONYM,
	PERMISSIONS.UKON_VRATENIE_PREPLATKOV,
	PERMISSIONS.UKON_BLOKOVANIE_UPOMINANIA,
	PERMISSIONS.UKON_PREPIS_IDEALNY,
	PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA,
	PERMISSIONS.UKON_ZMENA_SUHLASU,
	PERMISSIONS.UKON_GENEROVANIE_PIN,
	PERMISSIONS.UKON_OBNOVA_MS,
	PERMISSIONS.UKON_NOVY_ODBER,
	PERMISSIONS.UKON_ZMENA_PRODUKTU,
	PERMISSIONS.UKON_UKONCENIE_ZMLUVY,
	PERMISSIONS.UKON_ZMENA_UDAJOV_OP_UMRTIE
]

export const UKONY_VIEW_PERMISSIONS = {
	ZMENA_UDAJOV_OP: [PERMISSIONS.VIEW_OP_ZMLUVY],
	NASTAVENIE_ZU: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMENA_UDAJOV_MS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	KONZULTACIA_FAKTURY: [PERMISSIONS.VIEW_FAKTURY],
	KONZULTACIA_UPOMIENKY: [PERMISSIONS.VIEW_UPOMIENKY],
	KONZULTACIA_UCTOVNEHO_DOKLADU: [PERMISSIONS.VIEW_POHLADAVKY],
	DUPLIKAT_FAKTURY: [PERMISSIONS.VIEW_FAKTURY],
	DUPLIKAT_UPOMIENKY: [PERMISSIONS.VIEW_UPOMIENKY],
	DUPLIKAT_UCTOVNEHO_DOKLADU: [PERMISSIONS.VIEW_POHLADAVKY],
	DUPLIKAT_SEPA_MANDATU: [PERMISSIONS.VIEW_OP_ZMLUVY],
	OVERENIE_PLATBY: [PERMISSIONS.VIEW_POHLADAVKY],
	PREDDAVKOVE_PLATBY: [PERMISSIONS.VIEW_PREDDAVKOVE_PLATBY],
	SPLNOMOCNENIE: [PERMISSIONS.VIEW_SPLNOMOCNENIE],
	SPLNOMOCNENIE_UKONCENIE: [PERMISSIONS.VIEW_SPLNOMOCNENIE],
	VSEOBECNY_UKON_PASIVNY: [PERMISSIONS.VIEW_OP_ZMLUVY],
	VSEOBECNY_UKON_AKTIVNY: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_SEPA_MANDATU: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PLAN_SPLATOK: [PERMISSIONS.VIEW_PLAN_SPLATOK],
	PLAN_SPLATOK_SPLNOMOCNENEC: [PERMISSIONS.VIEW_PLAN_SPLATOK],
	PLAN_SPLATOK_DEDIC: [PERMISSIONS.VIEW_PLAN_SPLATOK],
	ZALOZENIE_OP: [PERMISSIONS.VIEW_OP_ZMLUVY],
	VRATENIE_PREPLATKOV: [PERMISSIONS.VIEW_POHLADAVKY],
	BLOKOVANIE_UPOMINANIA: [PERMISSIONS.VIEW_POHLADAVKY],
	SYSTEMOVE_LISTY: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_PREPIS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_UKONCENIE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_VAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_DODATOK: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_ZMENA: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_EE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_GAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_PREPIS_EE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMLUVNY_VZTAH_PREPIS_GAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK: [PERMISSIONS.VIEW_OP_ZMLUVY],
	UKONCENIE_ZMLUVY_EE: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVY_GAS_NOVY_ODBER: [PERMISSIONS.VIEW_OP_ZMLUVY],
	PODPIS_ZMLUVY_GAS_OBNOVA_MS: [PERMISSIONS.VIEW_OP_ZMLUVY],
	NOVA_ZMLUVA_EE_MIGRACIA: [PERMISSIONS.VIEW_OP_ZMLUVY],
	NOVA_ZMLUVA_EE_PREPIS_MIGRACIA: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMENA_PRODUKTU_EE_MIGRACIA: [PERMISSIONS.VIEW_OP_ZMLUVY],
	UKONCENIE_ZMLUVY_EE_MIGRACIA: [PERMISSIONS.VIEW_OP_ZMLUVY],
	ZMENA_DODAVATELA_EE_MIGRACIA: [PERMISSIONS.VIEW_OP_ZMLUVY]
}

export const UKONY_WRITE_PERMISSIONS = {
	ZMLUVNY_VZTAH_PREPIS: [PERMISSIONS.UKON_PREPIS_IDEALNY],
	ZMLUVNY_VZTAH_NOVY: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_UKONCENIE: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_VAS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_DODATOK: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_ZMENA: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_EE: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_GAS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE: [PERMISSIONS.UKON_ZMENA_PRODUKTU],
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS: [PERMISSIONS.UKON_ZMENA_PRODUKTU],
	ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS: [PERMISSIONS.UKON_PREPIS_IDEALNY],
	ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS: [PERMISSIONS.UKON_PREPIS_IDEALNY],
	ZMLUVNY_VZTAH_PREPIS_EE: [PERMISSIONS.UKON_PREPIS_IDEALNY],
	ZMLUVNY_VZTAH_PREPIS_GAS: [PERMISSIONS.UKON_PREPIS_IDEALNY],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK: [PERMISSIONS.UKON_NOVA_ZMLUVA_KOMODITA],
	OBNOVA_MS: [PERMISSIONS.UKON_OBNOVA_MS],
	NOVY_ODBER: [PERMISSIONS.UKON_NOVY_ODBER],
	ZMENA_UDAJOV_OP_UMRTIE: [PERMISSIONS.UKON_ZMENA_UDAJOV_OP_UMRTIE],
	ZMENA_UDAJOV_OP: [PERMISSIONS.UKON_UDAJE_OP]
}

class Permissions extends React.Component {
	static propTypes = {
		roles: PropTypes.array.isRequired,
		allowed: PropTypes.arrayOf(PropTypes.string),
		except: PropTypes.arrayOf(PropTypes.string),
		businessChannels: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
		businessChannel: PropTypes.shape().isRequired,
		obchodnyPartnerDetail: PropTypes.shape().isRequired,
		render: PropTypes.func,
		dataActions: PropTypes.shape({
			dataChanged: PropTypes.func.isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)

		const mopException = getMopException(props.obchodnyPartnerDetail)

		this.state = {
			hasPermissions: checkPermissions(props.roles, props.allowed, props.except, mopException) && this.checkBusinessChannel()
		}
	}

	componentDidUpdate() {
		this.checkValidPermissions()
	}

	checkBusinessChannel = () => {
		const { businessChannels, businessChannel } = this.props
		const allowed = get(businessChannels, 'allowed', [])
		const except = get(businessChannels, 'except', [])

		if (isEmpty(allowed) && isEmpty(except)) {
			return true
		}

		let hasPermission = false
		if (!isEmpty(except)) {
			hasPermission = !includes(except, get(businessChannel, 'actual.id'))
		} else if (!isEmpty(allowed)) {
			hasPermission = includes(allowed, get(businessChannel, 'actual.id'))
		}
		return hasPermission
	}

	checkValidPermissions = () => {
		const { roles, allowed, except } = this.props
		const { hasPermissions } = this.state

		const { obchodnyPartnerDetail } = this.props
		const mopException = getMopException(obchodnyPartnerDetail)

		const newVisibility = checkPermissions(roles, allowed, except, mopException) && this.checkBusinessChannel()

		if (hasPermissions !== newVisibility) {
			this.setState({
				hasPermissions: newVisibility
			})
		}
	}

	render() {
		const { hasPermissions } = this.state
		const { render, dataActions } = this.props

		if (render) {
			return render(hasPermissions, {
				openForbiddenModal: () => {
					dataActions.dataChanged('forbiddenModal', true)
				}
			})
		}

		if (hasPermissions) {
			return <>{this.props.children}</>
		}

		return null
	}
}

const mapStateToProps = (state) => ({
	roles: get(state, 'auth.user.roles'),
	businessChannel: get(state, 'auth.businessChannel'),
	obchodnyPartnerDetail: get(state, 'obchodnyPartner.detail')
})

const mapDispatchToProps = (dispatch) => ({
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Permissions)
