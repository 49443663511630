import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { get, head, isEmpty, isEqual } from 'lodash'
import { initialize, reset, getFormValues, touch, destroy, isPristine, getFormInitialValues } from 'redux-form'
import qs from 'qs'

// actions
import * as DataActions from '../../actions/DataActions'
import * as SpravaSuhlasovActions from '../../actions/ObchodniPartneri/SpravaSuhlasov'
import * as PodradeneSuhlasyActions from '../../actions/ObchodniPartneri/PodradeneSuhlasyActions'

// utils
import { FORMS, SCENARE, BUSINESS_CHANNELS, SUHLASY_KATEGORIA, BIZNIS_REFERENCIA_TYP, SUHLAS_TYP_VYJADRENIA, DEFINICIA_TYP } from '../../utils/enums'
import { createFormInitValues } from '../../utils/form'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import { setRouteParams, ZOZNAM_SUHLASOV } from '../../utils/routes'
import { history } from '../../utils/history'
import { postReq } from '../../utils/request'

// components
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ZmenaSuhlasuForm from '../../components/SpravaSuhlasov/ZmenaSuhlasuForm'
import ZmenaSuhlasuConfirm from '../../components/SpravaSuhlasov/ZmenaSuhlasuConfirm'
import DefaultModal from '../../components/Modals/DefaultModal'

const scenarioOptions = [
	{
		kanal: BUSINESS_CHANNELS.ZSE_CENTRUM,
		scenarios: [{ typ: SCENARE.PODPISANIE_NA_MIESTE }, { typ: SCENARE.VYTLACIT_A_NAHRAT }, { typ: SCENARE.NAHRAT }],
		generateDocumentFromCes: true,
		notificationRequired: false,
		documentRequired: true
	},
	{
		kanal: BUSINESS_CHANNELS.ZAKAZNICKA_LINKA,
		scenarios: [{ typ: SCENARE.NAHRAT }],
		notificationRequired: false,
		documentRequired: false
	},
	{
		kanal: BUSINESS_CHANNELS.BACK_OFFICE,
		scenarios: [{ typ: SCENARE.NAHRAT }],
		notificationRequired: false,
		documentRequired: false
	}
]

class ZmenaSuhlasuPage extends React.Component {
	static propTypes = {
		t: PropTypes.func,
		auth: PropTypes.shape().isRequired,
		suhlasy: PropTypes.shape(),
		podradeneSuhlasyActions: PropTypes.shape(),
		dataActions: PropTypes.shape().isRequired,
		interakcia: PropTypes.shape({
			id: PropTypes.number.isRequired,
			opCislo: PropTypes.string.isRequired
		}).isRequired,
		zmluvneUcty: PropTypes.shape(),
		spravaSuhlasovActions: PropTypes.shape()
	}

	constructor(props) {
		super(props)

		// find scenar for signing pdf files
		const scenarioOption = scenarioOptions.find((scenarioOption) => {
			return scenarioOption.kanal === props.auth.businessChannel.actual.id
		})

		this.state = {
			step: 1,
			numOfSteps: 2,
			isLoading: false,
			result: null,
			success: false,
			options: [],
			scenarioOption
		}
	}

	_mounted = false

	filterAndFormatSuhlasy(rawSuhlasy, paramId) {
		const suhlasToEdit = rawSuhlasy?.flatMap((kategoria) => kategoria.suhlasDefinicia).find((suhlas) => Number(suhlas.id) === Number(paramId))

		const verzieSuhlasov = suhlasToEdit.suhlasVerzia

		let udeleneSuhlasy = null
		if (Array.isArray(verzieSuhlasov) && verzieSuhlasov.length > 0) {
			udeleneSuhlasy = verzieSuhlasov
				.map((verziaSuhlasu) => {
					if (Array.isArray(verziaSuhlasu.suhlas) && verziaSuhlasu.suhlas.length > 0) {
						return {
							...verziaSuhlasu.suhlas[0],
							verzia: verziaSuhlasu.verzia
						}
					}

					return null
				})
				.filter((udelenySuhlas) => udelenySuhlas !== null)
		}

		let poslednySuhlas = null
		if (udeleneSuhlasy && Array.isArray(udeleneSuhlasy) && udeleneSuhlasy.length > 0) {
			poslednySuhlas = udeleneSuhlasy[udeleneSuhlasy.length - 1]
		}

		return JSON.parse(
			JSON.stringify({
				...suhlasToEdit,
				poslednySuhlas
			})
		)
	}

	initFormValues(filteredSuhlas) {
		const { suhlasVerzia } = filteredSuhlas
		const aktualnaVerzia = head(suhlasVerzia)
		const poslednySuhlas = head(aktualnaVerzia?.suhlas)

		const initValues = createFormInitValues({
			id: poslednySuhlas?.id,
			datumVyjadrenie: new Date(),
			nesuhlasPoznamka: null,
			platnostOd: new Date(),
			typVyjadrenieSuhlas: null,
			definiciaId: aktualnaVerzia?.definiciaId,
			nesuhlasDovod: null,
			produktKod: poslednySuhlas?.produktKod,
			zmluvnyUcet: poslednySuhlas?.zmluvnyUcet
		})

		return initValues
	}

	async componentDidMount() {
		this._mounted = true

		const { auth, dispatch, computedMatch, dataActions, podradeneSuhlasyActions, spravaSuhlasovActions, suhlasy, interakcia } = this.props
		const { params } = computedMatch

		const { suhlasKategoriaId } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		const kategoriaId = suhlasKategoriaId && Number(suhlasKategoriaId)
		const definiciaId = params?.definiciaId && Number(params.definiciaId)

		dataActions.registerLeavePageModal()

		// TODO: Check if I shoud use id or name of businessChannel (CES ciselnik doesnt match BB ciselnik)
		const activeBusinessChannel = get(auth, 'businessChannel.actual.id')
		let initValues = {}

		// NOTE: fixing bug https://jira.zsee.sk/jira/browse/CP-3001
		if (suhlasy.isPrepared === false) {
			await spravaSuhlasovActions.loadSpravaSuhlasov(activeBusinessChannel)
		}
		const aktualnaVerziaSuhlasu = await podradeneSuhlasyActions.loadAktualnaVerziaSuhlasu(params.definiciaId, activeBusinessChannel)
		if (!aktualnaVerziaSuhlasu) return
		initValues = this.initFormValues(aktualnaVerziaSuhlasu)

		dispatch(initialize(FORMS.ZMENA_SUHLASU, initValues))
		dispatch(reset(FORMS.ZMENA_SUHLASU))

		// trigger validation immediately after init form
		dispatch(touch(FORMS.ZMENA_SUHLASU, 'typVyjadrenieSuhlas'))

		// NOTE: call only in case of DIGITALIZACIA and CRIF -> https://jira.zsee.sk/jira/browse/CP-2963
		if (
			definiciaId === DEFINICIA_TYP.E_FAKTURA ||
			definiciaId === DEFINICIA_TYP.E_PLATBA ||
			definiciaId === DEFINICIA_TYP.E_PREPLATKY ||
			kategoriaId === SUHLASY_KATEGORIA.CRIF
		) {
			const options = []
			const zoznam = await spravaSuhlasovActions.loadZoznamPreDefinicu(get(interakcia, 'opCislo'), definiciaId)
			zoznam?.map((zmluvnyUcetCislo) => options.push({ label: zmluvnyUcetCislo, value: zmluvnyUcetCislo }))
			this.setState({
				...this.state,
				options
			})
		}
	}

	componentWillUnmount() {
		const { dispatch, dataActions } = this.props

		dispatch(destroy(FORMS.ZMENA_SUHLASU))

		dataActions.unregisterLeavePageModal()

		this._mounted = false
	}

	filterSuhlas = () => {
		const { computedMatch, suhlasy } = this.props
		const { params } = computedMatch
		const { suhlasKategoriaId } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})
		const kategoriaId = suhlasKategoriaId && Number(suhlasKategoriaId)
		const filteredDefinicie = head(suhlasy.data?.filter((suhlas) => suhlas.suhlasKategoria.id === kategoriaId))
		return head(head(filteredDefinicie?.suhlasDefinicia?.filter((suhlasDef) => suhlasDef.id === Number(params.definiciaId)))?.suhlasVerzia)?.suhlas
	}

	async componentDidUpdate(prevProps) {
		const { suhlasKategoriaId } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})
		const { isPristine, formValues, auth, initFormValues } = this.props
		const { scenarioOption } = this.state

		if (!isEqual(prevProps.formValues?.typVyjadrenieSuhlas, formValues?.typVyjadrenieSuhlas)) {
			const typVyjadrenieSuhlasValue = get(formValues, 'typVyjadrenieSuhlas')
			if (auth.businessChannel.actual.id === BUSINESS_CHANNELS.ZSE_CENTRUM && typVyjadrenieSuhlasValue === SUHLAS_TYP_VYJADRENIA.SUHLAS) {
				this.setState({
					...this.state,
					scenarioOption: {
						...scenarioOption,
						scenarios: [...scenarioOptions[0].scenarios],
						generateDocumentFromCes: true,
						documentRequired: true
					}
				})
			} else {
				this.setState({
					...this.state,
					scenarioOption: {
						...scenarioOption,
						scenarios: [{ typ: SCENARE.NAHRAT }],
						generateDocumentFromCes: false,
						documentRequired: false
					}
				})
			}
		}

		const kategoriaId = suhlasKategoriaId && Number(suhlasKategoriaId)
		// NOTE: call only in case of DIGITALIZACIA and CRIF -> https://jira.zsee.sk/jira/browse/CP-2963
		if (
			(kategoriaId === SUHLASY_KATEGORIA.DIGITALIZACIA || kategoriaId === SUHLASY_KATEGORIA.CRIF) &&
			isPristine &&
			(prevProps.formValues?.zmluvnyUcet !== formValues?.zmluvnyUcet || prevProps.formValues?.produktKod !== formValues?.produktKod) &&
			(initFormValues?.zmluvnyUcet !== formValues?.zmluvnyUcet || initFormValues?.produktKod !== formValues?.produktKod)
		) {
			const { computedMatch, dispatch } = this.props
			const { params } = computedMatch

			const filteredSuhlas = this.filterSuhlas()
			let poslednySuhlasPreZuProdukt = null
			if (formValues?.produktKod) {
				poslednySuhlasPreZuProdukt = head(filteredSuhlas.filter((suhlas) => suhlas.produktKod === formValues.produktKod))
			} else if (formValues?.zmluvnyUcet) {
				poslednySuhlasPreZuProdukt = head(filteredSuhlas.filter((suhlas) => suhlas.zmluvnyUcet === formValues.zmluvnyUcet))
			}

			if (poslednySuhlasPreZuProdukt) {
				const initValues = createFormInitValues({
					id: poslednySuhlasPreZuProdukt?.id,
					datumVyjadrenie: new Date(),
					nesuhlasPoznamka: null,
					platnostOd: new Date(),
					typVyjadrenieSuhlas: null,
					definiciaId: Number(params.definiciaId),
					nesuhlasDovod: null,
					produktKod: poslednySuhlasPreZuProdukt?.produktKod,
					zmluvnyUcet: poslednySuhlasPreZuProdukt?.zmluvnyUcet || poslednySuhlasPreZuProdukt?.zmluvnyUcetCislo
				})

				dispatch(initialize(FORMS.ZMENA_SUHLASU, initValues))
				dispatch(reset(FORMS.ZMENA_SUHLASU))

				// trigger validation after init form
				dispatch(touch(FORMS.ZMENA_SUHLASU, 'typVyjadrenieSuhlas'))
			}
		}
	}

	commonContentContainer = (content) => {
		return <div className='box-content'>{content}</div>
	}

	stepOneClickHandler = async () => {
		this.setState({
			step: 2
		})
	}

	formatDataForUkon = (values) => {
		const {
			id,
			datumVyjadrenie,
			nesuhlasPoznamka,
			platnostOd,
			typVyjadrenieSuhlas,
			nesuhlasDovod,
			definiciaId,
			zmluvnyUcet,
			produktKod,
			biznisReferencia
		} = values
		const { interakcia, signedPdf } = this.props

		// NOTE: add to body based on https://jira.zsee.sk/jira/browse/CP-3088 request
		let biznisRef = {}
		let dokument
		if (signedPdf?.data) {
			dokument = {
				contentType: signedPdf.data.type,
				nazov: signedPdf.data.name,
				data: signedPdf.data.dataAsBase64
			}
			biznisRef = {
				biznisReferenciaTyp: BIZNIS_REFERENCIA_TYP.DOCUMENT_URL.id
			}
		}
		if (biznisReferencia) {
			biznisRef = {
				biznisReferenciaTyp: BIZNIS_REFERENCIA_TYP.RECORDINGS.id,
				biznisReferencia
			}
		}

		return {
			id,
			datumVyjadrenie,
			nesuhlasPoznamka,
			platnostOd,
			pouzivatel: `${interakcia?.riesitelIdentita?.meno} ${interakcia?.riesitelIdentita?.priezvisko}`,
			pouzivatelIdentifikator: interakcia?.riesitelIdentita?.id,
			zmluvnyUcet,
			produktKod,
			opCislo: interakcia.opCislo,
			// TODO: Neposielať SÚHLAS ak sa mení námietka/námietka na schválenie na súhlas
			typVyjadrenieSuhlas,
			kanal: interakcia.kanal.nazov, // TODO: overit co tu ma byt
			definiciaId,
			nesuhlasDovod,
			dokument,
			...biznisRef
		}
	}

	formatDataForBulkChange = (values) => {
		const body = []
		const filteredSuhlas = this.filterSuhlas()
		if (!isEmpty(values?.zmluvnyUcet)) {
			const zmluvneUcty = values?.zmluvnyUcet?.map((option) => option.value)
			zmluvneUcty.forEach((zu) => {
				// NOTE: adding id for zmluvnyUcet due this issue https://jira.zsee.sk/jira/browse/CP-3256
				const suhlas = filteredSuhlas?.find((suhlas) => suhlas.zmluvnyUcet === zu)
				body.push(this.formatDataForUkon({ ...values, zmluvnyUcet: zu, id: suhlas?.id }))
			})
		}
		if (!isEmpty(values?.produktKod)) {
			const produktoveKody = values?.produktKod?.map((option) => option.value)
			produktoveKody.forEach((pk) => {
				// NOTE: adding id for produktKod due this issue https://jira.zsee.sk/jira/browse/CP-3256
				const suhlas = filteredSuhlas?.find((suhlas) => suhlas.produktKod === pk)
				body.push(this.formatDataForUkon({ ...values, produktKod: pk, id: suhlas?.id }))
			})
		}
		return body
	}

	stepTwoClickHandler = async (values) => {
		const { dataActions, t, interakcia } = this.props
		const { options } = this.state

		this.setState({
			isLoading: true
		})

		const isBulkChange = options?.length > 1

		try {
			if (isBulkChange) {
				const body = this.formatDataForBulkChange(values)
				await postReq(`/api/ces/suhlasy/${interakcia?.opCislo}`, null, body)
			} else {
				const body = this.formatDataForUkon(values)
				await postReq(`/api/ces/suhlas/${interakcia?.opCislo}`, null, body)
			}
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Úkon bol úspešne odoslaný'),
					success: true,
					isLoading: false
				})
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
			if (this._mounted) {
				dataActions.unregisterLeavePageModal()
				this.setState({
					result: t('translation:Common.Počas odosielania úkonu nastala chyba'),
					success: false,
					isLoading: false
				})
			}
		}
	}

	confirmModal = () => {
		const { interakcia } = this.props
		const { success } = this.state

		if (success) {
			this.setState(
				{
					result: null,
					success: false
				},
				() => {
					history.replace(setRouteParams(ZOZNAM_SUHLASOV, interakcia?.opCislo))
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	render() {
		const { t, suhlasy, computedMatch, formValues, interakcia } = this.props
		const { step, success, result, options, scenarioOption } = this.state
		const { params } = computedMatch

		const definiciaId = params?.definiciaId && Number(params.definiciaId)
		const { suhlasKategoriaId } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})

		const kategoriaId = suhlasKategoriaId && Number(suhlasKategoriaId)

		if (suhlasy.isFailure) {
			return this.commonContentContainer(<ElementFailure text={t('containers:SpravaSuhlasovPage.Nepodarilo sa načítať súhlas')} />)
		}

		if (!suhlasy || suhlasy.isLoading || !formValues) {
			return this.commonContentContainer(<ElementLoading />)
		}

		if (suhlasy.isPrepared === false) {
			return this.commonContentContainer(<ElementLoading />)
		}

		const filteredSuhlas = this.filterAndFormatSuhlasy(suhlasy?.data, definiciaId)

		switch (step) {
			case 1:
				return (
					<ZmenaSuhlasuForm
						definiciaId={definiciaId}
						kategoriaId={kategoriaId}
						formTitle={`${t('translation:Suhlasy.Krok 1 z 2 Zmena súhlasu')} - ${filteredSuhlas.nazov}`}
						originalValues={filteredSuhlas}
						formValues={formValues}
						suhlasyOptions={options}
						scenarioOption={scenarioOption}
						onSubmit={this.stepOneClickHandler}
						onCancelClick={() => history.push(setRouteParams(ZOZNAM_SUHLASOV, interakcia?.opCislo))}
						onBackClick={() => history.push(setRouteParams(ZOZNAM_SUHLASOV, interakcia?.opCislo))}
					/>
				)
			case 2:
				return (
					<>
						{result && (
							<DefaultModal
								modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
								modalContent={result}
								leftButton={{
									onClick: this.confirmModal,
									text: t('translation:Common.Zavrieť'),
									color: success ? 'green' : 'red'
								}}
								visible
							/>
						)}
						<ZmenaSuhlasuConfirm
							{...this.props}
							formTitle={`${t('translation:Suhlasy.Krok 2 z 2 Zmena súhlasu')} - ${filteredSuhlas.nazov}`}
							onSubmit={this.stepTwoClickHandler}
							onCancelClick={() => history.push(setRouteParams(ZOZNAM_SUHLASOV, interakcia?.opCislo))}
							onBackClick={() => {
								this.setState({ step: 1 })
							}}
							originalValues={filteredSuhlas}
							scenarioOption={this.state.scenarioOption}
						/>
					</>
				)
		}
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	suhlasy: get(state, 'obchodnyPartner.suhlasy'),
	interakcia: get(state, 'interakcie.detail.data'),
	formValues: getFormValues(FORMS.ZMENA_SUHLASU)(state),
	initFormValues: getFormInitialValues(FORMS.ZMENA_SUHLASU)(state),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	zmluvneUcty: get(state, 'obchodnyPartner.zoznamZmluvnychUctovPreDefinicu')
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	isPristine: dispatch(isPristine(FORMS.ZMENA_SUHLASU)),
	podradeneSuhlasyActions: bindActionCreators(PodradeneSuhlasyActions, dispatch),
	spravaSuhlasovActions: bindActionCreators(SpravaSuhlasovActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.UKON_ZMENA_SUHLASU])
)(ZmenaSuhlasuPage)
