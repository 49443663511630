import { get, isEmpty } from 'lodash'
import { DOCUMENT_ACTIONS, DOCUMENT_KEYS, DOKUMENT_TYP, UKONY_CISELNIK } from './enums'
import { postReq } from './request'

export const parseDocumentUrl = (document) => {
	if (document) {
		return `data:${get(document, 'contentType')};base64,${get(document, 'data')}`
	}
	return ''
}

export const parseDocuments = (parsedDocuments, document) => {
	switch (document?.typ?.id) {
		case DOKUMENT_TYP.VSTUPNY:
			return (parsedDocuments = {
				...parsedDocuments,
				[DOCUMENT_KEYS.VSTUPNY]: [...get(parsedDocuments, DOCUMENT_KEYS.VSTUPNY, []), document]
			})
		case DOKUMENT_TYP.VYSTUPNY:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.VYSTUPNY]: [...get(parsedDocuments, DOCUMENT_KEYS.VYSTUPNY, []), document]
			}
		case DOKUMENT_TYP.OSTATNE:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.OSTATNE]: [...get(parsedDocuments, DOCUMENT_KEYS.OSTATNE, []), document]
			}
		case DOKUMENT_TYP.STORNO:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.STORNO]: [...get(parsedDocuments, DOCUMENT_KEYS.STORNO, []), document]
			}
		case DOKUMENT_TYP.UKONCENY:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.UKONCENY]: [...get(parsedDocuments, DOCUMENT_KEYS.UKONCENY, []), document]
			}
		default:
			return {}
	}
}

export const parseDocumentsForRequest = (dokumenty) => {
	if (!Array.isArray(dokumenty)) {
		return []
	}

	const onlyUploadedDocuments = dokumenty.filter((dokument) => typeof dokument?.id === 'string')

	const documentsForRequest = onlyUploadedDocuments.map((dokument) => {
		return {
			id: get(dokument, 'id')
		}
	})

	return documentsForRequest
}

export const confirmDocuments = async (documents, ukonId) => {
	try {
		const dokumenty = documents
			?.filter((document) => document?.id && document?.akcia?.id === DOCUMENT_ACTIONS.NA_POTVRDENIE)
			?.map((dokument) => {
				return { id: dokument.id, precitanyOd: dokument?.precitanyOd }
			})

		if (!isEmpty(dokumenty)) {
			await postReq(`/api/v2/ukony/${ukonId}/potvrdit-dokumenty`, null, { dokumenty })
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Zlyhalo potvrdenie dokumentov:', error)
		throw error // NOTE: Zakáže prechod na ďalší krok ak zlyhá upload
	}
}

export const uploadDocuments = async (documents, ukonId) => {
	try {
		const uploadPromises = documents.map((document) => {
			const requestBody = {
				id: get(document, 'id', undefined),
				contentType: get(document, 'type'),
				nazov: get(document, 'name'),
				data: get(document, 'dataAsBase64', undefined),
				typ: get(document, 'dokumentTyp'),
				druh: get(document, 'dokumentDruh'),
				poradie: get(document, 'poradie'),
				poradieWacom: get(document, 'poradieWacom')
			}
			return postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, requestBody)
		})

		// Attach extra data
		const promisesWithData = uploadPromises.map((promise, index) =>
			promise.then((result) => ({ result, extraData: { akcia: get(documents[index], 'akcia') } }))
		)

		const promiseAllResult = await Promise.all(promisesWithData)

		const uploadedDocuments = promiseAllResult.map((resultData) => {
			const uploadedDocument = resultData?.result?.response

			return {
				id: get(uploadedDocument, 'id'),
				name: get(uploadedDocument, 'nazov'),
				type: get(uploadedDocument, 'contentType'),
				dokumentDruh: get(uploadedDocument, 'druh'),
				dokumentTyp: get(uploadedDocument, 'typ'),
				akcia: get(resultData?.extraData, 'akcia', undefined)
			}
		})

		return uploadedDocuments
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Zlyhalo nahranie prílohy:', error)
		throw error // NOTE: Zakáže prechod na ďalší krok ak zlyhá upload
	}
}

export const parseNewDocuments = (newDocuments) => {
	if (isEmpty(newDocuments)) return null

	return newDocuments.map((newDocument) => {
		return {
			id: get(newDocument, 'id'),
			name: get(newDocument, 'nazov'),
			type: get(newDocument, 'contentType'),
			dokumentDruh: get(newDocument, 'druh'),
			dokumentTyp: get(newDocument, 'typ')
		}
	})
}

export const scrollToFirstError = (errors, parentKey) => {
	let firstErrorKey = Object.keys(errors)?.[0]

	const currentPath = parentKey ? `${parentKey}.${firstErrorKey}` : firstErrorKey

	// NOTE: handle nested 'data' errors
	if (typeof errors[firstErrorKey] === 'object' && errors[firstErrorKey] !== null) {
		const result = scrollToFirstError(errors[firstErrorKey], currentPath)
		if (result) return result
	}

	const element = document.querySelector(`[data-name="${currentPath}"]`) || document.querySelector(`[name="${currentPath}"]`)
	if (element) {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		})
		return true
	}
	return false
}

export const generateUniqueId = (text) => {
	const formattedDate = new Date().toISOString()
	// NOTE: generate a random number to ensure uniqueness
	const uniqueNumber = Math.floor(Math.random() * 10000)

	// NOTE: create the unique ID by combining formattedDate, text, and unique number
	return `${formattedDate}_${text}_${uniqueNumber}`
}

export const formatData = (auth, ukon, typ, interakcia) => {
	const ukonId = get(ukon, 'id')
	let formattedData = {
		...(ukonId && { id: ukonId }),
		riesitel: get(auth, 'user.id'),
		kanal: get(auth, 'businessChannel.actual')
	}

	if (interakcia) {
		formattedData = {
			...formattedData,
			opCislo: get(interakcia, 'opCislo'),
			interakciaId: get(interakcia, 'id')
		}
	}

	if ([UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON, UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY].includes(typ)) {
		return {
			...formattedData,
			typ: { id: typ },
			poznamka: get(ukon, 'poznamka', null),
			data: get(ukon, 'data', null),
			vstup: get(ukon, 'vstup', null)
		}
	}
	// NOTE: ostatné generické úkony
	return {
		...formattedData,
		typ: { ...typ }
	}
}
