import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { initialize, reset, change, destroy, getFormValues } from 'redux-form'
import { find, get, map, filter, isEmpty, maxBy, forEach, isEqual, some, set, cloneDeep, omit, upperFirst } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import reactQueryParams from 'react-query-params'

// components
import DefaultModal from '../../components/Modals/DefaultModal'
import ElementOverlayLoading from '../../components/ElementOverlayLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import initProcesnyKonfigurator from '../../components/ProcesnyKonfigurator'

// utils
import { history } from '../../utils/history'
import { postReq } from '../../utils/request'
import { getUkonVstupByBiznisKanal } from '../../utils/scenar'
import { checkPermissions, getRequiredPermissionsForUkonWrite } from '../../utils/permissionsHoc'
import { NOTIFICATION_TYPES, BUSINESS_CHANNELS, FORMS, FILTER_SELECTORS, UKONY_CISELNIK, UKON_STAV, SUHLAS_HODNOTA, DEFINICIA_TYP } from '../../utils/enums'
import { setRouteParams, UKON_DETAIL, ZMLUVY_ZOZNAM } from '../../utils/routes'
import { getUkonEnumByType, getProcesnyKonfigType } from '../../utils/ukony'
import { confirmDocuments, formatData, parseDocuments, parseDocumentsForRequest, parseNewDocuments, uploadDocuments } from '../../utils/genericUkon'
import { formatHTML } from '../../utils/systemoveListy'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as UkonyActions from '../../actions/UkonyActions'
import * as TrackingActions from '../../actions/TrackingActions'
import * as DataActions from '../../actions/DataActions'
import * as StatusActions from '../../actions/StatusActions'
import * as OdberneMiestaActions from '../../actions/OdberneMiestaActions'
import * as UkonGenericActions from '../../actions/ProcesnyKonfigurator/UkonGenericActions'
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'
import * as MiestaSpotrebyActions from '../../actions/MiestaSpotrebyActions'
import * as FormAddresssesActions from '../../actions/FormAddressesActions'
import PodpisovanieDokumentovActions from '../../actions/PodpisovanieDokumentov'

// resources
import GenericUkonStep from '../../components/GenericUkon/GenericUkonStep'
import { EDIT_MODE, STEP, FIELD_PATH, getGenericUkonConfig } from './genericUkonConfig'
import { recognizeMethodologicalGuideline } from './guidelineConfig'

class GenericUkonPage extends reactQueryParams {
	static propTypes = {
		interakcia: PropTypes.shape().isRequired,
		auth: PropTypes.shape().isRequired,
		tracking: PropTypes.shape().isRequired,
		ukonNovy: PropTypes.shape().isRequired,
		ciselniky: PropTypes.shape().isRequired,
		notification: PropTypes.shape(),
		procesnyKonfigurator: PropTypes.shape().isRequired,
		formValues: PropTypes.shape(),
		context: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape().isRequired,
		trackingActions: PropTypes.shape().isRequired,
		dataActions: PropTypes.shape().isRequired,
		ukonGenericActions: PropTypes.shape().isRequired
	}

	defaultQueryParams = {
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		if (get(props.location, 'backUrlLink')) {
			backUrlLink = get(props.location, 'backUrlLink')
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		const ponuka = get(props.location, 'ponuka')

		this.state = {
			...this.prepareState(ponuka),
			backUrlLink
		}
	}

	prepareDocumentsForInitForm = (documents) => {
		if (isEmpty(documents)) {
			return null
		}
		let parsedDocuments = {}
		documents.forEach((document) => {
			parsedDocuments = { ...parseDocuments(parsedDocuments, document) }
		})
	}

	prepareState = (ponuka) => {
		const ukon = get(ponuka, 'ukon', {})
		const atributy = get(ponuka, 'atributy', [])
		const genericUkonConfig = getGenericUkonConfig(ukon?.typ)

		return {
			atributy,
			ukon,
			genericUkonConfig,
			supportedAttributes: [],
			unsupportedAttributes: [],
			step: STEP.ONE,
			stepsTotalCount: get(genericUkonConfig, 'steps', STEP.ONE),
			ProcesnyKonfigurator: null,
			originalValues: {}, // TODO overit
			isLoading: false,
			koKriteria: [],
			validacneKriteria: [],
			schvalovacieKriteria: [],
			showMissingPonukaModal: false,
			showMissingPermissionsModal: false,
			dependency: null
		}
	}

	onMount = () => {
		const {
			auth,
			ciselniky,
			dispatch,
			tracking,
			location,
			obchodnyPartner,
			ukonyActions,
			trackingActions,
			dataActions,
			formAddressesActions,
			procesnyKonfigurator,
			ukonGenericActions
		} = this.props
		const { step } = this.state

		const ponuka = get(location, 'ponuka')
		const ukon = get(ponuka, 'ukon', {})
		const atributy = get(ponuka, 'atributy', [])
		const ukonTyp = getUkonEnumByType(get(ukon, 'typ'))
		const ukonData = get(ukon, 'data', {})
		const ukonDataPred = get(ukon, 'dataPred', {})

		if (!ponuka) {
			dataActions.unregisterLeavePageModal()
			trackingActions.clearTracking()
			return this.setState({
				showMissingPonukaModal: true
			})
		}

		const permissions = getRequiredPermissionsForUkonWrite(ukon)
		if (!checkPermissions(get(auth, 'user.roles', []), permissions)) {
			dataActions.unregisterLeavePageModal()
			trackingActions.clearTracking()
			return this.setState({
				showMissingPermissionsModal: true
			})
		}

		formAddressesActions.formAddressClean()
		const addresses = [...map(get(obchodnyPartner, 'adresy', []), (address) => cloneDeep(address))]
		formAddressesActions.formAddressesInit(addresses)

		if (get(ukon, 'id')) {
			ukonyActions.setUkonId(get(ukon, 'id'))
		}

		const { supportedAttributes, unsupportedAttributes } = this.prepareAttributes(atributy, ukon, step) // TODO dat prec step

		const procesnyKonfigKey = getProcesnyKonfigType(get(ukon, 'typ.id'), get(procesnyKonfigurator, 'data.ukony'))
		const ProcesnyKonfigurator = procesnyKonfigKey ? initProcesnyKonfigurator(FORMS.GENERIC_UKON, procesnyKonfigKey) : null

		dataActions.registerLeavePageModal()

		if (!get(tracking, 'startTime') || (get(tracking, 'startTime') && get(tracking, 'type') !== ukonTyp)) {
			trackingActions.tryToStartTracking(ukonTyp)
		}

		const initValuesData = {
			INIT_TIMESTAMP: new Date(),
			vstup: getUkonVstupByBiznisKanal(get(auth, 'businessChannel.actual.id'), get(ciselniky, 'ukonVstup')),
			splnomocnenec: null,
			ziadanyOd: new Date(),
			data: {
				...ukonData
			},
			dokumenty: this.prepareDocumentsForInitForm(get(ukon, 'dokumenty', [])) // TODO: dat prec
		}

		forEach(supportedAttributes, (attribute) => {
			// TODO: tento IF s dokumentom dat prec
			if (get(attribute, 'cesta') !== 'dokumenty') {
				const attributeCesta = get(attribute, 'cesta')
				const attributeValue = get(ukon, attributeCesta, null)
				attributeCesta && set(initValuesData, attributeCesta, attributeValue, null)
			}
		})

		dispatch(initialize(FORMS.GENERIC_UKON, initValuesData, true))
		dispatch(reset(FORMS.GENERIC_UKON))

		this.setState(
			{
				supportedAttributes,
				unsupportedAttributes,
				ProcesnyKonfigurator,
				originalValues: {
					...initValuesData,
					data: {
						...ukonDataPred
					}
				}
			},
			() => {
				const body = this.formatDataForUkon()
				ukonGenericActions.loadContext({
					...body,
					data: {
						...get(initValuesData, 'data')
					}
				})
			}
		)
	}

	componentDidMount = async () => {
		this._mounted = true
		this.onMount()
	}

	componentWillUnmount = () => {
		const { dispatch, trackingActions, ukonyActions, dataActions } = this.props

		const { supportedAttributes } = this.state

		dispatch(destroy(FORMS.GENERIC_UKON))

		dataActions.unregisterLeavePageModal()
		trackingActions.clearTracking()

		// clear ukonId from storage
		ukonyActions.clearUkonId()

		forEach(supportedAttributes, (attribute) => {
			if (get(attribute, 'destroy')) {
				attribute.destroy(this.props, attribute)
			}
		})

		this._mounted = false
	}

	componentDidUpdate = async (prevProps) => {
		const { formValues, location } = this.props
		const { step, supportedAttributes } = this.state

		const locationPonuka = get(location, 'ponuka')
		if (locationPonuka?.ukon.typ.id !== prevProps.location.ponuka?.ukon.typ.id) {
			this.setState({ ...this.prepareState(locationPonuka) })
			this.onMount()
			return
		}

		const prevFormValues = get(prevProps, 'formValues')
		// NOTE: CP-3459- pri prekliknutí medzi úkonmi (Odpoveď zákazníkovi a Všeobecný aktívny úkon) je potrebné skontrolovať aj data['@type'] aby sa pracovalo so správnymi formValues a prevFormValues
		if (!isEmpty(prevFormValues) && !isEqual(formValues, prevFormValues) && formValues?.data['@type'] === prevFormValues?.data['@type']) {
			const changedRecalcAttribute = some(supportedAttributes, (attribute) => {
				if (!get(attribute, 'cesta')) {
					return
				}
				if (isEqual(get(formValues, get(attribute, 'cesta')), get(prevFormValues, `${get(attribute, 'cesta')}`))) {
					return
				}
				if (get(attribute, 'recalc') && !get(attribute, 'vstupny')) {
					return attribute
				}
			})
			if (changedRecalcAttribute) {
				this.setState({ isLoading: true })
				await this.prepareDraftRecalc(step)
				this.setState({ isLoading: false })
			}
		}
	}

	prepareAttributes = (attributes, ukon, step) => {
		const genericUkonConfig = getGenericUkonConfig(ukon?.typ)
		const fieldsConfig = genericUkonConfig.fields

		const supportedAttributes = attributes
			.filter((attribute) => {
				const configForAttribute = fieldsConfig[get(attribute, 'typ')]

				if (configForAttribute == undefined) {
					return false
				}

				if (this.getEditMode(step) === EDIT_MODE.CONFIRM) {
					return true
				}

				const stepConfig = get(configForAttribute, 'step')
				const hasStepDefined = stepConfig !== undefined

				if (hasStepDefined) {
					// Atribút môže byť zobrazovaný na viacerých krokoch (definovaný ako array)
					if (Array.isArray(stepConfig)) {
						return stepConfig.includes(step)
					}
					if (stepConfig === step) {
						return true
					}
				}

				if (!hasStepDefined && get(genericUkonConfig, 'defaultStep') === step) {
					return true
				}

				return false
			})
			.map((supportedAttribute) => {
				return {
					...supportedAttribute,
					...fieldsConfig[get(supportedAttribute, 'typ')]
				}
			})

		const unsupportedAttributes = map(
			attributes.filter((attribute) => {
				const configForAttribute = fieldsConfig[get(attribute, 'typ')]
				return !configForAttribute
			})
		)

		return {
			supportedAttributes,
			unsupportedAttributes,
			genericUkonConfig
		}
	}

	prepareDraftRecalc = async (step) => {
		const { dispatch, formValues, ukonGenericActions, procesnyKonfigurator, ukonyActions } = this.props
		const { originalValues } = this.state

		try {
			const body = this.formatDataForUkon(formValues)

			const res = await postReq(`/api/v2/ukony/draft-prepare`, null, body)
			const recalcResponse = get(res, 'response.content')

			const ukon = get(recalcResponse, 'ukon', {})
			const ukonDataPred = get(recalcResponse, 'ukon.dataPred', {})

			if (ukon?.id) {
				ukonyActions.setUkonId(ukon.id)
			}

			const { supportedAttributes, unsupportedAttributes, genericUkonConfig } = this.prepareAttributes(get(recalcResponse, 'atributy', []), ukon, step)

			forEach(supportedAttributes, (attribute) => {
				// NOTE: check documents and updated values
				if (get(attribute, 'cesta') === FIELD_PATH.DOKUMENTY) {
					const newDocuments = parseNewDocuments(get(ukon, get(attribute, 'cesta')))
					if (newDocuments) {
						dispatch(change(FORMS.GENERIC_UKON, get(attribute, 'cesta'), newDocuments))
					}
				} else if (get(attribute, 'cesta') !== FIELD_PATH.NOTIFIKACIE) {
					// NOTE: update form values  after recalc
					get(attribute, 'cesta') && dispatch(change(FORMS.GENERIC_UKON, get(attribute, 'cesta'), get(ukon, get(attribute, 'cesta'), null)))
				}
			})

			const procesnyKonfigKey = getProcesnyKonfigType(get(recalcResponse, 'ukon.typ.id'), get(procesnyKonfigurator, 'data.ukony'))
			const ProcesnyKonfigurator = procesnyKonfigKey ? initProcesnyKonfigurator(FORMS.GENERIC_UKON, procesnyKonfigKey) : null

			this.setState(
				{
					step,
					ukon: get(recalcResponse, 'ukon'),
					atributy: get(recalcResponse, 'atributy'),
					genericUkonConfig,
					stepsTotalCount: get(genericUkonConfig, 'steps', STEP.ONE),
					ProcesnyKonfigurator,
					supportedAttributes,
					unsupportedAttributes,
					originalValues: {
						...originalValues,
						data: {
							...get(originalValues, 'data', {}),
							...ukonDataPred
						}
					}
				},
				() => {
					const formattedValuesForUkon = this.formatDataForUkon(formValues)

					const body = {
						...formattedValuesForUkon,
						data: ukon.data
					}
					ukonGenericActions.loadContext(body)
				}
			)
			return ukon
		} catch (e) {
			// eslint-disable-next-line
			console.log(e)
		}
	}

	formDataForNesuhlasy = (values) => {
		let nesuhlasy = {}

		if (!isEmpty(values?.suhlasEfaktura?.nesuhlasDovod) && values.suhlasEfaktura?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_FAKTURA]: {
					...values.suhlasEfaktura.nesuhlasDovod,
					dovodAlt: values.suhlasEfaktura?.nesuhlasDovodIny
				}
			}
		}
		if (!isEmpty(values?.suhlasEplatba?.nesuhlasDovod) && values.suhlasEplatba?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_PLATBA]: {
					...values.suhlasEplatba.nesuhlasDovod,
					dovodAlt: values.suhlasEplatba?.nesuhlasDovodIny
				}
			}
		}
		if (!isEmpty(values?.suhlasEpreplatky?.nesuhlasDovod) && values.suhlasEpreplatky?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
			nesuhlasy = {
				...nesuhlasy,
				[DEFINICIA_TYP.E_PREPLATKY]: {
					...values.suhlasEpreplatky.nesuhlasDovod,
					dovodAlt: values.suhlasEpreplatky?.nesuhlasDovodIny
				}
			}
		}

		return nesuhlasy
	}

	// NOTE: remove this function after migrate from AttrPrilohaDTO and AttrPrilohyDTO to AttrUkonDokumentyDTO
	getNotification = (notifikacia, parsedNotifikacie) => {
		const { notification } = this.props

		if (notifikacia) {
			return {
				notifikacia: notifikacia ? parsedNotifikacie : undefined
			}
		}
		return {
			notifikacie: notification ? parsedNotifikacie : undefined
		}
	}

	isMiestoSpotrebyAddressValid = (address) => {
		return (
			!isEmpty(address) &&
			get(address, 'stat') &&
			get(address, 'obec') &&
			(get(address, 'supisneCislo') || get(address, 'orientacneCislo') || get(address, 'parcelneCislo')) &&
			get(address, 'psc')
		)
	}

	formatDataForUkon = (values) => {
		const { interakcia, auth, tracking, ukonNovy, notification } = this.props
		const { supportedAttributes, schvalovacieKriteria, ukon } = this.state

		let schvalovaciRezim = null
		if (!isEmpty(schvalovacieKriteria)) {
			const maxUrovenSchvalovania = get(
				maxBy(
					filter(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok')),
					'urovenSchvalovania'
				),
				'urovenSchvalovania',
				0
			)
			const schvalovacieKriteriaNormalize = map(schvalovacieKriteria, (schvalovacieKriterium) => ({
				id: get(schvalovacieKriterium, 'nazov'),
				vysledok: get(schvalovacieKriterium, 'vysledok'),
				dovod: get(schvalovacieKriterium, 'popis')
			}))
			schvalovaciRezim = {
				kod: maxUrovenSchvalovania,
				pravidla: schvalovacieKriteriaNormalize
			}
		}

		const startTime = get(tracking, 'startTime')
		const ziadanyOd = get(values, 'ziadanyOd')

		const documentsForRequest = parseDocumentsForRequest(get(values, FIELD_PATH.DOKUMENTY))

		// NOTE: remove notification after migrate from AttrPrilohaDTO and AttrPrilohyDTO to AttrUkonDokumentyDTO
		const notifikacia = get(values, 'notifikacia', null) || notification

		let notifikacie = {
			adresyPosta: [],
			adresyUri: []
		}
		if (notifikacia) {
			if (get(notifikacia, 'typ') == NOTIFICATION_TYPES.ADDRESS) {
				notifikacie.adresyPosta.push(get(notifikacia, 'address'))
			} else if (get(notifikacia, 'typ') == NOTIFICATION_TYPES.EMAIL) {
				notifikacie.adresyUri.push({
					typ: NOTIFICATION_TYPES.EMAIL,
					hodnota: get(notifikacia, 'email')
				})
			} else if (get(notifikacia, 'typ') == NOTIFICATION_TYPES.PRINTER) {
				notifikacie.adresyUri.push({
					typ: NOTIFICATION_TYPES.PRINTER,
					poradie: 0
				})
			}
		}

		// NOTE: remove notification after migrate from AttrPrilohaDTO and AttrPrilohyDTO to AttrUkonDokumentyDTO
		const parsedNotifikacia = this.getNotification(get(values, 'notifikacia', null), notifikacie)

		// NOTE: reformat the <br/> element because BB need XHTML compatibility for generating PDFs -> CP-3405
		const odpovedText = formatHTML(get(values, 'data.odpovedText', undefined))

		let ukonData = {
			...omit(values, ['INIT_TIMESTAMP']),
			data: {
				...get(values, 'data', {}),
				odpovedText,
				schvalovaciRezim
			},
			trvanie: dayjs().diff(dayjs(startTime), 'millisecond'),
			zacatyOd: dayjs(startTime).toISOString(),
			...parsedNotifikacia,
			ziadanyOd: ziadanyOd ? dayjs(ziadanyOd).toISOString() : undefined,
			dokumenty: documentsForRequest,
			...formatData(auth, ukonNovy, get(ukon, 'typ'), interakcia)
		}

		// NOTE: CP-3490: If data.miestoSpotreby.adresa is not a valid (complete) address, we need to remove it from data for draft-prepare api call to be successful
		if (get(values, 'data.miestoSpotreby.adresa') && !this.isMiestoSpotrebyAddressValid(get(values, 'data.miestoSpotreby.adresa'))) {
			delete ukonData.data.miestoSpotreby
		}

		forEach(supportedAttributes, (attribute) => {
			if (get(attribute, 'format')) {
				const formattedValue = attribute.format(this.props, get(values, get(attribute, 'cesta')))
				set(ukonData, get(attribute, 'cesta'), formattedValue)
			}
		})

		if (ukonData.data?.zmluvnyUcet) {
			const nesuhlasy = this.formDataForNesuhlasy(ukonData.data?.zmluvnyUcet)

			if (!isEmpty(nesuhlasy)) {
				ukonData = {
					...ukonData,
					data: {
						...ukonData.data,
						zmluvnyUcet: {
							...ukonData.data.zmluvnyUcet,
							nesuhlasy
						}
					}
				}
			}
		}

		return ukonData
	}

	processDocuments = async (values, ukonID) => {
		const { dispatch } = this.props

		const allDocuments = get(values, 'dokumenty', [])
		const uploadedDocuments = allDocuments?.filter((document) => document?.id !== undefined)
		const notUploadedDocuments = allDocuments?.filter((document) => document?.id == undefined)

		if (notUploadedDocuments == null || notUploadedDocuments?.length === 0) {
			return
		}

		const newlyUploadedDocuments = await uploadDocuments(notUploadedDocuments, ukonID)
		const newDocuments = [...(uploadedDocuments || []), ...(newlyUploadedDocuments || [])]
		await confirmDocuments(newDocuments, ukonID)

		// merge already uploaded documents and newly uploaded documents
		dispatch(change(FORMS.GENERIC_UKON, FIELD_PATH.DOKUMENTY, newDocuments))
	}

	stepSubmitClickHandler = async (values) => {
		const { step, stepsTotalCount, ukon } = this.state

		const newStep = step + 1
		const remainingSteps = stepsTotalCount - step

		try {
			this.setState({ isLoading: true })
			// NOTE: upload documents before each recalc or submit
			await this.processDocuments(values, get(ukon, 'id'))
			if (remainingSteps > 1) {
				await this.prepareDraftRecalc(newStep)
				this.setState({ isLoading: false })
			} else if (remainingSteps === 1) {
				await this.prepareDraftRecalc(newStep)
				this.setState({ isLoading: false })
			} else {
				const result = await this.submitUkon(values)

				this.setState(
					{
						result: get(result, 'message'),
						dependency: get(result, 'dependency'),
						success: get(result, 'success')
					},
					async () => {
						this.setState({ isLoading: false })
					}
				)
			}
		} catch (e) {
			this.setState({ isLoading: false })
			// eslint-disable-next-line
			console.log(e)
		}
	}

	submitUkon = async (values) => {
		const { t, auth, ukonNovy, dataActions } = this.props

		try {
			// Remove documents from payload
			const body = this.formatDataForUkon({
				...values,
				dokumenty: []
			})
			const res = await postReq(`/api/v2/ukony/${get(ukonNovy, 'id')}/spustit`, null, {
				...body,
				id: get(ukonNovy, 'id')
			})

			if (this._mounted) {
				dataActions.unregisterLeavePageModal()

				const dependency = find(get(res, 'response.content.ukonDependencies', []), (dependency) => {
					return get(dependency, 'ukonStav.id') == UKON_STAV.ODLOZENY && get(dependency, 'typ.id') == UKONY_CISELNIK.PODPIS_SEPA_MANDATU
				})

				let message = t('translation:Common.Úkon bol úspešne odoslaný')
				if (dependency) {
					if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
						message = 'Úkon bol úspešne odoslaný, môžete pokračovať podpisom alebo nahratím SEPA mandátu'
					} else if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
						message = 'Úkon bol úspešne odoslaný, môžete pokračovať nahratím alebo zaslaním SEPA mandátu'
					}
				}

				return {
					message,
					success: true,
					dependency: get(dependency, 'id')
				}
			}
		} catch (e) {
			dataActions.unregisterLeavePageModal()
			return {
				message: t('translation:Common.Počas odosielania úkonu nastala chyba'),
				success: false
			}
		}
	}

	confirmModal = (force) => {
		const { interakcia, ukonyActions, miestaSpotrebyActions, zmluvneVztahyActions, selectedFiltersUkony, obchodnyPartnerActions } = this.props
		const { success, backUrlLink } = this.state

		if (success || force) {
			this.setState(
				{
					success: false
				},
				() => {
					// refetch new data for OP
					miestaSpotrebyActions.clearMiestaSpotreby()
					zmluvneVztahyActions.clearZmluvneVztahy()

					// clear ukonId from storage
					ukonyActions.clearUkonId()

					// TODO: Podľa typu úkonu zavolať správnu akciu a presmerovať na správnu obrazovku
					// refetch new data for historia ukonov
					ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
					obchodnyPartnerActions.loadObchodnyPartnerDetail(get(interakcia, 'opCislo'))

					history.replace(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
				}
			)
		} else {
			this.setState({
				result: null
			})
		}
	}

	continueSEPASigning = () => {
		const { selectedFiltersUkony } = this.props
		const { interakcia, ukonyActions, obchodnyPartnerActions } = this.props
		const { dependency } = this.state

		this.setState(
			{
				success: false
			},
			() => {
				// refetch new data for OP
				obchodnyPartnerActions.loadZmluvneUcty(true)

				// clear ukonID from storage
				ukonyActions.clearUkonId()

				// refetch new data for historia ukonov
				ukonyActions.loadHistoriaUkonov(1, undefined, selectedFiltersUkony)
				history.replace(setRouteParams(UKON_DETAIL, get(interakcia, 'opCislo'), dependency))
			}
		)
	}

	koKriteriaHandler = (isKoKriteriumChecked, koKriteriaNew) => {
		const { koKriteria } = this.state

		if (!isEqual(koKriteria, koKriteriaNew)) {
			this.setState({
				koKriteria: koKriteriaNew
			})
		}
	}

	validacneKriteriaHandler = (validacneKriteriaNew) => {
		const { validacneKriteria } = this.state

		if (!isEqual(validacneKriteria, validacneKriteriaNew)) {
			this.setState({
				validacneKriteria: validacneKriteriaNew
			})
		}
	}

	schvalovacieKriteriaHandler = (schvalovacieKriteriaNew) => {
		const { schvalovacieKriteria } = this.state

		if (!isEqual(schvalovacieKriteria, schvalovacieKriteriaNew)) {
			this.setState({
				schvalovacieKriteria: schvalovacieKriteriaNew
			})
		}
	}

	disallowScenarios = (scenarios) =>
		this.setState({
			disallowedScenarios: scenarios
		})

	onBackClick = () => {
		const { interakcia } = this.props
		const { step, backUrlLink, atributy, ukon, genericUkonConfig } = this.state

		const newStep = step - 1
		const { supportedAttributes, unsupportedAttributes } = this.prepareAttributes(atributy, ukon, newStep)

		if (step === genericUkonConfig.defaultStep || step === STEP.ONE) {
			history.push(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
		} else {
			this.setState({
				step: newStep,
				supportedAttributes,
				unsupportedAttributes
			})
		}
	}

	onCancelClick = () => {
		const { interakcia } = this.props
		const { backUrlLink } = this.state

		history.push(backUrlLink || setRouteParams(ZMLUVY_ZOZNAM, get(interakcia, 'opCislo')))
	}

	getEditMode = (step) => {
		const { stepsTotalCount, genericUkonConfig } = this.state

		if (genericUkonConfig?.stepsConfig && genericUkonConfig?.stepsConfig[step]?.mode) {
			return genericUkonConfig.stepsConfig[step].mode
		}

		if (stepsTotalCount === step) {
			return EDIT_MODE.CONFIRM
		}

		return EDIT_MODE.EDIT
	}

	render = () => {
		const { procesnyKonfigurator, formValues, t, context } = this.props
		const {
			step,
			stepsTotalCount,
			ukon,
			supportedAttributes,
			unsupportedAttributes,
			isLoading,
			koKriteria,
			validacneKriteria,
			schvalovacieKriteria,
			success,
			result,
			showMissingPonukaModal,
			showMissingPermissionsModal,
			originalValues,
			dependency,
			ProcesnyKonfigurator,
			genericUkonConfig
		} = this.state

		const editMode = this.getEditMode(step)

		if (editMode == EDIT_MODE.EDIT) {
			if (get(procesnyKonfigurator, 'isFailure') || isEmpty(get(procesnyKonfigurator, 'data.ukony')) || get(context, 'isFailure')) {
				return <ElementEmptyContent text={t('translation:ProcesnyKonfigurator.Ľutujeme ale nepodarilo sa načítať Procesný konfigurátor')} />
			}
		}

		let modal = null
		if (showMissingPonukaModal) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Chyba')}
					modalContent={t('translation:Common.Prosím zvoľte si z ponuky na prechádzajúcom kroku')}
					leftButton={{
						onClick: () => this.confirmModal(true),
						text: t('translation:Common.Zavrieť'),
						color: 'red'
					}}
					visible
				/>
			)
		} else if (showMissingPermissionsModal) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Chyba')}
					modalContent={t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}
					leftButton={{
						onClick: () => this.confirmModal(true),
						text: t('translation:Common.Zavrieť'),
						color: 'red'
					}}
					visible
				/>
			)
		} else if (dependency && success) {
			modal = (
				<DefaultModal
					modalTitle={t('translation:Common.Odoslané')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: 'green',
						outline: 'outline'
					}}
					rightButton={{
						onClick: this.continueSEPASigning,
						text: t('translation:Common.Pokračovať'),
						color: 'green'
					}}
					visible
				/>
			)
		} else if (result != null) {
			modal = (
				<DefaultModal
					modalTitle={success ? t('translation:Common.Odoslané') : t('translation:Common.Chyba')}
					modalContent={result}
					leftButton={{
						onClick: this.confirmModal,
						text: t('translation:Common.Zavrieť'),
						color: success ? 'green' : 'red'
					}}
					visible
				/>
			)
		}

		const formTitle = t('translation:Common.Krok {step} z {stepsTotalCount} {ukonNazov}', {
			step,
			stepsTotalCount,
			ukonNazov: upperFirst(get(ukon, 'typ.nazov') || '-')
		})

		const methodologicalGuidelineLink = recognizeMethodologicalGuideline(ukon)

		let component = null
		if (!modal) {
			component = (
				<GenericUkonStep
					{...this.props}
					attributes={supportedAttributes}
					unsupportedAttributes={unsupportedAttributes}
					formValues={formValues}
					originalValues={originalValues}
					editMode={editMode}
					formTitle={formTitle}
					methodologicalGuidelineLink={methodologicalGuidelineLink}
					onSubmit={this.stepSubmitClickHandler}
					onBackClick={this.onBackClick}
					onCancelClick={this.onCancelClick}
					koKriteria={koKriteria}
					schvalovacieKriteria={schvalovacieKriteria}
					validacneKriteria={validacneKriteria}
					genericUkonConfig={genericUkonConfig}
				/>
			)
		}

		return (
			<>
				{(!this._mounted || isLoading || get(procesnyKonfigurator, 'isLoading') || get(context, 'isLoading')) && <ElementOverlayLoading />}
				{modal}
				{component}
				{this._mounted && editMode == EDIT_MODE.EDIT && !isEmpty(get(context, 'data')) && ProcesnyKonfigurator && (
					<ProcesnyKonfigurator
						koKriteriaHandler={this.koKriteriaHandler}
						validacneKriteriaHandler={this.validacneKriteriaHandler}
						schvalovacieKriteriaHandler={this.schvalovacieKriteriaHandler}
						formatDataForUkon={this.formatDataForUkon}
						context={get(context, 'data') || {}}
					/>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	auth: get(state, 'auth'),
	tracking: get(state, 'tracking'),
	ukonNovy: get(state, 'ukony.ukonNovy'),
	ciselniky: get(state, 'ciselniky.data'),
	notification: get(state, 'podpisovanieDokumentov.notification'),
	procesnyKonfigurator: get(state, 'procesnyKonfigurator.procesnyKonfigurator'),
	addresses: get(state, 'formAddresses.data'),
	statuses: get(state, 'statuses.statuses'),
	context: get(state, 'pkUkonGenericContext.context'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data'),
	formValues: getFormValues(FORMS.GENERIC_UKON)(state),
	selectedFiltersUkony: get(state, `selectedFilters.${FILTER_SELECTORS.SIDEBAR_HISTORY}`, {})
})

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	ukonyActions: bindActionCreators(UkonyActions, dispatch),
	trackingActions: bindActionCreators(TrackingActions, dispatch),
	dataActions: bindActionCreators(DataActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	miestaSpotrebyActions: bindActionCreators(MiestaSpotrebyActions, dispatch),
	zmluvneVztahyActions: bindActionCreators(ZmluvneVztahyActions, dispatch),
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	ukonGenericActions: bindActionCreators(UkonGenericActions, dispatch),
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(GenericUkonPage)
