import React from 'react'
import PropTypes from 'prop-types'
import { get, cloneDeep, isEmpty, size, omit } from 'lodash'
import cx from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

// atoms
import Input from '../Input'
import AsyncCreatableSelect from '../AsyncCreatableSelect'
import RadioGroupField from '../RadioGroupField'

// components
import Modal from '../../components/Modals/Modal'

// utils
import { searchOptionsObec, searchOptionsUlica, searchOptionsPsc, handleChangeAddress, revalidate } from './AddressSearchFieldModel'
import { ADDRESS_SEARCH_FIELD_MODE, ADDRESS_SOURCE } from '../../utils/enums'
import { formatAddressCityFirst } from '../../utils/address'

const ADDRESS_SEARCH_FIELD_MODE_OPTIONS = [
	{
		value: ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO,
		label: 'Cez súpisné / orientačné číslo'
	},
	{
		value: ADDRESS_SEARCH_FIELD_MODE.PARCELNE_CISLO,
		label: 'Cez parcelné číslo'
	}
]

class AddressSearchField extends React.Component {
	newAddressOptionSupisneCislo = {
		id: -1,
		stat: 'SK',
		obec: '',
		orientacneCislo: '',
		psc: '',
		supisneCislo: '',
		ulica: ''
	}

	newAddressOptionParcelneCislo = {
		id: -1,
		stat: 'SK',
		obec: '',
		parcelneCislo: '',
		psc: '',
		ulica: ''
	}

	static propTypes = {
		placeholder: PropTypes.string,
		required: PropTypes.bool,
		disabled: PropTypes.bool,
		ciselniky: PropTypes.shape().isRequired,
		obchodnyPartner: PropTypes.shape().isRequired,
		input: PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.any,
			onChange: PropTypes.func.isRequired
		}).isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired,
		t: PropTypes.func.isRequired,
		source: PropTypes.string,
		parcelneCislo: PropTypes.bool
	}

	constructor(props) {
		super(props)

		const { input, source } = props

		let selectedAddress = get(input, 'value') || null
		if (!selectedAddress) {
			const onChange = get(input, 'onChange', null)
			if (onChange) {
				const newEmptyAddress = cloneDeep(this.newAddressOptionSupisneCislo)
				selectedAddress = newEmptyAddress
				onChange(newEmptyAddress)
			}
		}

		this.state = {
			mode: ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO,
			source,
			obceOptions: [],
			uliceOptions: [],
			pscOptions: [],
			pscOptionsOptionsFromCisloDomu: [],
			orientacneCisloOptions: [],
			editMode: false,
			editAddress: selectedAddress,
			errors: {},
			warnings: {}
		}
	}

	componentDidMount() {
		const { errors, warnings } = revalidate.call(this, this.state)

		if (!isEmpty(errors) || !isEmpty(warnings)) {
			this.setState({
				errors,
				warnings
			})
		}
	}

	setEdit = (value) => {
		const { errors, warnings } = revalidate.call(this, this.state)

		this.setState({
			errors,
			warnings,
			editMode: value
		})
	}

	handleSaveEditableAddress = () => {
		const { input } = this.props
		const { editAddress } = this.state

		const stat = get(editAddress, 'stat')
		const obec = get(editAddress, 'obec')
		const supisneCislo = get(editAddress, 'supisneCislo')
		const orientacneCislo = get(editAddress, 'orientacneCislo')
		const parcelneCislo = get(editAddress, 'parcelneCislo')
		const psc = get(editAddress, 'psc')

		if (!stat || !obec || (!supisneCislo && !orientacneCislo && !parcelneCislo) || !psc) {
			return
		}

		if (stat === 'SK' && size(psc) === 5) {
			editAddress.psc = `${psc.substring(0, 3)} ${psc.substring(3)}`
		}

		if (stat === 'CZ' && size(psc) === 5) {
			editAddress.psc = `${psc.substring(0, 3)} ${psc.substring(3)}`
		}

		const onChange = get(input, 'onChange', null)
		if (onChange) {
			onChange(cloneDeep(editAddress))
		}
		this.setEdit(false)
	}

	handleModalCancel = () => {
		const { input } = this.props

		const selectedAddress = get(input, 'value')

		this.setState({
			editAddress: selectedAddress,
			editMode: false,
			errors: {},
			warnings: {}
		})
	}

	handleAddressTypeChange = (mode) => {
		/* const {
			editAddress
		} = this.state */

		const template = mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO ? this.newAddressOptionSupisneCislo : this.newAddressOptionParcelneCislo

		return {
			...template /* ,
			obec: get(editAddress, 'obec'),
			ulica: get(editAddress, 'ulica'),
			psc: get(editAddress, 'psc') */
		}
	}

	useTrvalyPobytOP = () => {
		const { obchodnyPartner } = this.props

		const adresaZakaznika = get(obchodnyPartner, 'adresaZakaznika')
		let newEditAddress = {
			...omit(adresaZakaznika, ['id', 'idIsu']),
			id: -1
		}

		if (get(newEditAddress, 'supisneCislo') == '-') {
			newEditAddress.supisneCislo = ''
		}

		const obec = get(adresaZakaznika, 'obec')
		const ulica = get(adresaZakaznika, 'ulica')
		const psc = get(adresaZakaznika, 'psc')

		this.setState(
			{
				mode: ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO,
				editAddress: cloneDeep(newEditAddress)
			},
			async () => {
				const { errors, warnings } = revalidate.call(this, this.state)
				this.setState({
					errors,
					warnings
				})
				await searchOptionsObec.bind(this)(obec)
				await searchOptionsUlica.bind(this)(ulica)
				await searchOptionsPsc.bind(this)(psc)
			}
		)
	}

	render() {
		const {
			input,
			placeholder,
			required,
			meta: { touched, error },
			disabled,
			parcelneCislo,
			t
		} = this.props

		const { mode, editMode, editAddress, obceOptions, uliceOptions, pscOptions, errors, warnings, source } = this.state

		/* Error is string only for this field. If error is object, it is error for another field (miestoSpotreby.adresa.budova) */
		const fieldError = typeof error === 'string' ? error : ''

		let editForm = null

		if (editMode) {
			const title = t('translation:Common.Vyberte adresu')

			editForm = (
				<Modal size='m' shown>
					<div className='content-wrapper'>
						<div className='modal-header'>
							<h3>{title}</h3>
						</div>
						<div className='modal-content'>
							{parcelneCislo && (
								<div className='row' style={{ marginBottom: '15px' }}>
									<div className='col-12'>
										<RadioGroupField
											input={{
												value: mode,
												onChange: (mode) => {
													let newEditAddress = this.handleAddressTypeChange(mode)
													this.setState(
														{
															mode,
															editAddress: cloneDeep(newEditAddress)
														},
														async () => {
															const { errors, warnings } = revalidate.call(this, this.state)
															this.setState({
																errors,
																warnings
															})
														}
													)
												}
											}}
											options={ADDRESS_SEARCH_FIELD_MODE_OPTIONS}
											compare={(option, val) => {
												return get(option, 'value') == val
											}}
										/>
									</div>
								</div>
							)}
							<div className='row'>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.Obec')}
										selectOptions={obceOptions}
										loadOptions={searchOptionsObec.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'obec', value)}
										value={get(editAddress, 'obec', '')}
										isDisabled={!get(editAddress, 'stat')}
										error={!!errors.obec}
									/>
									{errors.obec && <span className='text-danger'>{errors.obec}</span>}
								</div>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.Ulica')}
										selectOptions={uliceOptions}
										loadOptions={searchOptionsUlica.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'ulica', value)}
										value={get(editAddress, 'ulica', '')}
										isDisabled={!get(editAddress, 'obec')}
										error={!!errors.ulica}
									/>
									{errors.ulica && <span className='text-danger'>{errors.ulica}</span>}
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-6'>
									{(!parcelneCislo || mode == ADDRESS_SEARCH_FIELD_MODE.SUPISNE_CISLO) && (
										<div className='row'>
											<div className='col-5'>
												<Input
													placeholder={t('atoms:AddressField.Súpisné číslo')}
													onChange={(e) => handleChangeAddress.call(this, 'supisneCislo', get(e, 'target.value'))}
													value={get(editAddress, 'supisneCislo', '')}
													disabled={!get(editAddress, 'obec') || !get(editAddress, 'ulica')}
													error={!!(errors.supisneCislo || errors.orientacneAleboSupisneCislo)}
												/>
												{errors.supisneCislo && <span className='text-danger'>{errors.supisneCislo}</span>}
												{warnings.supisneCislo && <span className='text-warn'>{warnings.supisneCislo}</span>}
											</div>
											<div className='col-1' style={{ textAlign: 'center' }}>
												/
											</div>
											<div className='col-6'>
												<Input
													placeholder={t('atoms:AddressField.Orientačné číslo')}
													onChange={(e) => handleChangeAddress.call(this, 'orientacneCislo', get(e, 'target.value'))}
													value={get(editAddress, 'orientacneCislo', '')}
													disabled={!get(editAddress, 'obec') || !get(editAddress, 'ulica')}
													error={!!(errors.orientacneCislo || errors.orientacneAleboSupisneCislo)}
												/>
												{errors.orientacneCislo && <span className='text-danger'>{errors.orientacneCislo}</span>}
												{warnings.orientacneCislo && <span className='text-warn'>{warnings.orientacneCislo}</span>}
											</div>
											{errors.orientacneAleboSupisneCislo && (
												<div className='col-12'>
													<div className='text-danger'>{errors.orientacneAleboSupisneCislo}</div>
												</div>
											)}
											{warnings.orientacneAleboSupisneCislo && (
												<div className='col-12'>
													<div className='text-warn'>{warnings.orientacneAleboSupisneCislo}</div>
												</div>
											)}
										</div>
									)}
									{parcelneCislo && mode == ADDRESS_SEARCH_FIELD_MODE.PARCELNE_CISLO && (
										<>
											<Input
												placeholder={t('atoms:AddressField.Parcelné číslo')}
												onChange={(e) => handleChangeAddress.call(this, 'parcelneCislo', get(e, 'target.value'))}
												value={get(editAddress, 'parcelneCislo', '')}
												disabled={!get(editAddress, 'obec') || !get(editAddress, 'ulica')}
												error={!!errors.parcelneCislo}
											/>
											{errors.parcelneCislo && <span className='text-danger'>{errors.parcelneCislo}</span>}
										</>
									)}
								</div>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.PSČ')}
										selectOptions={pscOptions}
										loadOptions={searchOptionsPsc.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'psc', value)}
										value={get(editAddress, 'psc', '')}
										isDisabled={!get(editAddress, 'obec') || !get(editAddress, 'ulica')}
										error={!!errors.psc}
									/>
									{errors.psc && <span className='text-danger'>{errors.psc}</span>}
								</div>
							</div>
						</div>
						<div className='modal-footer clearfix'>
							{source != ADDRESS_SOURCE.SPPD && (
								<button
									onClick={this.useTrvalyPobytOP}
									className='button pull-left'
									data-type='outline'
									type='button'
									data-color='blue'
									style={{ marginLeft: '0px' }}
								>
									{t('translation:Common.Použiť adresu trvalého pobytu OP')}
								</button>
							)}
							<button
								className='button pull-right'
								onClick={() => this.handleSaveEditableAddress()}
								data-color='green'
								disabled={!isEmpty(errors)}
							>
								{t('atoms:AddressField.Vyhľadať')}
							</button>
							<button className={`button pull-right`} onClick={() => this.handleModalCancel()} data-type='outline' data-color='red'>
								{t('atoms:AddressField.Zrušiť')}
							</button>
						</div>
					</div>
				</Modal>
			)
		}

		return (
			<>
				<div className={cx('input-wrapper', { 'has-error': touched && fieldError })}>
					{disabled ? (
						formatAddressCityFirst(editAddress)
					) : (
						<input
							name={input.name}
							placeholder={placeholder}
							type='text'
							className={cx('form-control', 'input-field')}
							required={required}
							disabled={disabled}
							onChange={() => {}}
							value={formatAddressCityFirst(editAddress)}
							onFocus={() => this.setEdit(true)}
						/>
					)}
					<div className='text-danger'>{touched && fieldError}</div>
				</div>
				{!disabled && editForm}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: get(state, 'ciselniky.data'),
	obchodnyPartner: get(state, 'obchodnyPartner.detail.data')
})

export default compose(withTranslation('atoms'), connect(mapStateToProps))(AddressSearchField)
