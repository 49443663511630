import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { get, isEmpty } from 'lodash'

// components
import Modal from './Modal'

// resources
import crossIcon from '../../resources/img/icons/cross-black.svg'

class StavUctuSearch extends React.Component {
	static propTypes = {
		modalTitle: PropTypes.string,
		onSearchButton: PropTypes.func.isRequired,
		onCloseButton: PropTypes.func.isRequired,
		searchTerm: PropTypes.string,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			searchTerm: get(props, 'searchTerm') || ''
		}
	}

	render() {
		const { modalTitle, onSearchButton, onCloseButton, t } = this.props
		const { searchTerm } = this.state

		return (
			<Modal shown size='m'>
				{modalTitle && (
					<div className='modal-header'>
						<h3>{modalTitle}</h3>
						<div className='close' onClick={onCloseButton}>
							<img src={crossIcon} width='25' alt={t('translation:Common.Zavrieť')} />
						</div>
					</div>
				)}
				<div className='modal-content'>
					<div className='input-wrapper no-icon' style={{ paddingBottom: 0 }}>
						<label>{t('translation:Common.filter.Hľadanie')}</label>
						<input
							type='text'
							className='input-field'
							data-type='number'
							onChange={(e) =>
								this.setState({
									searchTerm: get(e, 'target.value', '')
										.replace(/[^a-zA-Z0-9\\-]+/g, '')
										.slice(0, 12)
								})
							}
							placeholder={t('translation:StavUctu.Napíšte číslo dokladu')}
							value={searchTerm}
						/>
					</div>
				</div>
				<div className='modal-footer clearfix'>
					<div className='row'>
						<div className='col-12'>
							<button
								className='button'
								type='button'
								data-color='blue'
								onClick={() => onSearchButton(searchTerm)}
								style={{ width: '100%', marginLeft: 0 }}
								disabled={isEmpty(searchTerm)}
							>
								{t('translation:Common.Hľadať')}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default compose(withTranslation('components'))(StavUctuSearch)
