import React from 'react'
import PropTypes from 'prop-types'
import { map, get, find, cloneDeep, forOwn, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import AsyncSelect from '../AsyncSelect'
import AsyncCreatableSelect from '../AsyncCreatableSelect'
import Input from '../Input'
import addPlusIcon from '../../resources/img/icons/add-plus.svg'

import Select from '../BasicSelect'
import { formatAddress } from '../../utils/address'
import { searchOptionsStat, searchOptionsObec, searchOptionsUlica, searchOptionsPsc, handleChangeAddress, revalidate } from './AddressFieldModel'

class AddressCustomField extends React.Component {
	newAddressOption = {
		value: -1,
		label: this.props.t('atoms:AddressField.Pridať novú adresu'),
		addressDTO: {
			stat: 'SK',
			obec: '',
			orientacneCislo: '',
			psc: '',
			supisneCislo: '',
			ulica: '',
			doRukMeno: ''
		}
	}

	static propTypes = {
		selector: PropTypes.string.isRequired,
		names: PropTypes.arrayOf(PropTypes.string).isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		disabled: PropTypes.bool,
		handleNewAddress: PropTypes.func.isRequired,
		ciselniky: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		t: PropTypes.func.isRequired,
		onChange: PropTypes.func,
		value: PropTypes.shape(),
		isClearable: PropTypes.bool,
		newAddress: PropTypes.bool,
		label: PropTypes.string,
		error: PropTypes.string,
		showDoRukMenoField: PropTypes.bool
	}

	constructor(props) {
		super(props)
		const { selector } = props
		const input = props[selector]
		const addressId = get(input, 'id.input.value', null)

		let selectedAddress = null
		if (addressId) {
			const addresses = map(get(props, 'addresses', []), (address) => cloneDeep(address))
			selectedAddress = find(addresses, (address) => address.id == addressId)
		}

		this.state = {
			mestaOptions: [],
			uliceOptions: [],
			pscOptions: [],
			pscOptionsOptionsFromCisloDomu: [],
			orientacneCisloOptions: [],
			editMode: false,
			editAddress: selectedAddress,
			errors: {
				orientacneCislo: ''
			},
			warnings: {
				orientacneCislo: ''
			}
		}
	}

	componentDidMount() {
		const { errors, warnings } = revalidate.call(this, this.state)
		this.props.handleNewAddress({
			...this.state.editAddress,
			errors,
			warnings
		})
		if (!isEmpty(errors) || !isEmpty(warnings)) {
			this.setState({ errors, warnings })
		}
	}

	componentDidUpdate(prevProps) {
		const { newAddress } = this.props

		if (!prevProps.newAddress && newAddress) {
			const newState = { editAddress: { ...get(this.newAddressOption, 'addressDTO') } }
			this.setState(newState)
			const { errors, warnings } = revalidate.call(this, newState)
			this.props.handleNewAddress({
				...get(this.newAddressOption, 'addressDTO'),
				errors,
				warnings
			})
			if (!isEmpty(errors) || !isEmpty(warnings)) {
				this.setState({ errors, warnings })
			}
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.disabled && state.editMode) {
			return {
				editMode: false
			}
		}
		return null
	}

	render() {
		const { disabled, ciselniky, t, onChange, value, isClearable, newAddress, label, error, addresses, showDoRukMenoField } = this.props
		const { editAddress, mestaOptions, uliceOptions, pscOptions, errors, warnings, pscOptionsOptionsFromCisloDomu } = this.state
		const addressOptions = map(addresses, (address) => {
			return {
				value: address.id,
				label: formatAddress(address),
				addressDTO: address
			}
		})

		addressOptions.push(cloneDeep(this.newAddressOption))
		console.log('addressOptions:', addressOptions)
		let editForm = null

		const messages = []
		forOwn(warnings, (value, key) => {
			if (value) {
				messages.push(
					<React.Fragment key={`w-${key}`}>
						<span className='text-warn'>{value}</span>
						<br />
					</React.Fragment>
				)
			}
		})

		forOwn(errors, function (value, key) {
			if (value) {
				messages.push(
					<React.Fragment key={`e-${key}`}>
						<span className='text-danger'>{value}</span>
						<br />
					</React.Fragment>
				)
			}
		})

		if (newAddress) {
			const optionsPsc = pscOptionsOptionsFromCisloDomu.length !== 0 ? [...pscOptionsOptionsFromCisloDomu] : [...pscOptions]
			const staty = cloneDeep(get(ciselniky, 'staty', []))

			editForm = (
				<>
					<div className='row'>
						<div className='col-6'>
							<AsyncSelect
								value={get(editAddress, 'stat', '')}
								placeholder={t('atoms:AddressField.Štát')}
								selectOptions={staty}
								onChange={(value) => handleChangeAddress.call(this, 'stat', value)}
								error={!!errors.stat}
								defaultOptions={staty}
								loadOptions={searchOptionsStat.bind(this)}
							/>
						</div>
						<div className='col-6'>
							<AsyncCreatableSelect
								placeholder={t('atoms:AddressField.Obec')}
								selectOptions={mestaOptions}
								loadOptions={searchOptionsObec.bind(this)}
								onChange={(value) => handleChangeAddress.call(this, 'obec', value)}
								value={get(editAddress, 'obec', '')}
								isDisabled={!get(editAddress, 'stat')}
								error={!!errors.obec}
							/>
						</div>
					</div>
					<div className='row'>
						<div className='col-6'>
							<AsyncCreatableSelect
								placeholder={t('atoms:AddressField.Ulica')}
								selectOptions={uliceOptions}
								loadOptions={searchOptionsUlica.bind(this)}
								onChange={(value) => handleChangeAddress.call(this, 'ulica', value)}
								value={get(editAddress, 'ulica', '')}
								isDisabled={!get(editAddress, 'obec')}
								error={!!errors.ulica}
							/>
						</div>
						<div className='col-6'>
							<Input
								placeholder={t('atoms:AddressField.Orientačné Číslo')}
								onChange={(e) => handleChangeAddress.call(this, 'orientacneCislo', e.target.value)}
								value={get(editAddress, 'orientacneCislo', '')}
								disabled={!get(editAddress, 'obec')}
								error={!!errors.orientacneCislo}
							/>
						</div>
						<div className='col-6'>
							<Input
								placeholder={t('atoms:AddressField.Súpisné číslo')}
								onChange={(e) => handleChangeAddress.call(this, 'supisneCislo', e.target.value)}
								value={get(editAddress, 'supisneCislo', '')}
								disabled={!get(editAddress, 'obec')}
								error={!!errors.supisneCislo}
							/>
						</div>
						<div className='col-6'>
							<AsyncCreatableSelect
								placeholder={t('atoms:AddressField.PSČ')}
								selectOptions={optionsPsc}
								loadOptions={searchOptionsPsc.bind(this)}
								onChange={(value) => handleChangeAddress.call(this, 'psc', value)}
								value={get(editAddress, 'psc', '')}
								isDisabled={!get(editAddress, 'obec')}
								error={!!errors.psc}
							/>
						</div>
						{showDoRukMenoField && (
							<div className='col-6'>
								<Input
									placeholder={t('atoms:AddressField.Do rúk')}
									onChange={(e) => handleChangeAddress.call(this, 'doRukMeno', e.target.value)}
									value={get(editAddress, 'doRukMeno', '')}
								/>
							</div>
						)}

						<div className='col-12' style={{ paddingBottom: '20px' }}>
							{messages.length > 0 && messages}
						</div>
					</div>
				</>
			)
		}
		const isDisabled = !!disabled
		return (
			<>
				<div className='address-field clearfix'>
					<div>
						<Select
							error={error}
							label={label}
							value={value}
							options={addressOptions}
							classNamePrefix='react-select'
							onChange={onChange}
							isSearchable={false}
							placeholder={t('atoms:AddressField.Vyberte adresu')}
							isDisabled={isDisabled}
							isClearable={isClearable}
						/>
					</div>
					<div className='address-field-add' style={{ height: '101px' }}>
						<img className='address-field-icon' onClick={() => this.props.onChange(this.newAddressOption)} src={addPlusIcon} />
					</div>
				</div>
				<div className='address-edit-form'>{!isDisabled && editForm}</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	ciselniky: state.ciselniky.data
})

export default compose(withTranslation('atoms'), connect(mapStateToProps))(AddressCustomField)
