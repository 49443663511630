import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, touch } from 'redux-form'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import { get, size, map, isEmpty, indexOf, filter, find, head, values } from 'lodash'

// utils
import cx from 'classnames'
import { formatAddress } from '../../../utils/address'
import {
	formatIdentifikatorTyp,
	formatOdberneMiestoObsluha,
	formatOdberneMiestoObsluhaColor,
	formatOdberneMiestoObsluhaTooltip,
	isValidIdentifikator,
	formatTypMeraniaLabel,
	formatFazyPocetLabel,
	getHlavnyIsticOptions
} from '../../../utils/zmluvneVztahy'
import {
	KOMODITA_TYP,
	MERANIE_TYP,
	FAZY_POCET,
	RADIO_GROUP_FIELD_TYPE,
	UKON_STAV,
	ZMLUVNY_VZTAH_POCET_UKONOV,
	ODPOJENIE_STATUS,
	PREVADZKOVATEL_SIETE
} from '../../../utils/enums'
import { formatFormValue } from '../../../utils/form'
import Permissions, { PERMISSIONS } from '../../../utils/permissionsHoc'

// components
import ElementLoading from '../../ElementLoading'
import OdberneMiestoSearchRow from '../../ZmluvneVztahy/OdberneMiestoSearchRow'
import UkonList from '../../UkonList'

// atoms
import { RadioGroupField, TextInputField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// resources
import SearchIcon from '../../../resources/img/icons/search-blue.svg'

// actions
import * as StatusActions from '../../../actions/StatusActions'
import * as OdberneMiestaActions from '../../../actions/OdberneMiestaActions'
import * as UkonyActions from '../../../actions/UkonyActions'

class OdberneMiestoField extends React.Component {
	static propTypes = {
		interakcia: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		ciselniky: PropTypes.shape(),
		value: PropTypes.shape(),
		formValues: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		searchResults: PropTypes.array.isRequired,
		odberneMiesto: PropTypes.shape().isRequired,
		komoditaTyp: PropTypes.shape().isRequired,
		change: PropTypes.func.isRequired,
		povinny: PropTypes.bool.isRequired,
		vstupny: PropTypes.bool.isRequired,
		ukony: PropTypes.shape(),
		statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
		statusActions: PropTypes.shape().isRequired,
		ukonyActions: PropTypes.shape().isRequired,
		odberneMiestaActions: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			existingIdentifikator: false,
			readOnlyOMFormVisible: false,
			createNewOMFormVisible: false,
			searchTerm: ''
		}
	}

	componentDidMount() {
		this._mounted = true

		const { editMode, value, ukony, ukonyActions } = this.props

		if (editMode == EDIT_MODE.EDIT && !get(ukony, 'isLoaded') && !get(ukony, 'isLoading') && get(value, 'cislo')) {
			ukonyActions.loadUkonyForOdberneMiesto(get(value, 'cislo'), get(value, 'identifikator'), {
				stavId: filter(values(UKON_STAV), (value) => value != UKON_STAV.SPRACOVANY).join(',')
			})
		}
	}

	componentWillUnmount() {
		const { value, odberneMiestaActions, ukonyActions } = this.props

		odberneMiestaActions.searchOdberneMiestaClear()
		odberneMiestaActions.clearOdberneMiesto()

		if (get(value, 'cislo')) {
			ukonyActions.clearUkonyForOdberneMiesto(get(value, 'cislo'))
		}

		this._mounted = false
	}

	startSearch = () => {
		const { dispatch, field, statuses, statusActions, odberneMiestaActions, change, komoditaTyp } = this.props
		const { searchTerm } = this.state

		// prevent to dispach search user action if search string is empty
		if (searchTerm) {
			// clean previous error messages
			if (!isEmpty(statuses)) {
				statusActions.statusPop(size(statuses))
			}

			// refresh previous selected omCislo
			dispatch(change(field, null))
			odberneMiestaActions.clearOdberneMiesto()

			this.setState({
				existingIdentifikator: false,
				readOnlyOMFormVisible: false,
				createNewOMFormVisible: false
			})

			odberneMiestaActions.searchOdberneMiesta(
				{
					komoditaTyp: get(komoditaTyp, 'id'),
					fulltext: searchTerm
					// opTyp: OBCHODNY_PARTNER_DRUH.DOMACNOST CP-2639
				},
				25
			)
		}
	}

	getTypMeranieDefaultOption = () => {
		const { ciselniky } = this.props

		return find(get(ciselniky, 'meranieTyp', []), (typMerania) => {
			return get(typMerania, 'id') == MERANIE_TYP.A
		})
	}

	formatTypMeraniaOptions = () => {
		const { ciselniky, t } = this.props

		return map(
			filter(get(ciselniky, 'meranieTyp', []), (typMerania) => {
				return indexOf([MERANIE_TYP.A, MERANIE_TYP.C], get(typMerania, 'id')) >= 0
			}),
			(typMerania) => ({
				label: formatTypMeraniaLabel(typMerania, t),
				value: typMerania
			})
		)
	}

	getFazyPocetDefaultOption = () => {
		const { ciselniky } = this.props

		return find(get(ciselniky, 'fazyPocet', []), (fazyPocet) => {
			return get(fazyPocet, 'id') == FAZY_POCET.F3
		})
	}

	formatFazyPocetOptions = () => {
		const { ciselniky, t } = this.props

		return map(get(ciselniky, 'fazyPocet', []), (fazyPocet) => ({
			label: formatFazyPocetLabel(fazyPocet, t),
			value: fazyPocet
		}))
	}

	setOdberneMiestoDetail = (selectedOmDetail) => {
		const { dispatch, form, field, komoditaTyp, change } = this.props

		dispatch(change(field, selectedOmDetail))
		dispatch(touch(form, `${field}.hlavnyIsticHodnota`))
		dispatch(touch(form, `${field}.fazyPocet`))
		dispatch(touch(form, `${field}.typMerania`))
		dispatch(touch(form, `${field}.identifikator`))

		if (get(komoditaTyp, 'id') !== KOMODITA_TYP.ELEKTRINA) {
			return
		}

		if (!get(selectedOmDetail, 'fazyPocet')) {
			dispatch(change(`${field}.fazyPocet`, this.getFazyPocetDefaultOption()))
		}

		if (!get(selectedOmDetail, 'typMerania')) {
			dispatch(change(`${field}.typMerania`, this.getTypMeranieDefaultOption()))
		}
	}

	formatSearchResult = () => {
		const { searchResults, odberneMiesto, value, t, odberneMiestaActions, ukonyActions } = this.props

		const { existingIdentifikator } = this.state

		if (!get(searchResults, 'isReady')) {
			return null
		}
		if (get(searchResults, 'isLoading')) {
			return (
				<div className='cornered-loading' style={{ minHeight: '120px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(searchResults, 'isFailure')) {
			return (
				<div className='alert' data-color='red' style={{ width: 'auto', margin: '20px' }}>
					{t('translation:ZmluvneUcty.Pri vyhľadávaní odberných miest nastala chyba')}
				</div>
			)
		}

		if (existingIdentifikator) {
			return (
				<div className='alert' data-color='red' style={{ width: 'auto', margin: '20px' }}>
					{t('translation:ZmluvneUcty.Založenie OM pre EIC/POD nie je povolené (existujúce OM {cislo})', {
						cislo: get(head(get(searchResults, 'data', [])), 'cislo', '')
					})}
				</div>
			)
		}

		return (
			<div>
				<table className='search-results-table no-pointer' cellSpacing='0'>
					<thead style={{ display: isEmpty(get(searchResults, 'data', [])) ? 'none' : null }}>
						<tr>
							<th style={{ paddingLeft: '25px' }}>{t('translation:ZmluvneUcty.EIC / POD')}</th>
							<th>{t('translation:ZmluvneUcty.Adresa')}</th>
							<th
								style={{
									width: '150px',
									textAlign: 'center'
								}}
							>
								{t('translation:ZmluvneUcty.Existuje zmluva?')}
							</th>
							<th className='checkbox-column' />
						</tr>
					</thead>
					<tbody data-empty={t('translation:ZmluvneUcty.Nenašli sa žiadne výsledky')}>
						{map(get(searchResults, 'data', []), (om, index) => (
							<OdberneMiestoSearchRow
								checkRows
								key={`search-result-${index}`}
								t={t}
								cislo={get(om, 'cislo')}
								identifikator={get(om, 'identifikator')}
								adresa={get(om, 'adresa')}
								selectedOmCislo={get(value, 'cislo')}
								aktivnaKomoditnaZmluva={get(om, 'aktivnaKomoditnaZmluva')}
								disabledCheckbox={get(odberneMiesto, 'isLoading')}
								onClick={() => {}}
								onClickCheckbox={async () => {
									const selectedOmCislo = get(value, 'cislo') == get(om, 'cislo') ? null : get(om, 'cislo') // toogle
									let selectedOmDetail = null

									if (selectedOmCislo) {
										selectedOmDetail = await odberneMiestaActions.loadOdberneMiestoMimoOP(selectedOmCislo)
										ukonyActions.loadUkonyForOdberneMiesto(selectedOmCislo, get(selectedOmDetail, 'identifikator'))
										this.setState({
											readOnlyOMFormVisible: true,
											createNewOMFormVisible: false
										})
									} else {
										odberneMiestaActions.clearOdberneMiesto()
										this.setState({
											readOnlyOMFormVisible: false,
											createNewOMFormVisible: false
										})
									}
									this.setOdberneMiestoDetail(selectedOmDetail)
								}}
							/>
						))}
					</tbody>
				</table>
			</div>
		)
	}

	formatReadonlyOdberneMiesto = (odberneMiesto, komoditaTyp, hlavnyIsticOptions, t) => {
		const { ukony, interakcia, field } = this.props
		if (get(odberneMiesto, 'isLoading')) {
			return (
				<div style={{ position: 'relative', height: '100px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(odberneMiesto, 'isFailure')) {
			return (
				<div className='alert' data-color='red' style={{ width: 'auto', margin: '20px' }}>
					{t('translation:Common.Nepodarilo sa načítať detail odberného miesta')}
				</div>
			)
		}
		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<div className='pull-left'>
								<strong>
									{formatIdentifikatorTyp(
										{
											komoditaTyp: get(odberneMiesto, 'data.komoditaTyp')
										},
										t
									)}
								</strong>
							</div>
							{get(odberneMiesto, 'data.obsluha') && (
								<Tooltip
									html={formatOdberneMiestoObsluhaTooltip(get(odberneMiesto, 'data'), t)}
									position='bottom'
									trigger='mouseenter'
									theme='light'
									disabled={false}
									className='pull-right'
								>
									<span className='label' data-color={formatOdberneMiestoObsluhaColor(get(odberneMiesto, 'data'), t)}>
										{formatOdberneMiestoObsluha(get(odberneMiesto, 'data'), t)}
									</span>
								</Tooltip>
							)}
						</td>
						<td>
							<div className='input-wrapper'>{get(odberneMiesto, 'data.identifikator')}</div>
						</td>
					</tr>
					{get(komoditaTyp, 'id') == KOMODITA_TYP.ELEKTRINA && (
						<>
							{this.formatTypMeraniaField(odberneMiesto)}
							{/* CP-2152, CP-2149 */}
							{/* {get(odberneMiesto, 'data.hdoKod') && <tr>
						<td><strong>{t('translation:ZmluvneUcty.HDO kód')}</strong></td>
						<td>{get(odberneMiesto, 'data.hdoKod')}</td>
					</tr>}
					{get(odberneMiesto, 'data.fazyPocet.nazov') && <tr>
						<td><strong>{t('translation:Common.Počet fáz')}</strong></td>
						<td>{get(odberneMiesto, 'data.fazyPocet.nazov')}</td>
					</tr>} */}
							<tr>
								<td>
									<strong>{t('translation:Common.Hodnota hlavného ističa')}</strong>
								</td>
								<td>
									<Field
										name={`${field}.hlavnyIsticHodnota`}
										component={RadioGroupField}
										options={hlavnyIsticOptions}
										compare={(option, val) => {
											return get(option, 'value') == val
										}}
										editableOption
										editableOptionType={RADIO_GROUP_FIELD_TYPE.DECIMAL}
										placeholder={this.formatHlavnyIsticHodnota()}
										formatValue={(val) => (val > 0 ? this.formatHlavnyIsticHodnota(val) : '')}
										customParse={this.customParseHlavnyIsticHodnota}
										validate={this.validateHlavnyIsticHodnota}
									/>
								</td>
							</tr>
							{this.formatFazyPocetField(odberneMiesto)}
							{/* {get(odberneMiesto, 'data.hlavnyIsticHodnota') > 0 && <tr>
						<td><strong>{t('translation:Common.Hodnota hlavného ističa')}</strong></td>
						<td>{this.formatHlavnyIsticHodnota(get(odberneMiesto, 'data.hlavnyIsticHodnota'))}</td>
					</tr>} */}
							{get(odberneMiesto, 'data.odpojenieStatus') && (
								<tr>
									<td>
										<div className='pull-left'>
											<strong>{t('translation:Common.Stav odpojenia')}</strong>
										</div>
										<div className='pull-right'>
											<span
												className='label circle'
												data-color={get(odberneMiesto, 'data.odpojenieStatus.id') == ODPOJENIE_STATUS.NIEJE_ODPOJENE ? 'green' : 'red'}
												style={{ lineHeight: '21px', marginBottom: '2px', marginLeft: '5px' }}
											/>
										</div>
									</td>
									<td>{get(odberneMiesto, 'data.odpojenieStatus.nazov')}</td>
								</tr>
							)}
						</>
					)}
					{!isEmpty(get(ukony, 'data', [])) && (
						<tr>
							<td colSpan={2}>
								<strong>{t('translation:Common.Úkony')}</strong>
								<table className='content-table'>
									<tbody>
										<UkonList
											uniqueKeyPrefix={`odberne-miesto-ukon`}
											ukony={get(ukony, 'data', [])}
											interakcia={interakcia}
											rowStyleClass={`prepis-zmluvny-vztah-ukony`}
											openNewTab={true}
										/>
									</tbody>
								</table>
							</td>
						</tr>
					)}
					{/* CP-2152, CP-2149 */}
					{/* {get(odberneMiesto, 'data.fakturacnaOblast.nazov.nazov') && <tr>
					<td><strong>{t('translation:ZmluvneUcty.Fakturačná oblasť')}</strong></td>
					<td>
						<Tooltip
							title={`${t('translation:Common.Kód')} = ${get(odberneMiesto, 'data.fakturacnaOblast.nazov.id', '-')}`}
							position="top-start"
							trigger="mouseenter"
							theme="light"
						>
							{get(odberneMiesto, 'data.fakturacnaOblast.nazov.nazov')}
						</Tooltip>
					</td>
				</tr>} */}
				</tbody>
			</table>
		)
	}

	formatTypMeraniaField = (odberneMiesto) => {
		const { field, t } = this.props

		if (get(odberneMiesto, 'data.typMerania')) {
			if (get(odberneMiesto, 'data.typMerania.nazov')) {
				return (
					<tr>
						<td>
							<strong>{t('translation:ZmluvneUcty.Typ merania')}</strong>
						</td>
						<td>{get(odberneMiesto, 'data.typMerania.nazov')}</td>
					</tr>
				)
			}
		} else {
			const typMeraniaOptions = this.formatTypMeraniaOptions()
			return (
				<tr>
					<td>
						<strong>{t('translation:Common.Typ merania')}</strong>
					</td>
					<td>
						<Field
							name={`${field}.typMerania`}
							component={RadioGroupField}
							options={typMeraniaOptions}
							compare={(option, val) => {
								return get(option, 'value.id') == get(val, 'id')
							}}
						/>
					</td>
				</tr>
			)
		}
	}

	formatFazyPocetField = (odberneMiesto) => {
		const { field, t } = this.props

		if (get(odberneMiesto, 'data.fazyPocet.nazov')) {
			return (
				<tr>
					<td>
						<strong>{t('translation:Common.Počet fáz')}</strong>
					</td>
					<td>{get(odberneMiesto, 'data.fazyPocet.nazov')}</td>
				</tr>
			)
		}
		const fazyPocetOptions = this.formatFazyPocetOptions()

		return (
			<tr>
				<td>
					<strong>{t('translation:Common.Počet fáz')}</strong>
				</td>
				<td>
					<Field
						name={`${field}.fazyPocet`}
						component={RadioGroupField}
						options={fazyPocetOptions}
						compare={(option, val) => {
							return get(option, 'value.id') == get(val, 'id')
						}}
					/>
				</td>
			</tr>
		)
	}

	formatHlavnyIsticHodnota = (num) => {
		const { odberneMiesto } = this.props

		// CP-2832
		let ALLOW_NUMBER_OF_DECIMALS = 0
		if (get(odberneMiesto, 'data.prevadzkovatelSiete.id') !== PREVADZKOVATEL_SIETE.ZSE) {
			ALLOW_NUMBER_OF_DECIMALS = 2
		}
		if (num !== null && num !== undefined) {
			const integralPart = num.toString().split('.')[0]
			let decimalPart = num.toString().split('.')[1]
			decimalPart = decimalPart && decimalPart.slice(0, ALLOW_NUMBER_OF_DECIMALS)

			const finalNumber = decimalPart ? `${integralPart},${decimalPart}` : integralPart
			return `${finalNumber} A`
		}

		return 'A'
	}

	customParseHlavnyIsticHodnota = (stringValue) => {
		const commaReplacedValue = stringValue.replace(/,/, '.')
		const value = parseFloat(commaReplacedValue)

		if (isNaN(value) || value < 0) {
			return null
		}
		return value
	}

	validateIdentifikator = (fieldValue) => {
		const { komoditaTyp, t } = this.props

		if (!fieldValue) {
			return t('translation:Common.validate.Identifikátor je povinný')
		}
		if (get(komoditaTyp, 'id') === KOMODITA_TYP.ELEKTRINA && !isValidIdentifikator(fieldValue)) {
			return t('translation:Common.validate.Formát identifikátora je nesprávny')
		}
	}

	validateHlavnyIsticHodnota = (fieldValue) => {
		const { t } = this.props

		if (!fieldValue) {
			return t('translation:Common.validate.Hodnota hlavného ističa je povinná')
		}
		if (fieldValue <= 0) {
			return t('translation:Common.validate.Hodnota hlavného ističa musí byť kladné číslo')
		}
		if (fieldValue > 9999) {
			return t('translation:Common.validate.Hodnota hlavného ističa musí byť menšia ako 9999')
		}
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny) {
			if (!value) {
				return t('translation:Common.validate.Odberné miesto je povinné')
			}
		}
	}

	selectNoveOMClick = async () => {
		const { komoditaTyp, odberneMiestaActions } = this.props

		const { searchTerm } = this.state

		try {
			if (isValidIdentifikator(searchTerm)) {
				const existingIdentifikator = await odberneMiestaActions.searchOdberneMiesta(
					{
						identifikator: searchTerm
					},
					25
				)

				if (!isEmpty(existingIdentifikator)) {
					this.setState({
						existingIdentifikator: true
					})
					return
				}
			}
			odberneMiestaActions.clearOdberneMiesto()
			this.setState({
				readOnlyOMFormVisible: false,
				createNewOMFormVisible: true
			})
			this.setOdberneMiestoDetail({
				komoditaTyp,
				identifikator: isValidIdentifikator(searchTerm) ? searchTerm : undefined
			})
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	render() {
		const {
			interakcia,
			field,
			editMode,
			columnsCount = COLUMNS_COUNT.THREE,
			t,
			value,
			originalValue,
			ukony,
			vstupny,
			searchResults,
			odberneMiesto,
			komoditaTyp
		} = this.props

		if (editMode === EDIT_MODE.EDIT && get(ukony, 'isLoading')) {
			return (
				<div style={{ position: 'relative', height: '100px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (editMode === EDIT_MODE.EDIT || editMode === EDIT_MODE.CONFIRM || editMode === EDIT_MODE.DETAIL) {
			const komoditaTypOriginalValue = !vstupny ? get(originalValue, 'komoditaTyp.id') : null

			const valueBefore = !vstupny && (
				<>
					{(komoditaTypOriginalValue === KOMODITA_TYP.ELEKTRINA || komoditaTypOriginalValue === KOMODITA_TYP.PLYN) && (
						<>
							<div>
								{formatIdentifikatorTyp(originalValue, t)}: {get(originalValue, 'identifikator')}
							</div>
							{komoditaTypOriginalValue === KOMODITA_TYP.ELEKTRINA && (
								<div>
									{t('translation:Common.Typ merania')}: {get(originalValue, 'typMerania.nazov', '-')}
								</div>
							)}
						</>
					)}
					{formatAddress(get(originalValue, 'adresa'))}
				</>
			)

			const valueAfter = (
				<>
					{(get(value, 'komoditaTyp.id') === KOMODITA_TYP.ELEKTRINA || get(value, 'komoditaTyp.id') === KOMODITA_TYP.PLYN) && (
						<>
							<div>
								{columnsCount === COLUMNS_COUNT.THREE && !vstupny
									? formatFormValue(
											get(value, 'cislo'),
											get(originalValue, 'cislo'),
											`${formatIdentifikatorTyp(value, t)}: ${get(value, 'identifikator')}`
										)
									: `${formatIdentifikatorTyp(value, t)}: ${get(value, 'identifikator')}`}
							</div>
							<div>
								{get(value, 'komoditaTyp.id') === KOMODITA_TYP.ELEKTRINA &&
									(columnsCount === COLUMNS_COUNT.THREE && !vstupny
										? formatFormValue(
												get(value, 'typMerania.id', '-'),
												get(originalValue, 'typMerania.id', '-'),
												`${t('translation:Common.Typ merania')}: ${get(value, 'typMerania.nazov', '-')}`
											)
										: `${t('translation:Common.Typ merania')}: ${get(value, 'typMerania.nazov', '-')}`)}
							</div>
							<div>
								{get(value, 'komoditaTyp.id') === KOMODITA_TYP.ELEKTRINA &&
									(columnsCount === COLUMNS_COUNT.THREE && !vstupny
										? formatFormValue(
												get(value, 'hlavnyIsticHodnota', '-'),
												get(originalValue, 'hlavnyIsticHodnota', '-'),
												`${t('translation:Common.Hodnota hlavného ističa')}: ${get(value, 'hlavnyIsticHodnota', '-')}`
											)
										: `${t('translation:Common.Hodnota hlavného ističa')}: ${get(value, 'hlavnyIsticHodnota', '-')}`)}
							</div>
							<div>
								{get(value, 'komoditaTyp.id') === KOMODITA_TYP.ELEKTRINA &&
									(columnsCount === COLUMNS_COUNT.THREE && !vstupny
										? formatFormValue(
												get(value, 'fazyPocet.id', '-'),
												get(originalValue, 'typMerania.id', '-'),
												`${t('translation:Common.Počet fáz')}: ${get(value, 'fazyPocet.nazov', '-')}`
											)
										: `${t('translation:Common.Počet fáz')}: ${get(value, 'fazyPocet.nazov', '-')}`)}
							</div>
						</>
					)}
					{columnsCount === COLUMNS_COUNT.THREE && !vstupny
						? formatFormValue(formatAddress(get(value, 'adresa')), formatAddress(get(originalValue, 'adresa')))
						: formatAddress(get(value, 'adresa'))}
					{editMode === EDIT_MODE.EDIT && !isEmpty(get(ukony, 'data', [])) && (
						<div style={{ marginTop: '20px' }}>
							<strong>{t('translation:Common.Úkony')}</strong>
							<table className='content-table'>
								<tbody>
									<UkonList
										uniqueKeyPrefix={`odberne-miesto-ukon`}
										ukony={get(ukony, 'data', [])}
										interakcia={interakcia}
										rowStyleClass={`prepis-zmluvny-vztah-ukony`}
										openNewTab={true}
										maxCount={ZMLUVNY_VZTAH_POCET_UKONOV}
									/>
								</tbody>
							</table>
						</div>
					)}
				</>
			)

			return (
				<div className='inner-box'>
					<table className='content-table padded bordered' cellSpacing='0'>
						<tbody>
							<tr>
								<td>
									<strong>{t('translation:Common.Odberné miesto')}</strong>
								</td>
								{columnsCount === COLUMNS_COUNT.THREE && !vstupny && <td>{valueBefore}</td>}
								<td colSpan={columnsCount === COLUMNS_COUNT.THREE ? 2 : 1}>{valueAfter}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)
		}

		// NOTE: EDIT_MODE == LOOKUP
		const { readOnlyOMFormVisible, createNewOMFormVisible, searchTerm } = this.state

		const typMeraniaOptions = this.formatTypMeraniaOptions()
		const fazyPocetOptions = this.formatFazyPocetOptions()
		const hlavnyIsticOptions = getHlavnyIsticOptions()

		return (
			<>
				<div className='box'>
					<div className='row'>
						<div className='col-12'>
							<div className='search-wrapper no-border'>
								<input
									type='text'
									autoComplete='false'
									value={searchTerm}
									onChange={(e) =>
										this.setState({
											searchTerm: get(e, 'target.value')
										})
									}
									onKeyDown={(e) => {
										// start searching on key press Enter
										if (e.keyCode === 13) {
											e.target.blur() // call blur cause CARE-790
											this.startSearch()
										}
									}}
									placeholder={t('translation:ZmluvneUcty.Hľadať podľa mena, adresy MS alebo EIC/POD')}
								/>
								<div
									className='button circle search-button'
									data-type='outline'
									data-color='blue'
									style={{
										backgroundImage: `url(${SearchIcon})`
									}}
									onClick={this.startSearch}
								/>
							</div>
						</div>
					</div>
					{this.formatSearchResult()}
					<Field name={field} validate={this.validate} component='input' type='hidden' />
					<div className='text-center'>
						<Permissions
							allowed={[PERMISSIONS.UKON_NOVY_ODBER]}
							render={(hasPerm) => {
								let createOMBtn
								if (get(searchResults, 'isReady') && !get(searchResults, 'isLoading') && !createNewOMFormVisible && !readOnlyOMFormVisible) {
									createOMBtn = (
										<button
											className={cx('button', { disabled: !hasPerm })}
											type='button'
											data-color='blue'
											style={{ margin: '30px 0 20px 0' }}
											onClick={(e) => {
												if (!hasPerm) {
													e.preventDefault()
												} else {
													this.selectNoveOMClick()
												}
											}}
										>
											{t('translation:Common.Nové OM')}
										</button>
									)
								} else {
									return null
								}

								let tooltipText
								if (!hasPerm) {
									tooltipText = <span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>
								}

								return tooltipText ? (
									<Tooltip html={tooltipText} position='left' trigger='mouseenter' theme='light'>
										{createOMBtn}
									</Tooltip>
								) : (
									createOMBtn
								)
							}}
						/>
					</div>
				</div>
				{createNewOMFormVisible && (
					<div className='box'>
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{formatIdentifikatorTyp({ komoditaTyp }, t)}</strong>
										</td>
										<td>
											<div className='input-wrapper'>
												<Field
													name={`${field}.identifikator`}
													component={TextInputField}
													placeholder={t('translation:Common.Zadajte {identifikator} kód', {
														identifikator: formatIdentifikatorTyp({ komoditaTyp }, t)
													})}
													validate={this.validateIdentifikator}
												/>
												<Field name={`${field}.komoditaTyp`} component='input' type='hidden' />
											</div>
										</td>
									</tr>
									{get(komoditaTyp, 'id') === KOMODITA_TYP.ELEKTRINA && (
										<>
											<tr>
												<td>
													<strong>{t('translation:Common.Typ merania')}</strong>
												</td>
												<td>
													<Field
														name={`${field}.typMerania`}
														component={RadioGroupField}
														options={typMeraniaOptions}
														compare={(option, val) => {
															return get(option, 'value.id') === get(val, 'id')
														}}
													/>
												</td>
											</tr>
											{/* CP-2137 */}
											<tr>
												<td>
													<strong>{t('translation:Common.Počet fáz')}</strong>
												</td>
												<td>
													<Field
														name={`${field}.fazyPocet`}
														component={RadioGroupField}
														options={fazyPocetOptions}
														compare={(option, val) => {
															return get(option, 'value.id') === get(val, 'id')
														}}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Hodnota hlavného ističa')}</strong>
												</td>
												<td>
													<Field
														name={`${field}.hlavnyIsticHodnota`}
														component={RadioGroupField}
														options={hlavnyIsticOptions}
														compare={(option, val) => {
															return get(option, 'value') === val
														}}
														editableOption
														editableOptionType={RADIO_GROUP_FIELD_TYPE.DECIMAL}
														placeholder={this.formatHlavnyIsticHodnota()}
														formatValue={(val) => (val > 0 ? this.formatHlavnyIsticHodnota(val) : '')}
														customParse={this.customParseHlavnyIsticHodnota}
														validate={this.validateHlavnyIsticHodnota}
													/>
												</td>
											</tr>
										</>
									)}
								</tbody>
							</table>
						</div>
					</div>
				)}
				{readOnlyOMFormVisible && (
					<div className='box'>
						<div className='box-content'>{this.formatReadonlyOdberneMiesto(odberneMiesto, komoditaTyp, hlavnyIsticOptions, t)}</div>
					</div>
				)}
			</>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const omCislo = get(ownProps, 'value.cislo') || null
	const ukony = get(state, `ukony.odberneMiesta[${omCislo}]`)

	return {
		interakcia: get(state, 'interakcie.detail.data'),
		statuses: get(state, 'statuses.statuses'),
		ciselniky: get(state, 'ciselniky.data'),
		searchResults: get(state, 'odberneMiesta.searchResults'),
		odberneMiesto: get(state, 'odberneMiesta.detail'),
		ukony
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	odberneMiestaActions: bindActionCreators(OdberneMiestaActions, dispatch),
	statusActions: bindActionCreators(StatusActions, dispatch),
	ukonyActions: bindActionCreators(UkonyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(OdberneMiestoField)
