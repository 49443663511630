// noinspection JSUnusedAssignment

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { change, reduxForm, propTypes, getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, some, find, without, isEqual, pick, keys, indexOf, values, isEmpty, filter, head, slice } from 'lodash'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import qs from 'qs'
import reactQueryParams from 'react-query-params'
import { Tooltip } from 'react-tippy'

// components
import ElementLoading from '../../../ElementLoading'
import ElementEmptyContent from '../../../ElementEmptyContent'
import FakturaSearchResultRow from '../../../TableRows/FakturaSearchResultRow'
import FormInfo from '../../../FormInfo'
import ToastMessage from '../../../ToastMessage'

import validate from './validateForm'

// atoms
import Select from '../../../../atoms/BasicSelect'

// utils
import { FORMS, FILTER_SELECTORS } from '../../../../utils/enums'
import extractProperties from '../../../../utils/extractProperties'
import { setRouteParams, PLANY_SPLATOK_ZOZNAM } from '../../../../utils/routes'
import { isValidacneKriteriumError } from '../../../../utils/form'

const queryParams = {
	zuCislo: 'zuCislo',
	fakturaCislo: 'fakturaCislo'
}

const emptySelectState = {
	value: null,
	label: 'Všetky'
}

class PlanSplatokSearch extends reactQueryParams {
	static propTypes = {
		...propTypes,
		selectedFilters: PropTypes.shape().isRequired,
		formValues: PropTypes.shape(),
		/* koKriteriaHandler: PropTypes.func.isRequired, */
		setStep: PropTypes.func.isRequired
	}

	state = {
		zuCislo: null,
		statuses: []
	}

	defaultQueryParams = {
		[queryParams.zuCislo]: null,
		[queryParams.fakturaCislo]: null
	}

	_mounted = false

	componentDidMount = () => {
		this._mounted = true

		const { otvoreneFakturyActions, filtersActions, selectedFilters } = this.props

		const { zuCislo, fakturaCislo } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		const newFilter = {
			...selectedFilters
		}
		if (zuCislo && get(selectedFilters, queryParams.zuCislo) !== zuCislo) {
			newFilter[queryParams.zuCislo] = zuCislo
		}

		this.setQueryParams({
			[queryParams.fakturaCislo]: this.defaultQueryParams[queryParams.fakturaCislo]
		})

		this.setState({ fakturaCislo })

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...newFilter,
			...filteredParams
		}

		otvoreneFakturyActions.searchFaktury(filters).then((data) => {
			this.setState({
				statuses: map(filter(data, 'sumaBlokovanaPopis'), (faktura) => ({
					typ: 'WARN',
					popis: get(faktura, 'sumaBlokovanaPopis')
				}))
			})
		})

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PLANY_SPLATOK_CREATE_PAGE, newFilter)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		const { dispatch, selectedFilters, otvoreneFaktury, formValues } = this.props
		const { fakturaCislo } = this.state

		if (fakturaCislo) {
			const otvorenaFaktura = find(get(otvoreneFaktury, 'data', []), (faktura) => get(faktura, 'cislo') == fakturaCislo)
			const zdrojovaFaktura = find(get(formValues, 'zdrojoveFaktury', []), (faktura) => get(faktura, 'cislo') == fakturaCislo)
			if (otvorenaFaktura && !zdrojovaFaktura) {
				dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR, 'zdrojoveFaktury', [{ ...otvorenaFaktura }]))
				dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR, 'zuCislo', get(otvorenaFaktura, 'zmluvnyUcetCislo')))
				this.setState({
					fakturaCislo: null
				})
			}
		}

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))

		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	filterChanged = (value, fieldName) => {
		const {
			dispatch,
			formValues,
			otvoreneFakturyActions,
			filtersActions,
			otvoreneFaktury,
			selectedFilters,
			/* koKriteriaHandler, */
			setStep
		} = this.props

		const newFilter = {
			...selectedFilters,
			[fieldName]: null
		}

		switch (fieldName) {
			case queryParams.zuCislo:
				newFilter[fieldName] = value
				break
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}
		otvoreneFakturyActions.searchFaktury(newFilter).then((data) => {
			this.setState({
				statuses: map(filter(data, 'sumaBlokovanaPopis'), (faktura) => ({
					typ: 'WARN',
					popis: get(faktura, 'sumaBlokovanaPopis')
				}))
			})
		})

		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.PLANY_SPLATOK_CREATE_PAGE, newFilter)

		if (fieldName == queryParams.zuCislo && value) {
			const zdrojoveFaktury = [
				...filter(get(formValues, 'zdrojoveFaktury', []), (zdrojovaFaktura) => {
					const otvorenaFaktura = find(get(otvoreneFaktury, 'data', []), (faktura) => get(faktura, 'cislo') == get(zdrojovaFaktura, 'cislo'))
					return get(otvorenaFaktura, 'zmluvnyUcetCislo') == value
				})
			]
			dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR, 'zdrojoveFaktury', zdrojoveFaktury))
			if (isEmpty(zdrojoveFaktury)) {
				dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR, 'zuCislo', null))
				/* koKriteriaHandler(false, []) */
				setStep(1)
			}
		}
	}

	formatSearchResult = () => {
		const {
			otvoreneFaktury,
			formValues,
			change,
			t,
			/* koKriteriaHandler, */
			setStep
		} = this.props

		if (get(otvoreneFaktury, 'isLoading')) {
			return (
				<div className='cornered-loading' style={{ minHeight: '118px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (get(otvoreneFaktury, 'isFailure')) {
			return (
				<div className='alert' data-color='red'>
					{t('translation:PlanySplatok.Pri vyhľadávaní faktúr nastala chyba')}
				</div>
			)
		}

		if (isEmpty(get(otvoreneFaktury, 'data', []))) {
			return (
				<div className='box-content' style={{ minHeight: '318px' }}>
					<ElementEmptyContent
						text={t('translation:PlanySplatok.Zadanému filtru nekorešponduje žiadna faktúra pre vystavenie plánu splátok')}
						style={{ borderRadius: '5px' }}
					/>
				</div>
			)
		}

		return (
			<table className='search-results-table no-pointer' cellSpacing='0'>
				<thead>
					<tr>
						<th>{t('translation:Common.Číslo faktúry')}</th>
						<th>{t('translation:Common.Číslo ZÚ')}</th>
						<th>{t('translation:Common.Otvorená suma')}</th>
						<th>{t('translation:Common.Blokovaná suma')}</th>
						<th className='checkbox-column' />
					</tr>
				</thead>
				<tbody data-empty={t('translation:Common.Nenašli sa žiadne výsledky')}>
					{map(get(otvoreneFaktury, 'data', []), (faktura, index) => {
						const disabledCheckbox =
							(get(formValues, 'zuCislo') && get(faktura, 'zmluvnyUcetCislo') != get(formValues, 'zuCislo')) ||
							get(faktura, 'sumaOtvorena') < 0 ||
							get(faktura, 'sumaBlokovana') > 0
						let disabledCheckboxText
						if (disabledCheckbox) {
							if (get(faktura, 'sumaOtvorena') < 0) {
								disabledCheckboxText = t('translation:PlanySplatok.Preplatkovú faktúru nie je možné zahrnúť do plánu splátok')
							} else if (get(faktura, 'sumaBlokovana') > 0) {
								if (get(faktura, 'sumaBlokovanaPopis')) {
									disabledCheckboxText = get(faktura, 'sumaBlokovanaPopis')
								} else {
									disabledCheckboxText = t('translation:PlanySplatok.Faktúru nie je možné zahrnúť do plánu splátok')
								}
							} else {
								disabledCheckboxText = t('translation:PlanySplatok.Nie je možné kombinovať faktúry z rôznych zmluvných účtov')
							}
						}

						return (
							<FakturaSearchResultRow
								checkRows
								key={`faktura_${index}`}
								faktura={faktura}
								zdrojoveFaktury={get(formValues, 'zdrojoveFaktury', [])}
								disabledCheckbox={disabledCheckbox}
								disabledCheckboxText={disabledCheckboxText}
								onClickCheckbox={async () => {
									const zdrojovaFaktura = find(
										get(formValues, 'zdrojoveFaktury', []),
										(zdrojovaFaktura) => get(faktura, 'cislo') == get(zdrojovaFaktura, 'cislo')
									)
									let newZdrojoveFaktury = []
									let zuCislo
									if (zdrojovaFaktura) {
										newZdrojoveFaktury = without(get(formValues, 'zdrojoveFaktury', []), zdrojovaFaktura)
										zuCislo = isEmpty(newZdrojoveFaktury) ? null : get(head(newZdrojoveFaktury), 'zmluvnyUcetCislo')
									} else {
										newZdrojoveFaktury = [...get(formValues, 'zdrojoveFaktury', []), faktura]
										zuCislo = get(faktura, 'zmluvnyUcetCislo')
									}
									await change('zdrojoveFaktury', newZdrojoveFaktury)
									change('zuCislo', zuCislo)
									this.filterChanged(zuCislo, queryParams.zuCislo)
									if (zuCislo != get(formValues, 'zuCislo')) {
										/* koKriteriaHandler(false, []) */
										setStep(1)
									}
								}}
							/>
						)
					})}
				</tbody>
			</table>
		)
	}

	render = () => {
		const { interakcia, formTitle, handleSubmit, invalid, koKriteria, validacneKriteria, schvalovacieKriteria, onBackClick, formValues, t } = this.props
		const { statuses } = this.state

		// result from procesnyKonfigurator if form is valid
		const invalidKoKriteria = filter(koKriteria, {
			vysledok: false
		})
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium && isEmpty(invalidKoKriteria)
		const disableSubmit = !isValid || invalid || isEmpty(get(formValues, 'zdrojoveFaktury', []))

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (isEmpty(get(formValues, 'zdrojoveFaktury', []))) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					html={<span>{t('translation:PlanySplatok.validate.Zahrnuté faktúry sú povinné')}</span>}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else if (!isEmpty(invalidKoKriteria)) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					html={map(invalidKoKriteria, (koKriterium, index) => (
						<div key={`koKriterium-${index}`}>
							{koKriterium.nazov}: {koKriterium.popis}
						</div>
					))}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<>
				<form onSubmit={handleSubmit}>
					<div className='content-header clearfix'>
						{wrappedSubmitBtn}
						<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
							{t('translation:Common.Späť')}
						</button>
						<div className='header-title pull-left'>{formTitle}</div>
						<Link
							to={setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo'))}
							className='button pull-right'
							data-type='outline'
							data-color='red'
						>
							{t('translation:Common.Zrušiť')}
						</Link>
					</div>
					{this.filterElement()}
					<div className='content-wrapper'>
						<div className='box'>
							<FormInfo koKriteria={koKriteria} />
							{this.formatSearchResult()}
						</div>
					</div>
				</form>
				<ToastMessage
					statusPop={(count) => {
						this.setState({
							statuses: slice(statuses, count)
						})
					}}
					statuses={statuses}
				/>
			</>
		)
	}

	filterElement = () => {
		const { ciselnikySelectBox, selectedFilters, t } = this.props

		// build data for select box filter zmluvne ucty
		const zmluvnyUcetCisloFilterOptions = map(get(ciselnikySelectBox, 'zmluvneUcty.data', []), (zmluvnyUcet) => ({
			value: get(zmluvnyUcet, 'cislo'),
			label: get(zmluvnyUcet, 'cislo')
		}))

		return (
			<div className='content-header'>
				<div className='row'>
					<div className='col-3'>
						<div className='select-wrapper'>
							<label htmlFor='invoices-filter-account'>{t('translation:Common.Zmluvný účet')}</label>
							<Select
								value={
									find(zmluvnyUcetCisloFilterOptions, (element) => element.value == get(selectedFilters, queryParams.zuCislo)) ||
									emptySelectState
								}
								onChange={(e) => this.filterChanged(e.value, queryParams.zuCislo)}
								options={[emptySelectState, ...zmluvnyUcetCisloFilterOptions]}
								isLoading={get(ciselnikySelectBox, 'zmluvneUcty.data.isLoading')}
								isDisabled={
									get(ciselnikySelectBox, 'zmluvneUcty.data.isLoading') ||
									get(ciselnikySelectBox, 'zmluvneUcty.data.isFailure') ||
									isEmpty(ciselnikySelectBox, 'zmluvneUcty.data')
								}
								classNamePrefix='react-select'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const form = reduxForm({
	form: FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(PlanSplatokSearch)

const mapStateToProps = (state) => ({
	selectedFilters: get(state, `selectedFilters.${FILTER_SELECTORS.PLANY_SPLATOK_CREATE_PAGE}`, {}),
	formValues: getFormValues(FORMS.PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
