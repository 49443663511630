import React from 'react'
import { Fields, reduxForm, propTypes, Field } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { get, map, upperFirst, isEmpty, filter, every, some } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import dayjs from 'dayjs'

import validate from './validateForm'

// components
import FormInfo from '../../FormInfo'

// atoms
import { AddressField, TextareaField, SelectField, DropZoneField, TextInputField, DatePickerField } from '../../../atoms'

// utils
import { formatAddress, formatAddressForResponse } from '../../../utils/address'
import { BUSINESS_CHANNELS, FORMS, OBCHODNY_PARTNER_OSLOVENIE } from '../../../utils/enums'
import { isValidacneKriteriumError } from '../../../utils/form'

class ObchodnyPartnerZalozenieEdit extends React.Component {
	static propTypes = {
		...propTypes,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		onDeleteFile: PropTypes.func.isRequired,
		schvalovacieKriteria: PropTypes.array.isRequired,
		validacneKriteria: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			titulPred2Focus: false
		}
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {
		const { formValues, change } = this.props

		if (!get(formValues, 'titulPred1') && get(formValues, 'titulPred2')) {
			change('titulPred1', get(formValues, 'titulPred2'))
			change('titulPred2', null)
		}
	}

	render() {
		const {
			handleSubmit,
			invalid,
			formValues,
			ciselniky,
			addresses,
			t,
			validacneKriteria,
			schvalovacieKriteria,
			auth,
			onDeleteFile,
			formTitle,
			onBackClick,
			onCancelClick
		} = this.props

		const { titulPred2Focus } = this.state

		const ukonVstupOptions = map(get(ciselniky, 'data.ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		const titulyPredMenom = filter(get(ciselniky, 'data.akademickyTitulHodnota', []), 'pred_menom')
		const titulyZaMenom = filter(get(ciselniky, 'data.akademickyTitulHodnota', []), ['pred_menom', false])

		const titulyPredMenomOptions = map(titulyPredMenom, (item) => ({
			label: get(item, 'nazov'),
			value: get(item, 'id')
		}))
		const titulyZaMenomOptions = map(titulyZaMenom, (item) => ({
			label: get(item, 'nazov'),
			value: get(item, 'id')
		}))
		const oslovenieOptions = map(
			filter(get(ciselniky, 'data.obchodnyPartnerOslovenie'), (item) => get(item, 'id') != OBCHODNY_PARTNER_OSLOVENIE.FIRMA),
			(item) => ({
				label: get(item, 'nazov'),
				value: get(item, 'id')
			})
		)

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !get(validacneKriterium, 'vysledok'))
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{get(validacneKriterium, 'nazov')}: {get(validacneKriterium, 'popis')}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(
					schvalovacieKriteria,
					(schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok') || get(schvalovacieKriterium, 'error')
				)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{get(schvalovacieKriterium, 'nazov')}: {get(schvalovacieKriterium, 'popis')}
					</div>
				))
			}
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', formValues, addresses)

		let refuseEdit
		if (get(formValues, 'adresaZakaznika.id') === get(formValues, 'adresaKorespondencna.id')) {
			refuseEdit = t('translation:Common.Adresu nie je možné editovať nakoľko je použitá ako adresa trvalého pobytu')
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='container' style={{ marginTop: '20px', marginBottom: '20px' }}>
						<div className='box'>
							<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
							<FormInfo validacneKriteria={validacneKriteria} />
							<div className='box-content'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Vstup do úkonu')}</strong>
											</td>
											<td>
												<Field
													name='ukonVstup'
													component={SelectField}
													options={ukonVstupOptions}
													isSearchable={false}
													isDisabled={isEmpty(ukonVstupOptions)}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
											</td>
											<td>
												<Field
													name='podpisMiesto'
													component={TextInputField}
													placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
											</td>
											<td>
												<div className='select-wrapper'>
													<Field
														name='datumPrijatiaZiadosti'
														component={DatePickerField}
														showYearDropdown
														scrollableYearDropdown
														minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
														maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
														placeholderText={t('translation:Common.Zvoľte dátum')}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div className='inner-box'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:Common.Oslovenie')}</strong>
												</td>
												<td>
													<Field
														name='oslovenie'
														component={SelectField}
														options={oslovenieOptions}
														isClearable
														classNamePrefix='react-select'
														placeholder={t('translation:Common.Oslovenie')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Meno')}</strong>
												</td>
												<td>
													<div className='input-wrapper'>
														<Field name='meno' component={TextInputField} placeholder={t('translation:Common.Meno')} />
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Priezvisko')}</strong>
												</td>
												<td>
													<div className='input-wrapper'>
														<Field name='priezvisko' component={TextInputField} placeholder={t('translation:Common.Priezvisko')} />
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Titul pred menom')}</strong>
												</td>
												<td>
													<div className='row'>
														<div className='col-6'>
															<Field
																name='titulPred1'
																component={SelectField}
																options={titulyPredMenomOptions}
																isClearable={!titulPred2Focus}
																isDisabled={isEmpty(titulyPredMenomOptions)}
																classNamePrefix='react-select'
															/>
														</div>

														<div className='col-6'>
															<Field
																name='titulPred2'
																component={SelectField}
																options={titulyPredMenomOptions}
																isClearable
																isDisabled={isEmpty(titulyPredMenomOptions) || !get(formValues, 'titulPred1')}
																classNamePrefix='react-select'
																onMenuOpen={() => this.setState({ titulPred2Focus: true })}
																onMenuClose={() => this.setState({ titulPred2Focus: false })}
															/>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Titul za menom')}</strong>
												</td>
												<td>
													<Field
														name='titulZa'
														component={SelectField}
														options={titulyZaMenomOptions}
														isClearable
														isDisabled={isEmpty(titulyZaMenomOptions)}
														classNamePrefix='react-select'
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Dátum narodenia')}</strong>
												</td>
												<td>
													<Field
														name='narodenieDatum'
														component={DatePickerField}
														showYearDropdown
														dateFormatCalendar='MMMM'
														scrollableYearDropdown
														yearDropdownItemNumber={80}
														isClearable
														minDate={dayjs().add(-120, 'years').toDate()}
														maxDate={dayjs().add(-18, 'years').toDate()}
														manualEdit
														placeholderText={t('translation:Common.Zvoľte dátum narodenia')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
												</td>
												<td>
													<Fields
														names={['adresaZakaznika.id', 'adresaZakaznika.globalnaZmena']}
														selector={'adresaZakaznika'}
														component={AddressField}
														create
														globalnaZmenaLabel={t('translation:Common.Uplatniť túto adresu na celom OP')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Kontaktné telefónne číslo')}</strong>
												</td>
												<td>
													<Field
														name='telefonneCislo'
														component={TextInputField}
														placeholder={t('translation:Common.Zadajte kontaktné telefónne číslo')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Kontaktný email')}</strong>
												</td>
												<td>
													<Field
														name='kontaktnyEmail'
														component={TextInputField}
														placeholder={t('translation:Common.Zadajte kontaktný email')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Korešpondenčná adresa')}</strong>
												</td>

												<td>
													{!get(formValues, 'adresaZakaznika.globalnaZmena') ? (
														<Fields
															names={['adresaKorespondencna.id']}
															create
															edit
															refuseEdit={refuseEdit}
															selector={'adresaKorespondencna'}
															component={AddressField}
															disabled={get(formValues, 'adresaZakaznika.globalnaZmena')}
														/>
													) : (
														formatAddress(adresaZakaznika)
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:Common.Poznámka')}</strong>
											</td>
											<td>
												<Field
													name='poznamka'
													component={TextareaField}
													rows='3'
													placeholder={t('translation:Common.Zadajte poznámku')}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
											</td>
											<td>
												<Field
													name='dokumenty'
													onDelete={onDeleteFile}
													component={DropZoneField}
													placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.OBCHODNY_PARTNER_ZALOZENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(ObchodnyPartnerZalozenieEdit)

export default compose(withTranslation('components'))(form)
