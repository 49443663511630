import { isEmpty, isString, isNumber, get, find, trim, head } from 'lodash'
/**
 * @return string
 *
 * Format address object to formatted string
 * @param address
 * @param withIntoTheHands
 */
export function formatAddress(address, withIntoTheHands = true) {
	if (isEmpty(address)) {
		return ''
	}
	let formattedAddress = ''
	if (address.ulica && isString(address.ulica) && address.ulica !== '-') {
		formattedAddress += address.ulica.trim()
	}
	const streetNumber = composeStreetNumber(address)
	if (streetNumber) {
		formattedAddress += formattedAddress ? ` ${streetNumber}` : `${streetNumber}`
	}
	if (address.psc && isString(address.psc) && address.psc !== '-') {
		const trimmed = address.psc.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	if (address.obec && isString(address.obec) && address.obec !== '-') {
		const trimmed = address.obec.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}

	if (withIntoTheHands && address.doRukMeno && isString(address.doRukMeno) && address.doRukMeno !== '-') {
		const trimmed = address.doRukMeno.trim()
		if (trimmed) {
			formattedAddress += formattedAddress ? `, (${trimmed})` : `(${trimmed})`
		}
	}
	// TODO: Attribute "stat" is currently ignored because it's not included in designes.
	/* if (
		address.stat &&
		isString(address.stat) &&
		address.stat != '-'
	) {
		formattedAddress += formattedAddress
			? `, ${address.stat.trim()}`
			: `${address.stat.trim()}`
	} */
	return formattedAddress
}

export function formatAddressCityFirst(address) {
	if (isEmpty(address)) {
		return ''
	}
	let formattedAddress = ''
	if (get(address, 'obec') && isString(get(address, 'obec')) && get(address, 'obec') !== '-') {
		formattedAddress += trim(get(address, 'obec'))
	}
	if (get(address, 'ulica') && isString(get(address, 'ulica')) && get(address, 'ulica') !== '-') {
		const trimmed = trim(get(address, 'ulica'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	if (
		get(address, 'parcelneCislo') &&
		(isString(get(address, 'parcelneCislo')) || isNumber(get(address, 'parcelneCislo'))) &&
		get(address, 'parcelneCislo') !== '-'
	) {
		const trimmed = trim(get(address, 'parcelneCislo'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, parc.č. ${trimmed}` : `parc.č. ${trimmed}`
		}
	}
	const streetNumber = composeStreetNumber(address)
	if (streetNumber) {
		formattedAddress += formattedAddress ? ` ${streetNumber}` : `${streetNumber}`
	}
	if (get(address, 'psc') && isString(get(address, 'psc')) && get(address, 'psc') !== '-') {
		const trimmed = trim(get(address, 'psc'))
		if (trimmed) {
			formattedAddress += formattedAddress ? `, ${trimmed}` : `${trimmed}`
		}
	}
	return formattedAddress
}

function composeStreetNumber(address) {
	const streetNumberItems = []
	if (address && address.supisneCislo && (isString(address.supisneCislo) || isNumber(address.supisneCislo)) && address.supisneCislo !== '-') {
		const trimmed = isString(address.supisneCislo) ? address.supisneCislo.trim() : address.supisneCislo
		trimmed && streetNumberItems.push(trimmed)
	}
	if (address && address.orientacneCislo && (isString(address.orientacneCislo) || isNumber(address.orientacneCislo)) && address.orientacneCislo !== '-') {
		const trimmed = isString(address.orientacneCislo) ? address.orientacneCislo.trim() : address.orientacneCislo
		trimmed && streetNumberItems.push(trimmed)
	}
	return streetNumberItems.join('/')
}
/**
 * @param {Object} miestoSpotreby object from api
 * @return {string} formated poschodie, cisloBytu, adresaDodatok
 *
 */
export const miestoSpotrebyAdresaDoplnokFormat = (miestoSpotreby) => {
	if (!miestoSpotreby) {
		return
	}
	const adresa = []
	if (miestoSpotreby.poschodie && miestoSpotreby.poschodie !== '-') {
		adresa.push(miestoSpotreby.poschodie)
	}
	if (miestoSpotreby.cisloBytu && miestoSpotreby.cisloBytu !== '-') {
		adresa.push(miestoSpotreby.cisloBytu)
	}
	/* if (miestoSpotreby.adresaDodatok  && miestoSpotreby.adresaDodatok !== '-') {
		adresa.push(miestoSpotreby.adresaDodatok)
	} */
	return adresa.join(', ')
}

export const formatAddressForResponse = (fieldName, values, addresses) => {
	let temp = null
	if (get(values, `${fieldName}.id`) && get(values, `${fieldName}.id`) !== -1) {
		const address = find(addresses, (e) => e.id === values[fieldName].id)
		temp = {
			...address,
			stat: get(address, 'stat', null),
			obec: get(address, 'obec', null),
			ulica: get(address, 'ulica', null),
			orientacneCislo: get(address, 'orientacneCislo', null),
			supisneCislo: get(address, 'supisneCislo', null),
			psc: get(address, 'psc', null),
			doRukMeno: get(address, 'doRukMeno', null)
		}
		delete temp.globalnaZmena
	} else if (get(values, `adresaZakaznika`)) {
		// CP-3106
		const address = get(values, `adresaZakaznika`)
		temp = {
			...address
		}
		delete temp.globalnaZmena
	} else if (!isEmpty(addresses)) {
		const address = head(addresses)
		temp = {
			...address,
			stat: get(address, 'stat', null),
			obec: get(address, 'obec', null),
			ulica: get(address, 'ulica', null),
			orientacneCislo: get(address, 'orientacneCislo', null),
			supisneCislo: get(address, 'supisneCislo', null),
			psc: get(address, 'psc', null),
			doRukMeno: get(address, 'doRukMeno', null)
		}
		delete temp.globalnaZmena
	} else {
		return null
	}
	/* forOwn(temp, (value, key) => {
		if (!value) {
			temp[key] = ''
		}
	}) */
	return temp
}

export const checkIfAddressesAreEqual = (address1, address2) => {
	return address1?.id === address2?.id
}

export const filterUniqueAddresses = (addresses) => {
	if (Array.isArray(addresses) === false) {
		// eslint-disable-next-line no-console
		console.error('Error in filterUniqueAddresses function: param addresses is not an array')
		return []
	}

	const formatEmptyValue = (value) => {
		if (isEmpty(value) || value === '-') return ''
		return value
	}

	const uniqueMap = new Map()

	addresses.forEach((address) => {
		// Create a key from the address properties
		const key = `${address?.obec}-${address?.ulica}-${address?.orientacneCislo}-${address?.psc}-${formatEmptyValue(
			address?.supisneCislo
		)}-${formatEmptyValue(address?.doRukMeno)}`

		// Add to the map if it doesn't already exist
		if (!uniqueMap.has(key)) {
			uniqueMap.set(key, address)
		}
	})

	// Return the unique addresses as an array
	return Array.from(uniqueMap.values())
}
