import React from 'react'
import PropTypes from 'prop-types'
import { map, get, find, cloneDeep, min, isEqual, isNaN, forEach, isEmpty, omit } from 'lodash'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import AsyncSelect from '../AsyncSelect'
import AsyncCreatableSelect from '../AsyncCreatableSelect'
import Input from '../Input'

// actions
import * as FormAddresssesActions from '../../actions/FormAddressesActions'
import * as AddressActions from '../../actions/AddressActions'

// atoms
import Select from '../BasicSelect'
import CheckboxField from '../CheckboxField'
import ConfirmPreferredValue from '../generics/ConfirmPreferredValue'

// utils
import { formatAddress } from '../../utils/address'
import {
	searchOptionsStat,
	searchOptionsObec,
	searchOptionsUlica,
	searchOptionsPsc,
	searchOptionsOrientacneCislo,
	handleChangeAddress,
	revalidate
} from './AddressFieldModel'
import { isConfirmValid, createSuhlasDTO } from '../../utils/suhlas'
import { SUHLAS_HODNOTA } from '../../utils/enums'

// resources
import editPencilIcon from '../../resources/img/icons/edit-pencil.svg'
import addPlusIcon from '../../resources/img/icons/add-plus.svg'

// components
import Modal from '../../components/Modals/Modal'
import ElementLoading from '../../components/ElementLoading'

class AddressField extends React.Component {
	newAddressOption = {
		value: -1,
		label: this.props.t('atoms:AddressField.Pridať novú adresu'),
		addressDTO: {
			stat: 'SK',
			obec: '',
			orientacneCislo: '',
			psc: '',
			supisneCislo: '',
			ulica: ''
		}
	}

	static propTypes = {
		selector: PropTypes.string.isRequired,
		names: PropTypes.arrayOf(PropTypes.string).isRequired,
		addresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		disabled: PropTypes.bool,
		globalnaZmenaLabel: PropTypes.string,
		formAddressesActions: PropTypes.shape({
			formAddressPush: PropTypes.func.isRequired,
			formAddressUpdate: PropTypes.func.isRequired
		}).isRequired,
		checkAddress: PropTypes.shape(),
		addressActions: PropTypes.shape(),
		label: PropTypes.string,
		ciselniky: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		t: PropTypes.func.isRequired,
		obchodnyPartner: PropTypes.shape(),
		edit: PropTypes.bool,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		newConfirm: PropTypes.string,
		onChangeConfirm: PropTypes.func,
		validate: PropTypes.func,
		create: PropTypes.bool,
		refuseEdit: PropTypes.string,
		error: PropTypes.string,
		adresaZakaznika: PropTypes.shape(),
		disableGlobalnaZmenaChecbox: PropTypes.bool
	}

	constructor(props) {
		super(props)
		const { selector } = props
		const input = props[selector]
		const addressId = get(input, 'id.input.value', null)

		let selectedAddress = null
		if (addressId) {
			const addresses = map(get(props, 'addresses', []), (address) => cloneDeep(address))
			selectedAddress = find(addresses, (address) => address.id == addressId)
		}

		this.state = {
			mestaOptions: [],
			uliceOptions: [],
			pscOptions: [],
			pscOptionsOptionsFromCisloDomu: [],
			orientacneCisloOptions: [],
			editMode: false,
			editAddress: selectedAddress,
			beforeCreateAddressValue: null,
			errors: {},
			warnings: {},
			hideConfirmSelect: false
		}
	}

	componentDidMount() {
		const { editAddress } = this.state
		if (!editAddress) {
			return
		}
		const { errors, warnings } = revalidate.call(this, this.state)
		if (!isEmpty(errors) || !isEmpty(warnings)) {
			this.setState({ errors, warnings })
		}
	}

	componentDidUpdate() {
		const { editAddress, editMode } = this.state
		const { addresses, selector } = this.props
		const input = get(this.props, selector)

		const addressId = get(input, 'id.input.value', null)

		let selectedAddress = null
		if (addressId) {
			const clonedAddresses = map(addresses, (address) => cloneDeep(address))
			selectedAddress = find(clonedAddresses, (address) => address.id == addressId)
		}

		// synchronization between different address fields state and redux store
		if (!isEqual(selectedAddress, editAddress) && !editMode) {
			this.setState({
				editAddress: selectedAddress
			})
		}
	}

	setEdit = (value) => {
		const { editAddress } = this.state
		this.setState(
			{
				editMode: value
			},
			async () => {
				await searchOptionsUlica.call(this, get(editAddress, 'ulica'))
				await searchOptionsObec.call(this, get(editAddress, 'obec'))
				await searchOptionsPsc.call(this, get(editAddress, 'psc'))
				await searchOptionsOrientacneCislo.call(this)
			}
		)
	}

	static getDerivedStateFromProps(props, state) {
		if (props.disabled && state.editMode) {
			return {
				editMode: false
			}
		}
		return null
	}

	addressSelectChange = (option) => {
		const { selector, onChangeConfirm } = this.props
		const fields = get(this.props, selector)

		const value = option ? option.value : null
		const changeAddressObject = get(fields, 'id.input.onChange', null)
		if (changeAddressObject) {
			changeAddressObject(get(option, 'addressDTO.id', value))
			// update also address confirm date
			this.setState({ hideConfirmSelect: true })
			onChangeConfirm && onChangeConfirm(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS))
		}

		if (value === -1) {
			this.setState({ beforeCreateAddressValue: get(fields, 'id.input.value') })
		}

		this.setState(
			{
				editAddress: option && option.addressDTO ? cloneDeep(option.addressDTO) : null,
				editMode: option && option.value == -1,
				errors: {}
			},
			() => {
				if (option && option.value == -1) {
					const { errors, warnings } = revalidate.call(this, this.state)
					this.setState({
						errors,
						warnings
					})
				}
			}
		)
	}

	handleSaveEditableAddress = () => {
		const { editAddress, editMode } = this.state
		const { addresses, selector, onChangeConfirm } = this.props
		const fields = get(this.props, selector)

		if (!editMode || !editAddress) {
			return
		}

		// update also address confirm date
		this.setState({ hideConfirmSelect: true })
		onChangeConfirm && onChangeConfirm(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS))

		const psc = get(editAddress, 'psc')
		const orientacneCislo = get(editAddress, 'orientacneCislo')
		const obec = get(editAddress, 'obec', null)
		const stat = get(editAddress, 'stat', null)
		if (!psc || !orientacneCislo || !obec || !stat) {
			return
		}

		if (stat == 'SK' && psc.length == 5) {
			editAddress.psc = `${psc.slice(0, 3)} ${psc.slice(3)}`
		}

		if (stat == 'CZ' && psc.length == 5) {
			editAddress.psc = `${psc.slice(0, 3)} ${psc.slice(3)}`
		}

		if (editAddress.id) {
			this.props.formAddressesActions.formAddressUpdate(editAddress)
			this.setEdit(false)
		} else {
			// new address
			const changeAddressObject = get(fields, 'id.input.onChange', null)

			const exists = find(addresses, (address) => isEqual(omit(address, ['id', 'idIsu']), omit(editAddress, ['id', 'idIsu'])))
			if (exists) {
				if (changeAddressObject) {
					changeAddressObject(get(exists, 'id', ''))
				}

				this.setState({
					editAddress: cloneDeep(exists),
					editMode: false
				})
			} else {
				const ids = map(addresses, (address) => address.id)

				let minId = min(ids)

				if (!minId || isNaN(minId) || minId > -2) {
					minId = -2
				} else {
					minId--
				}

				const newAddress = {
					...editAddress,
					id: minId
				}

				this.setState(
					{
						editAddress: newAddress,
						editMode: false
					},
					() => {
						this.props.formAddressesActions.formAddressPush(newAddress)
						if (changeAddressObject) {
							changeAddressObject(get(newAddress, 'id', ''))
						}
					}
				)
			}
		}
	}

	handleModalCancel = () => {
		const { selector } = this.props
		const input = get(this.props, selector)
		const addressId = get(input, 'id.input.value', null)

		let selectedAddress = null
		if (addressId) {
			if (addressId !== -1) {
				const addresses = map(get(this.props, 'addresses', []), (address) => cloneDeep(address))
				selectedAddress = find(addresses, (address) => address.id == addressId)
			} else {
				// if modal during creation of new address was canceled select address witch was selected before creating
				input.id.input.onChange(this.state.beforeCreateAddressValue)
			}
		}

		this.setState({
			editAddress: selectedAddress,
			editMode: false,
			errors: {},
			warnings: {}
		})
	}

	// TODO: Presunúť logiku potvrdzovania aktualnosti adresy do samostatného komponentu (ak sa bude dať)
	// TODO: Refaktorovať logiku potvrdzovania adresy
	render() {
		const {
			selector,
			disabled,
			ciselniky,
			t,
			label,
			checkAddress,
			addressActions,
			edit,
			create,
			refuseEdit,
			error,
			newConfirm,
			onChangeConfirm,
			globalnaZmenaLabel,
			disableGlobalnaZmenaChecbox,
			validate
		} = this.props
		const { editMode, editAddress, mestaOptions, uliceOptions, pscOptions, errors, warnings, pscOptionsOptionsFromCisloDomu, hideConfirmSelect } =
			this.state
		const input = get(this.props, selector)
		const addressId = get(input, 'id.input.value', null)

		// CP-2339
		const uniqAddresses = []
		const activeAddress = get(this.props, 'addresses', []).find((address) => address.id == addressId)
		if (activeAddress) uniqAddresses.push(activeAddress)

		get(this.props, 'addresses', []).forEach((address) => {
			const foundIndex = uniqAddresses.findIndex((uniqAddress) => {
				return (
					uniqAddress.stat === address.stat &&
					uniqAddress.obec === address.obec &&
					uniqAddress.ulica === address.ulica &&
					uniqAddress.orientacneCislo === address.orientacneCislo &&
					uniqAddress.supisneCislo === address.supisneCislo &&
					uniqAddress.psc === address.psc &&
					uniqAddress.doRukMeno === address.doRukMeno
				)
			})

			// findIndex returns -1 when index is not found
			if (foundIndex === -1) uniqAddresses.push(address)
		})

		let addressOptions = map(uniqAddresses, (address) => {
			return {
				value: address.id,
				label: formatAddress(address),
				addressDTO: address
			}
		})

		addressOptions.push(cloneDeep(this.newAddressOption))

		let selectedAddress = null
		if (addressId) {
			selectedAddress = find(addressOptions, (option) => option.value == addressId)
		}

		let buttonEdit = null
		let editForm = null

		const globalnaZmenaValue = get(input, 'globalnaZmena.input.value', false)
		const globalnaZmenaOnChange = get(input, 'globalnaZmena.input.onChange', () => {})

		const globalnaZmenaCheckboxInput = {
			value: globalnaZmenaValue,
			onChange: (value) => {
				// update also address confirm date
				if (value === true) {
					this.setState({ hideConfirmSelect: true })
					const isGlobalnaZmena = true
					onChangeConfirm && onChangeConfirm(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS), isGlobalnaZmena)
				} else {
					this.setState({ hideConfirmSelect: false })
				}

				globalnaZmenaOnChange(value)
			}
		}

		const messages = []

		if (editMode) {
			const optionsPsc = pscOptionsOptionsFromCisloDomu.length !== 0 ? [...pscOptionsOptionsFromCisloDomu] : [...pscOptions]
			const staty = cloneDeep(get(ciselniky, 'staty', []))

			const warningMessages = []

			if (checkAddress.isLoading) {
				messages.push(<ElementLoading key='loading' />)
			}

			forEach(get(checkAddress, 'data.op'), (op, index) => {
				const opTitle =
					index === 0 ? (
						<div key={index} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
							<strong>{t('atoms:AddressField.Obchodný partner')}</strong>
						</div>
					) : null
				warningMessages.push(
					<React.Fragment key={`op-${index}`}>
						{opTitle}
						<div> {op.popis}</div>
					</React.Fragment>
				)
			})
			forEach(get(checkAddress, 'data.zmluvneUctyInyOp'), (zmluvnyUcetInyOp, key) => {
				warningMessages.push(
					<div key={key} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
						<strong>
							{t('atoms:AddressField.Iný obchodný partner')} {key}
						</strong>
					</div>
				)
				forEach(zmluvnyUcetInyOp, (inyOP, index) => {
					warningMessages.push(
						<div key={`inyOp-${key}-${index}`}>
							{key} {get(inyOP, 'popis')}
						</div>
					)
				})
			})
			forEach(get(checkAddress, 'data.zmluvneUcty'), (zmluvnyUcet, key) => {
				warningMessages.push(
					<div key={key} style={{ paddingBottom: '20px', paddingTop: '20px' }}>
						<strong>
							{t('atoms:AddressField.Zmluvný účet')} {key}
						</strong>
					</div>
				)
				forEach(zmluvnyUcet, (zu, index) => {
					warningMessages.push(
						<div key={`zu-${key}-${index}`}>
							{key} {get(zu, 'popis')}
						</div>
					)
				})
			})

			if (warningMessages.length > 0) {
				const warn = (
					<React.Fragment key={'adressWarn'}>
						<span className='text-warn'>
							<strong key='title'>{t('atoms:AddressField.Upozornenie zmena tejto adresy ovplyvní aj nasledujúce adresy')}</strong>
							<br />
							{warningMessages}
						</span>
						<br />
					</React.Fragment>
				)
				messages.push(warn)
			}

			const title = selectedAddress && selectedAddress.value == -1 ? t('atoms:AddressField.Vytvorenie adresy') : t('atoms:AddressField.Editácia adresy')
			const subTitle =
				selectedAddress && selectedAddress.value == -1 ? '' : t('atoms:AddressField.Editáciu adresy treba využívať primárne na opravu adresy!')

			editForm = (
				<Modal size='m' shown>
					<div className='content-wrapper'>
						<div className='modal-header'>
							<h3>{title}</h3>
							{subTitle && <h4 style={{ color: 'orange' }}>{subTitle}</h4>}
						</div>
						<div className='modal-content'>
							<div className='row'>
								<div className='col-6'>
									<AsyncSelect
										value={get(editAddress, 'stat', '')}
										placeholder={t('atoms:AddressField.Štát')}
										selectOptions={staty}
										onChange={(value) => handleChangeAddress.call(this, 'stat', value)}
										error={!!errors.stat}
										defaultOptions={staty}
										loadOptions={searchOptionsStat.bind(this)}
									/>
									{errors.stat && <span className='text-danger'>{errors.stat}</span>}
								</div>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.Obec')}
										selectOptions={mestaOptions}
										loadOptions={searchOptionsObec.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'obec', value)}
										value={get(editAddress, 'obec', '')}
										isDisabled={!get(editAddress, 'stat')}
										error={!!errors.obec}
									/>
									{errors.obec && <span className='text-danger'>{errors.obec}</span>}
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.Ulica')}
										selectOptions={uliceOptions}
										loadOptions={searchOptionsUlica.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'ulica', value)}
										value={get(editAddress, 'ulica', '')}
										isDisabled={!get(editAddress, 'obec')}
										error={!!errors.ulica}
									/>
									{errors.ulica && <span className='text-danger'>{errors.ulica}</span>}
								</div>
								<div className='col-6'>
									<Input
										placeholder={t('atoms:AddressField.Orientačné Číslo')}
										onChange={(e) => handleChangeAddress.call(this, 'orientacneCislo', e.target.value)}
										value={get(editAddress, 'orientacneCislo', '')}
										disabled={!get(editAddress, 'obec')}
										error={!!errors.orientacneCislo}
									/>
									{errors.orientacneCislo && <span className='text-danger'>{errors.orientacneCislo}</span>}
									{warnings.orientacneCislo && <span className='text-warn'>{warnings.orientacneCislo}</span>}
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-6'>
									<Input
										placeholder={t('atoms:AddressField.Súpisné číslo')}
										onChange={(e) => handleChangeAddress.call(this, 'supisneCislo', e.target.value)}
										value={get(editAddress, 'supisneCislo', '')}
										disabled={!get(editAddress, 'obec')}
										error={!!errors.supisneCislo}
									/>
								</div>
								<div className='col-6'>
									<AsyncCreatableSelect
										placeholder={t('atoms:AddressField.PSČ')}
										selectOptions={optionsPsc}
										loadOptions={searchOptionsPsc.bind(this)}
										onChange={(value) => handleChangeAddress.call(this, 'psc', value)}
										value={get(editAddress, 'psc', '')}
										isDisabled={!get(editAddress, 'obec')}
										error={!!errors.psc}
									/>
									{errors.psc && <span className='text-danger'>{errors.psc}</span>}
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-6'>
									<Input
										placeholder={t('atoms:AddressField.Do rúk')}
										onChange={(e) => handleChangeAddress.call(this, 'doRukMeno', e.target.value)}
										value={get(editAddress, 'doRukMeno', '')}
									/>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-12' style={{ minHeight: '150px', maxHeight: '300px', overflow: 'auto' }}>
									{messages.length > 0 && messages}
								</div>
							</div>
						</div>
						<div className='modal-footer clearfix'>
							<button
								className='button pull-right'
								onClick={() => this.handleSaveEditableAddress()}
								data-color='green'
								disabled={!isEmpty(errors)}
							>
								{t('atoms:AddressField.Uložiť')}
							</button>
							<button className={`button pull-right`} onClick={() => this.handleModalCancel()} data-type='outline' data-color='red'>
								{t('atoms:AddressField.Zrušiť')}
							</button>
						</div>
					</div>
				</Modal>
			)
		} else if (selectedAddress && selectedAddress.value !== -1 && !disabled) {
			buttonEdit = (
				<img
					className='address-field-icon'
					onClick={() => {
						this.setEdit(true)
						addressActions.checkUsedAddress(selectedAddress.value)
					}}
					src={editPencilIcon}
				/>
			)
		}

		let editBtn
		if (refuseEdit) {
			editBtn = (
				<div className='address-field-edit'>
					<Tooltip html={<span>{refuseEdit}</span>} position='left' trigger='mouseenter' theme='light'>
						<img className='address-field-icon' src={editPencilIcon} />
					</Tooltip>
				</div>
			)
		} else if (edit) {
			editBtn = <div className='address-field-edit'>{buttonEdit}</div>
		}

		const isDisabled = !!disabled
		return (
			<>
				<div className='address-field clearfix'>
					<div className={cx({ editable: edit && !!buttonEdit })}>
						<Select
							label={label}
							value={selectedAddress}
							options={addressOptions}
							classNamePrefix='react-select'
							onChange={this.addressSelectChange}
							isSearchable={false}
							placeholder={t('atoms:AddressField.Vyberte adresu')}
							isDisabled={isDisabled}
							error={get(input, 'id.meta.error') || error}
							validate={validate}
						/>
					</div>
					{editBtn}
					{create && (
						<div className='address-field-add'>
							<img
								className='address-field-icon'
								onClick={() => {
									// clear previou check userd address data
									addressActions.checkUsedAddressClear()
									this.addressSelectChange(this.newAddressOption)
								}}
								src={addPlusIcon}
							/>
						</div>
					)}
				</div>
				{!isDisabled && editForm}
				{selectedAddress && onChangeConfirm && !hideConfirmSelect && (
					<div className='select-divider'>
						<ConfirmPreferredValue
							onChange={onChangeConfirm}
							required={isConfirmValid(newConfirm) === false}
							confirmed={isConfirmValid(newConfirm)}
						/>
					</div>
				)}
				{globalnaZmenaLabel && (
					<div className='row' style={{ marginTop: '10px' }}>
						<div className='col-12'>
							<CheckboxField input={globalnaZmenaCheckboxInput} label={globalnaZmenaLabel} disabled={disableGlobalnaZmenaChecbox || disabled} />
						</div>
					</div>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data,
	obchodnyPartner: state.obchodnyPartner.detail.data,
	ciselniky: state.ciselniky.data,
	checkAddress: state.address.checkAddress
})

const mapDispathToProps = (dispatch) => ({
	formAddressesActions: bindActionCreators(FormAddresssesActions, dispatch),
	addressActions: bindActionCreators(AddressActions, dispatch)
})

export default compose(withTranslation('atoms'), connect(mapStateToProps, mapDispathToProps))(AddressField)
