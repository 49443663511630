import { get } from 'lodash'
import {
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START,
	OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE,
	OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_START,
	OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_DONE,
	OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_PART_LOAD_DONE,
	OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_FAIL
} from '../../types/obchodnyPartner'

import config from '../../utils/config'
import { getReq } from '../../utils/request'
import { history } from '../../utils/history'

export function zmluvneUctyLoadStart() {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_START
	}
}

export function zmluvneUctyLoadDone(filters) {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_DONE,
		payload: {
			filters
		}
	}
}

export function zmluvneUctyLoadFail() {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_FAIL
	}
}

export function zmluvneUctyLoadPartDone(zmluvneUcty) {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_LOAD_PART_DONE,
		payload: {
			zmluvneUcty
		}
	}
}

export function zmluvneUctyAllLoadStart() {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_START
	}
}

export function zmluvneUctyAllLoadDone(filters) {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_DONE,
		payload: {
			filters
		}
	}
}

export function zmluvneUctyAllLoadFail() {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_FAIL
	}
}

export function zmluvneUctyAllLoadPartDone(zmluvneUcty) {
	return {
		type: OBCHODNY_PARTNER_ZMLUVNE_UCTY_ALL_LOAD_PART_DONE,
		payload: {
			zmluvneUcty
		}
	}
}

export function aktivneZmluvneUctyAllLoadStart() {
	return {
		type: OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_START
	}
}

export function aktivneZmluvneUctyAllLoadDone() {
	return {
		type: OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_DONE
	}
}

export function aktivneZmluvneUctyAllPartLoadDone(aktivneZmluvneUcty) {
	return {
		type: OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_PART_LOAD_DONE,
		payload: {
			aktivneZmluvneUcty
		}
	}
}

export function aktivneZmluvneUctyAllLoadFail() {
	return {
		type: OBCHODNY_PARTNER_AKTIVNE_ZMLUVNE_UCTY_ALL_LOAD_FAIL
	}
}

const loadAktivneZmluvneUctyByParts = async (cisloOP, dispatch) => {
	const loadPage = async (page) => {
		const query = {
			velkostStranky: 100,
			stranka: page,
			stav: 'aktivne'
		}

		const res = await getReq(`/api/v2/op/${cisloOP}/zu`, query)

		const aktivneZmluvneUcty = res.response.content
		dispatch(aktivneZmluvneUctyAllPartLoadDone(aktivneZmluvneUcty))
		dispatch(aktivneZmluvneUctyAllLoadDone())
		return aktivneZmluvneUcty
	}
	return await loadPage(1)
}

const loadZmluvneUctyByParts = async (cisloOP, dispatch, activeFilters, dispatchToBoth) => {
	const loadPage = async (page) => {
		const nextPage = page + 1
		const query = {
			velkostStranky: config.zmluvneUctyPageSize,
			stranka: page
		}

		try {
			const res = await getReq(`/api/v0/obchodni-partneri/${cisloOP}/zmluvne-ucty`, query)
			const { strankovanie } = res.response
			if (!strankovanie) {
				return
			}

			const zmluvneUcty = res.response.obsah

			if (dispatchToBoth) {
				dispatch(zmluvneUctyLoadPartDone(zmluvneUcty))
				dispatch(zmluvneUctyAllLoadPartDone(zmluvneUcty))
			} else if (Object.keys(activeFilters).length) {
				dispatch(zmluvneUctyLoadPartDone(zmluvneUcty))
			} else {
				dispatch(zmluvneUctyAllLoadPartDone(zmluvneUcty))
			}

			const { velkostStranky } = res.response.strankovanie
			if (get(zmluvneUcty, 'length') < velkostStranky) {
				return
			}
			await loadPage(nextPage)
		} catch (err) {
			return err
		}
	}
	await loadPage(1)
}

const loadAktiveZmluvneUctyFromApi = async (cisloOP, dispatch) => {
	dispatch(aktivneZmluvneUctyAllLoadStart())
	try {
		return await loadAktivneZmluvneUctyByParts(cisloOP, dispatch)
	} catch (e) {
		dispatch(aktivneZmluvneUctyAllLoadFail())
	}
}

const loadZmluvneUctyFromApi = async (dispatch, getStore, disableCache) => {
	const store = getStore()
	const partner = store.obchodnyPartner.detail.data
	const { zmluvneUcty } = store.obchodnyPartner
	const selectedFilters = store.selectedFilters.zmluvneUctyPage
	const interakcia = store.interakcie.detail

	if (disableCache || (!Object.keys(selectedFilters).length && !zmluvneUcty.allDataPrepared)) {
		dispatch(zmluvneUctyLoadStart())
		dispatch(zmluvneUctyAllLoadStart())

		try {
			await loadZmluvneUctyByParts(partner.cislo, dispatch, selectedFilters, true)
			dispatch(zmluvneUctyLoadDone())
			dispatch(zmluvneUctyAllLoadDone())
		} catch (e) {
			dispatch(zmluvneUctyLoadFail())
			dispatch(zmluvneUctyAllLoadFail())
		}
		return
	}

	dispatch(zmluvneUctyLoadStart())
	try {
		await loadZmluvneUctyByParts(partner.cislo, dispatch, selectedFilters, false)
		dispatch(zmluvneUctyLoadDone())
	} catch (e) {
		dispatch(zmluvneUctyLoadFail())
		history.push(`/${interakcia.partner}`)
	}

	if (!zmluvneUcty.allDataPrepared && !zmluvneUcty.allDataLoading) {
		dispatch(zmluvneUctyAllLoadStart())
		try {
			await loadZmluvneUctyByParts(partner.cislo, dispatch, {}, false)
			dispatch(zmluvneUctyAllLoadDone())
		} catch (e) {
			dispatch(zmluvneUctyAllLoadFail())
			history.push(`/${interakcia.partner}`)
		}
	}
}

export function loadZmluvneUcty(disableCache) {
	return async (dispatch, getStore) => {
		loadZmluvneUctyFromApi(dispatch, getStore, disableCache)
	}
}

export const loadActiveZmluvneUcty = (cisloOP) => {
	return async (dispatch) => {
		return await loadAktiveZmluvneUctyFromApi(cisloOP, dispatch)
	}
}
