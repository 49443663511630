import React from 'react'

function ElementOverlayLoading({ isVisible = true }) {
	if (!isVisible) return null

	return (
		<div className='overlay'>
			<div className='loader' />
		</div>
	)
}

export default ElementOverlayLoading
