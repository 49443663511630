// noinspection JSUnusedAssignment

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import ReactQueryParams from 'react-query-params'
import { map, find, pick, keys, indexOf, isEmpty, isEqual, get, isArray, forEach, filter, sortBy, values } from 'lodash'
import { withTranslation } from 'react-i18next'

// atoms
import Select from '../../atoms/BasicSelect'
// actions
import ZmluvneUctyActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
// components
import ZmluvnyUcetTableRow from '../../components/TableRows/ZmluvnyUcetTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementEmptyContent from '../../components/ElementEmptyContent'
// utils
import { formatAddress, miestoSpotrebyAdresaDoplnokFormat } from '../../utils/address'
import { FILTER_SELECTORS } from '../../utils/enums'
import { withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'

const emptySelectState = {
	value: null,
	label: 'Všetky'
}

const queryParams = {
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	komoditnyTyp: 'komoditnyTyp',
	stavZmluvy: 'stavZmluvy',
	odberneMiesto: 'adresaIsuId'
}

const komoditnyTypFilterOptions = [
	{
		value: '2',
		label: 'GAS - Plyn'
	},
	{
		value: '1',
		label: 'EE - Elektrina'
	},
	{
		value: '10',
		label: 'Nekomoditné'
	}
]

const stavZmluvyFilterOptions = [
	{
		value: 'aktivne',
		label: 'Len aktívne'
	}
]

class ZmluvneUctyPage extends ReactQueryParams {
	static propTypes = {
		zmluvneUctyStore: PropTypes.shape({
			data: PropTypes.array.isRequired,
			isLoading: PropTypes.bool.isRequired,
			lastLoad: PropTypes.any.isRequired,
			isFailure: PropTypes.bool.isRequired
		}).isRequired,
		zmluvneUctyActions: PropTypes.shape({
			loadZmluvneUcty: PropTypes.func.isRequired
		}).isRequired,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				opCislo: PropTypes.string.isRequired
			})
		}).isRequired,
		t: PropTypes.func.isRequired
	}

	defaultQueryParams = {
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.stavZmluvy]: null,
		[queryParams.komoditnyTyp]: null,
		[queryParams.odberneMiesto]: null
	}

	state = {
		emptyElems: {}
	}

	_mounted = false

	componentDidMount = () => {
		this._mounted = true
		const { allDataLoading, allDataPrepared } = this.props.zmluvneUctyStore
		const { ciselnikySelectBoxActions } = this.props

		ciselnikySelectBoxActions.loadCiselnikMiestaSpotreby()
		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const selectedFilters = {
			...this.props.selectedFilters,
			...filteredParams
		}

		this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_UCTY_PAGE, selectedFilters)

		if (!allDataPrepared && !allDataLoading) {
			this.props.zmluvneUctyActions.loadZmluvneUcty()
		}
	}

	filterChanged = (filter, fieldName) => {
		// stringify value or set a default value null
		const newFilter = {
			...this.props.selectedFilters,
			[fieldName]: filter && filter.value ? `${filter.value}` : null
		}

		if (newFilter[fieldName] == this.props.selectedFilters[fieldName]) {
			return
		}

		// update filter state
		this.props.filtersActions.selectedFiltersChanged(FILTER_SELECTORS.ZMLUVNE_UCTY_PAGE, newFilter)

		if (fieldName !== 'zmluvnyUcetCislo') {
			if (this._mounted) {
				this.setState({
					emptyElems: {}
				})
			}
		}
	}

	componentDidUpdate = () => {
		if (!isEqual(this.queryParams, this.props.selectedFilters)) {
			this.setQueryParams(this.props.selectedFilters)
		}
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	emptyCallbackHandler = (zuCislo, isEmpty) => {
		if (this._mounted) {
			this.setState({
				emptyElems: {
					...this.state.emptyElems,
					[zuCislo]: isEmpty
				}
			})
		}
	}

	render = () => {
		const { ciselnikySelectBox, zmluvneUctyStore, selectedFilters, t } = this.props
		const { emptyElems } = this.state

		const { allDataPrepared, allData, allDataLoading } = zmluvneUctyStore
		const { stavZmluvy, komoditnyTyp, adresaIsuId, zmluvnyUcetCislo } = selectedFilters

		let odberneMiestaFilterOptions = []
		if (ciselnikySelectBox.miestaSpotreby.allDataPrepared) {
			// build data for select box filter odberne miesto
			odberneMiestaFilterOptions = map(get(ciselnikySelectBox, 'miestaSpotreby.data', []), (miestoSpotreby) => ({
				value: `${get(miestoSpotreby, 'adresa.idIsu')}`,
				label: `${formatAddress(get(miestoSpotreby, 'adresa'))}, ${miestoSpotrebyAdresaDoplnokFormat(miestoSpotreby)}`
			}))
		}

		let content = null
		if (allDataPrepared && !allData.length) {
			content = this.emptyContentElement()
		} else {
			const sortedZmluvneUcty = sortBy(allData, ['typ.id'])
			content = map(sortedZmluvneUcty, (zmluvnyUcet) => {
				if (zmluvnyUcetCislo) {
					if (zmluvnyUcet.cislo != zmluvnyUcetCislo) {
						return null
					}
				}
				if (get(emptyElems, zmluvnyUcet.cislo, false)) {
					return null
				}
				return (
					<ZmluvnyUcetTableRow
						key={`zu-${zmluvnyUcet.cislo}`}
						zmluvnyUcet={zmluvnyUcet}
						emptyCallbackHandler={this.emptyCallbackHandler}
						selectedFilters={{
							stavZmluvy,
							komoditnyTyp,
							adresaIsuId
						}}
					/>
				)
			})

			content = filter(content, (e) => e != null)
			if (!content.length) {
				content = this.emptyContentElement()
			}
		}

		let emptyContent = null
		if (allDataPrepared && isArray(content) && keys(emptyElems).length) {
			let areEmpty = true
			forEach(content, (element) => {
				if (get(emptyElems, element.props.cislo, false) == false) {
					areEmpty = false
					return false
				}
			})
			if (areEmpty) {
				emptyContent = this.emptyContentElement()
			}
		}

		const zmluvneUctyOptions = map(ciselnikySelectBox.zmluvneUcty.data, (zmluvnyUcet) => ({
			value: zmluvnyUcet.cislo,
			label: zmluvnyUcet.cislo
		}))

		const filters = {
			komoditnyTypFilterOptions: [emptySelectState, ...komoditnyTypFilterOptions],
			zmluvneUctyOptions: [emptySelectState, ...zmluvneUctyOptions],
			stavZmluvyFilterOptions: [emptySelectState, ...stavZmluvyFilterOptions],
			odberneMiestaFilterOptions: [emptySelectState, ...odberneMiestaFilterOptions]
		}

		return (
			<>
				<div className='content-header clearfix'>
					<div className='row'>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:ZmluvneUctyPage.Zmluvný účet')}</label>
								<Select
									value={find(filters.zmluvneUctyOptions, (element) => element.value == selectedFilters[queryParams.zmluvnyUcetCislo])}
									onChange={(zmluvnyUcetCislo) => this.filterChanged(zmluvnyUcetCislo, queryParams.zmluvnyUcetCislo)}
									options={filters.zmluvneUctyOptions}
									classNamePrefix='react-select'
									isLoading={ciselnikySelectBox.zmluvneUcty.isLoading}
									isDisabled={ciselnikySelectBox.zmluvneUcty.data.length === 0}
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:ZmluvneUctyPage.Miesto spotreby')}</label>
								<Select
									value={find(filters.odberneMiestaFilterOptions, (element) => element.value == selectedFilters[queryParams.odberneMiesto])}
									onChange={(odberneMiesto) => this.filterChanged(odberneMiesto, queryParams.odberneMiesto)}
									options={filters.odberneMiestaFilterOptions}
									classNamePrefix='react-select'
									isLoading={!ciselnikySelectBox.miestaSpotreby.allDataPrepared}
									isDisabled={!ciselnikySelectBox.miestaSpotreby.allDataPrepared}
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:ZmluvneUctyPage.Komoditný typ')}</label>
								<Select
									value={find(filters.komoditnyTypFilterOptions, (element) => element.value == selectedFilters[queryParams.komoditnyTyp])}
									onChange={(komoditnyTyp) => this.filterChanged(komoditnyTyp, queryParams.komoditnyTyp)}
									options={filters.komoditnyTypFilterOptions}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
						<div className='col-3'>
							<div className='select-wrapper'>
								<label htmlFor='partner-bank-accounts-account-filter'>{t('containers:ZmluvneUctyPage.Stav zmluvy')}</label>
								<Select
									value={find(filters.stavZmluvyFilterOptions, (element) => element.value == selectedFilters[queryParams.stavZmluvy])}
									onChange={(stavZmluvy) => this.filterChanged(stavZmluvy, queryParams.stavZmluvy)}
									options={filters.stavZmluvyFilterOptions}
									classNamePrefix='react-select'
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='content-wrapper'>
					{allDataPrepared && !allDataLoading && emptyContent}
					{allDataPrepared && content}
					{allDataLoading && (
						<div className='box' style={{ minHeight: '118px' }}>
							<ElementLoading />
						</div>
					)}
				</div>
			</>
		)
	}

	emptyContentElement = () => {
		const { t } = this.props
		return (
			<ElementEmptyContent
				text={t(
					'containers:ZmluvneUctyPage.Pre obchodného partnera neevidujeme žiadne zmluvné účty, alebo zadanému filtru nekorešponduje žiadny zmluvný účet'
				)}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	interakcia: get(state, 'interakcie.detail.data'),
	zmluvneUctyStore: get(state, 'obchodnyPartner.zmluvneUcty'),
	selectedFilters: get(state, `selectedFilters.${FILTER_SELECTORS.ZMLUVNE_UCTY_PAGE}`, {}),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox')
})

const mapDispatchToProps = (dispatch) => ({
	zmluvneUctyActions: bindActionCreators(ZmluvneUctyActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_OP_ZMLUVY])
)(ZmluvneUctyPage)
