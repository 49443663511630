import decode from 'jwt-decode'
import { filter, get, indexOf } from 'lodash'
import dayjs from 'dayjs'

import config from './config'

import { BUSINESS_CHANNELS } from './enums'

export const ACCESS_TOKEN_KEY = 'access_token'
export const LOGIN_URL = config.loginUrl

const AVAILABLE_BUSINESS_CHANNELS = [BUSINESS_CHANNELS.ZSE_CENTRUM, BUSINESS_CHANNELS.ZAKAZNICKA_LINKA, BUSINESS_CHANNELS.BACK_OFFICE]

export function filterAvailableBusinessChannels(channels) {
	return filter(channels, (channel) => {
		return indexOf(AVAILABLE_BUSINESS_CHANNELS, get(channel, 'id')) >= 0
	})
}

/**
 * Removes saved token from localstorage
 */
export function clearAccessToken() {
	localStorage.removeItem(ACCESS_TOKEN_KEY)
}

/**
 * Redirect user into login page
 */
export function login() {
	window.location.replace(LOGIN_URL)
}

/**
 * @return string token
 *
 * Returns acces token saved into storage or null
 */
export function getAccessToken() {
	return localStorage.getItem(ACCESS_TOKEN_KEY)
}

/**
 *
 * Save user token into localstorage
 * @param token
 */
export function setAccessToken(token) {
	localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

/**
 * @return boolean
 *
 * check if token exists and try to decode
 */
export function isLoggedIn() {
	try {
		const token = getAccessToken()
		if (!token) {
			return false
		}
		const payload = decode(token)
		const exp = get(payload, 'exp')
		if (!exp) {
			return false
		}
		const timestamp = dayjs().unix()
		// check if token is expired
		return timestamp <= exp
	} catch (error) {
		return false
	}
}
