import React from 'react'
import { touch, Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { get } from 'lodash'

// atoms
import { NumericInputField, DebounceFieldWrapper } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'
import { PREVADZKOVATEL_SIETE } from '../../../utils/enums'

// components
import ElektromobilBooleanField from './ElektromobilBooleanField'

class ElektromobilField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		ciselniky: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, field, form, editMode } = this.props

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			dispatch(touch(form, `${field}.instalovanyVykon`))
			dispatch(touch(form, `${field}.omIdentifikator`))
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {
		const { value, field, change, dispatch } = this.props

		if (get(value, 'instalovanyVykon') === 0) {
			dispatch(change(`${field}.instalovanyVykon`, null))
		}

		if (get(value, 'omIdentifikator') === null) {
			dispatch(change(field, null))
		}
	}

	validateInstalovanyVykon = (fieldValue, values) => {
		const { povinny, label, t } = this.props

		if (
			get(values, 'data.elektromobil.omIdentifikator') &&
			get(values, 'data.odberneMiesto.prevadzkovatelSiete.id') === PREVADZKOVATEL_SIETE.ZSE &&
			povinny
		) {
			if (fieldValue === null || fieldValue === undefined || fieldValue <= 0) {
				return t('translation:Common.validate.Výkon nabíjacej stanice musí byť vyšší ako 0', { label })
			}
		}
	}

	debounceNumericInput = DebounceFieldWrapper(NumericInputField, 100)

	formatValueInstalovanyVykon = (num) => {
		return num !== null && num !== undefined ? `${num.toString().split('.').join(',')} kW` : ''
	}

	customParseInstalovanyVykon = (stringValue) => {
		const val = stringValue.toString().split(',').join('.').replace(/\skW/, '')
		return parseInt(val)
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props

		let instalovanyVykonValueBefore = (
			<NumericFormat
				thousandSeparator={' '}
				decimalScale={0}
				displayType={'text'}
				suffix={' kW'}
				value={get(originalValue, 'instalovanyVykon')}
				defaultValue={''}
			/>
		)
		let instalovanyVykonValueAfter = null

		if (editMode === EDIT_MODE.EDIT || editMode === EDIT_MODE.LOOKUP) {
			/* Výška záloh */
			instalovanyVykonValueAfter = (
				<Field
					name={`${field}.instalovanyVykon`}
					component={this.debounceNumericInput}
					strict
					placeholder={this.formatValueInstalovanyVykon(get(originalValue, 'instalovanyVykon'))}
					validate={this.validateInstalovanyVykon}
					formatValue={(val) => (val > 0 ? this.formatValueInstalovanyVykon(val) : '')}
					customParse={this.customParseInstalovanyVykon}
					precision={0}
					disabled={get(value, 'omIdentifikator') === undefined}
				/>
			)
		} else if (editMode === EDIT_MODE.CONFIRM || editMode === EDIT_MODE.DETAIL) {
			/* Výkon nabíjacej stanice */
			const instalovanyVykonFormatted = (
				<NumericFormat
					thousandSeparator={' '}
					decimalScale={0}
					displayType={'text'}
					suffix={' kW'}
					value={get(value, 'instalovanyVykon')}
					defaultValue={''}
				/>
			)
			instalovanyVykonValueAfter =
				columnsCount === COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'instalovanyVykon'), get(originalValue, 'instalovanyVykon'), instalovanyVykonFormatted)
					: instalovanyVykonFormatted
		}

		const NabijaciaStanicaBooleanField = React.createElement(ElektromobilBooleanField, {
			...this.props,
			field: `${field}.omIdentifikator`,
			label: t('translation:Common.Nabíjacia stanica pre elektromobil'),
			nested: true
		})

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						{NabijaciaStanicaBooleanField}
						<tr>
							<td>
								<strong>{t('translation:Common.Inštalovaný výkon nabíjacej stanice')}</strong>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && <td>{instalovanyVykonValueBefore}</td>}
							<td>{instalovanyVykonValueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapDispatchToProps))(ElektromobilField)
