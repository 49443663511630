import { get, isEmpty } from 'lodash'
import { getAccessToken } from './auth'
import config from './config'
import { getReq } from './request'

/**
 * @param {File} file input
 * @return {Promise} base64
 */
export const getBase64FromFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

export const openDataUriWindow = (id, title) => {
	const accessToken = getAccessToken()

	if (isEmpty(id)) {
		return
	}

	if (id.indexOf('data:') === 0) {
		// much easier if not IE
		const [header] = id.split(';')
		const isImageOrPdf = /image|pdf/.test(header)

		if (isImageOrPdf) {
			// Extract the actual Base64 content by removing the `data:application/pdf;base64,` prefix
			const base64string = id.split(',')[1] // Removes the prefix up to the comma

			// Decode Base64 to binary data
			const binaryData = atob(base64string)

			// Create a Uint8Array for the binary data
			const len = binaryData.length
			const uint8Array = new Uint8Array(len)
			for (let i = 0; i < len; i++) {
				uint8Array[i] = binaryData.charCodeAt(i)
			}

			// Create a Blob with the PDF MIME type
			const blob = new Blob([uint8Array], { type: 'application/pdf' })

			// Create an object URL from the Blob
			const blobURL = URL.createObjectURL(blob)

			// Open the URL in a new browser tab
			window.open(blobURL)
		} else {
			const a = document.createElement('a')
			a.href = id
			a.target = '_blank'
			a.download = title
			document.body.appendChild(a)
			a.click()
		}
	} else {
		const a = document.createElement('a')
		a.href = `${get(config, 'baseUrl')}/api/v0/dokumenty/${id}?accessToken=${accessToken}`
		a.target = '_blank'
		document.body.appendChild(a)
		a.click()
	}
}

export const openLiveAgentTicketPreview = (id) => {
	const accessToken = getAccessToken()

	const a = document.createElement('a')
	a.href = `${get(config, 'baseUrl')}/api/v2/tickety/${id}?accessToken=${accessToken}`
	a.target = '_blank'
	document.body.appendChild(a)
	a.click()
}

export const downloadPdfDocument = async (dokumentId, ukonId) => {
	if (!dokumentId && !ukonId) {
		return
	}

	try {
		const { response } = await getReq(`${get(config, 'baseUrl')}/api/v2/ukony/${ukonId}/dokumenty/${dokumentId}`)
		return response?.content?.data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error downloading the document:', error.message)
		throw error
	}
}
