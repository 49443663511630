import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { get, forEach, max, find, head, split } from 'lodash'
import { withTranslation } from 'react-i18next'

import WidgetBox from '../WidgetBox'
import ElementLoading from '../ElementLoading'
import * as WidgetyActions from '../../actions/WidgetyActions'

// utils
import { checkPermissions } from '../../utils/permissionsHoc'
import widgets from '../../utils/widgets'

class WidgetModal extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		widgety: PropTypes.shape(),
		widgetyActions: PropTypes.shape(),
		shown: PropTypes.bool.isRequired,
		toggleWidgetModal: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		const { widgetyActions } = this.props

		widgetyActions.loadWidgetSettings()
	}

	setDefaultWidgetSettings = () => {
		const { updateDefaultWidgetSettings } = this.props.widgetyActions

		this.setState({ resetStatus: 'PROCESSING' })
		updateDefaultWidgetSettings()
			.then(() => {
				this.setState({ resetStatus: 'PROCESSED' })
				/* setTimeout(() => this.setState({ resetStatus: null }), 3000) */
			})
			.catch(() => this.setState({ resetStatus: null }))
	}

	addWidgetBoxHandler = (boxId) => {
		const { widgety, widgetyActions } = this.props
		const sorting = get(widgety, 'data.sorting', [])

		const ids = []
		forEach(sorting, (item) => {
			const regex = new RegExp(`${boxId}`, 'g')
			if (regex.test(item.id)) {
				const getBoxIdCount = item.id.split('-')
				// if getBoxIdCount[1] return count 1 as starting counter, point legacy compatibility
				ids.push(getBoxIdCount[1] || 1)
			}
		})
		const lastId = max(ids)
		const newId = lastId ? lastId + 1 : 2
		const newBoxId = `${boxId}-${newId}`

		this.setState({ resetStatus: null })

		// NOTE: change order of new added widget due this bug https://jira.zsee.sk/jira/browse/CP-2783
		return widgetyActions.updateWidgetSettings({ sorting: [...sorting, { id: newBoxId }] })
	}

	toggleWidgetModal = (state) => {
		this.props.toggleWidgetModal(state)
		this.setState({ resetStatus: null })
	}

	render() {
		const { shown, t, auth, widgety } = this.props

		return (
			<div className={cx('modal', 'dismissible', { shown })} data-size='m'>
				<div className='modal-wrapper'>
					<div className='modal-header clearfix' style={{ paddingTop: '10px' }}>
						<button className='button pull-left' data-type='back-button' data-color='blue' onClick={() => this.toggleWidgetModal(false)}>
							{t('components:Späť')}
						</button>
						<h3 className='pull-left' style={{ marginTop: '18px' }}>
							{t('components:Widgety.Správa widgetov')}
						</h3>
						{!this.state.resetStatus && (
							<button className='button pull-right' data-type='outline' data-color='blue' onClick={this.setDefaultWidgetSettings}>
								{t('components:Widgety.Pôvodné nastavenia widgetov')}
							</button>
						)}
						{this.state.resetStatus == 'PROCESSING' && <ElementLoading />}
						{this.state.resetStatus == 'PROCESSED' && (
							<button className='button pull-right' data-color='green'>
								{t('components:Widgety.Pôvodné nastavenia boli obnovené')}
							</button>
						)}
					</div>
					<div className='modal-content'>
						<div className='row'>
							{widgets.map((value, index) => {
								const hasPermission = !value.permission || checkPermissions(get(auth, 'user.roles', []), [value.permission])
								return (
									<WidgetBox
										key={`widget-box-${index}`}
										title={value.title}
										attributes={value.attributes}
										isStatic={value.static}
										availableQuantity={value.availableQuantity}
										assignedQuantity={value.assignedQuantity}
										alreadyAdded={!!find(get(widgety, 'data.sorting', []), (widget) => head(split(get(widget, 'id'), '-')) == value.id)}
										modifiable={value.modifiable}
										addWidgetBoxHandler={() => this.addWidgetBoxHandler(value.id)}
										hasPermission={hasPermission}
									/>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	widgety: state.widgety
})

const mapDispatchToProps = (dispatch) => ({
	widgetyActions: bindActionCreators(WidgetyActions, dispatch)
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(WidgetModal)
