import React from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

// atoms
import { size, get, startsWith } from 'lodash'
import { TextInputField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'
import { PREUKAZ_TYP } from '../../../utils/enums'

class PreukazCisloField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		form: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape(),
		formValues: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (fieldValue) => {
		const { povinny, formValues, t } = this.props

		if (povinny) {
			if (get(formValues, 'data.preukazTyp.id') == PREUKAZ_TYP.ISIC) {
				if (!startsWith(fieldValue, 'S421')) {
					return t('translation:Common.validate.Nesprávne zadané číslo preukazu')
				}
				if (size(fieldValue) != 14) {
					return t('translation:Common.validate.Hodnota musí obsahovať práve 14 znakov')
				}
			} else if (get(formValues, 'data.preukazTyp.id') == PREUKAZ_TYP.ITIC) {
				if (!startsWith(fieldValue, 'T421')) {
					return t('translation:Common.validate.Nesprávne zadané číslo preukazu')
				}
				if (size(fieldValue) != 14) {
					return t('translation:Common.validate.Hodnota musí obsahovať práve 14 znakov')
				}
			} else if (get(formValues, 'data.preukazTyp.id') == PREUKAZ_TYP.EURO26) {
				if (!startsWith(fieldValue, 'E421')) {
					return t('translation:Common.validate.Nesprávne zadané číslo preukazu')
				}
				if (size(fieldValue) != 11) {
					return t('translation:Common.validate.Hodnota musí obsahovať práve 11 znakov')
				}
			} else if (!fieldValue) {
				return t('translation:Common.validate.Číslo preukazu je povinné')
			}
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props

		const valueBefore = originalValue
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = <Field name={field} component={TextInputField} validate={this.validate} />
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = columnsCount == COLUMNS_COUNT.THREE ? formatFormValue(value, originalValue) : value
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Číslo preukazu')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(PreukazCisloField)
