import React from 'react'
import { compose, bindActionCreators } from 'redux'
import {
	get,
	pick,
	keys,
	indexOf,
	values,
	isEqual,
	forEach,
	deburr,
	split,
	filter,
	includes,
	orderBy,
	map,
	isEmpty,
	upperFirst,
	keyBy,
	concat,
	find,
	difference,
	some,
	head
} from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import cx from 'classnames'
import reactQueryParams from 'react-query-params'
import { NumericFormat } from 'react-number-format'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { Tooltip } from 'react-tippy'

// actions
import ObchodnyPartnerActions from '../../actions/ObchodniPartneri'
import * as FiltersActions from '../../actions/SelectedFiltersActions'
import * as CiselnikySelectBoxActions from '../../actions/CiselnikySelectBoxActions'
import * as OtvoreneFakturyActions from '../../actions/ObchodniPartneri/OtvoreneFakturyActions'

// atoms
import Select from '../../atoms/BasicSelect'
import StickyTableHeader from '../../atoms/StickyTableHeader'
import DatePickerField from '../../atoms/DatePickerField'

// components
import StavUctuTableRow from '../../components/TableRows/StavUctuTableRow'
import ElementLoading from '../../components/ElementLoading'
import ElementFailure from '../../components/ElementFailure'
import ElementEmptyContent from '../../components/ElementEmptyContent'
import StavUctuSearch from '../../components/Modals/StavUctuSearch'

// utils
import { FILTER_SELECTORS, POHLADAVKA_TYP, POHLADAVKA_CUSTOM_TYPE } from '../../utils/enums'
import Permissions, { checkPermissions, withPermissions, PERMISSIONS } from '../../utils/permissionsHoc'
import extractProperties from '../../utils/extractProperties'
import { formatDate } from '../../utils/date'
import { setRouteParams, POHLADAVKY_DETAIL, PREPLATKY_ZOZNAM, BLOKOVANIE_UPOMINANIA_ZOZNAM, POHLADAVKY_ZOZNAM } from '../../utils/routes'
import { history } from '../../utils/history'

const Scroll = require('react-scroll')

const { scroller } = Scroll

const queryParams = {
	typ: 'typ',
	triedenie: 'triedenie',
	zmluvnyUcetCislo: 'zmluvnyUcetCislo',
	vyrovnanieStatus: 'vyrovnanieStatus',
	datumOd: 'datumOd',
	datumDo: 'datumDo',
	variabilnySymbol: 'variabilnySymbol'
}

const emptySelectState = {
	value: null,
	label: 'Všetky'
}

const emptyMultiSelectState = {
	value: null,
	label: 'Všetky'
}

const initFilters = {
	[queryParams.typ]: null,
	[queryParams.triedenie]: 'dokladDatum:desc',
	[queryParams.vyrovnanieStatus]: null,
	[queryParams.zmluvnyUcetCislo]: null,
	[queryParams.datumOd]: null,
	[queryParams.datumDo]: null,
	[queryParams.variabilnySymbol]: null
}

class StavUctuDetailPage extends reactQueryParams {
	static propTypes = {
		computedMatch: PropTypes.shape({
			params: PropTypes.shape({
				idPohladavky: PropTypes.string.isRequired
			}).isRequired
		}).isRequired,
		interakcia: PropTypes.shape().isRequired,
		selectedFilters: PropTypes.shape(),
		pohladavky: PropTypes.shape().isRequired,
		obchodnyPartnerActions: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		auth: PropTypes.shape().isRequired
	}

	defaultQueryParams = {
		[queryParams.typ]: null,
		[queryParams.triedenie]: null,
		[queryParams.zmluvnyUcetCislo]: null,
		[queryParams.vyrovnanieStatus]: null,
		[queryParams.datumOd]: null,
		[queryParams.datumDo]: null,
		[queryParams.variabilnySymbol]: null,
		backUrl: ''
	}

	_mounted = false

	constructor(props) {
		super(props)

		const { search, backUrl } = qs.parse(window.location.search, {
			ignoreQueryPrefix: true
		})
		const { idPohladavky } = props.computedMatch.params

		let backUrlLink
		if (backUrl) {
			backUrlLink = atob(backUrl)
		}

		// NOTE: clear backUrl query param from url just esthetic reason, do not use history.replace or similar clearing cause NavigationPrompt issue
		this.setQueryParams({
			backUrl: ''
		})

		this.state = {
			isSearch: !!search,
			searchTerm: decodeURIComponent(idPohladavky),
			typPolozkyFilterOptions: [],
			vyrovnanieStatusOptions: [],
			backUrlLink
		}
	}

	componentDidMount = () => {
		this._mounted = true

		const { idPohladavky } = this.props.computedMatch.params

		const { obchodnyPartnerActions, auth, selectedFilters, filtersActions, ciselniky, ciselnikySelectBoxActions, otvoreneFakturyActions } = this.props

		const typPolozkyCiselnikOptions = keyBy(get(ciselniky, 'pohladavkaTyp', []), 'id')
		const typPolozkyFilterOptionsAll = map(values(POHLADAVKA_TYP), (typ) => {
			return {
				...typ,
				label: typ.label || get(typPolozkyCiselnikOptions, `${typ.value}.nazov`)
			}
		})
		const typPolozkyFilterOptionsStandard = filter(typPolozkyFilterOptionsAll, (typ) => !includes(values(POHLADAVKA_CUSTOM_TYPE), typ.value))
		const newlyAddedTypPolozky = map(
			filter(
				typPolozkyCiselnikOptions,
				(typ) =>
					!find(typPolozkyFilterOptionsStandard, {
						value: typ.id
					})
			),
			(typ) => ({
				value: typ.id,
				label: typ.nazov
			})
		)
		const typPolozkyFilterOptions = concat(
			orderBy(concat(typPolozkyFilterOptionsStandard, newlyAddedTypPolozky), [(typ) => deburr(typ.label)], ['asc']),
			filter(typPolozkyFilterOptionsAll, (typ) => includes(values(POHLADAVKA_CUSTOM_TYPE), typ.value))
		)

		const vyrovnanieStatusOptions = map(get(ciselniky, 'vyrovnanieStatus', []), (vyrovnanieStatus) => ({
			value: get(vyrovnanieStatus, 'id'),
			label: upperFirst(get(vyrovnanieStatus, 'nazov'))
		}))

		this.setState({
			typPolozkyFilterOptions,
			vyrovnanieStatusOptions
		})

		const filteredParams = pick(
			this.queryParams,
			keys(this.queryParams).filter((key) => {
				return indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])
			})
		)

		const filters = {
			...selectedFilters,
			...filteredParams
		}

		ciselnikySelectBoxActions.loadCiselnikZmluvneUcty()
		if (checkPermissions(get(auth, 'user.roles', []), [PERMISSIONS.VIEW_PLAN_SPLATOK])) {
			otvoreneFakturyActions.searchFaktury()
		}

		if (!get(location, 'state.fromDetail')) {
			obchodnyPartnerActions.loadPohladavkaDetail(decodeURIComponent(idPohladavky), filters, get(ciselniky, 'pohladavkaTyp', []))
			obchodnyPartnerActions.loadPohladavkyZostatok(filters[queryParams.zmluvnyUcetCislo])
		} else if (get(location, 'state.pohladavkaID')) {
			let identifier = get(location, 'state.pohladavkaID')
			if (get(location, 'state.pohladavkaTyp')) {
				identifier += `_${get(location, 'state.pohladavkaTyp')}`
			}
			const elmnts = document.getElementsByClassName(identifier)
			if (!isEmpty(elmnts)) {
				scroller.scrollTo(get(head(elmnts), 'id'), {
					delay: 1000,
					isDynamic: true,
					smooth: true,
					containerId: 'scroll-pohladavky-container',
					offset: 0
				})
			}
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, filters)
	}

	componentWillUnmount = () => {
		const { filtersActions } = this.props

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, initFilters)
	}

	multiFilterAdd = (filters, fieldName) => {
		const { selectedFilters } = this.props

		if (!get(filters, '[0].value')) {
			return this.clearFilters(fieldName)
		}

		const filterArray = map(filters, (filter) => get(filter, 'value'))
		const oldFilters = split(selectedFilters[fieldName], ',')
		if (
			includes(filterArray, POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY) ||
			includes(filterArray, POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB) ||
			includes(oldFilters, POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY) ||
			includes(oldFilters, POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB)
		) {
			this.filterChanged({ value: filterArray }, fieldName)
			return
		}
		const value = [...oldFilters, ...filterArray].filter((e) => e !== null && e !== '' && e !== '[]').join(',')
		this.filterChanged({ value }, fieldName)
	}

	filterChanged = (filter, fieldName) => {
		const { obchodnyPartnerActions, filtersActions, selectedFilters, ciselniky } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[fieldName]: get(filter, 'value') ? `${filter.value}` : null
		}
		if (isEqual(newFilter[fieldName], selectedFilters[fieldName])) {
			return
		}

		obchodnyPartnerActions.loadPohladavkaDetail(decodeURIComponent(idPohladavky), newFilter, get(ciselniky, 'pohladavkaTyp', []))
		if (fieldName === queryParams.zuCislo) {
			obchodnyPartnerActions.loadPohladavkyZostatok(newFilter[queryParams.zmluvnyUcetCislo])
		}
		// update filter state
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, newFilter)
	}

	componentDidUpdate = () => {
		const { selectedFilters } = this.props

		// prevent to inject not allowed query param
		const query = extractProperties(this.queryParams, values(queryParams))

		if (!isEqual(selectedFilters, query)) {
			this.setQueryParams({
				...selectedFilters
			})
		}
	}

	sortingColumn = (stringFormat) => {
		const { obchodnyPartnerActions, filtersActions, selectedFilters, ciselniky } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		// stringify value or set a default value null
		const newFilter = {
			...selectedFilters,
			[queryParams.triedenie]: stringFormat || initFilters.triedenie
		}
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, newFilter)
		obchodnyPartnerActions.loadPohladavkaDetail(decodeURIComponent(idPohladavky), newFilter, get(ciselniky, 'pohladavkaTyp', []))

		return newFilter[queryParams.triedenie]
	}

	clearFilters = (fieldName) => {
		const { filtersActions, obchodnyPartnerActions, selectedFilters, ciselniky } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		let filter
		if (fieldName) {
			filter = {
				...selectedFilters
			}
			filter[fieldName] = initFilters[fieldName]
		} else {
			filter = {
				...initFilters,
				triedenie: selectedFilters.triedenie
			}
		}
		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, filter)
		obchodnyPartnerActions.loadPohladavkaDetail(decodeURIComponent(idPohladavky), filter, get(ciselniky, 'pohladavkaTyp', []))
		if (fieldName === queryParams.zmluvnyUcetCislo) {
			obchodnyPartnerActions.loadPohladavkyZostatok()
		}
	}

	clearFilter = (value, type) => {
		const { selectedFilters, filtersActions, obchodnyPartnerActions, ciselniky } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		let val
		if (type === queryParams.zmluvnyUcetCislo) {
			val = null
		} else {
			const filter = split(selectedFilters[type], ',').filter((item) => item != value) // do not check type only value
			val = !isEmpty(filter) ? filter.join(',') : null
		}

		const newFilter = {
			...selectedFilters,
			[type]: val
		}

		filtersActions.selectedFiltersChanged(FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, newFilter)
		obchodnyPartnerActions.loadPohladavkaDetail(decodeURIComponent(idPohladavky), newFilter, get(ciselniky, 'pohladavkaTyp', []))
		if (type === queryParams.zmluvnyUcetCislo) {
			obchodnyPartnerActions.loadPohladavkyZostatok()
		}
	}

	failureContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementFailure text={t('translation:StavUctu.Nepodarilo sa načítať detail pohľadávky')} />
			</div>
		)
	}

	emptyContentElement = () => {
		const { t } = this.props

		return this.commonContentContainer(
			<div className='content-wrapper'>
				<ElementEmptyContent text={t('translation:StavUctu.Pre pohľadávku obchodného partnera neevidujeme žiadne ďalšie súvisiace pohľadávky')} />
			</div>
		)
	}

	commonContentContainer = (content) => {
		const { t, auth, interakcia, ciselnikySelectBox, selectedFilters, pohladavkyZostatok, obchodnyPartnerActions, ciselniky } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		const { searchTerm, showSearchModal, isSearch, typPolozkyFilterOptions, vyrovnanieStatusOptions, backUrlLink } = this.state

		const zmluvnyUcetCisloFilterOptions = map(get(ciselnikySelectBox, 'zmluvneUcty.data', []), (zmluvnyUcet) => ({
			value: zmluvnyUcet.cislo,
			label: zmluvnyUcet.cislo
		}))

		const filters = {
			typPolozkyFilterOptions: [emptyMultiSelectState, ...typPolozkyFilterOptions],
			zmluvnyUcetCisloFilterOptions: [emptySelectState, ...zmluvnyUcetCisloFilterOptions],
			vyrovnanieStatusOptions: [emptySelectState, ...vyrovnanieStatusOptions]
		}

		const selectedFiltersValues = {
			[queryParams.typ]: filter(filters.typPolozkyFilterOptions, (el) => includes(split(selectedFilters[queryParams.typ], ','), `${el.value}`)),
			[queryParams.zmluvnyUcetCislo]: filter(filters.zmluvnyUcetCisloFilterOptions, (el) =>
				includes(selectedFilters[queryParams.zmluvnyUcetCislo], el.value)
			),
			[queryParams.vyrovnanieStatus]: filter(filters.vyrovnanieStatusOptions, (el) => includes(selectedFilters[queryParams.vyrovnanieStatus], el.value)),
			[queryParams.datumOd]: selectedFilters[queryParams.datumOd],
			[queryParams.datumDo]: selectedFilters[queryParams.datumDo],
			[queryParams.variabilnySymbol]: selectedFilters[queryParams.variabilnySymbol]
		}

		const filtersOptions = {
			[queryParams.typ]: filter(
				filters.typPolozkyFilterOptions,
				(el) => difference(split(el.value, ','), split(selectedFilters[queryParams.typ], ',')).length > 0
			),
			[queryParams.zmluvnyUcetCislo]: filter(
				filters.zmluvnyUcetCisloFilterOptions,
				(el) => !includes(selectedFilters[queryParams.zmluvnyUcetCislo], el.value)
			),
			[queryParams.vyrovnanieStatus]: filter(filters.vyrovnanieStatusOptions, (el) => !includes(selectedFilters[queryParams.vyrovnanieStatus], el.value))
		}

		const columns = [
			{
				title: t('translation:Common.Typ'),
				name: 'typ'
			},
			{
				title: t('translation:StavUctu.Číslo dokladu'),
				name: 'cislo',
				width: '110px'
			},
			{
				title: t('translation:Common.Variabilný symbol'),
				name: 'variabilnySymbol',
				width: '110px'
			},
			{
				title: t('translation:Common.Zmluvný účet'),
				name: 'zuCislo',
				width: '110px'
			},
			{
				title: t('translation:Common.Dátum'),
				name: 'dokladDatum',
				width: '110px',
				tooltip: t('translation:StavUctu.Vystavená / prijatá / odoslaná')
			},
			{
				title: t('translation:StavUctu.Splatnosť'),
				name: 'splatnostDatum',
				width: '110px'
			},
			{
				title: t('translation:Common.Suma'),
				name: 'suma',
				width: '110px'
			},
			{
				title: t('translation:StavUctu.Otvorená suma'),
				name: 'sumaOtvorena',
				width: '110px'
			},
			{
				title: t('translation:StavUctu.Vyrovnávané doklady'),
				name: 'suvisiaceDoklady'
			},
			{
				title: t('translation:Common.Info'),
				notActive: true,
				width: '80px'
			},
			{
				title: t('translation:Common.Stav'),
				notActive: true,
				width: '80px'
			},
			{
				notActive: true,
				width: '52px'
			}
		]

		const filterItems = []
		forEach(selectedFiltersValues, (values, filter) => {
			let color = 'blue'
			switch (filter) {
				case queryParams.typ:
					color = 'green'
					break
				case queryParams.zmluvnyUcetCislo:
					color = 'blue'
					break
				case queryParams.vyrovnanieStatus:
					color = 'purple'
			}

			forEach(values, (value, index) => {
				// prevent to push Empty state
				if (
					get(value, 'value') &&
					get(value, 'value') != POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY &&
					get(value, 'value') != POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB
				) {
					filterItems.push(
						<div
							key={`${filter}-${index}`}
							className='filter-item'
							data-color={color}
							onClick={() => this.clearFilter(get(value, 'value'), filter)}
							style={{ cursor: 'pointer' }}
						>
							<span className='filter-text'>{get(value, 'label')}</span>
							<span className='remove' />
						</div>
					)
				}
			})

			if (filter == queryParams.variabilnySymbol && values) {
				filterItems.push(
					<div
						key={`${filter}`}
						className='filter-item'
						data-color={color}
						onClick={() => this.clearFilter(values, filter)}
						style={{ cursor: 'pointer' }}
					>
						<span className='filter-text'>{values}</span>
						<span className='remove' />
					</div>
				)
			}
		})

		const isActiveZUFilter = !isEmpty(get(selectedFiltersValues, `[${queryParams.zmluvnyUcetCislo}]`))
		let zuCisloPlaceholder = t('translation:Common.filter.Všetky')
		if (isActiveZUFilter) {
			zuCisloPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${get(selectedFiltersValues, `[${queryParams.zmluvnyUcetCislo}].length`)})`
		}

		const isActiveTypFilter = !isEmpty(get(selectedFiltersValues, `[${queryParams.typ}]`))
		let typPolozkyPlaceholder = t('translation:Common.filter.Všetky')
		if (isActiveTypFilter) {
			if (find(get(selectedFiltersValues, `[${queryParams.typ}]`), { value: POHLADAVKA_CUSTOM_TYPE.LEN_PLATBY })) {
				typPolozkyPlaceholder = POHLADAVKA_TYP.LEN_PLATBY.label
			} else if (find(get(selectedFiltersValues, `[${queryParams.typ}]`), { value: POHLADAVKA_CUSTOM_TYPE.OKREM_PLATIEB })) {
				typPolozkyPlaceholder = POHLADAVKA_TYP.OKREM_PLATIEB.label
			} else {
				typPolozkyPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${get(selectedFiltersValues, `[${queryParams.typ}].length`)})`
			}
		}

		const isActiveVyrovnanieStatus = !isEmpty(get(selectedFiltersValues, `[${queryParams.vyrovnanieStatus}]`))
		let vyrovnanieStatusPlaceholder = t('translation:Common.filter.Všetky')
		if (isActiveVyrovnanieStatus) {
			vyrovnanieStatusPlaceholder = `${t('translation:Common.filter.Aktívne filtre')} (${get(
				selectedFiltersValues,
				`[${queryParams.vyrovnanieStatus}].length`
			)})`
		}

		const rychlyFilterBtnText = isSearch ? t('translation:StavUctu.Vyhľadávanie', { searchTerm }) : t('translation:StavUctu.Rýchly filter')

		const backUrl = btoa(`${window.location.pathname}${window.location.search}`)

		return (
			<>
				<div className='content-header' style={{ boxShadow: 'none' }}>
					<div className='row' style={{ borderBottom: '1px solid #DFDFDF', paddingBottom: '10px' }}>
						<div className='col-4'>
							{backUrlLink && (
								<Link to={backUrlLink} className='button pull-left' data-type='back-button' data-color='blue' style={{ marginTop: '20px' }}>
									{t('translation:Common.Späť')}
								</Link>
							)}
							<h2 style={{ display: 'inline-block', marginBottom: '0' }}>{t('translation:StavUctu.Celkový stav účtu')}</h2>
							<br />
							<small>
								{t('translation:StavUctu.Aktualizovaný k ')} {formatDate(new Date(), '-', 'DD.MM.YYYY')}{' '}
								{dayjs().subtract(get(auth, 'config.stavUctuUpdateOffset'), 'minute').format('H:mm')}
							</small>
						</div>
						<div className='col-2 header-amount'>
							<div style={{ display: 'inline-block' }}>
								<span>{t('translation:StavUctu.Preplatky')}</span>
								<br />
								<strong>
									<NumericFormat
										thousandSeparator={' '}
										decimalSeparator={','}
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										suffix=' €'
										value={get(pohladavkyZostatok, 'data.sumaPreplatky')}
									/>
								</strong>
							</div>
							<div className='pull-right'>
								<Permissions
									allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
									render={(hasPerm, actions) => {
										const canPerformAction =
											get(pohladavkyZostatok, 'data.sumaPreplatky') < 0 &&
											(selectedFilters[queryParams.zmluvnyUcetCislo] || get(zmluvnyUcetCisloFilterOptions, 'length') == 1)
										const actionBtn = (
											<Link
												to={`${setRouteParams(PREPLATKY_ZOZNAM, get(interakcia, 'opCislo'))}?zmluvnyUcetCislo=${
													selectedFilters[queryParams.zmluvnyUcetCislo] || get(head(zmluvnyUcetCisloFilterOptions), 'value')
												}&backUrl=${backUrl}`}
												className={cx('button', 'pull-right', { disabled: !hasPerm || !canPerformAction })}
												style={{ marginTop: '0px', marginLeft: '20px' }}
												type='button'
												data-color='blue'
												onClick={(e) => {
													if (!hasPerm || !canPerformAction) {
														e.preventDefault()
														if (!hasPerm) {
															actions.openForbiddenModal()
														}
													}
												}}
											>
												{t('translation:Preplatky.Vrátiť')}
											</Link>
										)

										let tooltipText
										if (!hasPerm) {
											tooltipText = <span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>
										} else if (get(pohladavkyZostatok, 'data.sumaPreplatky') >= 0) {
											tooltipText = <span>{t('translation:Preplatky.Neexistuje žiadny preplatok na vrátenie')}</span>
										} else if (!selectedFilters[queryParams.zmluvnyUcetCislo] && get(zmluvnyUcetCisloFilterOptions, 'length') != 1) {
											tooltipText = <span>{t('translation:Preplatky.Vyberte konkrétny zmluvný účet cez filter')}</span>
										}
										return tooltipText ? (
											<Tooltip html={tooltipText} position='left' trigger='mouseenter' theme='light'>
												{actionBtn}
											</Tooltip>
										) : (
											actionBtn
										)
									}}
								/>
							</div>
						</div>
						<div className='col-2 header-amount'>
							<Tooltip
								html={
									<span>
										{t(
											'translation:Common.Súhrn všetkých platieb v splatnosti, po splatnosti a očakávaných platieb do konca splátkového kalendára'
										)}
									</span>
								}
								position='top'
								trigger='mouseenter'
								theme='light'
							>
								<span style={{ paddingLeft: '20px' }}>{t('translation:StavUctu.Očakávané platby')}</span>
								<br />
								<strong data-text-color='red'>
									<NumericFormat
										thousandSeparator={' '}
										decimalSeparator={','}
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										suffix=' €'
										value={get(pohladavkyZostatok, 'data.sumaNedoplatky')}
									/>
								</strong>
							</Tooltip>
						</div>
						<div className='col-4 header-amount'>
							<div style={{ display: 'inline-block' }}>
								<span style={{ paddingLeft: '20px' }}>{t('translation:StavUctu.Nedoplatky (po splatnosti)')}</span>
								<br />
								<strong data-text-color='red'>
									<NumericFormat
										thousandSeparator={' '}
										decimalSeparator={','}
										decimalScale={2}
										fixedDecimalScale={true}
										displayType='text'
										suffix=' €'
										value={get(pohladavkyZostatok, 'data.sumaNedoplatkyPoSplatnosti')}
									/>
								</strong>
							</div>
							<div className='pull-right'>
								<Permissions
									allowed={[PERMISSIONS.VIEW_POHLADAVKY]}
									render={(hasPerm, actions) => {
										const canPerformAction =
											get(pohladavkyZostatok, 'data.sumaNedoplatkyPoSplatnosti') > 0 &&
											(selectedFilters[queryParams.zmluvnyUcetCislo] || get(zmluvnyUcetCisloFilterOptions, 'length') == 1)
										const actionBtn = (
											<Link
												to={`${setRouteParams(BLOKOVANIE_UPOMINANIA_ZOZNAM, get(interakcia, 'opCislo'))}?zmluvnyUcetCislo=${
													selectedFilters[queryParams.zmluvnyUcetCislo] || get(head(zmluvnyUcetCisloFilterOptions), 'value')
												}&vyrovnanieStatus=1&backUrl=${backUrl}`}
												className={cx('button', 'pull-right', { disabled: !hasPerm || !canPerformAction })}
												style={{ marginTop: '0px', marginLeft: '20px' }}
												type='button'
												data-color='blue'
												onClick={(e) => {
													if (!hasPerm || !canPerformAction) {
														e.preventDefault()
														if (!hasPerm) {
															actions.openForbiddenModal()
														}
													}
												}}
											>
												{t('translation:BlokovanieUpominania.Blokovať')}
											</Link>
										)

										let tooltipText
										if (!hasPerm) {
											tooltipText = <span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>
										} else if (get(pohladavkyZostatok, 'data.sumaNedoplatkyPoSplatnosti') <= 0) {
											tooltipText = <span>{t('translation:BlokovanieUpominania.Filtru nevyhovuje žiadna otvorená pohľadávka')}</span>
										} else if (!selectedFilters[queryParams.zmluvnyUcetCislo] && get(zmluvnyUcetCisloFilterOptions, 'length') != 1) {
											tooltipText = <span>{t('translation:BlokovanieUpominania.Vyberte konkrétny zmluvný účet cez filter')}</span>
										}
										return tooltipText ? (
											<Tooltip html={tooltipText} position='left' trigger='mouseenter' theme='light'>
												{actionBtn}
											</Tooltip>
										) : (
											actionBtn
										)
									}}
								/>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-2'>
							<div className='input-wrapper no-icon'>
								<label>{t('translation:StavUctu.Variabilný symbol (č faktúry)')}</label>
								<input
									type='text'
									className='input-field'
									data-type='number'
									style={{ width: !isSearch ? '100%' : 'calc(100% - 48px)', float: 'left' }}
									onChange={(e) => {
										const value = get(e, 'target.value', '').replace(/\D+/g, '').slice(0, 10) || null
										this.filterChanged({ value }, queryParams.variabilnySymbol)
									}}
									placeholder={t('translation:Common.filter.Hľadanie')}
									value={selectedFilters[queryParams.variabilnySymbol] || ''}
								/>
								{isSearch && (
									<Tooltip
										html={t('translation:StavUctu.Vyhľadanie podľa čísla dokladu')}
										position='top'
										trigger='mouseenter'
										theme='light'
										style={{ marginLeft: '10px', float: 'right' }}
									>
										<a
											style={{ width: '38px', height: '38px' }}
											className='button'
											data-type='icon-button'
											data-icon='view'
											onClick={() =>
												this.setState({
													showSearchModal: true
												})
											}
										/>
									</Tooltip>
								)}
							</div>
						</div>
						<div className='col-2'>
							<Select
								className={cx({ 'active-filters': isActiveZUFilter, blue: isActiveZUFilter })}
								label={t('translation:Common.Zmluvný účet')}
								options={filtersOptions[queryParams.zmluvnyUcetCislo]}
								onChange={(typ) => this.filterChanged(typ, queryParams.zmluvnyUcetCislo)}
								placeholder={zuCisloPlaceholder}
								closeMenuOnSelect={false}
							/>
						</div>
						<div className='col-2'>
							<Select
								className={cx({ 'active-filters': isActiveVyrovnanieStatus, purple: isActiveVyrovnanieStatus })}
								label={t('translation:StavUctu.Stav položky')}
								classNamePrefix='react-select'
								options={filtersOptions[queryParams.vyrovnanieStatus]}
								onChange={(typ) => this.filterChanged(typ, queryParams.vyrovnanieStatus)}
								placeholder={vyrovnanieStatusPlaceholder}
							/>
						</div>
						<div className='col-2'>
							<Select
								className={cx({ 'active-filters': isActiveTypFilter, green: isActiveTypFilter })}
								label={t('translation:StavUctu.Typ položky')}
								options={filtersOptions[queryParams.typ]}
								onChange={(typ) => this.multiFilterAdd(typ, queryParams.typ)}
								placeholder={typPolozkyPlaceholder}
								isMulti
								closeMenuOnSelect={false}
							/>
						</div>
						<div className='col-2'>
							<DatePickerField
								label={t('translation:StavUctu.Obdobie od')}
								locale='sk'
								input={{
									value: selectedFilters[queryParams.datumOd],
									onChange: (e) => {
										const value = e ? dayjs(e).format('YYYY-MM-DD') : null
										this.filterChanged({ value }, queryParams.datumOd)
									}
								}}
								maxDate={selectedFilters[queryParams.datumDo] ? dayjs(selectedFilters[queryParams.datumDo]).toDate() : null}
								tabIndex={50}
								meta={{}}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
							/>
						</div>
						<div className='col-2'>
							<DatePickerField
								label={t('translation:StavUctu.Obdobie do')}
								locale='sk'
								input={{
									value: selectedFilters[queryParams.datumDo],
									onChange: (e) => {
										const value = e ? dayjs(e).format('YYYY-MM-DD') : null
										this.filterChanged({ value }, queryParams.datumDo)
									}
								}}
								tabIndex={50}
								meta={{}}
								showYearDropdown
								scrollableYearDropdown
								placeholderText='Zvoľte dátum'
								isClearable
								minDate={selectedFilters[queryParams.datumOd] ? dayjs(selectedFilters[queryParams.datumOd]).toDate() : null}
							/>
						</div>
						<div className='col-12'>
							<div className='filter-items clearfix' style={{ paddingRight: '150px' }}>
								<Link
									to={{
										pathname: setRouteParams(POHLADAVKY_ZOZNAM, get(interakcia, 'opCislo')),
										state: { fromDetail: true, pohladavkaID: decodeURIComponent(idPohladavky) }
									}}
								>
									<div key='rychly-filter' className='filter-item' data-color='blue' style={{ cursor: 'pointer' }}>
										<span className='filter-text'>{rychlyFilterBtnText}</span>
										<span className='remove' />
									</div>
								</Link>
								{filterItems}
								{(!isEmpty(filterItems) || selectedFilters[queryParams.datumOd] || selectedFilters[queryParams.datumdo]) && (
									<div
										className='filter-item'
										style={{ margin: 0, position: 'absolute', top: '5px', right: '15px', cursor: 'pointer' }}
										data-color='red'
										onClick={() => this.clearFilters()}
									>
										<span className='filter-text'>{t('translation:Common.filter.Zrušiť filtre')}</span>
										<span className='remove' />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<StickyTableHeader columns={columns} triedenie={selectedFilters[queryParams.triedenie]} sortingColumn={this.sortingColumn} />
				{content}
				{showSearchModal && (
					<StavUctuSearch
						modalTitle={t('translation:StavUctu.Vyhľadanie podľa čísla dokladu')}
						onSearchButton={(searchTerm) => {
							if (!isEmpty(searchTerm)) {
								this.setState({
									searchTerm,
									showSearchModal: false
								})
								history.replace(setRouteParams(`${POHLADAVKY_DETAIL}?search=true`, get(interakcia, 'opCislo'), searchTerm))
								obchodnyPartnerActions.loadPohladavkaDetail(searchTerm, filters, get(ciselniky, 'pohladavkaTyp', []))
							}
						}}
						onCloseButton={() =>
							this.setState({
								showSearchModal: false
							})
						}
						searchTerm={searchTerm}
					/>
				)}
			</>
		)
	}

	render = () => {
		const { t, pohladavky, interakcia, otvoreneFaktury } = this.props

		const { idPohladavky } = this.props.computedMatch.params

		const { filtered, isLoading, isFailure } = pohladavky

		if (isLoading || get(otvoreneFaktury, 'isLoading')) {
			return this.commonContentContainer(
				<div className='box' style={{ height: '500px' }}>
					<ElementLoading />
				</div>
			)
		}

		if (isFailure /*  || get(otvoreneFaktury, 'isFailure') */) {
			return this.failureContentElement()
		}

		if (isEmpty(filtered)) {
			return this.emptyContentElement()
		}

		const selectedPohladavka = split(decodeURIComponent(idPohladavky), ',')

		const rows = map(filtered, (pohladavka, index) => (
			<StavUctuTableRow
				key={`pohladavka-${index}`}
				t={t}
				otvoreneFaktury={otvoreneFaktury}
				selectedPohladavka={selectedPohladavka}
				pohladavka={pohladavka}
				interakcia={interakcia}
			/>
		))

		return this.commonContentContainer(
			<div id='scroll-pohladavky-container' className='content-wrapper'>
				<div className='row'>
					<div className='col-12'>
						<div className='box'>
							<div className='box-content table-wrapper stav-uctu' style={{ padding: 0 }}>
								<table
									id='pohladavky'
									className={cx('content-table padded bordered', { highlighted: !!some(filtered, (pohladavka) => pohladavka.highlighted) })}
									cellSpacing='0'
								>
									<tbody style={{ position: 'relative' }}>{rows}</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	ciselniky: get(state, 'ciselniky.data'),
	interakcia: get(state, 'interakcie.detail.data'),
	selectedFilters: get(state.selectedFilters, FILTER_SELECTORS.STAV_UCTU_DETAIL_PAGE, {}),
	ciselnikySelectBox: get(state, 'ciselnikySelectBox'),
	pohladavky: get(state, 'obchodnyPartner.pohladavkaDetail'),
	pohladavkyZostatok: get(state, 'obchodnyPartner.pohladavkyZostatok'),
	otvoreneFaktury: get(state, 'otvoreneFaktury.otvoreneFaktury')
})

const mapDispatchToProps = (dispatch) => ({
	obchodnyPartnerActions: bindActionCreators(ObchodnyPartnerActions, dispatch),
	filtersActions: bindActionCreators(FiltersActions, dispatch),
	ciselnikySelectBoxActions: bindActionCreators(CiselnikySelectBoxActions, dispatch),
	otvoreneFakturyActions: bindActionCreators(OtvoreneFakturyActions, dispatch)
})

export default compose(
	withTranslation('containers'),
	connect(mapStateToProps, mapDispatchToProps),
	withPermissions([PERMISSIONS.VIEW_POHLADAVKY])
)(StavUctuDetailPage)
