import React from 'react'
import { Fields, reduxForm, propTypes, Field, change, touch } from 'redux-form'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { get, map, upperFirst, isEmpty, filter, every, some, size, forEach } from 'lodash'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import dayjs from 'dayjs'

import validate from './validateForm'

// components
import FormInfo from '../FormInfo'
import PaymentMethodField from '../GenericUkon/fields/ZmluvnyUcet/PaymentMethodField'
import EfakturaEmailsField from '../GenericUkon/fields/ZmluvnyUcet/EfakturaEmailsField'

import { EDIT_MODE, COLUMNS_COUNT } from '../../containers/GenericUkon/genericUkonConfig'

// atoms
import { AddressField, TextareaField, SelectField, DropZoneField, TextInputField, DatePickerField } from '../../atoms'

// utils
import { formatAddress, formatAddressForResponse } from '../../utils/address'
import { BUSINESS_CHANNELS, FORMS, SPOSOB_PLATBY_DOSLA_PLATBA, SPOSOB_PLATBY_ODOSLANA_PLATBA, SUHLAS_HODNOTA } from '../../utils/enums'
import { isValidacneKriteriumError } from '../../utils/form'

import { getSposobPlatbyFromCiselnik } from '../../utils/platby'
import { createSuhlasDTO } from '../../utils/suhlas'
import CheckboxField from '../../atoms/CheckboxField'

class ZmluvnyUcetEdit extends React.Component {
	static propTypes = {
		...propTypes,
		cisloZU: PropTypes.string.isRequired,
		isNCB: PropTypes.bool.isRequired,
		originalValues: PropTypes.shape().isRequired,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		onDeleteFile: PropTypes.func.isRequired,
		schvalovacieKriteria: PropTypes.array.isRequired,
		validacneKriteria: PropTypes.array.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			neposielatPripomienkuPlatbyOptions: []
		}
	}

	componentDidMount() {
		const { dispatch, formValues, t, ciselniky } = this.props
		const { doslePlatbySposob, odoslanePlatbySposob } = formValues

		// NOTE: Poziadavka z BRD - pri nastavenom inkase pre prijate platby je mozne pre odoslane platby nastavit iba bankovy prevod
		// NOTE: CP-1632
		if (doslePlatbySposob.id == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ && odoslanePlatbySposob.id == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA) {
			dispatch(
				change(
					FORMS.UPRAVA_ZMLUVNEHO_UCTU,
					'odoslanePlatbySposob',
					getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ, ciselniky)
				)
			)
		}

		if (doslePlatbySposob.id == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA && odoslanePlatbySposob.id == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ) {
			dispatch(
				change(
					FORMS.UPRAVA_ZMLUVNEHO_UCTU,
					'odoslanePlatbySposob',
					getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA, ciselniky)
				)
			)
		}

		if (doslePlatbySposob.id == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ) {
			dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'doslePlatbyBankoveSpojenie'))
		}

		this.setState({
			neposielatPripomienkuPlatbyOptions: [
				{
					value: true,
					label: t('translation:Common.Neaktívne')
				},
				{
					value: false,
					label: t('translation:Common.Aktívne')
				}
			]
		})
	}

	componentDidUpdate(prevProps) {
		const { dispatch, formValues, originalValues, ciselniky } = this.props
		const { doslePlatbySposob, odoslanePlatbySposob, doslePlatbyBankoveSpojenie, odoslanePlatbyBankoveSpojenie, eFakturaEmaily } = formValues

		if (formValues?.odoslanePlatbySposob?.id !== SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA && formValues?.showEditAdresaOdoslanePlatby !== false) {
			dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'showEditAdresaOdoslanePlatby', false))
		}

		if (get(doslePlatbySposob, 'id') != get(prevProps, 'formValues.doslePlatbySposob.id')) {
			if (get(doslePlatbySposob, 'id') != SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'doslePlatbyBankoveSpojenie'))
			} else {
				dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'adresaDoslePlatby'))
			}

			// NOTE: Poziadavka z BRD - pri nastavenom inkase pre prijate platby je mozne pre odoslane platby nastavit iba bankovy prevod
			if (
				get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ &&
				get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA
			) {
				dispatch(
					change(
						FORMS.UPRAVA_ZMLUVNEHO_UCTU,
						'odoslanePlatbySposob',
						getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ, ciselniky)
					)
				)
			}
			// NOTE: Poziadavka z BRD - pri nastavenych postovych poukazkach pre prijate platby je mozne pre odoslane platby nastavit iba postove poukazky
			if (
				get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA &&
				get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ
			) {
				dispatch(
					change(
						FORMS.UPRAVA_ZMLUVNEHO_UCTU,
						'odoslanePlatbySposob',
						getSposobPlatbyFromCiselnik('odoslanaPlatbaSposob', SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA, ciselniky)
					)
				)
			}

			// NOTE: Pri zmene sposobu platby je vzdy na editaciu k dispozicii iba jedno pole (IBAN alebo adresa) a druhe je vyresetovane na povodnu hodnotu
			if (get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'doslePlatbyBankoveSpojenie', get(originalValues, 'doslePlatbyBankoveSpojenie', null)))
			} else {
				dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'adresaDoslePlatby', get(originalValues, 'adresaDoslePlatby', null)))
			}
		}

		if (get(odoslanePlatbySposob, 'id') != get(prevProps, 'formValues.odoslanePlatbySposob.id')) {
			if (get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.PREVODNY_PRIKAZ) {
				dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'odoslanePlatbyBankoveSpojenie'))
			} else {
				dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'adresaOdoslanePlatby'))
			}

			// NOTE: Pri zmene sposobu platby je vzdy na editaciu k dispozicii iba jedno pole (IBAN alebo adresa) a druhe je vyresetovane na povodnu hodnotu
			if (get(odoslanePlatbySposob, 'id') == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA) {
				dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'odoslanePlatbyBankoveSpojenie', get(originalValues, 'odoslanePlatbyBankoveSpojenie', null)))
			} else {
				dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'adresaOdoslanePlatby', get(originalValues, 'adresaOdoslanePlatby', null)))
			}
		}

		// NOTE: CP-1017 - V pripade zvoleneho inkasneho prikazu pre prijate platby sa cislo uctu IBAN pouzije automaticky aj pre odoslane platby
		if (
			get(doslePlatbySposob, 'id') == SPOSOB_PLATBY_DOSLA_PLATBA.INKASNY_PRIKAZ &&
			get(doslePlatbyBankoveSpojenie, 'id') != get(odoslanePlatbyBankoveSpojenie, 'id')
		) {
			dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'odoslanePlatbyBankoveSpojenie', doslePlatbyBankoveSpojenie))
		}

		if (size(eFakturaEmaily) !== size(get(prevProps, 'formValues.eFakturaEmaily'))) {
			forEach(eFakturaEmaily, (email, index) => {
				dispatch(touch(FORMS.UPRAVA_ZMLUVNEHO_UCTU, `eFakturaEmaily[${index}].email`))
			})
		}
	}

	handleChangeSuhlas = (suhlasDTO, propertyName) => {
		const { dispatch } = this.props
		dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, propertyName, suhlasDTO))
	}

	render() {
		const {
			handleSubmit,
			invalid,
			originalValues,
			formValues,
			ciselniky,
			addresses,
			t,
			isNCB,
			validacneKriteria,
			schvalovacieKriteria,
			auth,
			onDeleteFile,
			formTitle,
			onBackClick,
			onCancelClick,
			dispatch,
			obchodnyPartnerActions
		} = this.props

		const { neposielatPripomienkuPlatbyOptions } = this.state

		const isPostovaPoukazka = formValues?.odoslanePlatbySposob?.id == SPOSOB_PLATBY_ODOSLANA_PLATBA.POSTOVA_POUKAZKA

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))
		const fakturacnaPeriodaOptions = map(get(ciselniky, 'fakturacnaPerioda', []), (fakturacnaPerioda) => ({
			label: upperFirst(get(fakturacnaPerioda, 'nazov')),
			value: get(fakturacnaPerioda, 'id')
		}))

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		const adresaFakturacie = formatAddressForResponse('adresaFakturacie', formValues, addresses)

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !get(validacneKriterium, 'vysledok'))
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{get(validacneKriterium, 'nazov')}: {get(validacneKriterium, 'popis')}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(
					schvalovacieKriteria,
					(schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok') || get(schvalovacieKriterium, 'error')
				)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{get(schvalovacieKriterium, 'nazov')}: {get(schvalovacieKriterium, 'popis')}
					</div>
				))
			}
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<FormInfo validacneKriteria={validacneKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Aktuálne hodnoty')}</th>
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={isEmpty(ukonVstupOptions)}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Splnomocnenec')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='splnomocnenec'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte titul, meno a priezvisko splnomocnenca')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>{get(originalValues, 'podpisMiesto')}</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td />
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							{isNCB && (
								<div className='inner-box'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:ZmluvneUcty.Frekvencia platby')}</strong>
												</td>
												<td>{upperFirst(get(originalValues, 'fakturacnaPerioda.nazov')) || '-'}</td>
												<td>
													<Field
														name='fakturacnaPerioda'
														component={SelectField}
														options={fakturacnaPeriodaOptions}
														isDisabled={isEmpty(fakturacnaPeriodaOptions)}
														isSearchable={false}
														classNamePrefix='react-select'
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}
							<EfakturaEmailsField
								field='eFakturaEmaily'
								editMode={EDIT_MODE.EDIT}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
								onChangeSuhlas={(suhlas) => this.handleChangeSuhlas(suhlas, 'suhlasEfaktura')}
								onDeactivate={() => {
									dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'suhlasEfaktura', createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, null)))
									dispatch(change(FORMS.UPRAVA_ZMLUVNEHO_UCTU, 'eFakturaEmaily', []))
								}}
							/>
							<PaymentMethodField
								field='doslePlatbySposob'
								paymentMethod='doslePlatbySposob'
								editMode={EDIT_MODE.EDIT}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
								onChangeSuhlas={(suhlas) => this.handleChangeSuhlas(suhlas, 'suhlasEplatba')}
								onAddIBAN={(IBAN) => obchodnyPartnerActions.saveManuallyAddedAccount({ IBAN })}
							/>
							<PaymentMethodField
								field='odoslanePlatbySposob'
								paymentMethod='odoslanePlatbySposob'
								editMode={EDIT_MODE.EDIT}
								columnsCount={COLUMNS_COUNT.THREE}
								originalValues={originalValues}
								formValues={formValues}
								onChangeSuhlas={(suhlas) => this.handleChangeSuhlas(suhlas, 'suhlasEpreplatky')}
								onAddIBAN={(IBAN) => obchodnyPartnerActions.saveManuallyAddedAccount({ IBAN })}
							/>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:ZmluvneUcty.Fakturačná adresa')}</strong>
											</td>
											<td>
												<span style={{ position: 'relative', paddingRight: '20px' }}>
													{get(originalValues, 'adresaFakturacieSection')}
													{get(originalValues, 'adresaFakturacieSectionInherit') && (
														<Tooltip
															html={
																<>
																	<span>{t('translation:Common.Odvodená adresa z')}</span>
																	<br />
																	<span>
																		<strong>{get(originalValues, 'adresaFakturacieSectionInherit')}</strong>
																	</span>
																</>
															}
															position='bottom'
															trigger='mouseenter'
															theme='light'
														>
															<div className='info-icon' />
														</Tooltip>
													)}
												</span>
											</td>
											<td>
												<Fields names={['adresaFakturacie.id']} selector={'adresaFakturacie'} create component={AddressField} />
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:ZmluvneUcty.Adresa pre doručovanie poštových poukážok')}</strong>
											</td>
											<td>
												<span style={{ position: 'relative', paddingRight: '20px' }}>
													{get(originalValues, 'adresaOdoslanePlatbySection')}
													{get(originalValues, 'adresaOdoslanePlatbySectionInherit') && (
														<Tooltip
															html={
																<>
																	<span>{t('translation:Common.Odvodená adresa z')}</span>
																	<br />
																	<span>
																		<strong>{get(originalValues, 'adresaOdoslanePlatbySectionInherit')}</strong>
																	</span>
																</>
															}
															position='bottom'
															trigger='mouseenter'
															theme='light'
														>
															<div className='info-icon' />
														</Tooltip>
													)}
												</span>
											</td>
											<td>
												{formValues?.showEditAdresaOdoslanePlatby ? (
													<Fields
														names={['adresaOdoslanePlatby.id']}
														selector={'adresaOdoslanePlatby'}
														originalValue={get(originalValues, 'adresaOdoslanePlatby')}
														create
														component={AddressField}
														validate={(value) => {
															if (formValues?.showEditAdresaOdoslanePlatby && value === formValues?.adresaFakturacie?.id) {
																return t(
																	'translation:ZmluvneUcty.Adresa pre doručovanie poštových poukážok musí byť odlišná od fakturačnej adresy'
																)
															}
														}}
													/>
												) : (
													formatAddress(adresaFakturacie)
												)}
												{isPostovaPoukazka && (
													<div className='row' style={{ marginTop: '10px' }}>
														<div className='col-12'>
															<Field
																name={'showEditAdresaOdoslanePlatby'}
																component={CheckboxField}
																label={t('translation:ZmluvneUcty.Nastaviť odlišnú adresu pre doručovanie poštových poukážok')}
															/>
														</div>
													</div>
												)}
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:ZmluvneUcty.Adresa pre zasielanie upomienok')}</strong>
											</td>
											<td>
												<span style={{ position: 'relative', paddingRight: '20px' }}>
													{get(originalValues, 'adresaUpominaciaSection')}
													{get(originalValues, 'adresaUpominaciaSectionInherit') && (
														<Tooltip
															html={
																<>
																	<span>{t('translation:Common.Odvodená adresa z')}</span>
																	<br />
																	<span>
																		<strong>{get(originalValues, 'adresaUpominaciaSectionInherit')}</strong>
																	</span>
																</>
															}
															position='bottom'
															trigger='mouseenter'
															theme='light'
														>
															<div className='info-icon' />
														</Tooltip>
													)}
												</span>
											</td>
											<td>{formatAddress(adresaFakturacie)}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:ZmluvneUcty.Pripomienka platby')}</strong>
											</td>
											<td>
												{get(originalValues, 'neposielatPripomienkuPlatby')
													? t('translation:Common.Neaktívne')
													: t('translation:Common.Aktívne')}
											</td>
											<td>
												<Field
													name='neposielatPripomienkuPlatby'
													component={SelectField}
													options={neposielatPripomienkuPlatbyOptions}
													isDisabled={isEmpty(neposielatPripomienkuPlatbyOptions)}
													isSearchable={false}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:ZmluvneUcty.Poznámka')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='poznamka'
												component={TextareaField}
												rows='3'
												placeholder={t('translation:ZmluvneUcty.Zadajte poznámku')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td />
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:ZmluvneUcty.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.UPRAVA_ZMLUVNEHO_UCTU,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(ZmluvnyUcetEdit)

export default compose(withTranslation('components'))(form)
