import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, propTypes, getFormValues, change } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, upperFirst, isEmpty, some, filter, maxBy, isEqual } from 'lodash'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'
import { NumericFormat } from 'react-number-format'

import validate from './validateForm'

// components
import FormInfo from '../../../FormInfo'
import PlanSplatokAktualneNastavenie from '../../PlanSplatokAktualneNastavenie'

// atoms
import { SelectField, DropZoneField, DatePickerField, TextareaField, TextInputField } from '../../../../atoms'

// utils
import { FORMS, BUSINESS_CHANNELS, TYP_PLATCU_PLANU_SPLATOK } from '../../../../utils/enums'
import { setRouteParams, PLANY_SPLATOK_ZOZNAM } from '../../../../utils/routes'
import { getPlatcaTypString, getPlatcaTypLabel, getNormalizedDatumSplatnosti, getContextForPlanSplatokAktualneNastavenie } from '../../../../utils/planySplatok'
import { celeMeno } from '../../../../utils/obchodnyPartner'
import { formatDate } from '../../../../utils/date'
import { isValidacneKriteriumError } from '../../../../utils/form'

class PlanSplatokInfo extends React.Component {
	static propTypes = {
		...propTypes,
		formValues: PropTypes.shape()
	}

	state = {
		pocetSplatokOptions: [],
		urokOptions: [],
		skontrolovaneZalohyOptions: []
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { t } = this.props

		this.composeDenSplatnostiRange()

		this.setState({
			urokOptions: [
				{
					value: true,
					label: t('translation:Common.Áno')
				},
				{
					value: false,
					label: t('translation:Common.Nie')
				}
			],
			skontrolovaneZalohyOptions: [
				{
					value: true,
					label: t('translation:Common.Áno')
				},
				{
					value: false,
					label: t('translation:Common.Nie')
				}
			]
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { formValues, schvalovacieKriteria, planySplatokActions, procesnyKonfigurator } = this.props
		if (
			!isEqual(formValues, get(prevProps, 'formValues')) ||
			!isEqual(schvalovacieKriteria, get(prevProps, 'schvalovacieKriteria')) ||
			!isEqual(
				get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy'),
				get(prevProps, 'procesnyKonfigurator.data.ukony.planSplatok.standardneAtributy')
			)
		) {
			this.composeDenSplatnostiRange()
		}
		if (!isEqual(formValues, get(prevProps, 'formValues'))) {
			const errors = validate(formValues, this.props)
			if (!isEmpty(errors)) {
				planySplatokActions.aktualneNastavenieClear()
			} else {
				const context = getContextForPlanSplatokAktualneNastavenie(formValues)
				planySplatokActions.loadPlanSplatokAktualneNastavenie(context)
			}
		}
	}

	composeDenSplatnostiRange = () => {
		const { dispatch, schvalovacieKriteria, procesnyKonfigurator, formValues } = this.props

		const maxUrovenSchvalovania = get(
			maxBy(
				filter(schvalovacieKriteria, (schvalovacieKriterium) => !get(schvalovacieKriterium, 'vysledok')),
				'urovenSchvalovania'
			),
			'urovenSchvalovania',
			0
		)

		const denSplatnostiKalDniMin = +get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy.denSplatnostiKalDniMin', 0)
		const denSplatnostiKalDniMax = +get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy.denSplatnostiKalDniMax', 0)
		const schvalovanieKalDniMax = +get(procesnyKonfigurator, 'data.ukony.planSplatok.standardneAtributy.schvalovanieKalDniMax', 1)

		const denPrvejSplatkyMin = getNormalizedDatumSplatnosti(maxUrovenSchvalovania, schvalovanieKalDniMax, denSplatnostiKalDniMin)
		const denPrvejSplatkyMax = getNormalizedDatumSplatnosti(maxUrovenSchvalovania, schvalovanieKalDniMax, denSplatnostiKalDniMax)

		if (
			get(formValues, 'datumSplatnosti') &&
			get(formValues, 'datumSplatnosti') < denPrvejSplatkyMin &&
			get(formValues, 'datumSplatnosti') > denPrvejSplatkyMax
		) {
			dispatch(
				change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'datumSplatnosti', !denPrvejSplatkyMin.diff(denPrvejSplatkyMax) ? denPrvejSplatkyMin.toDate() : null)
			)
		} else if (denPrvejSplatkyMin && denPrvejSplatkyMax && !denPrvejSplatkyMin.diff(denPrvejSplatkyMax)) {
			dispatch(change(FORMS.PLANY_SPLATOK_VYTVORENIE, 'datumSplatnosti', denPrvejSplatkyMin.toDate()))
		}

		this.setState({
			denPrvejSplatkyMin,
			denPrvejSplatkyMax,
			denSplatnostiKalDniMin,
			denSplatnostiKalDniMax,
			maxUrovenSchvalovania,
			schvalovanieKalDniMax
		})
	}

	render() {
		const {
			interakcia,
			formTitle,
			handleSubmit,
			invalid,
			auth,
			validacneKriteria,
			schvalovacieKriteria,
			onDeleteFile,
			onBackClick,
			ciselniky,
			formValues,
			planSplatokAktualneNastavenie,
			t
		} = this.props

		const {
			maxUrovenSchvalovania,
			denPrvejSplatkyMin,
			denPrvejSplatkyMax,
			denSplatnostiKalDniMin,
			denSplatnostiKalDniMax,
			schvalovanieKalDniMax,
			urokOptions,
			skontrolovaneZalohyOptions
		} = this.state

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), {
			vysledok: true
		})
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, {
			error: true
		})

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium
		const disableSubmit = !isValid || invalid

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('translation:Common.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (!isValid) {
			let btnTooltipText
			if (!passValidacneKriterium) {
				const valKrit = filter(validacneKriteria, (validacneKriterium) => !validacneKriterium.vysledok)
				btnTooltipText = map(valKrit, (validacneKriterium) => (
					<div>
						{validacneKriterium.nazov}: {validacneKriterium.popis}
					</div>
				))
			} else if (errorSchvalovacieKriterium) {
				const schvalKrit = filter(schvalovacieKriteria, (schvalovacieKriterium) => !schvalovacieKriterium.vysledok || schvalovacieKriterium.error)
				btnTooltipText = map(schvalKrit, (schvalovacieKriterium) => (
					<div>
						{schvalovacieKriterium.nazov}: {schvalovacieKriterium.popis}
					</div>
				))
			}
			wrappedSubmitBtn = (
				<Tooltip html={btnTooltipText} position='bottom' trigger='mouseenter' theme='light'>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		let platcaComponent
		switch (get(formValues, 'platcaTyp')) {
			case TYP_PLATCU_PLANU_SPLATOK.SPLNOMOCNENEC: {
				platcaComponent = (
					<span>{`${get(formValues, 'splnomocnenie.op2.cislo') || '0'} - ${celeMeno(get(formValues, 'splnomocnenie.op2')) || '-'}`}</span>
				)
				break
			}
			case TYP_PLATCU_PLANU_SPLATOK.DEDIC:
				platcaComponent = <span>{get(formValues, 'dedic', '-') || '-'}</span>
				break
		}

		let denPrvejSplatkyTooltipComponent
		if (denPrvejSplatkyMin && denPrvejSplatkyMax) {
			if (!denPrvejSplatkyMin.diff(denPrvejSplatkyMax)) {
				denPrvejSplatkyTooltipComponent = (
					<Tooltip
						html={
							<div style={{ textAlign: 'left' }}>
								<span>
									<strong>{t('translation:PlanySplatok.Výpočet dátumu splatnosti 1 splátky')}</strong>
									<br />
									<br />
									{t(
										'translation:PlanySplatok.Aktuálny dátum ({currentDate}) + ({schvalovanieKalDniMax} KD * {maxUrovenSchvalovania} úrovne schvaľovania) + {denSplatnostiKalDniMin} KD + 1 KD = {denPrvejSplatkyMin}',
										{
											currentDate: dayjs().format('DD.MM.YYYY'),
											maxUrovenSchvalovania,
											denPrvejSplatkyMin: dayjs(denPrvejSplatkyMin).format('DD.MM.YYYY'),
											denSplatnostiKalDniMin,
											schvalovanieKalDniMax
										}
									)}
								</span>
								<br />
								<br />
								<strong>
									{t(
										'translation:PlanySplatok.Výsledný dátum je informatívny a môže byť ešte upravený o víkendy a sviatky resp na základe reálnej doby schvaľovania\nZáväzný dátum bude uvedený vo výstupnej dohode o platbách'
									)}
								</strong>
							</div>
						}
						position='right'
						trigger='mouseenter'
						theme='light'
					>
						<div className='info-icon' />
					</Tooltip>
				)
			} else {
				denPrvejSplatkyTooltipComponent = (
					<Tooltip
						html={
							<div style={{ textAlign: 'left' }}>
								<span>
									<strong>{t('translation:PlanySplatok.Výpočet minimálneho dátumu splatnosti 1 splátky')}</strong>
									<br />
									<br />
									{t(
										'translation:PlanySplatok.Aktuálny dátum ({currentDate}) + ({schvalovanieKalDniMax} KD * {maxUrovenSchvalovania} úrovne schvaľovania) + {denSplatnostiKalDniMin} KD + 1 KD = {denPrvejSplatkyMin}',
										{
											currentDate: formatDate(new Date(), null, 'DD.MM.YYYY'),
											maxUrovenSchvalovania,
											denPrvejSplatkyMin: formatDate(denPrvejSplatkyMin, null, 'DD.MM.YYYY'),
											denSplatnostiKalDniMin,
											schvalovanieKalDniMax
										}
									)}
								</span>
								<br />
								<span>
									<strong>{t('translation:PlanySplatok.Výpočet maximálneho dátumu splatnosti 1 splátky')}</strong>
									<br />
									<br />
									{t(
										'translation:PlanySplatok.Aktuálny dátum ({currentDate}) + ({schvalovanieKalDniMax} KD * {maxUrovenSchvalovania} úrovne schvaľovania) + {denSplatnostiKalDniMax} KD + 1 KD = {denPrvejSplatkyMax}',
										{
											currentDate: formatDate(new Date(), null, 'DD.MM.YYYY'),
											maxUrovenSchvalovania,
											denPrvejSplatkyMax: formatDate(denPrvejSplatkyMax, null, 'DD.MM.YYYY'),
											denSplatnostiKalDniMax,
											schvalovanieKalDniMax
										}
									)}
								</span>
								<br />
								<br />
								<strong>
									{t(
										'translation:PlanySplatok.Výsledný dátum je informatívny a môže byť ešte upravený o víkendy a sviatky resp na základe reálnej doby schvaľovania\nZáväzný dátum bude uvedený vo výstupnej dohode o platbách'
									)}
								</strong>
							</div>
						}
						position='right'
						trigger='mouseenter'
						theme='light'
					>
						<div className='info-icon' />
					</Tooltip>
				)
			}
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>{wrappedSubmitBtn}</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<Link
						to={setRouteParams(PLANY_SPLATOK_ZOZNAM, get(interakcia, 'opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('translation:Common.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<FormInfo schvalovacieKriteria={schvalovacieKriteria} />
						<FormInfo validacneKriteria={validacneKriteria} />
						<div className='box-content'>
							<table className='content-table padded bordered' cellSpacing='0'>
								<thead>
									<tr>
										<th />
										<th>{t('translation:Common.Nové hodnoty')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstup do úkonu')}</strong>
										</td>
										<td>
											<Field
												name='ukonVstup'
												component={SelectField}
												options={ukonVstupOptions}
												isSearchable={false}
												isDisabled={isEmpty(ukonVstupOptions)}
												classNamePrefix='react-select'
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
										</td>
										<td>
											<Field
												name='podpisMiesto'
												component={TextInputField}
												placeholder={t('translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)')}
											/>
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
										</td>
										<td>
											<div className='select-wrapper'>
												<Field
													name='datumPrijatiaZiadosti'
													component={DatePickerField}
													showYearDropdown
													scrollableYearDropdown
													minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
													maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className='inner-box'>
								<table className='content-table padded bordered' cellSpacing='0'>
									<tbody>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Typ platcu')}</strong>
											</td>
											<td>{getPlatcaTypString(get(formValues, 'platcaTyp'), t) || '-'}</td>
										</tr>
										{platcaComponent && (
											<tr>
												<td>
													<strong>{getPlatcaTypLabel(get(formValues, 'platcaTyp'), t) || '-'}</strong>
												</td>
												<td>{platcaComponent}</td>
											</tr>
										)}
										<tr>
											<td>
												<strong>{t('translation:Common.Zmluvný účet')}</strong>
											</td>
											<td>{get(formValues, 'zuCislo')}</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Zahrnuté faktúry')}</strong>
											</td>
											<td style={{ padding: 0 }}>
												<table className='search-results-table no-pointer' cellSpacing='0' style={{ border: 'none' }}>
													<thead>
														<tr>
															<th>{t('translation:Common.Číslo faktúry')}</th>
															<th>{t('translation:Common.Otvorená suma')}</th>
														</tr>
													</thead>
													<tbody data-empty={t('translation:PlanySplatok.Neboli zvolené žiadne faktúry')}>
														{map(get(formValues, 'zdrojoveFaktury', []), (faktura, index) => (
															<tr key={`faktura_${index}`}>
																<td>{get(faktura, 'cislo', '-')}</td>
																<td style={{ whiteSpace: 'nowrap' }}>
																	<NumericFormat
																		thousandSeparator={' '}
																		decimalSeparator={','}
																		decimalScale={2}
																		fixedDecimalScale={true}
																		displayType='text'
																		suffix=' €'
																		value={get(faktura, 'sumaOtvorena')}
																		defaultValue='-'
																	/>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Počet splátok')}</strong>
											</td>
											<td>
												<Field
													name='splatkyPocet'
													component={TextInputField}
													type='number'
													min='0'
													placeholder={t('translation:PlanySplatok.Zadajte počet splátok')}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>
													{t('translation:PlanySplatok.Dátum splatnosti 1 splátky (informatívny)')}
													{denPrvejSplatkyTooltipComponent}
												</strong>
											</td>
											<td>
												<Field
													name='datumSplatnosti'
													component={DatePickerField}
													minDate={denPrvejSplatkyMin?.toDate()}
													maxDate={denPrvejSplatkyMax?.toDate()}
													placeholderText={t('translation:Common.Zvoľte dátum')}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Úrok')}</strong>
											</td>
											<td>
												<Field
													name='urok'
													component={SelectField}
													options={urokOptions}
													isSearchable={false}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:PlanySplatok.Skontrolované zálohy')}</strong>
											</td>
											<td>
												<Field
													name='skontrolovaneZalohy'
													component={SelectField}
													options={skontrolovaneZalohyOptions}
													isSearchable={false}
													classNamePrefix='react-select'
												/>
											</td>
										</tr>
										<tr>
											<td>
												<strong>{t('translation:Common.Poznámka (interná)')}</strong>
											</td>
											<td>
												<Field
													name='poznamkaInterna'
													component={TextareaField}
													rows='3'
													placeholder={t('translation:Common.Zadajte internú poznámku')}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<table className='content-table padded bordered' cellSpacing='0'>
								<tbody>
									<tr>
										<td>
											<strong>{t('translation:Common.Poznámka')}</strong>
										</td>
										<td>
											<Field name='poznamka' component={TextareaField} rows='3' placeholder={t('translation:Common.Zadajte poznámku')} />
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
										</td>
										<td>
											<Field
												name='dokumenty'
												onDelete={onDeleteFile}
												component={DropZoneField}
												placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
											/>
										</td>
									</tr>
									<PlanSplatokAktualneNastavenie aktualneNastavenie={planSplatokAktualneNastavenie} />
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.PLANY_SPLATOK_VYTVORENIE,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(PlanSplatokInfo)

const mapStateToProps = (state) => ({
	formValues: getFormValues(FORMS.PLANY_SPLATOK_VYTVORENIE)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
