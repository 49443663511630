import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Fields, Field, reduxForm, propTypes, change, getFormSyncErrors } from 'redux-form'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { filter, map, get, upperFirst, isEmpty, times } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'

import validate from './validateForm'
import warn from './warnForm'

// atoms
import TextInputField from '../../../atoms/TextInputField'
import TextareaField from '../../../atoms/TextareaField'
import DatePickerField from '../../../atoms/DatePickerField'
import SelectField from '../../../atoms/SelectField'
import AddressField from '../../../atoms/AddressField/AddressField'
import DropZoneField from '../../../atoms/DropZoneField'

// generic
import ContactPhoneField from '../../GenericUkon/fields/ContactPhoneField'
import ContactEmailField from '../../GenericUkon/fields/ContactEmailField'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatDate } from '../../../utils/date'
import { formatAddress, formatAddressForResponse } from '../../../utils/address'
import { FORMS, BUSINESS_CHANNELS, SUHLAS_HODNOTA } from '../../../utils/enums'
import { isConfirmValid } from '../../../utils/suhlas'

class ObchodnyPartnerEditInfo extends React.Component {
	static propTypes = {
		...propTypes,
		formTitle: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onBackClick: PropTypes.func.isRequired,
		onCancelClick: PropTypes.func.isRequired,
		onDeleteFile: PropTypes.func.isRequired,
		isDisabledGlobalZmenaAdresaZakaznika: PropTypes.bool.isRequired,
		isDisabledGlobalZmenaAdresaKorespondencna: PropTypes.bool.isRequired
	}

	state = {}

	constructor(props) {
		super(props)

		this.state = {
			titulPred2Focus: false,
			denSplatnostiPreferovanyOptions: []
		}
	}

	componentDidMount() {
		const { touch, form, dispatch, formValues } = this.props

		// NOTE: CP-2994 z BB môže prísť nevalídna hodnota, a preto je treba zvalidovať všetky inputy pri inite
		Object.keys(formValues)?.map((key) => dispatch(touch(form, key)))

		this.setState({
			denSplatnostiPreferovanyOptions: times(14, (index) => ({
				value: index + 15,
				label: `${index + 15}.`
			}))
		})
	}

	componentDidUpdate(prevProps) {
		const { dispatch, formValues } = this.props

		if (formValues) {
			const { titulPred1, titulPred2, uplatnitEfaktury } = formValues

			if (!titulPred1 && titulPred2) {
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'titulPred1', titulPred2))
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'titulPred2', null))
			}

			if (get(formValues, 'adresaZakaznika.globalnaZmena') && get(formValues, 'adresaKorespondencna.globalnaZmena')) {
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'adresaKorespondencna.globalnaZmena', false))
			}

			if (get(formValues, 'kontaktnyEmail') != get(prevProps, 'formValues.kontaktnyEmail')) {
				const errors = validate(formValues, this.props)
				if (isEmpty(get(formValues, 'kontaktnyEmail')) || !isEmpty(get(errors, 'kontaktnyEmail'))) {
					dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'uplatnitEfaktury', false))
				}
			}

			// reset eFatkura subfileds if checkbox value change
			if (uplatnitEfaktury !== get(prevProps, 'formValues.uplatnitEfaktury')) {
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'eFaktury', []))
			}
		}
	}

	handleChangeSuhlas = (suhlasDTO, propertyName) => {
		const { dispatch } = this.props

		dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, propertyName, suhlasDTO))
	}

	handleAddressConfirm = (suhlas, propertyNames) => {
		const { dispatch, formValues } = this.props

		if (get(formValues, 'adresaZakaznika.id') === get(formValues, 'adresaKorespondencna.id')) {
			dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'aktualnostAdresaZakaznika', suhlas ? suhlas.platnostOd : null))
			dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, 'aktualnostAdresa', suhlas ? suhlas.platnostOd : null))
		} else {
			propertyNames.forEach((propertyName) => {
				dispatch(change(FORMS.OBCHODNY_PARTNER_EDITACIA, propertyName, suhlas ? suhlas.platnostOd : null))
			})
		}
	}

	render() {
		const {
			auth,
			handleSubmit,
			invalid,
			synchronousError,
			ciselniky,
			originalValues,
			formValues,
			addresses,
			t,
			formTitle,
			onBackClick,
			onCancelClick,
			onDeleteFile,
			change,
			dispatch,
			isDisabledGlobalZmenaAdresaZakaznika,
			isDisabledGlobalZmenaAdresaKorespondencna
		} = this.props

		const { titulPred2Focus, denSplatnostiPreferovanyOptions } = this.state

		const ukonVstupOptions = map(get(ciselniky, 'ukonVstup', []), (ukonVstup) => ({
			label: upperFirst(get(ukonVstup, 'nazov')),
			value: get(ukonVstup, 'id')
		}))

		const titulyPredMenom = filter(get(ciselniky, 'akademickyTitulHodnota', []), 'pred_menom')
		const titulyZaMenom = filter(get(ciselniky, 'akademickyTitulHodnota', []), ['pred_menom', false])

		const titulyPredMenomOptions = map(titulyPredMenom, (item) => ({
			label: get(item, 'nazov'),
			value: get(item, 'id')
		}))
		const titulyZaMenomOptions = map(titulyZaMenom, (item) => ({
			label: get(item, 'nazov'),
			value: get(item, 'id')
		}))

		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', formValues, addresses)

		let refuseEdit
		if (get(formValues, 'adresaZakaznika.id') === get(formValues, 'adresaKorespondencna.id')) {
			refuseEdit = t('translation:Common.Adresu nie je možné editovať nakoľko je použitá ako adresa trvalého pobytu')
		}

		let minTimeDatumPrijatiaZiadosti = null
		let maxTimeDatumPrijatiaZiadosti = null
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZSE_CENTRUM) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.ZAKAZNICKA_LINKA) {
			minTimeDatumPrijatiaZiadosti = dayjs().startOf('day')
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}
		if (get(auth, 'businessChannel.actual.id') == BUSINESS_CHANNELS.BACK_OFFICE) {
			maxTimeDatumPrijatiaZiadosti = dayjs().endOf('day')
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					<div className='pull-right'>
						<button
							className={cx('button', 'pull-right', { disabled: invalid })}
							disabled={invalid}
							type='submit'
							data-color='blue'
							style={{ marginLeft: '20px' }}
						>
							{t('translation:Common.Pokračovať')}
						</button>
					</div>
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('translation:Common.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<button onClick={onCancelClick} type='button' className='button pull-right' data-type='outline' data-color='red'>
						{t('translation:Common.Zrušiť')}
					</button>
				</div>
				<div className='content-wrapper'>
					<div className='row'>
						<div className='col-12'>
							<div className='box'>
								<div className='box-content'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<thead>
											<tr>
												<th />
												<th>{t('translation:Common.Aktuálne hodnoty')}</th>
												<th>{t('translation:Common.Nové hodnoty')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:Common.Vstup do úkonu')}</strong>
												</td>
												<td />
												<td>
													<Field
														name='ukonVstup'
														component={SelectField}
														options={ukonVstupOptions}
														isSearchable={false}
														isDisabled={isEmpty(ukonVstupOptions)}
														classNamePrefix='react-select'
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Splnomocnenec')}</strong>
												</td>
												<td />
												<td>
													<Field
														name='splnomocnenec'
														component={TextInputField}
														placeholder={t('translation:Common.Zadajte titul, meno a priezvisko splnomocnenca')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Miesto podpisu úkonu')}</strong>
												</td>
												<td>{get(originalValues, 'podpisMiesto')}</td>
												<td>
													<Field
														name='podpisMiesto'
														component={TextInputField}
														placeholder={t(
															'translation:Common.Zadajte miesto podpisu úkonu (ak nejde o prípad odloženého podpisu)'
														)}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Dátum prijatia žiadosti')}</strong>
												</td>
												<td />
												<td>
													<div className='select-wrapper'>
														<Field
															name='datumPrijatiaZiadosti'
															component={DatePickerField}
															showYearDropdown
															scrollableYearDropdown
															minDate={minTimeDatumPrijatiaZiadosti?.toDate()}
															maxDate={maxTimeDatumPrijatiaZiadosti?.toDate()}
															placeholderText={t('translation:Common.Zvoľte dátum')}
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>

									{/* Osobné údaje */}
									<div className='inner-box'>
										<table className='content-table padded bordered' cellSpacing='0'>
											<tbody>
												<tr>
													<td>
														<strong>{t('translation:Common.Meno')}</strong>
													</td>
													<td>{get(originalValues, 'meno')}</td>
													<td>
														<div className='input-wrapper'>
															<Field name='meno' component={TextInputField} placeholder={t('translation:Common.Meno')} />
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<strong>{t('translation:Common.Priezvisko')}</strong>
													</td>
													<td>{get(originalValues, 'priezvisko')}</td>
													<td>
														<div className='input-wrapper'>
															<Field
																name='priezvisko'
																component={TextInputField}
																placeholder={t('translation:Common.Priezvisko')}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<strong>{t('translation:Common.Titul pred menom')}</strong>
													</td>
													<td>
														<div className='row'>
															<div className='col-6'>{get(originalValues, 'titulPred1.hodnota.nazov')}</div>
															<div className='col-6'>{get(originalValues, 'titulPred2.hodnota.nazov')}</div>
														</div>
													</td>
													<td>
														<div className='row'>
															<div className='col-6'>
																<Field
																	name='titulPred1'
																	component={SelectField}
																	options={titulyPredMenomOptions}
																	isClearable={!titulPred2Focus}
																	isDisabled={isEmpty(titulyPredMenomOptions)}
																	classNamePrefix='react-select'
																/>
															</div>
															<div className='col-6'>
																<Field
																	name='titulPred2'
																	component={SelectField}
																	options={titulyPredMenomOptions}
																	isClearable
																	isDisabled={isEmpty(titulyPredMenomOptions) || !get(formValues, 'titulPred1')}
																	classNamePrefix='react-select'
																	onMenuOpen={() => this.setState({ titulPred2Focus: true })}
																	onMenuClose={() => this.setState({ titulPred2Focus: false })}
																/>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<strong>{t('translation:Common.Titul za menom')}</strong>
													</td>
													<td>{get(originalValues, 'titulZa.hodnota.nazov')}</td>
													<td>
														<Field
															name='titulZa'
															component={SelectField}
															options={titulyZaMenomOptions}
															isClearable
															isDisabled={isEmpty(titulyZaMenomOptions)}
															classNamePrefix='react-select'
														/>
													</td>
												</tr>
												<tr>
													<td>
														<strong>{t('translation:Common.Dátum narodenia')}</strong>
													</td>
													<td>{formatDate(get(originalValues, 'narodenieDatum'), null, 'DD.MM.YYYY')}</td>
													<td>
														<Field
															name='narodenieDatum'
															component={DatePickerField}
															showYearDropdown
															dateFormatCalendar='MMMM'
															scrollableYearDropdown
															yearDropdownItemNumber={80}
															isClearable
															minDate={dayjs().add(-120, 'years').toDate()}
															maxDate={dayjs().add(-18, 'years').toDate()}
															placeholderText={t('translation:Common.Zvoľte dátum narodenia')}
														/>
													</td>
												</tr>
												<ContactPhoneField
													field='kontaktnyTelefon'
													change={change}
													dispatch={dispatch}
													editMode={EDIT_MODE.EDIT}
													columnsCount={COLUMNS_COUNT.THREE}
													originalValue={get(originalValues, 'kontaktnyTelefon')}
													aktualnostTelefon={get(formValues, 'aktualnostTelefon')}
													value={get(formValues, 'kontaktnyTelefon')}
													originalSuhlas={get(originalValues, 'suhlasKontaktnyTelefon')}
													newSuhlas={get(formValues, 'suhlasKontaktnyTelefon')}
													onChangeSuhlas={(suhlas) => {
														// NOTE: set only nesuhlas
														if (suhlas?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
															this.handleChangeSuhlas(suhlas, 'suhlasKontaktnyTelefon')
														} else {
															// NOTE: set null if is not nesuhlas
															this.handleChangeSuhlas(null, 'suhlasKontaktnyTelefon')
														}
														if (
															suhlas?.hodnota === SUHLAS_HODNOTA.SUHLAS &&
															get(suhlas, 'platnostOd') !== get(formValues, 'aktualnostTelefon')
														) {
															this.handleChangeSuhlas(get(suhlas, 'platnostOd'), 'aktualnostTelefon')
														}
														if (suhlas === null) {
															this.handleChangeSuhlas(null, 'aktualnostTelefon')
														}
													}}
												/>
												<ContactEmailField
													field='kontaktnyEmail'
													change={change}
													dispatch={dispatch}
													editMode={EDIT_MODE.EDIT}
													columnsCount={COLUMNS_COUNT.THREE}
													originalValue={get(originalValues, 'kontaktnyEmail')}
													value={get(formValues, 'kontaktnyEmail')}
													aktualnostEmail={get(formValues, 'aktualnostEmail')}
													originalSuhlas={get(originalValues, 'suhlasKontaktnyEmail')}
													newSuhlas={get(formValues, 'suhlasKontaktnyEmail')}
													onChangeSuhlas={(suhlas) => {
														// NOTE: set only nesuhlas
														if (suhlas?.hodnota === SUHLAS_HODNOTA.NAMIETKA) {
															this.handleChangeSuhlas(suhlas, 'suhlasKontaktnyEmail')
														} else {
															// NOTE: set null if is not nesuhlas
															this.handleChangeSuhlas(null, 'suhlasKontaktnyEmail')
														}
														if (
															suhlas?.hodnota === SUHLAS_HODNOTA.SUHLAS &&
															get(suhlas, 'platnostOd') !== get(formValues, 'aktualnostEmail')
														) {
															this.handleChangeSuhlas(get(suhlas, 'platnostOd'), 'aktualnostEmail')
														}
														if (suhlas === null) {
															this.handleChangeSuhlas(null, 'aktualnostEmail')
														}
													}}
													uplatnitEfaktury={get(formValues, 'uplatnitEfaktury')}
													disabledEfakturyComponent={
														isEmpty(get(formValues, 'kontaktnyEmail')) || !isEmpty(get(synchronousError, 'kontaktnyEmail'))
													}
												/>
												<tr
													className={cx({
														'field-has-invalid-pattern': !isConfirmValid(get(formValues, 'aktualnostAdresaZakaznika'))
													})}
												>
													<td>
														<strong>{t('translation:Common.Adresa trvalého pobytu')}</strong>
													</td>
													<td>{formatAddress(get(originalValues, 'adresaZakaznika'))}</td>
													<td>
														<Fields
															names={['adresaZakaznika.id', 'adresaZakaznika.globalnaZmena']}
															selector={'adresaZakaznika'}
															component={AddressField}
															disableGlobalnaZmenaChecbox={isDisabledGlobalZmenaAdresaZakaznika}
															create
															edit
															globalnaZmenaLabel={t('translation:ObchodnyPartner.Uplatniť zmenu tejto adresy v celom OP a ZÚ')}
															originalValue={get(originalValues, 'adresaZakaznika')}
															newConfirm={get(formValues, 'aktualnostAdresaZakaznika')}
															onChangeConfirm={(suhlas, isGlobalnaZmena = false) => {
																const propertyNames = isGlobalnaZmena
																	? ['aktualnostAdresaZakaznika', 'aktualnostAdresa']
																	: ['aktualnostAdresaZakaznika']

																this.handleAddressConfirm(suhlas, propertyNames)
															}}
														/>
													</td>
												</tr>
												<tr className={cx({ 'field-has-invalid-pattern': !isConfirmValid(get(formValues, 'aktualnostAdresa')) })}>
													<td>
														<strong>{t('translation:Common.Korešpondenčná adresa')}</strong>
													</td>
													<td>
														<span style={{ position: 'relative', paddingRight: '20px' }}>
															{get(originalValues, 'adresaKorespondencnaSection')}
															{get(originalValues, 'adresaKorespondencnaSectionInherit') && (
																<Tooltip
																	html={
																		<>
																			<span>{t('translation:Common.Odvodená adresa z')}</span>
																			<br />
																			<span>
																				<strong>{get(originalValues, 'adresaKorespondencnaSectionInherit')}</strong>
																			</span>
																		</>
																	}
																	position='bottom'
																	trigger='mouseenter'
																	theme='light'
																>
																	<div className='info-icon' />
																</Tooltip>
															)}
														</span>
													</td>
													<td>
														{!get(formValues, 'adresaZakaznika.globalnaZmena') ? (
															<Fields
																names={['adresaKorespondencna.id', 'adresaKorespondencna.globalnaZmena']}
																create
																edit
																refuseEdit={refuseEdit}
																selector={'adresaKorespondencna'}
																component={AddressField}
																disableGlobalnaZmenaChecbox={isDisabledGlobalZmenaAdresaKorespondencna}
																disabled={get(formValues, 'adresaZakaznika.globalnaZmena')}
																globalnaZmenaLabel={
																	get(formValues, 'adresaKorespondencna')
																		? t('translation:ObchodnyPartner.Uplatniť zmenu tejto adresy v celom OP')
																		: undefined
																}
																originalValue={get(originalValues, 'adresaKorespondencna')}
																newConfirm={get(formValues, 'aktualnostAdresa')}
																onChangeConfirm={(suhlas) => this.handleAddressConfirm(suhlas, ['aktualnostAdresa'])}
															/>
														) : (
															formatAddress(adresaZakaznika)
														)}
													</td>
												</tr>
												<tr>
													<td>
														<strong>{t('translation:Common.Deň splatnosti preferovaný')}</strong>
													</td>
													<td>{get(originalValues, 'denSplatnostiPreferovany')}</td>
													<td>
														<Field
															name='denSplatnostiPreferovany'
															component={SelectField}
															options={denSplatnostiPreferovanyOptions}
															isClearable
															isSearchable={false}
															isDisabled={isEmpty(denSplatnostiPreferovanyOptions)}
															classNamePrefix='react-select'
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											<tr>
												<td>
													<strong>{t('translation:Common.Poznámka')}</strong>
												</td>
												<td />
												<td>
													<Field
														name='poznamka'
														component={TextareaField}
														rows='3'
														placeholder={t('translation:Common.Zadajte poznámku')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('translation:Common.Vstupné dokumenty k úkonu')}</strong>
												</td>
												<td />
												<td>
													<Field
														name='dokumenty'
														onDelete={onDeleteFile}
														component={DropZoneField}
														placeholder={t('translation:Common.Klikni alebo presuň súbory pre nahratie')}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.OBCHODNY_PARTNER_EDITACIA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate,
	warn
})(ObchodnyPartnerEditInfo)

const mapStateToProps = (state) => ({
	synchronousError: getFormSyncErrors(FORMS.OBCHODNY_PARTNER_EDITACIA)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
