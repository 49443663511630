import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { get, upperFirst } from 'lodash'

// atoms
import OptionsPickerField from '../../../atoms/OptionsPickerField'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class UkonceniaZDDTOField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		typyEvidencie: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && (value === null || value === undefined)) {
			return t('translation:Common.validate.Ukončenie z dôvodu zmeny dodávateľa je povinný')
		}
	}

	render() {
		const { field, editMode, t, value } = this.props

		const options = [
			{
				value: true,
				label: t('translation:Common.Áno')
			},
			{
				value: false,
				label: t('translation:Common.Nie')
			}
		]

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Ukončenie z dôvodu zmeny dodávateľa')}</strong>
							</td>
							<td />
							<td>
								{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
									<Field
										name={field}
										compare={(option, val) => {
											return get(option, 'value') == val && value !== null && value !== undefined
										}}
										component={OptionsPickerField}
										options={options}
										validate={this.validate}
										onlyValue
									/>
								)}
								{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && upperFirst(get(value, 'nazov'))}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}
export default compose(withTranslation('components'))(UkonceniaZDDTOField)
